import { useEffect, useState } from "react"

export default function OrderTracker({status}) {
    const [level, setLevel] = useState(1);

    useEffect(() => {
        switch(status){
            case 'Completed' : setLevel(5); break;
            case 'Delivered' : setLevel(4); break;
            case 'Shipped'   : setLevel(3); break;
            case 'Processing': setLevel(2); break;
            default          : setLevel(1); break;
        }
    }, [status]);


    return (
        <div className="py-2 overflow-auto text-easy">
            <div className="d-flex justify-content-between align-items-center">
                <div className={`m-0 px-2 py-1 border rounded-pill font-xm ${level >= 1 ? 'bg-warning bg-opacity-10 border-warning': 'bg-light bg-opacity-25 border-light'}`}>Pending</div>
                <hr className={`w-100 border border-1 ${level >= 2 ? 'border-warning' : ''}`} />
                <div className={`m-0 px-2 py-1 border rounded-pill font-xm ${level >= 2 ? 'bg-warning bg-opacity-10 border-warning': 'bg-light bg-opacity-25 border-light'}`}>Processing</div>
                <hr className={`w-100 border border-1 ${level >= 3 ? 'border-warning' : ''}`} />
                <div className={`m-0 px-2 py-1 border rounded-pill font-xm ${level >= 3 ? 'bg-warning bg-opacity-10 border-warning': 'bg-light bg-opacity-25 border-light'}`}>Shipped</div>
                <hr className={`w-100 border border-1 ${level >= 4 ? 'border-warning' : ''}`} />
                <div className={`m-0 px-2 py-1 border rounded-pill font-xm ${level >= 4 ? 'bg-warning bg-opacity-10 border-warning': 'bg-light bg-opacity-25 border-light'}`}>Delivered</div>
                <hr className={`w-100 border border-1 ${level >= 5 ? 'border-warning' : ''}`} />
                <div className={`m-0 px-2 py-1 border rounded-pill font-xm ${level >= 5 ? 'bg-warning bg-opacity-10 border-warning': 'bg-light bg-opacity-25 border-light'}`}>Completed</div>
            </div>
        </div>
    )
}
