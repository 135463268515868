import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../../../../components/Avatar";
import DropZone from "../../../../components/DropZone";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function AmbulancePhoto({ambulance}) {
    const token = useSelector(selectToken);

    const [isLoading, setIsLoading]         = useState(false);
    const [photoIsLoaded, setPhotoIsLoaded] = useState(false);
    const [photo, setPhoto]                 = useState('');
    const [photoPath, setPhotoPath]         = useState('');
    const [photoError, setPhotoError]       = useState(null);

    function onChangePhoto(files) {
        setPhoto(files[0]);
        setPhotoIsLoaded(true);
    }

    function handlePhotoSave(e){
        e.preventDefault();
        setIsLoading(true);

        const data = new FormData();
        data.append('photo', photo);

        api(token).post('/api/ambulance/update/ambulance-photo', data)
        .then(response => {
            setIsLoading(false);
            setPhotoIsLoaded(false);
            setPhotoError(null);
            toast('Photo successfully updated.', 'success');
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors){
                error.errors.photo ? setPhotoError(error.errors.photo[0]) : setPhotoError(null);
            }
        });
        
    }

    useEffect(() => {
        if(photo) setPhotoPath(URL.createObjectURL(photo));
        else setPhotoPath(process.env.REACT_APP_STORAGE + ambulance.photo)
        return () => {}
    }, [ambulance, photo, photoIsLoaded])


    return (
        <div className="py-2">
            <h6 className=" bg-primary bg-opacity-10 shadow-sm rounded p-2 my-2">Ambulance photo</h6>

            <div className="row justify-content-between">
                <div className="col-5 col-md-4 col-lg-3">
                    <div className="">
                        <Avatar avatarPath={photoPath} sizeClass='my-lg-avatar' rounded='rounded m-3 border'/>
                        {/* <img className="img-thumbnail" src={photoPath} alt="" width="150px" /> */}
                    </div>
                </div>
                <div className="col-7 col-md-8 col-lg-9">
                    <form onSubmit={handlePhotoSave} className="needs-validation" noValidate encType="multipart/form-data">
                        <div className="mb-1">
                            <label className="pb-2" htmlFor="photo">Change photo <span className="small text-info">(PNG/JPG/JPEG)</span></label>
                            <DropZone callbackSetFiles={onChangePhoto}
                                classList="bg-light p-3 p-md-4 text-center border-dashed rounded" 
                                text="Drag and drop Photo here, or click to select"
                                fileClass=""
                                isPreview={false}
                                isInvalid={photoError}
                                errorText={photoError}
                            />
                        </div>

                        <div className="d-flex justify-content-end">
                            {photoIsLoaded && <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

