import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DropZone from "../../../../components/DropZone";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function Signature({doctor}) {
    const token = useSelector(selectToken);

    const [isLoading, setIsLoading]         = useState(false);
    const [signatureIsLoaded, setSignatureIsLoaded] = useState(false);
    const [signature, setSignature]                 = useState('');
    const [signaturePath, setSignaturePath]         = useState('');
    const [singatureError, setSignatureError]       = useState(null);

    function onChangesignature(files) {
        setSignature(files[0]);
        setSignatureIsLoaded(true);
    }

    function handleSignatureSave(e){
        e.preventDefault();
        setIsLoading(true);

        const data = new FormData();
        data.append('signature', signature);

        api(token).post('/api/doctor/update/signature', data)
        .then(response => {
            setIsLoading(false);
            setSignatureIsLoaded(false);
            setSignatureError(null);
            toast('Signature successfully updated.', 'success');
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors){
                error.errors.signature ? setSignatureError(error.errors.signature[0]) : setSignatureError(null);
            }
        });
        
    }

    useEffect(() => {
        if(signature) setSignaturePath(URL.createObjectURL(signature));
        else setSignaturePath(process.env.REACT_APP_STORAGE + doctor.signature)
        return () => {}
    }, [doctor, signature, signatureIsLoaded])


    return (
        <div className="py-2">
            <h6 className=" bg-primary bg-opacity-10 shadow-sm rounded p-2 my-2">Signature</h6>

            <div className="row justify-content-evenly">
                <div className="col-4 col-md-4 col-lg-3">
                    <div className="py-5 py-md-3">
                        <img className="img-thumbnail img-fluid" src={signaturePath} alt="signature" />
                    </div>
                </div>
                <div className="col-8 col-md-6 col-lg-5">
                    <form onSubmit={handleSignatureSave} className="needs-validation" noValidate encType="multipart/form-data">
                        <div className="mb-1">
                            <label className="pb-2" htmlFor="signature">Change signature <span className="small text-info">(PNG/JPG/JPEG)</span></label>
                            <DropZone callbackSetFiles={onChangesignature}
                                classList="bg-light p-3 p-md-4 text-center border-dashed rounded" 
                                text="Drag and drop signature here, or click to select"
                                fileClass=""
                                isPreview={false}
                                isInvalid={singatureError}
                                errorText={singatureError}
                            />
                        </div>

                        <div className="d-flex justify-content-end">
                            {signatureIsLoaded && <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

