import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputClassic from "../../components/form/InputClassic";
import SubmitBtn from "../../components/form/SubmitBtn";
import useScrollPosition from "../../hooks/useScrollPosition";
import api from "../../services/api";
import { selectToken } from "../../slice/userSlice";
import toast from "../../utlities/toast";


const MedicineRequest = () => {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [allError, setAllError] = useState([]);

    const handleSubmitRequest = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const body = {
            name: name,
        };

        api(token).post('/api/auth/medicine-request/store', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            toast(response.data.message, response.data.status);
            history.push('/profile/my-medicine-requests');
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        })
    }

    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">Send Medicine Request</h6>
            </div>
            <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                <form onSubmit={handleSubmitRequest} className="needs-validation" noValidate>
                    <div className="row justify-content-between">
                        <div className="">
                            <InputClassic data={name} setOnChange={setName} error={allError['name']} label="Medicine Name" htmlFor="name" required={true}/>
                        </div>
                    </div>
                
                    <div className="d-flex justify-content-end">
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Send"/>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default MedicineRequest;