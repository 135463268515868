import useScrollTop from "../../hooks/useScrollTop";

export default function PrivacyPolicy() {
    useScrollTop();

    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center fw-bold">Privacy Policy</div>
            </div>

            <div className="bg-white rounded shadow-sm p-2 p-md-4">
                <div>
                    <p className="fw-bold text-secondary">Introduction</p>
                    <div className="text-muted">
                        <p>This Privacy Policy (“Privacy Policy”) is incorporated into our Duare Doctor mobile Apps and Website
                            (“Duare Doctor”). If you have any questions about this Privacy Policy, or any other aspect of Duare
                            Doctor, please contact support@duaredoctor.com</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Duare Doctor Copyright Policy</p>
                    <div className="text-muted">
                        <p>This privacy policy (“Privacy Policy”) applies to your use of the domain name duaredoctor.com.</p>

                        <p>The information we provide at duaredoctor.com is intended to replace consultation with a qualified
                            medical professional. Seek the advice of your physician or another qualified health provider with any
                            questions you may have regarding a medical condition.</p>

                        <p>The content we provide is intended to be relied upon for medical diagnosis or treatment. Inform your
                            physician of any changes you may make to your lifestyle and discuss these with him or her. Do disregard
                            medical advice or delay visiting a medical professional and also something you read on our site, app, or
                            on our other communication channels.</p>

                        <p>You may not use our recipes or membership content unless you have obtained the right to do so from us.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">What Information Duare Doctor Collects And Why Information From Website Browsers</p>
                    <div className="text-muted">
                        <p>We store standard access logs of all visits to the site. This includes access time, page visited, browser
                            information and internet address, which can potentially be personally identifying. We may also store
                            how the user interacts with the site, including clicks, scrolling etc.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Types of personal information collected by Duare Doctor</p>
                    <div className="text-muted">
                        <p>A User may have limited access to the Website and utilize some of the Services provided by Duare
                            Doctor without creating an account on the Website. Unregistered Users can access some of the
                            information and details available on the Website. In order to have access to all the features and benefits
                            on our Website, a User may be required to first create an account on our Website. As part of the
                            registration process, Duare Doctor may collect the following categories of Personal Information from the
                            Users (hereinafter collectively referred to as “User Information”):</p>

                        <p className="fw-bold small">Name;</p>
                        <p className="fw-bold small">User ID;</p>
                        <p className="fw-bold small">Email address;</p>
                        <p className="fw-bold small">Address (including country and ZIP/postal code);</p>
                        <p className="fw-bold small">Gender;</p>
                        <p className="fw-bold small">Age;</p>
                        <p className="fw-bold small">Phone Number;</p>
                        <p className="fw-bold small">Password chosen by the User;</p>
                        <p className="fw-bold small">Valid financial account information; and</p>
                        <p className="fw-bold small">Other details as the User may volunteer.</p>

                        <p>In order to avail the Services, the Users may be required to upload copies of their prescriptions, on the
                            Website and/ or e-mail the same to Duare Doctor in accordance with the Terms of Use and the
                            prescriptions will be stored/ disclosed by Duare Doctor only in the manner specified in this Privacy Policy
                            and the Terms of Use. The term “User Information” shall also include any such prescriptions uploaded or
                            otherwise provided by Users.</p>
                            
                        <p>Duare Doctor may keep records of telephone calls received and made for making inquiries, orders, or
                            other purposes for the purpose of administration of Services.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Why Do We Collect This?</p>
                    <div className="text-muted">
                        <p>In connection with providing you with Duare Doctor, we and our affiliates and service providers may use
                            your Information for a number of purposes, including, but not limited to:</p>

                        <ul className=" text-justify font-weight-light">
                            <li>verifying your identity, processing your payments and fulfilling your request/orders;</li>

                            <li>communicating with you about Duare Doctor or your use of Duare Doctor, and sending you
                                communications on behalf of your Providers;</li>

                            <li>ensuring quality customer service by providing you with customer support, responding to your
                                requests or concerns, ensuring that Duare Doctor functions properly for you and tailoring our Duare
                                Doctor and its features to meet your needs;</li>

                            <li>facilitating the provision of healthcare services to you by your Providers, and ensuring your Providers
                                provide the services and support necessary for healthcare operations;</li>

                            <li>sending you push notifications (notifications may be enabled or disabled through your device or
                                application or website settings depending on your device type);</li>

                            <li>detecting, preventing, investigating and responding to fraud, intellectual property infringement,
                                violations of terms and conditions, or other misuse of Digital Hospital or a Provider's services;</li>
                            
                            <li>sending Internet content and SMS and voice messages on general health as well as specialized health topics;</li>

                            <li>enhancing the experience of Duare Doctor which may include analysis of your data and application of
                                algorithms to provide assistance such as relevant recommendations, advice and new products;</li>
                            
                            <li>providing certain marketing communications or promotional materials relating to Duare Doctor that
                                may be of interest to you; and</li>

                            <li>understanding how you interact within Duare Doctor and any of our websites or applications to
                                which Duare Doctor links or third party websites that link you to Duare Doctor.</li>
                        </ul>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Sharing Of Information With Third Parties</p>
                    <div className="text-muted">
                        <p>Duare Doctor will not share your personal information with any third party unless absolutely required to
                            do so by law, or unless required for those service providers we use to operate and improve Duare
                            Docotor.</p>

                        <p>Service providers handle services such as payment processing, spam detection, network data
                            transmission, and other similar services.</p>

                        <p>We remain responsible for your information when shared with our service providers, and it will only be
                            used and stored for the purposes detailed in this document.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Changes in the privacy policy</p>
                    <div className="text-muted">
                        <p>Duare Doctor may update this Privacy Policy at any time, with or without advance notice. In the event
                            there are significant changes in the way Duare Doctor treats User&#39;s Personal Information, Arogga will
                            display a notice on the Website or send Users an email. If a User uses the Service after notice of changes
                            have been sent to such User or published on the Website, such User hereby provides his/ her/ its
                            consent to the changed practices.</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}


