import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useFormInput from "../../hooks/useFormInput";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import api from '../../services/api';
import toast from '../../utlities/toast';
import useScrollTop from '../../hooks/useScrollTop';
import useQuery from '../../hooks/useQuery';
import { useDispatch } from 'react-redux';
import { login, setUser } from '../../slice/userSlice';


export default function Register() {
    useScrollTop();
    const dispatch = useDispatch();

    const query = useQuery();
    const history = useHistory();
    // const location = useLocation();
    // let { from } = location.state || { from: { pathname: "/" } };

    const name                  = useFormInput('');
    const email                 = useFormInput('');
    const phone                 = useFormInput('');
    const parent_referral_code  = useFormInput(query.get('ddrefc') ?? '');
    const password              = useFormInput('');
    const password_confirmation = useFormInput('');

    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    function handleRegister(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            name:                  name.value,
            email:                 email.value, 
            phone:                 phone.value, 
            parent_referral_code:  parent_referral_code.value, 
            password:              password.value, 
            password_confirmation: password_confirmation.value,
        };

        api().post('/api/auth/register', body)
        .then(response => {

            setIsLoading(false);
            setAllError([]);

            if(response.data.need_verification){
                toast(response.data.message, 'success');
                history.push('/email/verify');
            }
            else {
                dispatch(login(response.data.token));
                dispatch(setUser(response.data.user));
                toast(response.data.message, 'success');
                history.replace('/');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }


    return (
        <div className="py-5">
            <div className="d-flex justify-content-center">
                <div className="col-11 col-md-8 col-lg-6">
                    <div className="bg-white p-2 p-md-3 shadow rounded">
                        <h5 className="text-center text-secondary border-3 border-bottom border-master p-2">REGISTER</h5>

                        {/* <SocialLogin from={from}/> */}
                        <div className="pt-2 pt-md-4"></div>
                        
                        <form onSubmit={handleRegister} method="POST" >
                            <div className="input-group has-validation mb-4">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-person bi-m text-secondary"></i>
                                </span>
                                <input {...name}
                                    type="text" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${allError['name'] ? 'is-invalid border-danger' : ''}`} 
                                    id="name"
                                    name="name"  
                                    placeholder="Full Name" 
                                    autoComplete="off"/>
                                {allError['name'] && <div className="invalid-tooltip">{allError['name']}</div>}
                            </div>

                            <div className="input-group has-validation mb-4">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-envelope bi-m text-secondary"></i>
                                </span>
                                <input {...email}
                                    type="email" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${allError['email'] ? 'is-invalid border-danger' : ''}`} 
                                    id="email"
                                    name="email"  
                                    placeholder="your.email@gmail.com" 
                                    autoComplete="off"/>
                                {allError['email'] && <div className="invalid-tooltip">{allError['email']}</div>}
                            </div>

                            <div className="input-group has-validation mb-4">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-telephone bi-m text-secondary"></i>
                                </span>
                                <input {...phone}
                                    type="tel" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${allError['phone'] ? 'is-invalid border-danger' : ''}`} 
                                    id="phone" 
                                    name="phone"  
                                    placeholder="Mobile no" 
                                    autoComplete="off"/>
                               {allError['phone'] && <div className="invalid-tooltip">{allError['phone']}</div>}
                            </div>

                            <div className="input-group has-validation mb-4">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-file-lock bi-m text-secondary"></i>
                                </span>
                                <input {...password}
                                    type="password" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${allError['password'] ? 'is-invalid border-danger' : ''}`} 
                                    id="password" 
                                    name="password"  
                                    placeholder="Password" 
                                    autoComplete="off"/>
                                {allError['password'] && <div className="invalid-tooltip">{allError['password']}</div>}
                            </div>

                            <div className="input-group has-validation mb-3">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-file-lock bi-m text-secondary"></i>
                                    </span>
                                <input {...password_confirmation}
                                    type="password" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none`} 
                                    id="password_confirmation" 
                                    name="password_confirmation"  
                                    placeholder="Confirm Password" 
                                    autoComplete="off"/>
                            </div>

                            <div className="pt-3">
                                <div className="bg-light p-2 rounded">
                                    <h6 className='text-info small m-0'>Referral code if you have any, otherwise leave it blank</h6>
                                </div>
                                <div className="input-group has-validation mb-4">
                                    <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                        <i className="bi bi-broadcast bi-m text-secondary"></i>
                                    </span>
                                    <input {...parent_referral_code}
                                        type="text" 
                                        className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${allError['parent_referral_code'] ? 'is-invalid border-danger' : ''}`} 
                                        id="parent_referral_code" 
                                        name="parent_referral_code"  
                                        placeholder="Referral code" 
                                        autoComplete="off"/>
                                {allError['parent_referral_code'] && <div className="invalid-tooltip">{allError['parent_referral_code']}</div>}
                                </div>
                            </div>

                            <div className="d-grid">
                                <button className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`} type="submit">
                                    <div className="d-flex justify-content-center">
                                        <div>REGISTER </div> 
                                        {isLoading && <BtnLoader/>}
                                    </div>
                                </button>
                            </div>
                        </form>

                        <div className="pt-1">
                            <p className="text-secondary text-center">Already have an account? <Link to="/login" className="text-decoration-none text-primary fw-bolder">Login Now!</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
