import { useState } from "react";
import { useSelector } from "react-redux";
import InputSelect from "../../../../components/form/InputSelect";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function ExperienceEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const experience = useFormInput(doctor.experience ?? '');

    function handleExperienceSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            experience : experience.value,
        };

        api(token).post('/api/doctor/update/experience', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor){
                updateDoctor(response.data.doctor);
            }
            toast('Experience successfully updated', 'success');
            setAllError([]);
            cancelEdit(false);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleExperienceSubmit}>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <InputSelect data={experience} error={allError['experience']} label="Experience" htmlFor="experience" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Experience</option>
                                    {Array.from(Array(31)).map((year, index) => (
                                        <option key={index} value={index}>{index < 1 ? 'Less than 1': index} year{index > 1 ? 's':''}</option>
                                    ))}
                                </>}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}