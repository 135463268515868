import { useEffect } from 'react';
import { useState } from 'react';

export default function ZoomImage({image_path, zoom = 100}) {

    const [bgStyle, setBgStyle] = useState({
        backgroundImage: `url(${image_path})`,
        backgroundPosition: '0% 0%',
        backgroundSize: `${zoom}%`,
    })

    useEffect(() => {
        setBgStyle(prev => ({...prev, backgroundImage: `url(${image_path})`}));
    }, [image_path]);
      
    function handleMouseMove(e) {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100;
        const y = (e.pageY - top) / height * 100;
        setBgStyle(prev => ({...prev, backgroundPosition: `${x}% ${y}%`}));
    }
    

    return (
        <figure className="zoom-img-container m-0" onMouseMove={handleMouseMove} style={bgStyle}>
            <img src={image_path} alt="" className="img-fluid zoom-img w-100" />
        </figure>
    )
}
