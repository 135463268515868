import { useState } from "react";
import { useSelector } from "react-redux";
import BoxEmpty from "../../../components/BoxEmpty";
import ItemLoader from "../../../components/loader/item_loder/ItemLoader";
import MostSimplePaginator from "../../../components/pagination/MostSimplePaginator";
import useItems from "../../../hooks/useItems";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { selectToken } from "../../../slice/userSlice";
import AppointmentItem from "./AppointmentItem";

export default function AppointmentList() {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/nurse/appointment/appointments', token, nextPage);

    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm border-top rounded-top border-3 border-master">
                <h6 className="m-0">Appointment list</h6>
            </div>

            <div>
                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-5" />
                        </div>
                    </div>
                ))}

                {item_data.length>0 && item_data.map(appointment => (
                    <AppointmentItem key={appointment.id} appointment={appointment} />
                ))}

                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-2">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>
        </div>
    )
}
