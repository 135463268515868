import { Link } from "react-router-dom";
import useScrollTop from "../hooks/useScrollTop";

export default function NotFound404() {
    useScrollTop();
    return (
        <section>
            <div className="container">
                <div className="" style={{paddingTop: "20%", paddingBottom: "20%",}}>
                    <h5 className="text-center text-muted">404 | Not Found</h5>
                    <div className="text-center py-3">
                        <Link to="/" className="btn-master px-4 py-2 rounded">Go Home</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
