import Avatar from "./Avatar";

export default function AmbulanceList({ambulance, next_arrow, locationCharLimit=100, hideRating = false}) {
    return (
        <>
            <div className={`${next_arrow ? 'col-md-6 col-lg-5' : 'col-md-7'}`}>
                <div className="d-flex justify-content-start">
                    <div className="py-2">
                        <Avatar avatarPath={process.env.REACT_APP_STORAGE + ambulance.photo} sizeClass='my-lg-avatar' rounded='rounded'/>
                        {/* <img className="img-fluid img-thumbnail p-1" width="180px" height="180px" src={process.env.REACT_APP_STORAGE + ambulance.photo} alt={ambulance.user.name} /> */}
                    </div>
                    <div className="p-2 w-100">
                        <h6 className="fw-bold text-dark mb-2">{ambulance.user.name}</h6>
                        <h6 className="small fw-light text-dark">Condition: {ambulance.condition}</h6>
                        <h6 className="small text-secondary my-1">{ambulance.district?.name},{ambulance.sub_district?.name}</h6>
                        <h6 className="small text-secondary my-0">Start area: <span className="fw-light">{ambulance.starting_area.substring(0,locationCharLimit)}</span></h6>
                    </div>
                </div>
            </div>
            <div className={`${next_arrow ? 'col-md-3 col-lg-4' : 'col-md-5'}  border-start border-light`}>
                <div className="p-2">
                    <div className="flex">
                        <div className="mb-2">
                            <p className="text-secondary small my-0">Owner: <span className="text-dark fw-bold">{ambulance.owner_name}</span></p>
                            <p className="text-secondary small my-0">Driver: <span className="text-dark fw-bold">{ambulance.driver_name}</span></p>
                        </div>
                        {!hideRating && (
                            <div>
                                <p className="text-secondary small my-0">Total Reviews ({ambulance.reviews_count})</p>
                                <div className="d-flex">
                                    {Array.from(Array(5)).map((item, index) => (
                                        <i key={index} className={`bi bi-star${parseInt(ambulance.reviews_avg_rating ?? 0) > index ? '-fill':''} text-warning`}></i>
                                    ))}
                                    <p className="my-0 px-2 text-dark fw-bold">{parseFloat(ambulance.reviews_avg_rating ?? 0).toFixed(1)}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {next_arrow && <div className="col-md-3 col-lg-3 bg-light">
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <div><i className="bi bi-arrow-right icon-xlg"></i></div>
                </div>
            </div>}
        </>
    )
}
