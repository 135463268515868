import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { selectCart, set_cart } from "../slice/cartSlice"
import useScrollTop from "../hooks/useScrollTop";
import { selectToken, selectUser } from "../slice/userSlice";
import api from "../services/api";
import { isEmpty } from "lodash";
import ItemLoader from "../components/loader/item_loder/ItemLoader";
import SubmitBtn from "../components/form/SubmitBtn";
import Swal from "sweetalert2";
import toast from "../utlities/toast";
import MyCF from "../components/MyCF";


export default function CartCheckout() {
    useScrollTop();

    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    const history = useHistory();
    const dispatch = useDispatch();
    const [shippingFee, setShippingFee] = useState(30);
    const cart_content = useSelector(selectCart);
    const [subTotal, setSubTotal] = useState(0);
    const [payment_method, setPayment_method] = useState(1)

    const [shipping_address, setShipping_address] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingShippingAddress, setIsLoadingShippingAddress] = useState(true);

    const [couponIsLoading, setCouponIsLoading] = useState(false);
    const [code, setCode] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);

    const [allError, setAllError] = useState([]);

    useEffect(() => {
        if(cart_content.length < 1){
            history.push('/shop');
        }
    }, [cart_content, history]);

    useEffect(() => {
        var total = 0;
        cart_content.forEach(item => {
            total += item.final_price * item.c_qty;
        });
        setSubTotal(total);
    }, [cart_content]);

    useEffect(() => {
        api(token).get('/api/auth/get-shipping-address')
        .then(response => {
            setShipping_address(response.data.shipping_address);
            setShippingFee(response.data.shipping_fee);
            setIsLoadingShippingAddress(false);
        })
        .catch(err => {
            setIsLoadingShippingAddress(false);
        })
        return () => {setShipping_address([])}
    }, [token]);

    function handleApplyCoupon(e){
        e.preventDefault();
        setCouponIsLoading(true);

        const body = {
            code      : code,
            min_spend : subTotal,
        }

        api(token).post('/api/cart/coupon/status', body)
        .then(response => {
            setCouponIsLoading(false);
            setAllError([]);
            if(response.data.discount){
                setCouponDiscount(response.data.discount);
                Swal.fire('Congratulations', `You get ${response.data.discount} tk coupon discount`, 'success');
            }
            else{
                setCouponDiscount(0);
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setCouponIsLoading(false);
            setCouponDiscount(0);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        })
    }


    function handleProceedToPay(e){
        e.preventDefault();
        if(isEmpty(shipping_address)){
            Swal.fire('We did not found your shipping address.','Please first add your shipping address','warning');
            return;
        }
        
        setIsLoading(true);

        const cart_contents = [];
        cart_content.forEach(item => {
            cart_contents.push({'id': item.id, 'qty': item.c_qty});
        });

        const body = {
            cart_contents : cart_contents,
            payment_method: payment_method,
            code          : code,
        };

        api(token).post(`/api/cart/order/store`, body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            
            if(response.data.status !== 'success'){
                Swal.fire('Something worng!','Please try again.','error');
                setIsLoading(false);
                return;
            }

            if(response.data.need_payment){
                Swal.fire({
                    title: 'Please wait...',
                    text: "Don't refresh or close the page.",
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
                Swal.showLoading();

                window.location.replace(process.env.REACT_APP_API_URL + '/payment/payment-submit/' + response.data.need_payment_id);
                dispatch(set_cart([]));
                return;
            }
            else {
                Swal.fire('Your order sussessfully submited','','success');
                history.push('/profile/my-order');
                dispatch(set_cart([]));
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        })
    }


    return (
        <section className="bg-light">
            <div className="container py-4">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-8">
                        <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-master">
                            <h6 className="m-0">Proceed to pay</h6>
                        </div>
                        <div className="mt-3 p-1 p-md-2 p-md-3 bg-white shadow-sm">
                            <div className="border p-1 p-md-2 rounded">
                                <div className="d-flex justify-content-between">
                                    <h6 className="m-0">Shipping informatin</h6>
                                    {!isEmpty(shipping_address) && <Link to="/profile/my-shipping-address?_reb=checkout" className="btn btn-sm btn-master px-3 btn-outline-none">Edit</Link>}
                                </div>
                                {isLoadingShippingAddress ? <div>
                                    <ItemLoader classList="py-5 my-2 shadow-sm"/>
                                </div>
                                :
                                <div>
                                    {!isEmpty(shipping_address) ? <div className="bg-light rounded p-2 mt-1">
                                        <h6 className="small"> <span className="text-master"><i className="bi bi-geo-alt-fill"></i></span> {shipping_address.name || user.name}</h6>
                                        <div className="d-flex">
                                            <h6 className="bg-info bg-opacity-25 rounded p-1 m-0 mx-2 h-100">{shipping_address.effective_delivery}</h6>
                                            <p className="m-0 align-self-center"> {shipping_address?.country?.name}, {shipping_address?.district?.name}, {shipping_address?.sub_district?.name}</p>
                                        </div>
                                    </div>
                                    :
                                    <div className="bg-light rounded p-2 mt-1 text-center">
                                        <h6 className="small text-info">We didn't get your shipping address. Please first add your shipping address.</h6>
                                        <Link to="/profile/my-shipping-address?_reb=checkout" className="btn btn-sm btn-master px-3 btn-outline-none">Add shipping address</Link>
                                    </div>}
                                </div>}
                            </div>

                            <div className="border p-1 p-md-2 rounded mt-4">
                                <h6>Discount coupon</h6>
                                <form onSubmit={handleApplyCoupon}>
                                    <div className="input-group my-3">
                                        <input value={code} onChange={e => setCode(e.target.value)} type="text" className="form-control outline-none" placeholder="Coupon code" aria-describedby="coupon-code-btn"/>
                                        <SubmitBtn isLoading={couponIsLoading} className="btn btn-secondary btn-outline-none" title="Apply"/>
                                    </div>
                                    {allError['code'] && <div className="alert alert-danger py-1">{allError['code']}</div>}
                                </form>
                            </div>

                            <div className="border p-1 p-md-2 rounded mt-4">
                                <h6>Select Payment Method</h6>
                                <div className="d-flex justify-content-evenly py-2">
                                    <div onClick={() => setPayment_method(1)}
                                        role="button"
                                        className={`border rounded d-flex ${payment_method === 1 ? 'border-info text-info shadow-sm':''}`}>
                                        <div className="text-center bg-light p-1 p-md-2 rounded">
                                            <div><i className="bi bi-credit-card-2-front icon-huge text-main"></i></div>
                                            <div className="px-2">Online payment</div>
                                        </div>
                                    </div>

                                    <div onClick={() => setPayment_method(2)}
                                        role="button"
                                        className={`border rounded d-flex ${payment_method === 2 ? 'border-primary text-primary shadow-sm':''}`}>
                                        <div className="text-center bg-light p-1 p-md-2 rounded">
                                            <div><i className="bi bi-cash-stack icon-huge text-master"></i></div>
                                            <div className="px-2">Cash on delivery</div>
                                        </div>
                                    </div>
                                </div>
                                {allError['payment_method'] && <div className="alert alert-danger py-1">allError['payment_method']</div>}
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 pt-3 pt-md-0">
                        <div className="sticky-top top-except-nav">
                            <div className="p-2 bg-white shadow-sm rounded-top shadow-sm border-top border-3 border-master">
                                <h6 className="m-0">Price Summary</h6>
                            </div>
                            {cart_content.length > 0 && <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                                <div className="d-flex justify-content-between border-bottom">
                                    <p className="m-0 py-2">Sub Total</p>
                                    <p className="m-0 py-2"><MyCF value={subTotal}/></p>
                                </div>    
                                <div className="d-flex justify-content-between border-bottom text-secondary">
                                    <p className="m-0 py-2">Shipping Fee</p>
                                    <p className="m-0 py-2"><MyCF value={shippingFee}/></p>
                                </div>   
                                <div className="d-flex justify-content-between border-bottom text-secondary">
                                    <p className="m-0 py-2">Coupon discount</p>
                                    <p className="m-0 py-2"><MyCF value={couponDiscount}/></p>
                                </div>   
                                <div className="d-flex justify-content-between border-bottom fw-bold">
                                    <p className="m-0 py-2">Total</p>
                                    <p className="m-0 py-2"><MyCF value={subTotal + shippingFee - couponDiscount}/></p>
                                </div>
                                <form onSubmit={handleProceedToPay}>
                                    <div className="d-grid pt-3">
                                        <SubmitBtn isLoading={isLoading} className="btn-master outline-none" title="PROCEED TO PAY"/>
                                    </div>
                                </form>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5"></div>
        </section>
    )
}
