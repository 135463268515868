import useScrollTop from "../../hooks/useScrollTop";
import useFormInput from "../../hooks/useFormInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../../slice/userSlice";
import api from "../../services/api";
import toast from "../../utlities/toast";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import InputSelect from "../../components/form/InputSelect";
import Input from "../../components/form/Input";

export default function JoinAsNurseStep1() {
    useScrollTop();
    const token   = useSelector(selectToken);
    const history = useHistory();

    const [districtLists, setDistrictLists]   = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);
    
    const country_id      = useFormInput('');
    const district_id     = useFormInput('');
    const sub_district_id = useFormInput('');
    const location        = useFormInput('');
    const experience      = useFormInput('');
    const present_add     = useFormInput('');
    const permanent_add   = useFormInput('');


    useEffect(() => {
        api().get('/api/load-district-with-sub-district')
        .then(response => {
            setDistrictLists(response.data.district_lists);
        })
        .catch(err => { });
        return () => {setDistrictLists([]);};
    }, []);


    function handleStep1Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            country_id     : country_id.value,
            district_id     : district_id.value,
            sub_district_id : sub_district_id.value,
            location        : location.value,
            experience      : experience.value,
            present_add     : present_add.value,
            permanent_add   : permanent_add.value,
        };

        api(token).post('/api/nurse/join/step-1', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            
            if(response.data.icon === 'success'){
                history.replace('/join/nurse/step-2');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Nurse',
                    text: 'If you want to update your Nurse profile, Then click Edit Nurse Profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Nurse Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/nurse');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Nurse [Step 1 of 3]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Personal Information</h6>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                elements={<>
                                    <option disabled className="bg-light text-info fw-bold" value="">Select country</option>
                                    <option value={1}>Bangladesh</option>
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={district_id} error={allError['district_id']} label="District" htmlFor="district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select District</option>
                                    {districtLists?.map(districtList => (
                                        <option key={districtList.id} disabled={districtList.name !== 'Dhaka'} value={districtList.id}>{districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={sub_district_id} error={allError['sub_district_id']} label="Sub District" htmlFor="sub_district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select Sub district</option>
                                    {districtLists?.find(district => district.id === parseInt(district_id.value))?.sub_districts?.map(sub_districtList => (
                                        <option key={sub_districtList.id} value={sub_districtList.id}>{sub_districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <Input data={location} error={allError['location']} label="Location" htmlFor="location" required={true}/>
                        </div>
                        
                        <div className="col-lg-6">
                            <Input data={present_add} error={allError['present_add']} label="Present address" htmlFor="present_add" required={true}/>
                        </div>
                        
                        <div className="col-lg-6">
                            <Input data={permanent_add} error={allError['permanent_add']} label="Permanent address" htmlFor="permanent_add" required={true}/>
                        </div>
                    </div>
                </div>

                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Experience</h6>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <InputSelect data={experience} error={allError['experience']} label="Experience" htmlFor="experience" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Experience</option>
                                    {Array.from(Array(31)).map((year, index) => (
                                        <option key={index} value={index}>{index < 1 ? 'Less than 1': index} year{index > 1 ? 's':''}</option>
                                    ))}
                                </>}
                            />
                        </div>
                    </div>
                </div>
            
                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep1Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}