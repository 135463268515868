import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import Avatar from './Avatar';
import BoxEmpty from './BoxEmpty';
import ItemLoader from './loader/item_loder/ItemLoader';

const PopularDoctor = ({api_url,next_link, next_route}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [doctors, setDoctors] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api().get(api_url)
        .then(response => {
            setDoctors(response.data);
            setIsLoading(false);
        })
        .catch(err => {});
        return () => setDoctors([]);
    }, [api_url]);

    return (
        <section className="bg-light">
            <div className="container py-4">
                <div className="p-2 p-md-3 shadow-sm rounded-sm bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="m-0">Popular Doctors</h5>
                        <Link to={next_link} className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View All Doctor</Link>
                    </div>
                </div>
                <div className="row row-cols-sm-2 row-cols-lg-3 p-1">
                    {isLoading && Array.from(Array(6)).map((item, index) => (
                        <div key={index} className="p-1 p-md-2">
                            <div className="shadow-sm rounded-sm bg-white ">
                                <ItemLoader classList="p-5"/>
                            </div>
                        </div>
                    ))}

                    {doctors?.map((doctor) => (
                        <div key={doctor.id} className="p-1 p-md-2">
                            <div className="card border-0 shadow-sm item-card p-2">
                                <Link to={`${next_route}/${doctor.id}`} className="text-decoration-none">
                                    <div className=" d-flex g-0">
                                        <div className="">
                                            <Avatar avatarPath={process.env.REACT_APP_STORAGE + doctor.user.avatar} sizeClass='my-lg-avatar' rounded='rounded'/>
                                        </div>
                                        <div className="card-body py-0 px-2">
                                            <h6 className="fw-bold text-dark mb-0">{doctor.title} {doctor.user.name} <span className={`${doctor.user?.online_status === 'online' ? 'text-success':'text-muted fw-light'} small`}>{doctor.user?.online_status}</span></h6>
                                            <p className="card-text small text-secondary fw-500 m-0">{doctor.qualifications.map(qualification => qualification.degree).join(', ')}</p>

                                            <div className="d-flex position-absolute bottom-5">
                                                {Array.from(Array(5)).map((i, index) => (
                                                    <i key={index} className={`bi bi-star${parseInt(doctor.reviews_avg_rating ?? 0) > index ? '-fill':''} text-warning`}></i>
                                                ))}
                                                <p className="my-0 px-2 text-dark fw-bold">{parseFloat(doctor.reviews_avg_rating ?? 0).toFixed(1)}</p>
                                                <p className="cart-text text-secondary m-0 px-1">({doctor.reviews_count})</p>
                                            </div>
                                        </div>
                                    </div>

                                </Link>
                            </div>
                        </div>
                    ))}
                    {!isLoading && doctors.length < 1 && (
                        <div className="m-auto">
                            <BoxEmpty/>
                        </div>
                    )}
                </div>

            </div>
        </section>
    )
}

export default PopularDoctor