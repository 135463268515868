import { useHistory } from "react-router-dom";
import toast from "../../../utlities/toast";

export default function EmailVerifyFail() {

    const history = useHistory();

    toast('Email verification fail!', 'error');
    history.push('/');

    return (
        <></>
    )
}
