import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BoxEmpty from "../../components/BoxEmpty";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import useItems from "../../hooks/useItems";
import useScrollPosition from "../../hooks/useScrollPosition";
import { selectToken } from "../../slice/userSlice";

const MyMedicineRequest = () => {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/auth/medicine-request/index', token, nextPage);

    const statusColor = (status) => {
        switch (status) {
            case 'Processing': return 'text-secondary';
            case 'Ready': return 'text-primary';
            default: return 'text-warning';
        }
    }


    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">My Medicine Requests</h6>
            </div>

            <div>
                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-4" />
                        </div>
                    </div>
                ))}

                {item_data.length>0 && item_data.map(item => (
                    <div  key={item.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <p className="text-secondary mb-1">Requested medicine name</p>
                                <p className="mb-1 text-dark">{item.name}</p>
                                <small className="text-info">{moment(item.created_at).format('Do MMMM, YYYY hh:mm A')}</small>
                            </div>
                            <div className="col-6 col-lg-3 border-end border-start">
                                <p className="text-secondary text-center mb-1">Status</p>
                                <p className={`${statusColor(item.status)} text-center mb-1` }>{item.status}</p>
                            </div>
                            <div className="col-6 col-lg-3">
                                <p className="text-secondary text-center mb-1">Action</p>
                                {item.status === 'Ready' && item.product?.slug ?
                                    <div className="text-center">
                                        <Link to={`/product/${item.product.slug}`} className='btn btn-sm btn-success'>Click to View</Link>
                                    </div>:
                                    <div className="text-center">
                                        <button disabled className="btn btn-sm btn-light"> no action </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                ))}

                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-2">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>

        </div>
    )
}

export default MyMedicineRequest