import { Link } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop"
import useQuery from "../../hooks/useQuery";
import { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import api from "../../services/api";
import useDoctors from "../../hooks/useDoctors";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import BoxEmpty from "../../components/BoxEmpty";
import changeScrollPosition from "../../services/changeScrollPosition";
import DoctorList from "../../components/DoctorList";
import { debounce } from "lodash";
import RadioClassic from "../../components/form/RadioClassic";
import CommonSlider from "../../components/sliders/CommonSlider";

export default function InVideoConsultancy() {
    useScrollTop();

    let query = useQuery();
    const searchRef = useRef();

    const [nextPage, setNextPage] = useState(1);

    const [expendFilter, setExpendFilter] = useState(true);

    const [specialistOptions, setSpecialistOptions] = useState([]);

    const [specialists, setSpecialists]    = useState([])
    const [gender, setGender]              = useState('');
    const [online_status, setOnline_status]= useState('');
    const [search, setSearch]              = useState('');
    const [order_by, setOrder_by]          = useState('');

    const handleSetSearch = debounce((search_text) => {
        setSearch(search_text);
    }, 700);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    useEffect(() => {
        changeScrollPosition('my-position', 150);
        return () => {};
    }, [specialists, gender, order_by]);

    useEffect(() => {
        setNextPage(1);
        return () => {setNextPage(1)};
    }, [search, specialists, gender, online_status, order_by]);

    function handleResetFilter(e){
        e.preventDefault();
        setSpecialists([]);
        setGender('');
        setOnline_status('');
        setSearch('');
        searchRef.current.value = '';
        setOrder_by('');
        setNextPage(1);
    }

    useEffect(() => {
        api().get('/api/load-specialists-all')
        .then(response => {
            response.data.forEach(item => {
                setSpecialistOptions(specialistOptions => [...specialistOptions, {value: item.id, label: `${item.title}`}]);
                if(item.id === parseInt(query.get('specialist'))){
                    setSpecialists([{value: item.id, label: `${item.title}`}]);
                }
            });
        })
        .catch(err => {});
        return () => {
            setSpecialistOptions([]);
        }
    }, [query]);


    const {
        doctors, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useDoctors('/api/doctors-in-video-consultancy', search, specialists, null, null, null, null, gender, null, online_status, order_by, nextPage);


    return (
        <section className="bg-light">
            <div className="container-fluid py-4">
                <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-3 mb-3" id="sticky-sidebar">
                        <div className="bg-white shadow-sm sticky-top">
                            <div className="category-hero">
                                <button onClick={() => setExpendFilter(!expendFilter)} className="btn-master px-3 py-2 w-100 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                                    <div className="d-flex justify-content-between">
                                        <span><i className="bi bi-list"></i></span>
                                        <span>Filters</span>
                                        {expendFilter ? <span><i className="bi bi-chevron-down"></i></span>:<span><i className="bi bi-chevron-up"></i></span>}
                                    </div>
                                </button>
                                <div className="collapse show" id="collapseExample">
                                    <div className="card card-body border-0 shadow-sm rounded-0 small py-1">

                                        <div className="form-group bg-light px-1 py-1">
                                            <label className="w-100 px-1 fw-bold" htmlFor=""><i className="bi bi-layers-half"></i> Specialities</label>
                                            <Select
                                                onChange={setSpecialists}
                                                isMulti
                                                value={specialists}
                                                options={specialistOptions}
                                                placeholder="Search..."
                                                components={makeAnimated()}
                                            />
                                        </div>

                                        <div className="bg-light px-1 py-1 mt-2">
                                            <div className="w-100 px-1 fw-bold"><i className="bi bi-gender-male"></i> Gender</div>
                                            <div className="border-top p-1">
                                                <RadioClassic data={gender} setOnChange={setGender} htmlFor="male" label="Male" value="male" name="gendrRadios" />
                                                <RadioClassic data={gender} setOnChange={setGender} htmlFor="female" label="Female" value="female" name="gendrRadios" />
                                                <RadioClassic data={gender} setOnChange={setGender} htmlFor="other" label="Other" value="other" name="gendrRadios" />
                                            </div>
                                        </div>

                                        <div className="bg-light px-1 py-1 mt-2">
                                            <div className="w-100 px-1 fw-bold"><i className="bi bi-record-circle"></i> Online status</div>
                                            <div className="border-top p-1">
                                                <RadioClassic data={online_status} setOnChange={setOnline_status} htmlFor="online" label="Online" value="online" name="onlineStatusRadios" />
                                                <RadioClassic data={online_status} setOnChange={setOnline_status} htmlFor="offline" label="Offline" value="offline" name="onlineStatusRadios" />
                                            </div>
                                        </div>

                                        <div className="bg-light px-1 py-1 mt-2">
                                            <div className="w-100 px-1 fw-bold"><i className="bi bi-sort-down"></i> Sort By</div>
                                            <div className="border-top p-1">
                                                <RadioClassic data={order_by} setOnChange={setOrder_by} htmlFor="video_fees_asc" label="Fees :low to high" value="video_fees_asc" name="orderRadios" />
                                                <RadioClassic data={order_by} setOnChange={setOrder_by} htmlFor="video_fees_desc" label="Fees :high to low" value="video_fees_desc" name="orderRadios" />
                                                <RadioClassic data={order_by} setOnChange={setOrder_by} htmlFor="experience" label="Experience" value="experience" name="orderRadios" />
                                                <RadioClassic data={order_by} setOnChange={setOrder_by} htmlFor="rating" label="Rating" value="rating" name="orderRadios" />
                                            </div>
                                        </div>

                                        <div className="d-grid mt-2">
                                            <button onClick={handleResetFilter} type="button"  className="btn btn-sm btn-warning text-white fw-bold btn-outline-none">Reset Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9" id="my-position">
                        <div className="bg-white shadow-sm">
                            <div className="form-group d-flex p-2 p-md-3 bg-light">
                                <div className="input-group">
                                    <input onChange={e => handleSetSearch(e.target.value)} ref={searchRef} className="form-control form-control-lg outline-none" type="search"  placeholder="Search..."/>
                                    <button className="btn btn-success btn-outline-none"><i className="bi bi-search"></i></button>
                                </div>
                            </div>

                            <CommonSlider url={'/api/sliders/video-slider'}/>
                            <div className="p-2 bg-light"></div>


                            <div className="flex gx-2">
                                {isLoading && Array.from(Array(5)).map((item,index) => (
                                    <div key={index} className="p-3">
                                        <div className="shadow">
                                            <ItemLoader classList="p-5" />
                                        </div>
                                    </div>
                                ))}
                                {doctors.length>0 && doctors.map(doctor => (
                                    <div key={doctor.id} className="p-3">
                                        <Link to={`/video-consultancy/doctor/${doctor.id}`} className="text-decoration-none">
                                            <div className="row justify-content-center shadow">
                                                <DoctorList
                                                    doctor={doctor}
                                                    fee={doctor.video_fee}
                                                    final_fee={doctor.final_video_fee}
                                                    discount={doctor.video_discount}
                                                    fee_title="Per consultation"
                                                    next_arrow={true}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                                {!isLoading && doctors.length<1 && <BoxEmpty/>}
                            </div>

                            <div className="py-4">
                                <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
