import React from 'react';
import { isEmpty, split } from 'lodash';
import { useSelector } from 'react-redux';
import { selectUser } from '../slice/userSlice';
import ItemsRequest from '../request/ItemsRequest';

const Referral = () => {
    const user = useSelector(selectUser);
    const referral_thing = ItemsRequest('/api/referral-things');

    return (
        <section className='bg-light'>
            <div className="text-center">
                <img src={process.env.REACT_APP_STORAGE + referral_thing.banner} className="w-100" alt=""/>
            </div>
            
            <div className="container py-3 py-lg-5">
                <div className="bg-white shadow-sm rounded py-4 px-1 px-sm-3 px-md-4 px-lg-5">
                    <div className="row m-0 justify-content-center align-items-center">
                        <div className="col-4 col-lg-3">
                            <h6 className="">Referral Code</h6>
                        </div>
                        <div className="col-8 col-lg-9 bg-light border rounded">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                    <h6 className="m-0 py-2 px-1">{user.referral_code}</h6>
                                </div>
                                <div className="">
                                    <button className='btn btn-sm btn-light outline-none'  onClick={() => {navigator.clipboard.writeText(user.referral_code)}}>
                                        <i className="bi bi-files"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 justify-content-center align-items-center pt-3">
                        <div className="col-4 col-lg-3">
                            <h6 className="">Referral Link</h6>
                        </div>
                        <div className="col-8 col-lg-9 bg-light border rounded">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="overflow-hidden">
                                    <p className="m-0 py-2 px-1 text-muted text-truncate">https://duaredoctor.com/register?ddrefc={user.referral_code}</p>
                                </div>
                                <div className="">
                                    <button className='btn btn-sm btn-light outline-none'  onClick={() => {navigator.clipboard.writeText(`https://duaredoctor.com/register?ddrefc=${user.referral_code}`)}}>
                                        <i className="bi bi-files"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container pb-5">
                <div className="bg-white shadow-sm rounded py-3 px-1 px-sm-3">
                    <ul className="list-group list-group-flush  list-group-numbered">
                        {split(referral_thing.text_box, '\n')?.map((item, index) => (
                            !isEmpty(item) && (
                                <li key={index} className="list-group-item">{item}</li>
                            )
                        ))}
                    </ul>
                </div>
            </div>
        </section>   
    )
}

export default Referral