import React, { useState } from 'react';
import Avatar from '../../components/Avatar';
import BoxEmpty from '../../components/BoxEmpty';
import ItemLoader from '../../components/loader/item_loder/ItemLoader';
import MostSimplePaginator from '../../components/pagination/MostSimplePaginator';
import useItems from '../../hooks/useItems';

const ShowReviews = ({api_url}) => {

    const [nextPage, setNextPage] = useState(1);
    const handleSetNextPage = (page) => { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems(api_url, '', nextPage);


    return (
        <section className="bg-light py-4 px-1">
            <div className="container bg-white shadow-sm p-2 fw-bold">Reviews</div>
            <div className="container p-0">
                <div className="">
                    {isLoading && Array.from(Array(5)).map((item, index) => (
                        <div key={index} className="p-2 bg-white shadow-sm my-3">
                            <ItemLoader classList="p-5" />
                        </div>
                    ))}
                    {item_data && item_data?.map((review, index) => (
                        <div key={review.id} className="py-2">
                            <div className="flex d-md-flex justify-content-start bg-white shadow-sm p-2">
                                <div className="py-2">
                                    <Avatar avatarPath={process.env.REACT_APP_STORAGE + review.user?.avatar} sizeClass='my-sm-avatar' rounded='rounded-circle border'/>
                                    {/* <img className="img-fluid img-thumbnail p-1" width="100px" height="100px" src={process.env.REACT_APP_STORAGE + review.user?.avatar} alt={review.user?.name} /> */}
                                </div>
                                <div className="p-2 w-100">
                                    <h6 className="fw-bold text-dark my-0">{review.user?.name}</h6>
                                    <p className="text-dark small fw-light mb-1">{review.created_at}</p>
                                    {Array.from(Array(5)).map((item, index) => (
                                        <i key={index} className={`bi bi-star${parseInt(review.rating ?? 0) > index ? '-fill':''} text-warning`}></i>
                                    ))}
                                    <p className="small text-seconday my-0">{review.detail}</p>
                                </div>
                            </div>
                        </div>
                    ))}

                    {!isLoading && parseInt(item_data.length) < 1 && 
                        <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                    }
                    <div className="py-2">
                        <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShowReviews