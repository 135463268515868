import { useEffect } from "react";

function useScrollPosition(id, top) {
    useEffect(() => {
        const topPosition = document.getElementById(id).offsetTop;
        window.scrollTo(0, topPosition - top);
    }, [id, top]);
}

export default useScrollPosition;
