import { useState } from "react";
import PersonalInformationEdit from "./PersonalInformationEdit";

export default function PersonalInformation({doctor, updateDoctor}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div>
            {/* <h6 className="bg-light border rounded p-2 ">Personal Information</h6> */}
            <div className="d-flex justify-content-between bg-info bg-opacity-10 shadow-sm rounded p-1 mb-2">
                <h6 className="align-self-center m-0">Personal Information</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div className="row justify-content-start px-2">
                <dt className="col-5 col-lg-4">Title </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.title}</dd>
                
                <dt className="col-5 col-lg-4">Gender </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.gender}</dd>
                
                <dt className="col-5 col-lg-4">Country </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.country?.name }</dd>

                {doctor.district && <>
                    <dt className="col-5 col-lg-4">District </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.district.name}</dd>
                </>}

                {doctor.sub_district && <>
                    <dt className="col-5 col-lg-4">Sub District </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.sub_district.name}</dd>
                </>}

                {doctor.state && <>
                    <dt className="col-5 col-lg-4">State </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.state.name}</dd>
                </>}

                <dt className="col-5 col-lg-4">Location </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.location}</dd>

                <dt className="col-5 col-lg-4">NID/Passport No </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.nid_passport_no}</dd>

                <dt className="col-5 col-lg-4">Registration No </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.registration_no}</dd>

                <dt className="col-5 col-lg-4">Registration Institution </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.registered_institution}</dd>
               
                {parseInt(doctor.country_id) === 2 && ( <>
                    <dt className="col-5 col-lg-4">Language </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.language}</dd>
                </>)}
            </div>}

            {isEdit && <PersonalInformationEdit doctor={doctor} updateDoctor={updateDoctor} cancelEdit={(value) => setIsEdit(false)}/>}
            
        </div>
    )
}

