import { useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import { selectToken, selectUser } from "../slice/userSlice";

export default function GuestRoute({ children, ...rest }) {
    const history = useHistory();

    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    return (
        <Route {...rest}
            render={({ location }) => user && token ? 
                (<Redirect
                    to={{
                        pathname: history.goBack(),
                        state: { from: location }
                    }}
                />) :
                (children)
            }
        />
    );
}
