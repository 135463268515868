import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import { selectToken } from '../../slice/userSlice';
import toast from '../../utlities/toast';
import BtnLoader from '../loader/btn_loader/BtnLoader';

const PrescriptoinDownload = ({appointmentId}) => {
    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = (e) => {
        e.preventDefault();
        setIsLoading(true);

        api(token).get(`api/auth/prescription/${appointmentId}/download-pdf`, {responseType: 'blob'})
        .then(response => {
            setIsLoading(false);
            window.open(URL.createObjectURL(response.data));
        })
        .catch(err => {
            // const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast('Prescriptoin is not ready yet', 'error')
        });
    }

    return (
        <div className={`p-4 bg-success bg-opacity-10 border border-success rounded text-center ${isLoading ? 'disabled' : ''}`}>
            <button onClick={handleDownload} className='btn btn-outline-info rounded-pill shadow-sm'>
                <div className="d-flex justify-content-center"><div>Download prescription </div> {isLoading && <BtnLoader/>}</div>
            </button>
        </div>
    )
}

export default PrescriptoinDownload