import Avatar from "./Avatar";

export default function DoctorShortList({doctor}) {
    return (
        <>
            <div>
                <div className="d-flex justify-content-start">
                    <div className="py-2">
                        <Avatar avatarPath={process.env.REACT_APP_STORAGE + doctor.user.avatar} sizeClass='my-lg-avatar' rounded='rounded'/>
                        {/* <img className="img-fluid img-thumbnail p-1" width="100px" height="100px" src={process.env.REACT_APP_STORAGE + doctor.user.avatar} alt={doctor.user.name} /> */}
                    </div>
                    <div className="p-2 w-100">
                        <h6 className="fw-bold text-dark">{doctor.title} {doctor.user.name} <span className="text-primary fw-light">({doctor.country.name_code})</span> <span className={`${doctor.user?.online_status === 'online' ? 'text-primary':'text-muted fw-light'} small`}>{doctor.user?.online_status}</span></h6>
                        <p className="text-dark fw-light">{doctor.qualifications?.map(qualification => qualification.degree).join(', ')}</p>
                        <h6 className="small text-secondary fw-light my-0">Specialities</h6>
                        <div className="text-dark small fw-bold">{doctor.specialists?.map(specialist => specialist.title).join(', ')}</div>
                        {parseInt(doctor.country_id) === 2 && doctor.language && (
                            <p className="small text-secondary fw-light my-0">Language: <span className="text-dark fw-bold">{doctor.language}</span></p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
