import useScrollTop from "../../hooks/useScrollTop";

export default function Faq() {
    useScrollTop();
    
    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center fw-bold">FAQ</div>
            </div>

            <div className="bg-white rounded shadow-sm p-2 p-md-4">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="HeadingOne">
                            <button className="accordion-button collapsed outline-none fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Question book 1
                            </button>
                        </h2>
                     
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed outline-none fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Question book 2
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed outline-none fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Question book 3
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4">
                            <button className="accordion-button collapsed outline-none fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                Question electronics 1
                            </button>
                        </h2>
                        <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, iure facere! Eum vitae dicta accusantium neque nostrum alias voluptatem laborum. Expedita eligendi similique maiores ad nisi repellendus. Quibusdam voluptatum, corrupti omnis reprehenderit molestias iusto, necessitatibus aliquid ipsa assumenda, consequuntur eaque enim aut. Quibusdam in tenetur quasi magnam ipsam alias! Officia.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading5">
                            <button className="accordion-button collapsed outline-none fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                Question electronics 2
                            </button>
                        </h2>
                        <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus architecto repellendus dolor aliquid impedit delectus tenetur optio, nostrum laborum, ratione maiores eos ut id suscipit autem odit deleniti vel quidem dolore ea, perspiciatis voluptatum dicta numquam cum. Accusantium modi incidunt atque, repellat, sunt vel similique fugit magni voluptate amet cum aperiam repellendus! Cupiditate corporis provident nesciunt quibusdam excepturi, unde dolores exercitationem. Esse, aspernatur, voluptate dicta dignissimos ut voluptas non rem deleniti, vero eveniet assumenda at.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading6">
                            <button className="accordion-button collapsed outline-none fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                Question electronics 3
                            </button>
                        </h2>
                        <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus architecto repellendus dolor aliquid impedit delectus tenetur optio, nostrum laborum, ratione maiores eos ut id suscipit autem odit deleniti vel quidem dolore ea, perspiciatis voluptatum dicta numquam cum. Accusantium modi incidunt atque, repellat, sunt vel similique fugit magni voluptate amet cum aperiam repellendus! Cupiditate corporis provident nesciunt quibusdam excepturi, unde dolores exercitationem. Esse, aspernatur, voluptate dicta dignissimos ut voluptas non rem deleniti, vero eveniet assumenda at.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
  }
  