import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useScrollTop from "../hooks/useScrollTop";
import api from "../services/api";
import BoxEmpty from "./BoxEmpty";
import ItemLoader from "./loader/item_loder/ItemLoader";

export default function Specialist({next_link}) {
    useScrollTop();

    const [isLoading, setIsLoading] = useState(false);
    const [specialists, setSpecialists] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api().get('/api/load-specialists-all')
        .then(response => {
            setSpecialists(response.data);
            setIsLoading(false);
        })
        .catch(err => {});
        return () => setSpecialists([]);
    }, []);

    return (
        <section className="bg-light">
            <div className="container py-4">
                <div className="p-2 p-md-3 shadow-sm rounded-sm bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="m-0">Specialist</h5>
                        <Link to={next_link} className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View All Doctor</Link>
                    </div>
                </div>
                <div className="row row-cols-sm-2 row-cols-md-3 row-cols-xl-4 p-1">
                    {isLoading && Array.from(Array(4)).map((item, index) => (
                        <div key={index} className="p-1 p-md-2">
                            <div className="shadow bg-white ">
                                <ItemLoader classList="p-5"/>
                                <ItemLoader classList="p-3 my-2"/>
                                <ItemLoader classList="p-4 my-2"/>
                            </div>
                        </div>
                    ))}
                    {specialists?.map(specialist => (
                        <div key={specialist.id} className="p-1 p-md-2">
                            <div className="shadow-sm bg-white rounded">
                                <Link to={`${next_link}?specialist=${specialist.id}`} className="text-decoration-none">
                                    <div className="item-card">
                                        <img className="img-fluid m-auto d-block p-2"  src={process.env.REACT_APP_STORAGE + specialist.logo} alt={specialist.title}
                                            style={{height: '7rem'}}
                                        />
                                        <div className="fw-bold text-center">{specialist.title}</div>
                                        <div className="small p-2 text-secondary overflow-hidden my-text-justify" style={{height:'75px',}}>{specialist.description}</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}

                    {!isLoading && specialists.length < 1 && (
                        <div className="m-auto">
                            <BoxEmpty/>
                        </div>
                    )}
                </div>

            </div>
        </section>
    )
}
