import { useSelector } from "react-redux";
import { NavLink, Switch } from "react-router-dom";
import { selectUser } from "../slice/userSlice";
import AuthRoute from "../middleware/AuthRoute";
import DoctorProfile from "../pages/profile/doctor/DoctorProfile";
import NotFound404 from "../pages/NotFound404";
import AppointmentList from "../pages/profile/doctor/AppointmentList";
import AudioConsultancyList from "../pages/profile/doctor/AudioConsultancyList";
import VideoConsultancyList from "../pages/profile/doctor/VideoConsultancyList";
import AppointmentView from "../pages/profile/doctor/AppointmentView";
import AudioConsultancyView from "../pages/profile/doctor/AudioConsultancyView";
import VideoConsultancyView from "../pages/profile/doctor/VideoConsultancyView";


export default function DoctorRoute() {

    const user = useSelector(selectUser);

    return (
        <div className="bg-light">
            <div className="container-fluid py-2">
                <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-3 py-3" id="sticky-sidebar">
                        <div className="sticky-top top-except-nav">
                            <div className="bg-white shadow-sm mb-3 border-start border-4 border-master rounded">
                                <div className="list-group list-group-flush">
                                    <div className="list-group-item list-group-item-light p-3">
                                        {user.avatar ? <img className="rounded-circle border border-2" src={user.avatar.substring(0, 4).toLowerCase() === 'http' ? user.avatar : process.env.REACT_APP_STORAGE + user.avatar} alt="Name" width="40px" height="40px"/>
                                            : <span className="rounded-circle bg-success text-light fw-bolder p-2 m-1 font-xl">{user.name[0]}</span> 
                                        } <span className="fw-bold">{user.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="border-end bg-white shadow-sm">
                                <div className="list-group list-group-flush">
                                    <NavLink activeClassName="active" exact to="/sp/doctor" className="list-group-item list-group-item-action list-group-item-info p-3 fw-bold">Doctor Profile</NavLink>
                                    <NavLink activeClassName="active" to="/sp/doctor/appointment" className="list-group-item list-group-item-action list-group-item-info p-3 fw-bold">Appointment List</NavLink>
                                    <NavLink activeClassName="active" to="/sp/doctor/audio-consultancy" className="list-group-item list-group-item-action list-group-item-info p-3 fw-bold">Audio Consultancy List</NavLink>
                                    <NavLink activeClassName="active" to="/sp/doctor/video-consultancy" className="list-group-item list-group-item-action list-group-item-info p-3 fw-bold">Video Consultancy List</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9 py-3">

                        <Switch>
                            <AuthRoute exact path="/sp/doctor/"><DoctorProfile/></AuthRoute>

                            <AuthRoute exact path="/sp/doctor/appointment"><AppointmentList/></AuthRoute>
                            <AuthRoute exact path="/sp/doctor/appointment/:appointment_id/view"><AppointmentView/></AuthRoute>

                            <AuthRoute exact path="/sp/doctor/audio-consultancy"><AudioConsultancyList/></AuthRoute>
                            <AuthRoute exact path="/sp/doctor/audio-consultancy/:appointment_id/view"><AudioConsultancyView/></AuthRoute>

                            <AuthRoute exact path="/sp/doctor/video-consultancy"><VideoConsultancyList/></AuthRoute>
                            <AuthRoute exact path="/sp/doctor/video-consultancy/:appointment_id/view"><VideoConsultancyView/></AuthRoute>

                            <AuthRoute path="*"><NotFound404 /></AuthRoute>
                        </Switch>

                    </div>
                </div>
            </div>
        </div>
    )
}
