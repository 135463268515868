import { useState } from "react";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import api from "../../services/api";
import useFormInput from "../../hooks/useFormInput";
import toast from '../../utlities/toast';
import useScrollTop from "../../hooks/useScrollTop";

export default function ForgotPassword() {
    useScrollTop();

    const email = useFormInput('');

    const [emailError, setEmailError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    function handleForgotPassword(e){
        e.preventDefault();
        setIsLoading(true);

        api().post('/api/auth/forgot-password', {email: email.value})
        .then(response => {
            setIsLoading(false);

            if(response.data.status === false){
                setEmailError(response.data.message);
                toast(response.data.message, 'error');
                setSuccessMessage(null);
            }
            else{
                setEmailError(null);
                toast(response.data.message, 'success');
                setSuccessMessage(response.data.message);
            }
        }).catch(err => {
            setIsLoading(false);
            setSuccessMessage(null);

            const error = err.response ? err.response.data : err;
            if(error.errors){
                error.errors.email ? setEmailError(error.errors.email[0]) : setEmailError(null);
            }
            else {
                setEmailError(null);
            }
        });
    }


    return (
        <div className="py-5">
            <div className="d-flex justify-content-center">
                <div className="col-11 col-md-8 col-lg-6">
                    <div className="bg-white p-2 p-md-3 shadow rounded">

                        <h5 className="text-center text-secondary border-3 border-bottom border-master p-2">Reset Password</h5>
                        <p className="small text-secondary text-center">Enter your email address</p>

                        {successMessage && <div className="alert alert-success text-center py-1">{successMessage} . Please check your email.</div>}
                
                        <form  className="pt-3" onSubmit={handleForgotPassword}>
                            <div className="input-group has-validation mb-5">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-envelope bi-m text-secondary"></i>
                                </span>
                                <input {...email}
                                     type="text" 
                                     className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${emailError ? 'is-invalid border-danger' : ''}`}
                                     id="email" 
                                     name="email"  
                                     placeholder="your.email@gmail.com" 
                                     autoComplete="off"/>
                                {emailError && <div className="invalid-tooltip">{emailError}</div> }
                            </div>
                           
                            <div className="d-grid">
                                <button className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`} type="submit">
                                    <div className="d-flex justify-content-center">
                                        <div>Submit </div> 
                                        {isLoading && <BtnLoader/>}
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
