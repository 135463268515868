import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import bd_doctor_appointment from "../../assets/img/services/BD-Doctor.png"
import in_doctor_appointment from "../../assets/img/services/Indian-Doctor.png"
import nurse_appointment from "../../assets/img/services/Nurse-Appointment.png";
import medicine_shop from "../../assets/img/services/Medicine-shop.png";
import ambulance_booking from "../../assets/img/services/Ambulance-Booking.png";
// import audio_consultancy from "../../assets/img/services/Audio-Consultancy.png";
// import video_consultancy from "../../assets/img/services/Video-Consultancy.png";
import bd_video_consultancy from "../../assets/img/services/bd_video_consultancy.png";
import in_video_consultancy from "../../assets/img/services/in_video_consultancy.png";
// import diagnostic_service from "../../assets/img/services/Diagnostic-Service.png";
// import operation_surgery from "../../assets/img/services/Operation-Surgery.png";
// import upload_prescription from "../../assets/img/services/Upload-Prescription.png";
import reorder_medicine from "../../assets/img/services/Reorder-Medicine.png";

export default function Bookings() {
    const {t} = useTranslation();
    const services = [
        { image: bd_doctor_appointment, title: 'Bangladeshi Doctor Appointment', routeName: '/bd-doctor-appointment/specialists'},
        { image: in_doctor_appointment, title: 'Indian Doctor Appointment', routeName: '/in-doctor-appointment/specialists'},
        // { image: audio_consultancy, title: 'Audio Consultancy', routeName: '/audio-consultancy/specialists'},
        // { image: video_consultancy, title: 'Video Consultancy', routeName: '/video-consultancy/specialists'},
        { image: bd_video_consultancy, title: 'Bangladeshi Video Consultancy', routeName: '/bd-video-consultancy/specialists'},
        { image: in_video_consultancy, title: 'Indian Video Consultancy', routeName: '/in-video-consultancy/specialists'},
        { image: nurse_appointment, title: 'Nurse Appointment', routeName: '/nurse-appointment'},
        { image: ambulance_booking, title: 'Ambulance Booking', routeName: '/ambulance-booking'},
        { image: medicine_shop, title: 'Medicine Order', routeName: '/shop'},
        // { image: diagnostic_service, title: 'Diagnostic Service', routeName: '/diagnostic-service'},
        // { image: operation_surgery, title: 'Operation Surgery', routeName: '/operation-surgery'},
        // { image: upload_prescription, title: 'Upload Prescription', routeName: '/profile/upload-prescription'},
        { image: reorder_medicine, title: 'Reorder Medicine', routeName: '/profile/my-order'},
    ];

    return (
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 justify-content-center">    
            {services?.map((service, index) => (
                <Link key={index} to={service.routeName} className="p-1 p-md-3 text-decoration-none">
                    <div className="bg-white shadow">
                        <div className="">
                            <img className="img-fluid"  src={service.image} alt={service.title} />
                        </div>
                        <div className="text-center min-h-65">
                            <h5 className="fw-bold p-2" style={{color: '#234890'}}>{t(service.title)}</h5>
                        </div>
                    </div>
                </Link>
            ))}       
        </div>
    )
}
