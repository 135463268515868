import { useEffect, useState } from "react";
import { useParams } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import api from "../../services/api";
import DoctorLoader from "../../components/loader/DoctorLoader";
import moment from "moment";
import BoxEmpty from "../../components/BoxEmpty";
import useFormInput from "../../hooks/useFormInput";
import DoctorList from "../../components/DoctorList";
import TextArea from "../../components/form/TextArea";
import Input from "../../components/form/Input";
import InputSelect from "../../components/form/InputSelect";
import SubmitBtn from "../../components/form/SubmitBtn";
import { selectToken } from "../../slice/userSlice";
import toast from "../../utlities/toast";

export default function VideoConsultancyNow() {
    const { id } = useParams();
    useEffect(() => {window.scrollTo(0, 0); return;}, [id]);

    const token = useSelector(selectToken);

    const [isLoading, setIsLoading] = useState(true);
    const [doctor, setDoctor]       = useState([]);
    const [error, setError]         = useState(null);

    const name          = useFormInput('');
    const dob           = useFormInput('');
    const gender        = useFormInput('');
    const occupation    = useFormInput('');
    const country_id    = useFormInput('');
    const city          = useFormInput('');
    const disease_about = useFormInput('');
    const [patient_type, setPatient_type] = useState('New')

    const [allError, setAllError] = useState([]);
    const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);

    function handleAppointmentSubmit(e){
        e.preventDefault();
        setIsAppointmentLoading(true);

        const body = {
            name       : name.value,
            dob        : dob.value,
            gender     : gender.value,
            occupation : occupation.value,
            country_id : country_id.value,
            city       : city.value,
            disease_about: disease_about.value,
            patient_type : patient_type,
        };


        api(token).post(`/api/auth/doctor-consultancy/video/${id}/appointment`, body)
        .then(response => {
            setIsAppointmentLoading(false);
            setAllError([]);
            if(response.data.status !== 'success'){
                Swal.fire('Something worng!', `${response.data.message}`,'warning');
                setIsLoading(false);
                return;
            }
            if(response.data.need_payment){
                Swal.fire({
                    title: 'Please wait...',
                    text: "Don't refresh or close the page.",
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
                Swal.showLoading();
                window.location.replace(process.env.REACT_APP_API_URL + '/payment/payment-submit/' + response.data.need_payment_id);
                return;
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsAppointmentLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        })
    }

    useEffect(() => {
        api().get(`/api/doctor-video-consultancy/${id}`)
        .then(response => {
            if(response.data === "") setError('Doctor not found');
            else setDoctor(response.data);
            setIsLoading(false);
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setIsLoading(false);
        });
        return () => {setDoctor([]); setError(null)};
    }, [id]);


    if(isLoading){
        return (
            <DoctorLoader/>
        )
    }

    else if(error){
        return (
            <section className="bg-light py-4 px-1">
                <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Doctor Profile</div>
                <div className="container">
                    <div className="bg-white">
                        <div className="row">
                            <div className="p-2 bg-white">
                                <div className="text-center"><BoxEmpty/>{error}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    else if(doctor.user){
        return (
            <>
                <section className="bg-light p-4 py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Doctor Profile</div>
                    <div className="container">
                        <div className="bg-white">
                            <div className="row justify-content-center shadow-sm bg-white">
                                <DoctorList
                                    doctor={doctor}
                                    fee={doctor.video_fee}
                                    final_fee={doctor.final_video_fee}
                                    discount={doctor.video_discount}
                                    fee_title="Per consultation"
                                    next_arrow={false}
                                />
                                
                                <div className="col-12 bg-white border-top">
                                    <div className="row  row-cols-2 row-cols-lg-4 text-secondary">
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Available days</p>
                                                <h6 className="text-dark fw-bold">
                                                    {doctor.wdays?.map((wday,index) => (
                                                        <span key={wday.id}>{index !== 0 && ', '}{wday.short_title}</span>
                                                    ))}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Available time</p>
                                                <h6 className="text-dark fw-bold">{moment(doctor.time_from, 'hh:mm:ss').format('h:m A')} - {moment(doctor.time_to, 'hh:mm:ss').format('h:m A')}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Doctor Code</p>
                                                <h6 className="text-dark fw-bold">{doctor.doc_id}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Patient attended</p>
                                                <h6 className="text-dark fw-bold">{doctor.doctor_appointments_count}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Patient Information</div>
                    <div className="container bg-white shadow-sm">
                        <div className="p-2">
                            <div className="row">
                                <div className="col-lg-6">
                                    <Input data={name} error={allError['name']} label="Name" htmlFor="name" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={dob} type="date" error={allError['dob']} label="Your Date of Birth" htmlFor="dob" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <InputSelect data={gender} error={allError['gender']} label="Gender" htmlFor="gender" required={true}
                                        elements={<>
                                            <option className="bg-light text-info fw-bold" disabled value="">Select your gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </>}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Input data={occupation} error={allError['occupation']} label="Occupation" htmlFor="occupation" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                        elements={<>
                                            <option disabled className="bg-light text-info fw-bold" value="">Select your country</option>
                                            <option value="1">Bangladesh</option>
                                        </>}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Input data={city} error={allError['city']} label="City" htmlFor="city" required={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Disease Information</div>
                    <div className="container bg-white shadow-sm">
                        <div className="py-2 py-md-4">
                            <div className="px-md-3">
                                <TextArea
                                    data={disease_about} 
                                    label='Short about diseas'
                                    htmlFor='disease_about'
                                    autoFocus={false}
                                    error={allError['disease_about']}
                                    required={true}
                                />

                                <div className="mb-4 border rounded p-2">
                                    <h6 className="d-flex ">Select patient type <span className="text-danger">*</span> </h6>
                                    <div className="d-flex justify-content-evenly">
                                        <div onClick={e => setPatient_type('New')}
                                            role="button"
                                            className={`border p-3 rounded ${patient_type === "New" ? 'border-info text-info shadow-sm':''}`}>

                                            <div className="px-2">New Patient</div>
                                        </div>

                                        <div onClick={e => setPatient_type('Old')}
                                            role="button"
                                            className={`border p-3 rounded ${patient_type === "Old" ? 'border-primary text-primary shadow-sm':''}`}>
                                            <div className="px-2">Old Patient</div>
                                        </div>
                                    </div>
                                    {allError['patient_type'] && <div className="alert alert-danger py-1 my-2">{allError['patient_type']}</div>}
                                </div>
                            </div>
                            <form className="pt-3" onSubmit={handleAppointmentSubmit}>
                                <div className="d-flex justify-content-end">
                                    <SubmitBtn isLoading={isAppointmentLoading} title="Submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
        
            </>
        )
    }

    else return (<DoctorLoader/>)
}
