import Avatar from "./Avatar";

export default function DoctorList({doctor, fee, final_fee, discount, fee_title, next_arrow, fee_section = true}) {
    return (
        <>
            <div className={`${fee_section ? 'col-md-6 col-lg-5' : 'col-md-7'}`}>
                <div className="d-flex justify-content-start">
                    <div className="py-2">
                        <Avatar avatarPath={process.env.REACT_APP_STORAGE + doctor.user.avatar} sizeClass='my-lg-avatar' rounded='rounded'/>
                        {/* <img className="img-fluid img-thumbnail p-1" width="180px" height="180px" src={process.env.REACT_APP_STORAGE + doctor.user.avatar} alt={doctor.user.name} /> */}
                    </div>
                    <div className="p-2 w-100">
                        <h6 className="fw-bold text-dark mb-0">{doctor.title} {doctor.user.name} <span className="text-primary fw-light">({doctor.country.name_code})</span> <span className={`${doctor.user?.online_status === 'online' ? 'text-primary':'text-muted fw-light'} small`}>{doctor.user?.online_status}</span></h6>
                        <p className="text-dark fw-light">{doctor.qualifications?.map(qualification => qualification.degree).join(', ')}</p>
                        <h6 className="small text-secondary fw-light my-0">Specialities</h6>
                        <div className="text-dark small fw-bold">{doctor.specialists?.map(specialist => specialist.title).join(', ')}</div>
                        {parseInt(doctor.country_id) === 2 && doctor.language && (
                            <p className="small text-secondary fw-light my-0">Language: <span className="text-dark fw-bold">{doctor.language}</span></p>
                        )}
                    </div>
                </div>
            </div>
            <div className={`${fee_section ? 'col-md-3 col-lg-4' : 'col-md-5'}  border-start border-light`}>
                <div className="p-2">
                    <p className="text-secondary small my-0">Working in</p>
                    <p className="text-dark fw-bold small">{doctor.hospital_name}</p>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <p className="text-secondary small my-0">Experience</p>
                            <p className="text-dark fw-bold small my-0">{doctor.final_experience}</p>
                        </div>
                        <div className="">
                            <p className="text-secondary small my-0">Total Reviews ({doctor.reviews_count})</p>
                            <div className="d-flex">
                                {Array.from(Array(5)).map((item, index) => (
                                    <i key={index} className={`bi bi-star${parseInt(doctor.reviews_avg_rating ?? 0) > index ? '-fill':''} text-warning`}></i>
                                ))}
                                <p className="my-0 px-2 text-dark fw-bold">{parseFloat(doctor.reviews_avg_rating ?? 0).toFixed(1)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {fee_section && <div className="col-md-3 col-lg-3 bg-light">
                <div className="p-2">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h4 className="text-success fw-bold"><span className="fw-bolder">৳ </span>{final_fee}</h4>
                            {doctor.vat > 0 && <p className="text-secondary small my-0">+ {doctor.vat}% VAT</p>}
                            <p className="text-secondary small my-0">{fee_title}</p>
                            {discount > 0 && <span className="text-muted fw-light">Before <del><span className="fw-bolder">৳</span>{fee}</del></span>}
                        </div>
                        {next_arrow && <div className="align-self-center"><i className="bi bi-arrow-right icon-xlg"></i></div>}
                    </div>
                </div>
            </div>}
        </>
    )
}
