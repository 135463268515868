import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AddToCartBtn from "../components/AddToCartBtn";
import WishlistBtn from "../components/WishlistBtn";
import api from "../services/api";
import Spinner from "../components/loader/spinner2/Spinner";
import BoxEmpty from "../components/BoxEmpty";
import AlternativeBrandProduct from "../components/AlternativeBrandProduct";
import ZoomImage from "../components/ZoomImage";

export default function Product() {

    const { slug } = useParams();
    useEffect(() => {window.scrollTo(0, 0);}, [slug]);

    const [item, setItem] = useState([]);
    const [isProductLoading, setIsProductLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeImage, setActiveImage] = useState('');


    useEffect(() => {
        setIsProductLoading(true);
        api().get(`/api/product/${slug}/show`)
            .then(response => {
                if(response.data === ""){
                    setError('This product is unavailable');
                }
                setItem(response.data);
                setIsProductLoading(false);
            }).catch(err => {
                const error = err.response ? err.response.data : err;
                setError(error.message);
                setIsProductLoading(false);
            });
        return () => {
            setItem([]);
        }
    }, [slug]);


    useEffect(() => {
        if(!isProductLoading && !error){
            setActiveImage(item.images[0]?.image);
        }
    },[item,error,isProductLoading]);


    function createMarkup(data){
        return { __html: data };
    }
    

    if(error){
        return (
            <div className="container p-5">
                <BoxEmpty/>
                <div className="text-center text-muted">{error}</div>
            </div>
        )
    }
    else if(isProductLoading){
        return (
            <section>
                <div className="container">
                    <div className="" style={{paddingTop: "20%", paddingBottom: "20%",}}>
                        <div className="text-center">
                            <Spinner/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    else{
        return (
            <>
                <section>
                    <div className="container bg-white py-4">
                        <div className="row justify-content-between g-2 shadow-sm bg-white">
                            <div className="col-12 col-md-8 col-lg-9 pb-2">
                                <div className="row row-cols-1 row-cols-md-2 g-2">
                                    <div className="flex">
                                        <div className="text-center border">
                                            {activeImage && <ZoomImage image_path={process.env.REACT_APP_STORAGE + activeImage}  zoom={150}/>}
                                            {/* {activeImage && <img src={process.env.REACT_APP_STORAGE + activeImage} alt="" className="img-fluid w-100" />} */}
                                        </div>
                                        <div className="d-flex bg-light">
                                            {item.images.map(image => (
                                                <div  key={image.id} onClick={() => setActiveImage(image.image)}
                                                    className="" role="button">
                                                    <img src={process.env.REACT_APP_STORAGE + image.image} alt="" className={`img-thumbnail m-1 ${activeImage === image.image ? 'border-main': ''}`} style={{maxWidth: "50px"}} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <h4 className="">{item.title} {item.power && <sup className=" text-secondary">{parseFloat(item.power)}mg</sup>}</h4>

                                        <div className="px-2 px-lg-3 py-3 text-muted">
                                            <div className="">{item.medicinetype.title}</div>
                                            {item.generics?.length > 0 && <div><span className="fw-bold">Generic:</span> 
                                                {item.generics?.map((generic, index) => (
                                                    <span key={generic.id} className="fw-light">{generic.title}{item.generics?.length > index+1 ? ', ':''}</span>
                                                ))}
                                            </div>}
                                            <div><span className="fw-bold">Brand:</span> <span className="fw-light">{item.brand.name}</span></div>
                                            <div className="small text-secondary fw-bold"><i className="bi bi-bar-chart-line-fill"></i> Views: <span>{item.views}</span></div>
                                        </div>

                                        <div className="px-2 px-lg-3 py-2">
                                            <div className="d-flex justify-content-between">
                                                <h5 className="text-success"><span className="fw-800">৳ </span>{item.final_price}</h5>
                                                {item.discount > 0 && <>
                                                    <h5 className="text-warning px-3"><del><span className="fw-800">৳ </span>{parseFloat(item.price)}</del></h5>
                                                    <div className="alert alert-success py-0">{item.discount}% OFF </div>
                                                </>}
                                            </div>
                                        </div>
                                        <div className="px-2 px-lg-3">
                                            <div className="py-1">
                                                <AddToCartBtn product={item} classList="btn-master rounded w-100 py-2"/>
                                            </div>
                                            <div className="d-flex justify-content-between py-2">
                                                <WishlistBtn product={item}/>
                                                {/* <ShareBtn item={item}/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 bg-light">
                                <div className="px-2 py-3">
                                    <div className="d-flex justify-content-between border-bottom py-1">
                                        <h6 className="text-muted small">Delivery Options</h6>
                                        <span><i className="bi bi-info-circle"></i></span>
                                    </div>

                                    {/* <div className="d-flex justify-content-between py-2 border-bottom">
                                        <div className="d-flex">
                                            <span><i className="bi bi-pin-map"></i></span>
                                            <span className="small align-self-center px-2">Dhaka, North dhaka</span>
                                        </div>
                                        <button className="btn btn-sm btn-success py-0">Change</button>
                                    </div> */}
                                    
                                    {/* <div className="d-flex justify-content-between py-2">
                                        <div className="d-flex">
                                            <div><i className="bi bi-truck"></i></div>
                                            <div className="flex align-self-center">
                                                <div className="small px-2">Home Delivery</div>
                                                <div className="text-muted small px-3">4 to 7 days</div>
                                            </div>
                                        </div>
                                        <div className="small">35 Tk</div>
                                    </div> */}
                                    <div className="">
                                        <div className="small"><i className="bi bi-truck"></i> Home Delivery</div>
                                        <div className="d-flex justify-content-between">
                                            <div className="text-muted small px-3">Inside Dhaka</div>
                                            <div className="small">30 minutes - 1 day</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="text-muted small px-3">Outside Dhaka</div>
                                            <div className="small">2 - 3 days</div>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex justify-content-between py-2">
                                        <div className="d-flex">
                                            <div><i className="bi bi-truck"></i></div>
                                            <div className="flex align-self-center">
                                                <div className="small px-2">Home Delivery</div>
                                                <div className="text-muted small px-3">4 to 7 days</div>
                                            </div>
                                        </div>
                                        <div className="small">35 Tk</div>
                                    </div> */}

                                    <div className="d-flex py-2 border-bottom">
                                        <span><i className="bi bi-cash-coin"></i></span>
                                        <span className="small px-2">Cash on Delivery Available</span>
                                    </div>

                                    <div className="d-flex justify-content-between border-bottom py-2">
                                        <h6 className="text-muted small">Return & Warranty</h6>
                                        <span><i className="bi bi-info-circle"></i></span>
                                    </div>

                                    <div className="d-flex py-2">
                                        <span><i className="bi bi-check-square-fill text-master"></i></span>
                                        <span className="small px-2">100% Authentic</span>
                                    </div>
                                    {/* <div className="d-flex py-2">
                                        <span><i className="bi bi-check-square-fill text-master"></i></span>
                                        <div className="flex">
                                            <div className="small px-2">14 days easy return</div>
                                            <div className="small text-muted px-2">Change of mind is not applicable</div>
                                        </div>
                                    </div> */}
                                    {/* <div className="d-flex py-2 border-bottom">
                                        <span><i className="bi bi-backspace-reverse-fill text-main"></i></span>
                                        <span className="small px-2">Warranty not available</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <AlternativeBrandProduct productId={item.id}/>

                <section className="product-details ckeditor-text-tableable">
                    {item.product_detail.indications ? 
                        <div className="container py-3">
                            <div className="bg-white shadow-sm">
                                <div className="bg-light">
                                    <h5 className="p-2 p-md-3"><i className="bi bi-arrow-right-square-fill"></i> Indications</h5>
                                </div>
                                <div className="p-2 p-md-4">
                                    <div dangerouslySetInnerHTML={createMarkup(item.product_detail.indications)}></div>
                                </div>
                            </div>
                        </div>
                    :<></>}
                    {item.product_detail.uses_rule ? 
                        <div className="container py-3">
                            <div className="bg-white shadow-sm">
                                <div className="bg-light">
                                    <h5 className="p-2 p-md-3"><i className="bi bi-arrow-right-square-fill"></i> Uses Rules</h5>
                                </div>
                                <div className="p-2 p-md-4">
                                    <div dangerouslySetInnerHTML={createMarkup(item.product_detail.uses_rule)}></div>
                                </div>
                            </div>
                        </div>
                    :<></>}
                    {item.product_detail.side_effect ?
                        <div className="container py-3">
                            <div className="bg-white shadow-sm">
                                <div className="bg-light">
                                    <h5 className="p-2 p-md-3"><i className="bi bi-arrow-right-square-fill"></i> Side Effects</h5>
                                </div>
                                <div className="p-2 p-md-4">
                                    <div dangerouslySetInnerHTML={createMarkup(item.product_detail.side_effect)}></div>
                                </div>
                            </div>
                        </div>
                    :<></>}
                    {item.product_detail.description ?
                        <div className="container py-3">
                            <div className="bg-white shadow-sm">
                                <div className="bg-light">
                                    <h5 className="p-2 p-md-3"><i className="bi bi-arrow-right-square-fill"></i> Description</h5>
                                </div>
                                <div className="p-2 p-md-4">
                                    <div dangerouslySetInnerHTML={createMarkup(item.product_detail.description)}></div>
                                </div>
                            </div>
                        </div>
                    :<></>}
                </section>
            </>
        )
    }
}
