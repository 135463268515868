import { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import api from "../services/api";
import ItemLoader from "../components/loader/item_loder/ItemLoader";
import BoxEmpty from "../components/BoxEmpty";
import DoctorList from "../components/DoctorList";
import ShowReviews from "./reviews/ShowReviews";

export default function DoctorView() {
    const { id } = useParams();
    useEffect(() => {window.scrollTo(0, 0); return;}, [id]);

    const [isLoading, setIsLoading] = useState(true);
    const [doctor, setDoctor]       = useState([]);
    const [error, setError]         = useState(null);


    useEffect(() => {
        api().get(`/api/doctor-view/${id}`)
        .then(response => {
            if(response.data === "") setError('This doctor is unavailable');
            else setDoctor(response.data);
            setIsLoading(false);
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setIsLoading(false);
        });
        return () => {setDoctor([]); setError(null)};
    }, [id]);


    return (
        <>
            <section className="bg-light py-4 px-1">
                <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Doctor Profile</div>
                <div className="container">
                    <div className="bg-white">
                        {isLoading && 
                            <div className="row">
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-5" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-3" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-4" />
                                </div>
                            </div>
                        }
                        {!isLoading && !error && doctor.user?.avatar && 
                        <div className="row justify-content-center shadow-sm bg-white">
                            <DoctorList
                                doctor={doctor}
                                fee={0}
                                final_fee={0}
                                discount={0}
                                fee_title=""
                                next_arrow={false}
                                fee_section={false}
                            />
                            
                            <div className="col-12 bg-white border-top">
                                <div className="row  row-cols-2 row-cols-lg-4 text-secondary">
                                    <div className="p-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Available days</p>
                                            <h6 className="text-dark fw-bold">
                                                {doctor.wdays?.map((wday,index) => (
                                                    <span key={wday.id}>{index !== 0 && ', '}{wday.short_title}</span>
                                                ))}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Available time</p>
                                            <h6 className="text-dark fw-bold">{moment(doctor.time_from, 'hh:mm:ss').format('h:m A')} - {moment(doctor.time_to, 'hh:mm:ss').format('h:m A')}</h6>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Doctor Code</p>
                                            <h6 className="text-dark fw-bold">{doctor.doc_id}</h6>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Patient attended</p>
                                            <h6 className="text-dark fw-bold">{doctor.doctor_appointments_count}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 bg-white border-top">
                                <div className="row text-secondary">
                                    <div className={`${doctor.district ? 'col-4 col-lg-2' : 'col-6 col-lg-3'} p-2`}>
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">{doctor.district ? 'District' : 'State'}</p>
                                            <h6 className="text-dark fw-bold">{doctor.district ? doctor.district.name : doctor.state.name}</h6>
                                        </div>
                                    </div>
                                    {doctor.district && <div className="p-2 col-4 col-lg-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Sub district</p>
                                            <h6 className="text-dark fw-bold">{doctor.sub_district.name}</h6>
                                        </div>
                                    </div>}
                                    <div className={`${doctor.district ? 'col-4 col-lg-2' : 'col-6 col-lg-3'} p-2`}>
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Chamber name</p>
                                            <h6 className="text-dark fw-bold">{doctor.chamber_name}</h6>
                                        </div>
                                    </div>
                                    <div className="p-2 col-12 col-lg-6">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Chamber location</p>
                                            <h6 className="text-dark fw-bold">{doctor.chamber_location}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0 bg-white border-top">
                                <div className="flex d-md-flex text-secondary">
                                    {parseInt(doctor.appointment_service) === 1 && <div className="p-2 w-100">
                                        <div className="bg-light rounded-3 p-2">
                                            <h4 className="text-success fw-bold"><span className="fw-bolder">৳ </span>{doctor.final_appointment_fee}</h4>
                                            <p className="text-secondary small my-0">Per appointment, {doctor.appointment_discount > 0 && <span className="text-muted fw-light">Before <del><span className="fw-bolder">৳</span>{doctor.appointment_fee}</del></span>}</p>
                                        </div>
                                    </div>}
                                    {/* {parseInt(doctor.audio_consultancy) === 1 && <div className="p-2 w-100">
                                        <div className="bg-light rounded-3 p-2">
                                            <h4 className="text-success fw-bold"><span className="fw-bolder">৳ </span>{doctor.final_audio_fee}</h4>
                                            <p className="text-secondary small my-0">Per audio consultancy, {doctor.audio_discount > 0 && <span className="text-muted fw-light">Before <del><span className="fw-bolder">৳</span>{doctor.audio_fee}</del></span>}</p>
                                            
                                        </div>
                                    </div>} */}
                                    {parseInt(doctor.video_consultancy) === 1 && <div className="p-2 w-100">
                                        <div className="bg-light rounded-3 p-2">
                                            <h4 className="text-success fw-bold"><span className="fw-bolder">৳ </span>{doctor.final_video_fee}</h4>
                                            <p className="text-secondary small my-0">Per video consultancy, {doctor.video_discount > 0 && <span className="text-muted fw-light">Before <del><span className="fw-bolder">৳</span>{doctor.video_fee}</del></span>}</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-12 bg-white border-top p-0">
                                <div className="bg-light p-1">
                                    <div className="flex d-md-flex justify-content-evenly">
                                        {parseInt(doctor.appointment_service) === 1 && <div className="py-1"><Link to={`/${doctor.country.name_code.toLowerCase()}-doctor-appointment/doctor/${doctor.id}/now`} className="btn btn-info text-white w-100">Appointment now</Link></div>}
                                        {/* {parseInt(doctor.audio_consultancy) === 1 && <div className="py-1"><Link to={`/audio-consultancy/doctor/${doctor.id}/now`} className="btn btn-info text-white w-100">Book audio consultancy now</Link></div>} */}
                                        {parseInt(doctor.video_consultancy) === 1 && <div className="py-1"><Link to={`/video-consultancy/doctor/${doctor.id}/now`} className="btn btn-info text-white w-100">Book video consultancy now</Link></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {!isLoading && error && <div className="row"><div className="p-2 bg-white text-center"><BoxEmpty/>{error}</div></div>}
                    </div>
                </div>
            </section>

            <ShowReviews api_url={`/api/review/show/doctor-review/${doctor.id}`}/>

        </>
    )
}
