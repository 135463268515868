import { lowerCase, upperFirst } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import BoxEmpty from "../../components/BoxEmpty";
import SubmitBtn from "../../components/form/SubmitBtn";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import MyCF from "../../components/MyCF";
import NurseShortList from "../../components/NurseShortList";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import AppointmentTracker from "../../components/Tracker/AppointmentTracker";
import useItems from "../../hooks/useItems";
import useQuery from "../../hooks/useQuery";
import useScrollPosition from "../../hooks/useScrollPosition";
import { selectToken } from "../../slice/userSlice";

export default function MyNurseAppointment() {
    useScrollPosition('my-position', 150);

    let query = useQuery();

    useEffect(() => {
        if(query.get('trn_status') && query.get('trn_message')){
            setTimeout(() => {
                Swal.fire(query.get('trn_message'), '', query.get('trn_status'));
            }, 2000);
        }
    }, [query]);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);
    const [isLoadingPayNow, setIsLoadingPayNow] = useState(false);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/auth/nurse-appointment/my-appointment', token, nextPage);

    function handlePayNow(e, id){
        e.preventDefault();
        setIsLoadingPayNow(true);

        Swal.fire({
            title: 'Please wait...',
            text: "Don't refresh or close the page.",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        Swal.showLoading();
        window.location.replace(process.env.REACT_APP_API_URL + '/payment/payment-submit/' + id);
    }


    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">My nurse appointments</h6>
            </div>

            <div>
                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-5" />
                        </div>
                    </div>
                ))}

                {item_data.length>0 && item_data.map(appointment => (
                    <div  key={appointment.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="d-flex justify-content-between border-bottom">
                            <div className="">
                                <h6 className="m-0">Total: {appointment.payment_later ? <span className="small text-info">Payment not ready yet</span> : <MyCF value={appointment.total_amount}/>}</h6>
                                <p className="m-0 font-xm fw-500 text-muted">{moment(appointment.created_at).format('Do MMMM, YYYY hh:mm A')}</p>
                            </div>
                            <div className="">
                                <h6 className="m-0 text-end">Appointment Id: {appointment.id}</h6>
                                <p className="m-0 text-muted font-xm text-end">Payment status: <span className={appointment.payment_status_style}>{appointment.payment_status}</span></p>

                            </div>
                        </div>

                        {appointment.payment_status === 'Pending' && !appointment.payment_later &&
                            <div className="text-end py-2">
                                <form onSubmit={e => handlePayNow(e, appointment.need_payment_crypt_id)}>
                                    <SubmitBtn isLoading={isLoadingPayNow} className="btn-master px-3 py-1" title="Pay now"/>
                                </form>
                            </div>
                        }

                        <AppointmentTracker status={appointment.status}/>

                        <div className="">
                            <Link to={`/nurse-appointment/nurse/${appointment.nurse.id}`} className="text-decoration-none">
                                <NurseShortList
                                    nurse={appointment.nurse}
                                />
                            </Link>
                            <div className="bg-light p-2 row row-cols-lg-2 justify-content-between">
                                <div className="small">Appointment Date: <span className="fw-bold">{appointment.app_date ?? 'Any'}</span></div>
                                <div className="small text-lg-end">Service type: <span className="fw-bold">{upperFirst(lowerCase(appointment.nurse_service))} {appointment.more_day ? ' , ' + appointment.more_day + ' days' : ''}</span></div>
                            </div>
                        </div>
                        <div className="text-end pt-2">
                            {appointment.status === 'Completed' && !appointment.reviewed && <Link to={{ 
                                pathname: '/add-review',
                                state: {
                                    api_url: `/api/review/store/nurse-review/${appointment.nurse_id}/appointment/${appointment.id}`, 
                                    redirect_url: '/profile/my-nurse-appointment',
                                } 
                            }} className="btn btn-sm btn-outline-primary btn-outline-none">Write a review</Link>}
                        </div>
                    </div>
                ))}

                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-2">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>
        </div>
    )
}