import { createSlice } from '@reduxjs/toolkit';
import { removeStorage, setStorage } from '../app/storage';

function getStorage(key, need_parse = false){
    if(typeof(Storage) !== "undefined") {
        if (localStorage.getItem(key) !== null) {
            if(need_parse) {
                return JSON.parse(localStorage.getItem(key));
            } 
            else {
                return localStorage.getItem(key);
            }
        } 
        else {
            return [];
        }
    } 
    else {
        return [];
    }
}

export const wishlistSlice = createSlice({
    name: "wishlist",
    initialState : {
        cawishlistrt: getStorage('wishlist', true)
    },

    reducers: {
        add_wishlist: (state, action) => {
            setStorage('wishlist', action.payload, true);
            state.wishlist = getStorage('wishlist', true);
        },

        delete_wishlist: (state, action) => {
            removeStorage('wishlist');
        }
    },

});

export const { add_wishlist } = wishlistSlice.actions;

export const selectWishlist = (state) => state.wishlist.wishlist;

export default wishlistSlice.reducer;
