import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useScrollPosition from "../../hooks/useScrollPosition";
import api from "../../services/api";
import { selectToken, selectUser } from "../../slice/userSlice";
import toast from "../../utlities/toast";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import InputClassic from "../../components/form/InputClassic";
import TextAreaClassic from "../../components/form/TextAreaClassic";
import InputSelectClassic from "../../components/form/InputSelectClassic";
import SubmitBtn from "../../components/form/SubmitBtn";
import useQuery from "../../hooks/useQuery";
import { useHistory } from "react-router-dom";

export default function Wishlist() {
    useScrollPosition('my-position', 150);

    let query = useQuery();
    const history = useHistory();

    const token = useSelector(selectToken);
    const user  = useSelector(selectUser);

    const [isLoading, setIsLoading]                               = useState(false);
    const [isLoadingShippingAddress, setIsLoadingShippingAddress] = useState(true);

    const [districtLists, setDistrictLists] = useState([]);
    const [allError, setAllError]           = useState([]);

    const [name, setName]                             = useState(user?.name ?? '');
    const [phone, setPhone]                           = useState(user?.phone ?? '');
    const [country_id, setCountry_id]                 = useState('');
    const [district_id, setDistrict_id]               = useState('');
    const [sub_district_id, setSub_district_id]       = useState('');
    const [post_code, setPost_code]                   = useState('');
    const [effective_delivery, setEffective_delivery] = useState('');
    const [location, setLocation]                     = useState('');

    function setData(shippingAddress){
        setCountry_id(shippingAddress?.country_id ?? '');
        setDistrict_id(shippingAddress?.district_id ?? '');
        setSub_district_id(shippingAddress?.sub_district_id ?? '');
        setPost_code(shippingAddress?.post_code ?? '');
        setEffective_delivery(shippingAddress?.effective_delivery ?? '');
        setLocation(shippingAddress?.location ?? '');
    }


    useEffect(() => {
        api(token).get('/api/auth/get-shipping-address-with-district-list-with-child')
        .then(response => {
            setIsLoadingShippingAddress(false);
            setData(response.data.shipping_address);
            setDistrictLists(response.data.district_lists);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoadingShippingAddress(false);
            toast(error.message, 'error')
        });
        return () => { 
            setData([]);
            setDistrictLists([]);
        }
    }, [token]);


    function handleSubmitShippingAddress(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            name               : name,
            phone              : phone,
            country_id         : country_id,
            district_id        : district_id,
            sub_district_id    : sub_district_id,
            post_code          : post_code,
            effective_delivery : effective_delivery,
            location           : location,
        };

        api(token).post('/api/auth/update-shipping-address', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            toast(response.data.message, response.data.icon);
            if(query.get('_reb') === 'checkout'){
                history.push('/cart/checkout');
            }
            else if(query.get('_reb') === 'upload_prescription'){
                history.push('/profile/upload-prescription');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        })
    }



    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">Shipping Address</h6>
            </div>
            <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                {isLoadingShippingAddress ? 
                    <div className="row justify-content-between">
                        {Array.from(Array(8)).map((item,index) => (
                            <div key={index} className="col-lg-6">
                                <ItemLoader classList="py-4 my-3 shadow-sm"/>
                            </div>
                        ))}
                    </div>
                    : 
                    <form onSubmit={handleSubmitShippingAddress} className="needs-validation" noValidate>
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <InputClassic data={name} setOnChange={setName} error={allError['name']} label="Name" htmlFor="name" required={true}/>
                            </div>

                            <div className="col-lg-6">
                                <InputClassic data={phone} setOnChange={setPhone} error={allError['phone']} label="Phone" htmlFor="phone" required={true}/>
                            </div>

                            <div className="col-lg-6">
                                <InputSelectClassic data={country_id} setOnChange={setCountry_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                    elements={<>
                                        <option disabled className="bg-light text-info fw-bold" value="">Select country</option>
                                        <option value={1}>Bangladesh</option>
                                    </>}
                                />
                            </div>

                            <div className="col-lg-6">
                                <InputSelectClassic data={district_id} setOnChange={setDistrict_id} error={allError['district_id']} label="District" htmlFor="district_id" required={true}
                                    elements={<>
                                        <option className="bg-light text-info fw-bold" value="">Select District</option>
                                        {districtLists?.map(districtList => (
                                            <option key={districtList.id} value={districtList.id}>{districtList.name}</option>
                                        ))}
                                    </>}
                                />
                            </div>

                            <div className="col-lg-6">
                                <InputSelectClassic data={sub_district_id} setOnChange={setSub_district_id} error={allError['sub_district_id']} label="Sub District" htmlFor="sub_district_id" required={true}
                                    elements={<>
                                        <option className="bg-light text-info fw-bold" value="">Select Sub district</option>
                                        {districtLists?.find(district => district.id === parseInt(district_id))?.sub_districts?.map(sub_districtList => (
                                            <option key={sub_districtList.id} value={sub_districtList.id}>{sub_districtList.name}</option>
                                        ))}
                                    </>}
                                />
                            </div>

                            <div className="col-lg-6">
                                <InputClassic data={post_code} setOnChange={setPost_code} error={allError['post_code']} label="Post Code" htmlFor="post_code"/>
                            </div>

                            <div className="col-12">
                                <TextAreaClassic data={location} setOnChange={setLocation} label='Location' htmlFor='location' autoFocus={false} error={allError['location']} required={true}/>
                            </div>
                        </div>

                        <div className="border rounded p-3 mb-3">
                            <h6 className="d-flex ">Select a label for effective delivery</h6>
                            <div className="d-flex justify-content-evenly">
                                <div onClick={e => setEffective_delivery('Home')}
                                    role="button"
                                    className={`border p-3 rounded d-flex ${effective_delivery === "Home" ? 'border-info text-info shadow-sm':''}`}>
                                    <div><i className="bi bi-house-door-fill"></i></div>
                                    <div className="px-2">Home</div>
                                </div>

                                <div onClick={e => setEffective_delivery('Office')}
                                    role="button"
                                    className={`border p-3 rounded d-flex ${effective_delivery === "Office" ? 'border-primary text-primary shadow-sm':''}`}>
                                    <div><i className="bi bi-briefcase-fill"></i></div>
                                    <div className="px-2">Office</div>
                                </div>
                            </div>
                            {allError['effective_delivery'] && <div className="alert alert-danger py-1 my-2">{allError['effective_delivery']}</div> }
                        </div>
                    
                        <div className="d-flex justify-content-end">
                            <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                        </div>
                    </form>
                }
            </div>

        </div>
    )
}
