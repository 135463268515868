import { Link } from "react-router-dom";
import upload_prescription from "../assets/img/shop/upload_prescription.png";
import reorder_medicine from "../assets/img/shop/reorder_medicine.png";
import medicine_request from "../assets/img/shop/medicine_request.png";
import call_to_order from "../assets/img/shop/call_to_order.png";

export default function EasyShop(){

    return (
        <>
            <div className="row row-cols-2 row-cols-md-4 justify-content-between bg-white shadow-sm m-0">
                <div className="p-1 p-md-3">
                    <div className="bg-light">
                        <Link to="/profile/upload-prescription" className="btn btn-outline-info btn-outline-none w-100">
                            <img src={upload_prescription} alt="" className="img-fluid" />
                        </Link>
                    </div>
                </div>
                <div className="p-1 p-md-3">
                    <div className="bg-light">
                        <Link to="/profile/my-order" className="btn btn-outline-info btn-outline-none w-100">
                            <img src={reorder_medicine} alt="" className="img-fluid" />
                        </Link>
                    </div>
                </div>
                <div className="p-1 p-md-3">
                    <div className="bg-light">
                        <Link to="/profile/medicine-request" className="btn btn-outline-info btn-outline-none w-100">
                            <img src={medicine_request} alt="" className="img-fluid" />
                        </Link>
                        {/* <button type="button" className="btn btn-outline-info btn-outline-none w-100"  data-bs-toggle="modal" data-bs-target="#callToOrderModal">
                            <img src={medicine_request} alt="" className="img-fluid" />
                        </button> */}
                    </div>
                </div>
                <div className="p-1 p-md-3">
                    <div className="bg-light">
                        <button type="button" className="btn btn-outline-info btn-outline-none w-100"  data-bs-toggle="modal" data-bs-target="#callToOrderModal">
                            <img src={call_to_order} alt="" className="img-fluid" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="callToOrderModal" tabIndex="-1" aria-labelledby="callToOrderModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header border-0 shadow bg-master text-white-50">
                            <h5 className="modal-title text-white" id="callToOrderModalLabel">Contact phone number</h5>
                            <button type="button" className="btn-close outline-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-light">
                            <div className="px-lg-3 py-4">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div><h5 className="m-0 text-secondary">+8801945-010304</h5></div>
                                    <div><a className="btn btn-master outline-none" href="tel:+8801945010304"><i className="bi bi-telephone"></i> Call</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer bg-secondary bg-opacity-25 border-0 p-2">
                            <button type="button" className="btn btn-sm btn-secondary outline-none" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}