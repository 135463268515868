import ItemsRequest from "../request/ItemsRequest"
import ItemSlickSlider from "./ItemSlickSlider";

export default function AlternativeBrandProduct({productId}) {

    const items = ItemsRequest(`/api/alternative-brand-product/${productId}`);

    return (
        <section>
            {items.length > 0 && <div className="container py-3 pt-lg-4">
                <div className="bg-white shadow-sm">
                    <div className="bg-light d-flex p-2">
                        <span><i className="bi bi-list"></i></span>
                        <h5 className="px-2 m-0">Alternative brand</h5>
                    </div>
                    <div className="p-3">
                        <ItemSlickSlider items={items}/>
                    </div>
                </div>
            </div>}
        </section>
    )
}
