import axios from 'axios';
import { useEffect, useState } from 'react';
import api from "../services/api";

let cancelToken;

export default function useItems(api_path, token, nextPage) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [item_data, setItem_data] = useState([]);
    const [items, setItems] = useState([]);
    const [paginatorIsLoading, setPaginatorIsLoading] = useState(false);

    useEffect(() => {
        return () => {setItems([]);}
    },[]);

    useEffect(() => {
        if(nextPage === 1) setIsLoading(true);
        setPaginatorIsLoading(true)
        setError(false);

        let query_string = '?page=' + nextPage;
        if(cancelToken) cancelToken.cancel();
        cancelToken = axios.CancelToken.source();

        api(token ?? '').get(api_path + query_string, {cancelToken: cancelToken.token})
        .then(response => {
            // console.log(response.data);
            setItem_data(prevItems => {
                return [...new Set([...prevItems, ...response.data.data])]
            });
            setItems(response.data);
            window.history.replaceState(null, "", query_string);
            setIsLoading(false);
            setPaginatorIsLoading(false);

        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setPaginatorIsLoading(false);
        });
        return () => {}

    }, [api_path, token, nextPage]);

    return { item_data, items, isLoading, paginatorIsLoading, error}
}