import { useRef } from "react"
import { debounce } from 'lodash';

export default function SearchBar({onChangeSearch, handleParentCallbackResetAll}) {

    const searchRef = useRef();

    const handleSearchChange = debounce((search_text) => {
        onChangeSearch(search_text);
    }, 700);

    function handleResetAll(e){
        e.preventDefault();
        searchRef.current.value = '';
        handleParentCallbackResetAll();
    }

    return (
        <div>
            <div className="d-flex gap-5 justify-content-center pb-3" id="dropdownFilter">
                <div className="border-0 shadow-sm w-100 search-box position-relative">
                    <div className="d-flex bg-light p-1 p-md-3">
                        <div className="input-group px-1">
                            <input ref={searchRef} onChange={e => handleSearchChange(e.target.value)} type="search" className="form-control form-control-lg outline-none" placeholder="Search..."/>
                            <button type="submit" className="btn btn-success btn-outline-none"><i className="bi bi-search"></i></button>
                        </div>
                        <button onClick={handleResetAll} type="reset" className="btn btn-outline-danger btn-outline-none px-2 rounded">Reset</button>
                    </div>
                    {/* <ul className="list-unstyled mb-0 w-100 shadow-lg position-absolute top-100 bg-white p-1 p-md-3 z-5">
                        <li tabIndex="0">Action</li>
                        <li tabIndex="0">Another action</li>
                        <li tabIndex="0">Another action</li>
                    </ul> */}
                </div>
            </div>
        </div>
    )
}
