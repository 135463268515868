import BtnLoader from "../loader/btn_loader/BtnLoader";

export default function MostSimplePaginator({data, load_paginator, parentCallback}) {

    return (
        <>
            {data.current_page < data.last_page && 
                <div className="pt-3">
                    <div className="d-flex justify-content-center shadow-sm p-2">
                        <button onClick={() => parentCallback(data.current_page + 1)} 
                            className={`btn btn-master outline-none ${load_paginator ? 'disabled' : ''}`} 
                            type="button">
                            <div className="d-flex justify-content-center">
                                <div>Load More </div> 
                                {load_paginator && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            }
        </>
    )
}
