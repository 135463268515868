import { util } from "peerjs";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { SocketContext } from "../../context/SocketContext";
import { selectUser } from "../../slice/userSlice";
import Avatar from "../Avatar";

export default function CallToUser({patient, isVideo = true}){

    const user = useSelector(selectUser);
    const [called, setCalled] = useState(false);
    const { myVideo, userVideo, callStatus, sendCallRequest, localStream, remoteStream, leaveCall, mediaPermission } = useContext(SocketContext);
 
    useEffect(() => {
        if(callStatus === 'end-call') setCalled(false)
    }, [callStatus])

    const checkBrowserSuported = () => {
        if(util.supports.data) {
            return true;
        }
        else {
            Swal.fire({
                title: 'This Browser not support Audio and Video call',
                text: 'Use Chrome or Mozilla or Internet explorer or any other browser that support audio and video call.',
                showConfirmButton: true,
                confirmButtonText: 'Close',
                confirmButtonColor: 'red',
            });
            return false;
        }
    }

    const handleCall = async () => {
        if(checkBrowserSuported()){
            sendCallRequest(patient.id, isVideo);
            setCalled(true);
        }
    }

    const handleLeaveCall = async () => {
        leaveCall(patient.id);
        setCalled(false);
    }


    return (
        <>
            {!called && (
                <div className="py-4 text-center">
                    <button onClick={() => handleCall()} className="btn btn-primary outline-none">
                        {isVideo ? (
                            <i className="bi bi-camera-video-fill icon-lg"></i> 
                        ) : (
                            <i className="bi bi-telephone-fill icon-lg"></i> 
                        )}
                        <span className="px-2">Call Now</span>
                    </button>
                </div>
            )}
            {called && (
                <div className="modal fade show d-block" id="callReceiverModal" tabIndex="-1" aria-labelledby="callReceiverModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-body bg-dark p-0 m-0">
                                <div className="">
                                    {mediaPermission && (
                                        <div className='receiver-video-container'>
                                            {localStream && (
                                                <video className={`my-video mirror-video border rounded-1 shadow-sm bg-dark ${isVideo ? 'd-block' : 'd-none'}`} ref={myVideo} muted autoPlay></video>
                                            )}
                                            {remoteStream &&  (
                                                <video className={`user-video mirror-video bg-dark ${isVideo ? 'd-block' : 'd-none'}`} ref={userVideo} autoPlay></video>
                                            )}

                                            {localStream && !isVideo && (
                                                <div className="audio-call-container">
                                                    <div className="d-flex justify-content-center align-items-center text-white g-2">
                                                        {localStream && (
                                                            <div className="d-flex flex-column align-items-center">
                                                                {user.avatar ? <Avatar sizeClass="my-lg-avatar" avatarPath={user.avatar.substring(0, 4).toLowerCase() === 'http' ? user.avatar : process.env.REACT_APP_STORAGE + user.avatar} /> : <i className="bi bi-person-circle icon-huge"></i>}
                                                                <p className="small m-0 pt-2">{user.name}</p>
                                                            </div>
                                                        )}
                                                        {remoteStream && (<>
                                                            <div className="px-2"><i className="bi bi-phone-vibrate icon-lg"></i></div>
                                                            <div className="d-flex flex-column align-items-center gy-3">
                                                                {patient.avatar ? <Avatar sizeClass="my-lg-avatar" avatarPath={patient.avatar.substring(0, 4).toLowerCase() === 'http' ? patient.avatar : process.env.REACT_APP_STORAGE + patient.avatar} /> : <i className="bi bi-person-circle icon-huge"></i>}
                                                                <p className="small m-0 pt-2">{patient.name || 'Receiver'}</p>
                                                            </div>
                                                        </>)}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="call-controller">
                                                <div className="d-grid gap-2 d-flex">
                                                    <button onClick={handleLeaveCall} className='btn btn-lg btn-danger rounded-circle shadow'><i className="bi bi-telephone-x"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}