import { useState } from "react";
import PersonalInformationEdit from "./PersonalInformationEdit";

export default function PersonalInformation({ambulance, updateAmbulance}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div>
            <div className="d-flex justify-content-between bg-primary bg-opacity-10 shadow-sm rounded p-1 mb-2">
                <h6 className="align-self-center m-0">Personal Information</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div className="row justify-content-start px-2">
                <dt className="col-5 col-lg-4">Driver Name </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.driver_name}</dd>
                
                <dt className="col-5 col-lg-4">Owner Name </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.owner_name}</dd>
                
                <dt className="col-5 col-lg-4">Country </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.country?.name}</dd>

                {ambulance.district && <>
                    <dt className="col-5 col-lg-4">District </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.district.name}</dd>
                </>}

                {ambulance.sub_district && <>
                    <dt className="col-5 col-lg-4">Sub District </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.sub_district.name}</dd>
                </>}

                <dt className="col-5 col-lg-4">Location </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.location}</dd>

                <dt className="col-5 col-lg-4">Contact phone number </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.contact_phone}</dd>

                <dt className="col-5 col-lg-4">Ambulance code </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.ambulance_code}</dd>
            </div>}

            {isEdit && <PersonalInformationEdit ambulance={ambulance} updateAmbulance={updateAmbulance} cancelEdit={(value) => setIsEdit(false)}/>}
            
        </div>
    )
}

