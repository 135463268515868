import { useEffect, useState } from "react";
import { debounce } from 'lodash';

export default function useWindowScroll(){
    const [scrollTop, setScrollTop] = useState(window.pageYOffset);
    useEffect(() => {
        const scrollHandle = debounce(() => {setScrollTop(window.pageYOffset);}, 300);
        window.addEventListener('scroll', scrollHandle);
        return () => {window.removeEventListener('scroll', scrollHandle);}
    }, [scrollTop]);
    return scrollTop;
}
