import { lowerCase, upperFirst } from 'lodash';
import React from 'react'
import { useState } from 'react'
import CompleteTaskBtn from '../../../components/button/CompleteTaskBtn';
import UserList from '../../../components/UserList';

const AppointmentItem = ({appointment}) => {
    const [status, setStatus] = useState(appointment.status);

    return (
        <div key={appointment.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
            <div className="d-flex justify-content-between border-bottom">
                <div className="">
                    <h6 className="m-0 text-end">Appointment Id: {appointment.id}</h6>
                </div>
                <div className="">
                    <p className="m-0">Status: <span className={appointment.appointment_status_style}>{status}</span></p>
                </div>
            </div>

            <div className='row row-cols-lg-2 px-2 overflow-auto'>
                <UserList user={appointment.user}/>

                <div className="py-2">
                    <h6 className="m-0 p-1 small bg-light">Contact info</h6>
                    <p className="text-dark small mb-0"><span className="fw-500">Contact no: </span> {appointment.phone_no}</p>
                    <p className="text-dark small mb-0"><span className="fw-500">Country: </span>{appointment.country?.name}</p>
                    <p className="text-dark small mb-0"><span className="fw-500">District: </span>{appointment.district?.name}</p>
                    <p className="text-dark small mb-0"><span className="fw-500">Sub district: </span> {appointment.sub_district?.name}</p>
                    <p className="text-dark small mb-0"><span className="fw-500">Location: </span>{appointment.location}</p>
                    <p className="text-dark small mb-0"><span className="fw-500">Purpose: </span>{appointment.purpose}</p>
                </div>
            </div>

            <div className="p-2 border border-info rounded bg-info bg-opacity-10">
                <div className="row">
                    <div className="col-4 col-md-3 col-lg-2"><h5 className="small">Date</h5></div>
                    <div className="col-8 col-md-9 col-lg-10"><h6 className="small text-success">{appointment.app_date}</h6></div>
                    <div className="col-4 col-md-3 col-lg-2"><h5 className="small">Service type</h5></div>
                    <div className="col-8 col-md-9 col-lg-10"><h6 className="small text-success">{upperFirst(lowerCase(appointment.nurse_service))}</h6></div>
                    {appointment.nurse_service === 'more_then_1_day' && (<>
                        <div className="col-4 col-md-3 col-lg-2"><h5 className="small">Days</h5></div>
                        <div className="col-8 col-md-9 col-lg-10"><h6 className="small text-success">{appointment.more_day}</h6></div>
                    </>)}
                </div>
            </div>

            <div className="pt-1 d-flex justify-content-end">
                <CompleteTaskBtn appointment={appointment} status={status} setStatus={setStatus} apiPath={`/api/nurse/appointment/${appointment.id}/make-completed`} />
            </div>

        </div>
    )
}

export default AppointmentItem