import PopularDoctor from "../../components/PopularDoctor";
import Specialist from "../../components/Specialist";

export default function InVideoSpecialist() {
    return (
        <>
            <PopularDoctor api_url="/api/popular-in-video-doctor" next_link="/in-video-consultancy" next_route="/video-consultancy/doctor"/>
            <Specialist next_link="/in-video-consultancy"/>
        </>
    )
}
