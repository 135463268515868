import { useSelector } from "react-redux"
import { selectUser } from "../../slice/userSlice";
import Mobile from "../../components/profile/Mobile";
// import Password from "../../components/profile/Password";
import PersonalInformation from "../../components/profile/PersonalInformation";
import ProfilePhoto from "../../components/profile/ProfilePhoto";

export default function Profile() {
    const user = useSelector(selectUser);

    return (
        <div>
            {/* {(!user.email || !user.phone || !user.is_password) && <div className="bg-white shadow-sm p-2 mb-3 border-start border-4 border-master rounded">
                <div className="d-flex justify-content-start">
                    <span className="px-2"><i className="bi bi-exclamation-circle-fill text-info"></i></span>
                    <div className="small text-muted"> 
                        We don't have your {!user.email ? 'email address, ':''} {!user.phone ? 'phone number, ':''} {!user.is_password ? 'password ':''} yet!
                    </div>
                </div>
            </div>} */}

            <div className="p-2 bg-white shadow-sm rounded">

                <PersonalInformation/>
                
                {!user.phone && <Mobile/>}

                {/* {!user.is_password && <Password/>} */}

                <ProfilePhoto/>
                
            </div>
        </div>
    )
}
