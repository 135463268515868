import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLang } from "../../slice/langSlice";
import ItemLoader from "../loader/item_loder/ItemLoader";

export default function CategoryFilter({categories, handleParentCallbackCategoryQuery}) {
    const lang = useSelector(selectLang);
    const [expendCategory, setExpendCategory] = useState(true);

    function handleCategoryFilter(e, category_query){
        e.preventDefault();
        handleParentCallbackCategoryQuery(category_query)

        const topPosition = document.getElementById('items-position').offsetTop;
        window.scrollTo(0, topPosition - 150);
    }


    return (
        <>
            <div className="category-hero">
                <button onClick={() => setExpendCategory(!expendCategory)} className="btn-master px-3 py-2 w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                    <div className="d-flex justify-content-between">
                        <span><i className="bi bi-list"></i></span>
                        <span>All Category</span>
                        {expendCategory ? 
                            <span><i className="bi bi-chevron-down"></i></span>
                            :
                            <span><i className="bi bi-chevron-up"></i></span>
                        }
                    </div>
                </button>
            
                <div className="collapse show" id="collapseExample">
                    <div className="card card-body border-0 shadow-sm rounded-0 py-2">
                        <div className="list-group small">
                            {categories.length < 1 &&
                                Array.from(Array(9)).map((item,index) => (
                                    <ItemLoader key={index} classList="py-2 my-2 shadow-sm w-100"/>
                                ))
                            }
                            {categories.slice(0, 8).map((category) => (
                                <div key={category.id}>
                                    <button onClick={(e) => handleCategoryFilter(e, category.id)} className="list-group-item list-group-item-action border-0 py-1">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded" src={process.env.REACT_APP_STORAGE + category.logo} alt="" width="26px"/>
                                            <div className="align-self-center mx-1">{lang === 'bn' ? category.bn_name : category.name}</div>
                                        </div>
                                    </button>
                                </div>
                            ))}
                            {categories.length > 8 && 
                            <div className="text-center">
                                <button className="btn-master px-3 small rounded" data-bs-toggle="modal" data-bs-target="#moreCategoryModal">More</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="moreCategoryModal" tabIndex="-1" aria-labelledby="moreCategoryModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                    <div className="modal-content">
                    <div className="modal-header border-0 shadow">
                        <h5 className="modal-title" id="moreCategoryModalLabel">More category</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-light">
                        <div className="px-lg-3 pb-4">
                            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-xl-6 justify-content-center">
                                {categories.map((category) => (
                                    <div key={category.id} className="g-3 g-md-4">
                                        <div onClick={(e) => handleCategoryFilter(e, category.id)} className="card border-0 shadow-sm rounded-sm" role="button" data-bs-dismiss="modal">
                                            <div className="card-body">
                                                <img className="img-fluid" src={process.env.REACT_APP_STORAGE + category.logo} alt=""/>
                                            </div>
                                            <div className="card-footer border-0 bg-primary bg-opacity-10 px-1">
                                                <h6 className="text-center m-0">{lang === 'bn' ? category.bn_name : category.name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer bg-dark p-0">
                        <button type="button" className="btn btn-sm btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
