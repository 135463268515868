import { useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../../components/Avatar";
import BoxEmpty from "../../components/BoxEmpty";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import useItems from "../../hooks/useItems";
import useScrollPosition from "../../hooks/useScrollPosition";
import { selectToken } from "../../slice/userSlice";

export default function MyBrokerEarnings() {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/auth/get-my-benefit-list', token, nextPage);

    const statusColor = (status) => {
        switch (status) {
            case 'Ready': return 'text-primary';
            case 'Completed': return 'text-success';
            default: return 'text-warning';
        }
    }


    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">My Earnings</h6>
            </div>

            <div>
                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-4" />
                        </div>
                    </div>
                ))}
                {item_data.length > 0 &&
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="table-responsive table">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle p-1 py-3">Patient <br /> Avatar</th>
                                        <th className="text-center align-middle p-1 py-3">Patient <br /> Name</th>
                                        <th className="text-center align-middle p-1 py-3">Total Cost</th>
                                        <th className="text-center align-middle p-1 py-3">Exchange <br /> Rate <br/>(INR <i className="bi bi-arrow-right-short"></i> BDT)</th>
                                        <th className="text-center align-middle p-1 py-3">Earning <br /> %</th>
                                        <th className="text-center align-middle p-1 py-3">Earning <br/> Amount</th>
                                        <th className="text-center align-middle p-1 py-3">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item_data.map(item => (
                                        <tr>
                                            <th className="text-center align-middle p-1">
                                                <Avatar avatarPath={process.env.REACT_APP_STORAGE + item.patient.avatar} sizeClass='my-sm-avatar' rounded='rounded'/>
                                            </th>
                                            <td className="text-center align-middle p-1">{item.patient.name}</td>
                                            <td className="text-center align-middle p-1 small">{item.total_cost_in_bdt} BDT / {item.total_cost_in_inr} INR</td>
                                            <td className="text-center align-middle p-1">{item.exchange_rate}</td>
                                            <td className="text-center align-middle p-1">{item.benefit_percentage}</td>
                                            <td className="text-center align-middle p-1 small text-info fw-500">{item.total_benefit_in_bdt} BDT / {item.total_benefit_in_inr} INR</td>
                                            <td className={`text-center align-middle p-1 fw-500 ${statusColor(item.status)}`}>{item.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-2">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>

        </div>
    )
}