import { useState } from "react";
import AmbulanceInformationEdit from "./AmbulanceInformationEdit";

export default function AmbulanceInformation({ambulance, updateAmbulance}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="py-2">
            <div className="d-flex justify-content-between bg-primary bg-opacity-10 shadow-sm rounded p-1 my-2">
                <h6 className="align-self-center m-0">Ambulance Information</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div className="row justify-content-start px-2">
                <dt className="col-5 col-lg-4">Vehicle number </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.vehicle_no}</dd>
                
                <dt className="col-5 col-lg-4">Ambulance area/starting area </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.starting_area}</dd>
                
                <dt className="col-5 col-lg-4">Ambulance type </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{ambulance.condition}</dd>
            </div>}

            {isEdit && <AmbulanceInformationEdit ambulance={ambulance} updateAmbulance={updateAmbulance} cancelEdit={(value) => setIsEdit(false)}/>}
            
        </div>
    )
}

