import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import api from "../services/api";
import { logout, selectToken } from "../slice/userSlice";
import toast from "../utlities/toast";

export default function LogoutBtn({classList}) {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const {t} = useTranslation();

    function handleLogout(e){
        e.preventDefault();
        logoutHandler();

        dispatch(logout());
        toast('Logout successfull!', 'success')
    }

    function logoutHandler(){
        api(token).post('/api/auth/logout');
    }


    return (
        <>
            <button onClick={handleLogout} className={classList} ><i className="bi bi-box-arrow-left"></i> {t("Logout")}</button>
        </>
    )
}
