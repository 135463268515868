import { useEffect } from "react";
import { useSelector } from "react-redux";
import api from "../services/api";
import { selectToken, selectUser } from "../slice/userSlice";

export default function useUpdateUserStatus() {
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    useEffect(() => {
        function updateUserStatus(){
            api(token).post('/api/update-user-status');
        }

        if(user && token){
            if(user.roles.find(role => role.id === 2)){
                updateUserStatus();
                var intervalId = setInterval(updateUserStatus, 1000*120);
            }
        }
        return () => {
            clearInterval(intervalId);
        }
    }, [user,token]);
}
