import { useState } from "react";
import PersonalInformationEdit from "./PersonalInformationEdit";

export default function PersonalInformation({nurse, updateNurse}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div>
            <div className="d-flex justify-content-between bg-warning bg-opacity-10 shadow-sm rounded p-1 mb-2">
                <h6 className="align-self-center m-0">Personal Information</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div className="row justify-content-start px-2">
                <dt className="col-5 col-lg-4">Country </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.country?.name }</dd>

                {nurse.district && <>
                    <dt className="col-5 col-lg-4">District </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.district.name}</dd>
                </>}

                {nurse.sub_district && <>
                    <dt className="col-5 col-lg-4">Sub District </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.sub_district.name}</dd>
                </>}

                <dt className="col-5 col-lg-4">Location </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.location}</dd>

                <dt className="col-5 col-lg-4">Present address </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.present_add}</dd>

                <dt className="col-5 col-lg-4">Permanent address </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.permanent_add}</dd>

                <dt className="col-5 col-lg-4">Experience </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.final_experience}</dd>

                <dt className="col-5 col-lg-4">Nurse code </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{nurse.nurse_code}</dd>
            </div>}

            {isEdit && <PersonalInformationEdit nurse={nurse} updateNurse={updateNurse} cancelEdit={(value) => setIsEdit(false)}/>}
            
        </div>
    )
}

