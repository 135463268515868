import useScrollTop from "../../hooks/useScrollTop";

export default function ReturnPolicy() {
    useScrollTop();

    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center fw-bold">Return Policy</div>
            </div>

            <div className="bg-white rounded shadow-sm p-2 p-md-4">
                <ul className="text-secondary">
                    <li><p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis placeat sequi fugiat eligendi, repellendus fugit minima sit, culpa ex quae doloremque nemo quibusdam facilis beatae porro quis praesentium velit voluptate?</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusantium distinctio aspernatur nihil sequi possimus dolorum veniam laboriosam libero dignissimos id optio, nesciunt, at nobis labore est assumenda mollitia iure expedita obcaecati vel suscipit eveniet! Eum incidunt repellat nisi. Cum!</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quam consequatur iste? Veniam dolorem nulla asperiores deserunt fugit, quis consequuntur.</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta aut atque, libero maxime incidunt quos laboriosam quae tempore esse harum recusandae minima, veniam eum molestiae nisi, numquam alias fuga nam odit! Deleniti dignissimos doloribus nemo ex nostrum provident numquam rem accusantium nisi aperiam error at tempore, nam sit laboriosam eum?</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat quas fuga, assumenda unde ullam ut tenetur, iusto corporis minus velit illum consequatur eveniet saepe quasi voluptatum laborum tempora quibusdam dolorem hic expedita odit sit eaque eum itaque. Rerum, quaerat libero. Magnam quae explicabo non, doloremque quo distinctio voluptatum rem itaque soluta saepe at nemo laborum id facere molestias corporis a magni reiciendis perspiciatis aperiam quod architecto minus possimus numquam. Perspiciatis modi placeat odio sapiente delectus culpa soluta consequuntur accusamus commodi aperiam, voluptatibus unde doloremque, laboriosam, eum vel obcaecati. Nulla dignissimos totam rem corporis minima vero voluptates necessitatibus tempora sequi voluptatem.</p></li>
                    <li><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, explicabo.</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis placeat sequi fugiat eligendi, repellendus fugit minima sit, culpa ex quae doloremque nemo quibusdam facilis beatae porro quis praesentium velit voluptate?</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusantium distinctio aspernatur nihil sequi possimus dolorum veniam laboriosam libero dignissimos id optio, nesciunt, at nobis labore est assumenda mollitia iure expedita obcaecati vel suscipit eveniet! Eum incidunt repellat nisi. Cum!</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quam consequatur iste? Veniam dolorem nulla asperiores deserunt fugit, quis consequuntur.</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta aut atque, libero maxime incidunt quos laboriosam quae tempore esse harum recusandae minima, veniam eum molestiae nisi, numquam alias fuga nam odit! Deleniti dignissimos doloribus nemo ex nostrum provident numquam rem accusantium nisi aperiam error at tempore, nam sit laboriosam eum?</p></li>
                    <li><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat quas fuga, assumenda unde ullam ut tenetur, iusto corporis minus velit illum consequatur eveniet saepe quasi voluptatum laborum tempora quibusdam dolorem hic expedita odit sit eaque eum itaque. Rerum, quaerat libero. Magnam quae explicabo non, doloremque quo distinctio voluptatum rem itaque soluta saepe at nemo laborum id facere molestias corporis a magni reiciendis perspiciatis aperiam quod architecto minus possimus numquam. Perspiciatis modi placeat odio sapiente delectus culpa soluta consequuntur accusamus commodi aperiam, voluptatibus unde doloremque, laboriosam, eum vel obcaecati. Nulla dignissimos totam rem corporis minima vero voluptates necessitatibus tempora sequi voluptatem.</p></li>
                
                </ul>
                
            </div>
        </div>
    )
}
