import React from 'react';
import ItemsRequest from '../../request/ItemsRequest';
import ItemLoader from '../loader/item_loder/ItemLoader';

export default function CommonSlider({url}) {
    const sliders = ItemsRequest(url);

    return (
        <>
            {sliders.length < 1 ? <ItemLoader classList="shadow-sm w-100 common-slider-loading-h"/> 
            :
                <div className=" bg-white p-2">
                    <div className="slider-hero">
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                            <div className="carousel-indicators">
                                {sliders.map((slider, i) => (
                                    <button key={slider.id} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i} className={i === 0 ? 'active': ''} aria-current={i === 0 ? 'true': ''} aria-label={`Slide ${i+1}`}></button>
                                ))}
                            </div>
                            <div className="carousel-inner">
                                {sliders.map((slider, i) => (
                                    <div key={slider.id} className={`carousel-item ${i===0 ? ' active': ''}`}>
                                        <img src={process.env.REACT_APP_STORAGE + slider.image} className="d-block w-100" alt=""/>
                                    </div>
                                ))}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}
