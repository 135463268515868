import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import api from '../../services/api'
import { selectToken } from '../../slice/userSlice'
import toast from '../../utlities/toast'
import SubmitBtn from '../form/SubmitBtn'

const CompleteTaskBtn = ({appointment, status, setStatus, apiPath}) => {

    const token = useSelector(selectToken);
    const [isLoadingCompleted, setIsLoadingCompleted] = useState(false);

    const handleMakeCompleted = (e) => {
        e.preventDefault();
        setIsLoadingCompleted(true);

        api(token).put(apiPath)
        .then(response => {
            setIsLoadingCompleted(false);
            if(response.data.status === 'success'){
                setStatus('Completed');
                toast('Status successfully updated.', 'success');
            }
            else toast('Something worng!','error');
        })
        .catch(err => {
            setIsLoadingCompleted(false);
            toast('Something worng!','error');
        });
    }

    return (
        <>
            {status === 'Ready' && <form key={appointment.id} onSubmit={handleMakeCompleted}>
                <SubmitBtn title="Completed" isLoading={isLoadingCompleted} className="btn-master px-3 py-1"/>
            </form>}
        </>
    )
}

export default CompleteTaskBtn