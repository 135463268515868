import { useHistory } from "react-router-dom";
import toast from "../../../utlities/toast";

export default function EmailVerifySuccess() {

    const history = useHistory();
    toast('Email verification successful!', 'success');
    history.push('/');

    return (
        <></>
    );
}
