import PopularHospital from "../../components/PopularHospital";
import Specialist from "../../components/Specialist";

export default function InSpecialist() {
    return (
        <>
            <PopularHospital next_link="/in-doctor-appointment"/>
            <Specialist next_link="/in-doctor-appointment"/>
        </>
    )
}
