import { useState } from "react";
import { useSelector } from "react-redux";
import RadioClassic from "../../../../components/form/RadioClassic";
import Input from "../../../../components/form/Input";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function AmbulanceInformationEdit({ambulance, updateAmbulance ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const vehicle_no                = useFormInput(ambulance?.vehicle_no ?? '');
    const starting_area             = useFormInput(ambulance?.starting_area ?? '');
    const [condition, setCondition] = useState(ambulance?.condition ?? 'Non AC');

    function handleAmbulanceInformationSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            vehicle_no:      vehicle_no.value,
            condition:        condition,
            starting_area:   starting_area.value,
        };

        api(token).post('/api/ambulance/update/ambulance-information', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.ambulance){
                updateAmbulance(response.data.ambulance);
            }
            toast('Ambulance information successfully updated', 'success');
            setAllError([]);
            cancelEdit(false);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleAmbulanceInformationSubmit}>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Input data={vehicle_no} error={allError['vehicle_no']} label="Vehicle Number" htmlFor="vehicle_no" required={true}/>
                        </div>
                        
                        <div className="col-lg-6">
                            <Input data={starting_area} error={allError['starting_area']} label="Ambulance area/Starting area" htmlFor="starting_area" required={true}/>
                        </div>

                        <div className="col-12 py-3">
                            <p className="m-0 p-1 bg-light">Ambulance condition</p>
                            <RadioClassic data={condition} setOnChange={setCondition} htmlFor="ac" label="AC" value="AC" name="conditionRadios"/>
                            <RadioClassic data={condition} setOnChange={setCondition} htmlFor="non_ac" label="Non AC" value="Non AC" name="conditionRadios"/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}