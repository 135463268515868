import Echo from 'laravel-echo';
import { useCallback } from 'react';
import { useEffect, useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import api from '../services/api';
import { selectToken } from '../slice/userSlice';

window.Pusher = require('pusher-js');

const EchoContext = createContext();

const EchoContextProvider = ({children}) => {
    const token = useSelector(selectToken);

    const [pusherData] = useState({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_KEY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        forceTLS: true,
        disableState: true,
        authorizer: null
    });


    const authorizedPusher = useCallback(() => {
        return new Echo({
            broadcaster:  pusherData.broadcaster ,
            key:          pusherData.key ,
            cluster:      pusherData.cluster ,
            forceTLS:     pusherData.forceTLS ,
            disableState: pusherData.disableState ,
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        api(token).post('/api/broadcasting/auth', {socket_id: socketId, channel_name: channel.name})
                        .then((response) => {
                            callback(false, response.data);
                        })
                        .catch(error => {
                            callback(true, error);
                        });
                    }
                }
            }
        });
    }, [token, pusherData])


    const unAuthorizedPusher = useCallback(() => {
        return new Echo({
            broadcaster:  pusherData.broadcaster ,
            key:          pusherData.key ,
            cluster:      pusherData.cluster ,
            forceTLS:     pusherData.forceTLS ,
            disableState: pusherData.disableState ,
        });
    }, [pusherData])


    useEffect(() => {
        if(token) window.Echo = authorizedPusher();
        else window.Echo = unAuthorizedPusher();
    }, [token, authorizedPusher, unAuthorizedPusher])

    return (
        <EchoContext.Provider value={{}}>{children}</EchoContext.Provider>
    )
}

export { EchoContextProvider, EchoContext };