import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import useFormInput from "../hooks/useFormInput";
import api from "../services/api";
import { setFromLocation } from "../slice/fromLocationSlice";
import { login, setUser } from "../slice/userSlice";
import toast from "../utlities/toast";
import BtnLoader from "./loader/btn_loader/BtnLoader";
import SocialButton from "./SocialButton";

export default function SocialLogin({from, showForm, setShowForm}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const [localPrivider, setLocalProvider] = useState('');
    const [localToken, setLocalToken]       = useState('');
    const [required_email, setRequired_email] = useState(false);
    const [required_phone, setRequired_phone] = useState(false);
    const [isRegistrationLoading, setIsRegistrationLoading] = useState(false);
    const email = useFormInput('');
    const phone = useFormInput('');
    const [allError, setAllError] = useState([]);

    useEffect(() => {
        dispatch(setFromLocation(from));
    }, [dispatch, from]);

    const handleSocialLogin = (user, provider) => {

        // if(!user._profile.email){
        //     Swal.fire({title: 'Email dose not exist', text: '', icon: 'warning',});
        //     return;
        // }

        Swal.fire({
            title: 'Please wait...',
            text: "",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        Swal.showLoading();

        setIsLoading(true);

        api().post(`/api/auth/social-login/${provider}/${user._token.accessToken}`)
        .then(response => {
            setIsLoading(false);
            Swal.close();
            if(response.data.status === 'success'){
                dispatch(login(response.data.token));
                dispatch(setUser(response.data.user));
                toast(response.data.message, 'success');
                history.replace(from);
            }
            else if(response.data.status === 'require_accounts'){
                setRequired_email(response.data.required_email);
                setRequired_phone(response.data.required_phone);
                setShowForm(false);
                setLocalProvider(provider);
                setLocalToken(user._token.accessToken)
            }
            else {
                toast(response.data.message, 'warning');
            }
        })
        .catch((err) => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            Swal.close();
            toast(error.message, 'warning')
        });
    }


    const handleSocialRegistration = (e) => {
        e.preventDefault();
        setIsRegistrationLoading(true);

        const body = { 
            required_email: required_email,
            required_phone: required_phone,
            provider: localPrivider,
            token: localToken,
            email: email.value,
            phone: phone.value,
        };

        api().post(`/api/auth/social-registration`, body)
        .then(response => {
            setIsRegistrationLoading(false);
            if(response.data.status === 'success'){
                dispatch(login(response.data.token));
                dispatch(setUser(response.data.user));
                toast(response.data.message, 'success');
                history.replace(from);
            }
            else {
                toast(response.data.message, 'warning');
            }
        })
        .catch((err) => {
            const error = err.response ? err.response.data : err;
            
            setIsRegistrationLoading(false);
            toast(error.message, 'warning')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }


    const handleSocialLoginFailure = (error) => {
        // console.log(error);
        // Swal.fire({
        //     title: 'Something wrong!',
        //     text: 'Roload the page and try again',
        // }).then(() => {
        //     // window.location.reload();
        // })
    }
    
    return (
        <div className="py-3">
            {showForm ? 
                <>
                    <p className="text-secondary text-center mb-0">Login easily with your social media account</p>

                    <div className="d-grid gap-2 d-md-flex justify-content-center my-2">
                        <SocialButton
                            provider="facebook"
                            appId={process.env.REACT_APP_FACEBOOK_ID}
                            onLoginSuccess={(user) => handleSocialLogin(user, 'facebook')}
                            onLoginFailure={handleSocialLoginFailure}
                            className={`btn btn-primary outline-none ${isLoading ? 'disabled' : ''}`}
                        ><i className="bi bi-facebook bi-s px-2"></i>Sign in with Facebook
                        </SocialButton>
                        
                        <SocialButton
                            provider="google"
                            appId={process.env.REACT_APP_GOOGLE_ID}
                            onLoginSuccess={(user) => handleSocialLogin(user, 'google')}
                            onLoginFailure={handleSocialLoginFailure}
                            className={`btn btn-danger outline-none ${isLoading ? 'disabled' : ''}`}
                        ><i className="bi bi-google bi-s px-2"></i>Sign in with Google
                        </SocialButton>
                    </div>

                    <div className="">
                        <div className="d-flex justify-content-center align-items-center">
                            <hr className="w-50"/>
                            <div className="px-2 fw-bold text-secondary">OR</div>
                            <hr className="w-50"/>
                        </div>
                    </div>
                </>:<>
                    <form  className="py-2" onSubmit={handleSocialRegistration}>
                        <p className=" alert alert-info text-center">Please enter your {required_phone ? 'phone number' :''} {required_phone && required_email ? 'and':''} {required_email ? 'email':''} for successful social login</p>
                        {required_phone && 
                            <div className="input-group has-validation mb-4">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white"><i className="bi bi-telephone bi-m text-secondary"></i></span>
                                <input {...phone}
                                    type="text" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${allError['phone'] ? 'is-invalid border-danger' : ''}`}
                                    id="phone" 
                                    name="phone"  
                                    placeholder="+8801XXXXXXXXX" 
                                    autoComplete="off"/>
                                {allError['phone'] && <div className="invalid-tooltip">{allError['phone']}</div> }
                            </div>
                        }
                        {required_email && 
                            <div className="input-group has-validation mb-4">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white"><i className="bi bi-envelope bi-m text-secondary"></i></span>
                                <input {...email}
                                    type="email" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${allError['email'] ? 'is-invalid border-danger' : ''}`}
                                    id="email" 
                                    name="email"  
                                    placeholder="your.email@gmail.com" 
                                    autoComplete="off"/>
                                {allError['email'] && <div className="invalid-tooltip">{allError['email']}</div> }
                            </div>
                        }

                        <div className="d-grid">
                            <button className={`btn btn-success btn-outline-none ${isRegistrationLoading ? 'disabled' : ''}`} type="submit">
                                <div className="d-flex justify-content-center">
                                    <div>Submit </div> 
                                    {isRegistrationLoading && <BtnLoader/>}
                                </div>
                            </button>
                        </div>
                    </form>
                </>
            }
        </div>
    )
}
