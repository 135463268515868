import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InputSelect from "../../../../components/form/InputSelect";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function MyHospitalEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const hospital_id = useFormInput(doctor.hospital?.id ?? '');

    const [hospitalOptions, setHospitalOptions] = useState([]);

    useEffect(() => {
        api().get('/api/load-hospital-list')
        .then(response => {
            setHospitalOptions(response.data)
        })
        .catch(err => {});
        return () => {setHospitalOptions([])};
    }, [doctor]);

    function handleHospitalSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            hospital_id : hospital_id.value,
        };

        api(token).post('/api/doctor/update/hospital', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor){
                updateDoctor(response.data.doctor);
            }
            toast('Hospital successfully updated', 'success');
            setAllError([]);
            cancelEdit(false);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleHospitalSubmit}>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <InputSelect data={hospital_id} error={allError['hospital_id']} label="Hospital" htmlFor="hospital_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Hospital</option>
                                    {hospitalOptions?.map((hospital) => (
                                        <option key={hospital.id} value={hospital.id}>{hospital.name}</option>
                                    ))}
                                </>}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}