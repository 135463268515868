import CategoryFilter from "../components/filter/CategoryFilter";
import TopFilter from "../components/filter/TopFilter";
import SearchBar from "../components/SearchBar";
import ProductItem from "../components/ProductItem";
import useScrollTop from "../hooks/useScrollTop";
import useProduct from "../hooks/useProduct";
import ItemsRequest from "../request/ItemsRequest";
import { useEffect, useState } from "react";
import MostSimplePaginator from "../components/pagination/MostSimplePaginator";
import BoxEmpty from "../components/BoxEmpty";
import ItemLoader from "../components/loader/item_loder/ItemLoader";
import ItemSlickSlider from "../components/ItemSlickSlider";
import EasyShop from "../components/EasyShop";
import ShopSlider from "../components/sliders/ShopSlider";

export default function Shop() {
    useScrollTop();

    const categories = ItemsRequest('/api/categories');
    
    const [nextPage, setNextPage] = useState(1);
    const [search, setSearch] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [categoryQuery, setCategoryQuery] = useState('');


    useEffect(() => {
        setNextPage(1);
        return () => {setNextPage(1)};
    }, [search, orderBy, categoryQuery]);

    function handleResetAllFilter(){
        setSearch('');
        setCategoryQuery('');
        setOrderBy('');
    }

    function handleSetNextPage(page) { 
        setNextPage(page);
    }
    
    function handleOrderByFilter(order_by){
        setOrderBy(order_by);
    }

    function handleCategoryQueryFilter(category_query){
        setCategoryQuery(category_query);
    }

    const {
        products, 
        items, 
        isLoading, 
        paginatorIsLoading, 
        // error,
    } = useProduct('/api/shop',search, orderBy, categoryQuery, nextPage);

    const popularItems = ItemsRequest('/api/popular-product');


    return (
        <>
            <section className="bg-light">
                <div className="container py-3">
                    <div className="row justify-content-between gy-2">
                        <div className="col-12 col-lg-4 col-xl-3 order-3 order-lg-1">
                            <CategoryFilter categories={categories} handleParentCallbackCategoryQuery={handleCategoryQueryFilter}/>
                        </div>
                        <div className="col-12 col-lg-8 col-xl-9 order-1 order-lg-2">
                            <div className="flex">
                                {/* <SearchBar/> */}
                                <ShopSlider/>
                            </div>
                        </div>
                        <div className="col-12 order-2 order-lg-3">
                            <EasyShop/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5 bg-light">
                <div className="container py-2" id="items-position">
                    <TopFilter items={items} handleParentOrderByFilter={handleOrderByFilter}/>
                    <SearchBar onChangeSearch={search_val => setSearch(search_val)} handleParentCallbackResetAll={handleResetAllFilter}/>
                    <div className="py-2">
                        { isLoading &&
                            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gy-4">
                                {Array.from(Array(5)).map((item,index) => (
                                    <div key={index} className="px-1 px-md-2">
                                        <ItemLoader classList="h-product shadow-sm rounded"/>
                                    </div>
                                ))}
                            </div>
                        }
                        {products.length > 0 &&
                            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gy-4">
                                {products.map(item => (
                                    <ProductItem key={item.id} item={item}/>
                                ))}
                            </div>
                        }
                        {!isLoading && products.length<1 && <BoxEmpty scroll={false}/>}
                        <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                    </div>
                </div>
            </section>

            <section>
                <div className="container pb-4">
                    <div className="bg-white">
                        <div className="p-1 p-lg-2">
                            <h5 className="px-2 m-0">Popular Items</h5>
                        </div>
                        <div className="px-3 py-2">
                            <ItemSlickSlider items={popularItems}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
