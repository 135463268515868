import { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useState } from "react";
import api from "../../services/api";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import BoxEmpty from "../../components/BoxEmpty";
import AmbulanceList from "../../components/AmbulanceList";
import AmbulanceListDetails from "../../components/AmbulanceListDetails";
import ShowReviews from "../reviews/ShowReviews";

export default function Ambulance() {
    const { id } = useParams();
    useEffect(() => {window.scrollTo(0, 0); return;}, [id]);

    const [isLoading, setIsLoading] = useState(true);
    const [ambulance, setAmbulance]         = useState([]);
    const [error, setError]         = useState(null);


    useEffect(() => {
        api().get(`/api/ambulance-booking/${id}`)
        .then(response => {
            if(response.data === "") setError('This ambulance is unavailable');
            else setAmbulance(response.data);
            setIsLoading(false);
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setIsLoading(false);
        });
        return () => {setAmbulance([]); setError(null)};
    }, [id]);


    return (
        <>
            <section className="bg-light py-4 px-1">
                <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Ambulance service profider profile</div>
                <div className="container">
                    <div className="bg-white">
                        {isLoading && 
                            <div className="row">
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-5" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-3" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-4" />
                                </div>
                            </div>
                        }
                        {!isLoading && !error && ambulance.user?.name && 
                        <div className="row justify-content-center shadow-sm bg-white">
                            <AmbulanceList 
                                ambulance={ambulance}
                                next_arrow={false}
                                locationCharLimit={1000}
                            />

                            <AmbulanceListDetails ambulance={ambulance}/>

                            <div className="col-12 bg-white border-top p-0">
                                <div className="p-2">
                                    <div className="bg-light p-2 text-center">
                                        <Link to={`/ambulance-booking/ambulance/${ambulance.id}/now`} className="btn btn-info text-white">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {!isLoading && error && <div className="row"><div className="p-2 bg-white text-center"><BoxEmpty/>{error}</div></div>}
                    </div>
                </div>
            </section>

            <ShowReviews api_url={`/api/review/show/ambulance-review/${ambulance.id}`}/>
        </>
    )
}
