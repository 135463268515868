import { useContext } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "../../context/SocketContext";
import { selectUser } from "../../slice/userSlice";
import Avatar from "../Avatar";

export default function CallReceiver({showModal, setShowModal}){

    const user = useSelector(selectUser);

    const { callStack, callStatus, myVideo, userVideo, localStream, remoteStream, leaveCall, mediaPermission } = useContext(SocketContext);

    const handleLeaveCall = () => {
        setShowModal(false);
        leaveCall(callStack.from, true);
    }
    return (
        <>
            {showModal && (
                <div className="modal fade show d-block" id="callReceiverModal" tabIndex="-1" aria-labelledby="callReceiverModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-body bg-dark p-0 m-0">
                                <div className="">
                                    {mediaPermission && (
                                        <div className='receiver-video-container'>
                                            {localStream && (
                                                <video className={`my-video mirror-video border rounded-1 shadow-sm bg-dark ${callStack.isVideo ? 'd-block' : 'd-none'}`} ref={myVideo} muted autoPlay></video>
                                            )}
                                            {remoteStream && (
                                                <video className={`user-video mirror-video bg-dark ${callStack.isVideo ? 'd-block' : 'd-none'}`} ref={userVideo} autoPlay></video>
                                            )}

                                            {!callStack.isVideo && (
                                                <div className="audio-call-container">
                                                    <div className="d-flex justify-content-center align-items-center text-white g-2">
                                                        {localStream && (
                                                            <div className="d-flex flex-column align-items-center">
                                                                {user.avatar ? <Avatar sizeClass="my-lg-avatar" avatarPath={user.avatar.substring(0, 4).toLowerCase() === 'http' ? user.avatar : process.env.REACT_APP_STORAGE + user.avatar} /> : <i className="bi bi-person-circle icon-huge"></i>}
                                                                <p className="small m-0 pt-2">{user.name}</p>
                                                            </div>
                                                        )}
                                                        {remoteStream &&  (<>
                                                            <div className="px-2"><i className="bi bi-phone-vibrate icon-lg"></i></div>
                                                            <div className="d-flex flex-column align-items-center gy-3">
                                                                {callStack.avatar ? <Avatar sizeClass="my-lg-avatar" avatarPath={callStack.avatar.substring(0, 4).toLowerCase() === 'http' ? callStack.avatar : process.env.REACT_APP_STORAGE + callStack.avatar} /> : <i className="bi bi-person-circle icon-huge"></i>}
                                                                <p className="small m-0 pt-2">{callStack.name || 'Caller'}</p>
                                                            </div>
                                                        </>)}
                                                    </div>
                                                </div>
                                            )}

                                            {callStatus === 'received' && (
                                                <div className="call-controller">
                                                    <div className="d-grid gap-2 d-flex">
                                                        <button  onClick={() => handleLeaveCall()} className='btn btn-lg btn-danger rounded-circle shadow'><i className="bi bi-telephone-x"></i></button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}