import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectToken, selectUser, setUser } from "../../slice/userSlice";
import api from "../../services/api";
import useFormInput from "../../hooks/useFormInput";
import toast from "../../utlities/toast";
import BtnLoader from "../loader/btn_loader/BtnLoader";

export default function PersonalInformation() {
    const user     = useSelector(selectUser);
    const token    = useSelector(selectToken);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const name       = useFormInput(user.name);
    const dob        = useFormInput(user.dob ?? '');
    const sex        = useFormInput(user.sex ?? '');
    const occupation = useFormInput(user.occupation ?? '');
    const country    = useFormInput(user.country ?? '');
    const city       = useFormInput(user.city ?? '');
    const location   = useFormInput(user.location ?? '');

    const [nameError, setNameError] = useState(null);
    const [dobError, setDobError]   = useState(null);
    const [sexError, setSexError]   = useState(null);
    const [occupationError, setOccupationError] =useState(null);
    const [countryError, setCountryError] =useState(null);
    const [cityError, setCityError] =useState(null);
    const [locationError, setLocationError] =useState(null);

    const [isEdit, setIsEdit] = useState(false);


    function setAllErrorNull(){
        setNameError(null);
        setOccupationError(null);
        setDobError(null);
        setSexError(null);
    }

    function handleSubmitPersonalInformation(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            name       : name.value,
            occupation : occupation.value,
            dob        : dob.value,
            sex        : sex.value,
            country    : country.value,
            city       : city.value,
            location   : location.value,
        };

        api(token).post('/api/auth/update/personal-information', body)
        .then(response => {
            setIsLoading(false);
            setIsEdit(false);

            setAllErrorNull();
            toast(response.data.message, response.data.icon);

            if(response.data.icon === 'success'){
                dispatch(setUser(response.data.user));
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);

            toast(error.message, 'error')
            if(error.errors){
                error.errors.name       ? setNameError(error.errors.name[0])             : setNameError(null);
                error.errors.occupation ? setOccupationError(error.errors.occupation[0]) : setOccupationError(null);
                error.errors.dob        ? setDobError(error.errors.dob[0])               : setDobError(null);
                error.errors.sex        ? setSexError(error.errors.sex[0])               : setSexError(null);
                error.errors.country    ? setCountryError(error.errors.country[0])       : setCountryError(null);
                error.errors.city       ? setCityError(error.errors.city[0])             : setCityError(null);
                error.errors.location   ? setLocationError(error.errors.location[0])     : setLocationError(null);
            }
            else {
                setAllErrorNull();
            }
        })
    }


    return (
        <>
            <div className="d-flex justify-content-between p-2 border-bottom">
                <h5 className="text-muted ">Personal Information</h5>
                <Link to="#" onClick={() => setIsEdit(true)} className="btn btn-sm btn-outline-success outline-none">Change Personal Information</Link>
            </div>

            <div className="p-1 p-md-2 border-bottom">  
                {isEdit ? 
                    <form onSubmit={handleSubmitPersonalInformation} className="needs-validation" noValidate>
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <div className="form-floating mb-4">
                                    <input {...name}
                                        type="text" 
                                        className={`form-control outline-none ${nameError ? 'is-invalid':''}`}
                                        placeholder="Name"/>
                                    <label htmlFor="name">Name<span className="text-danger">*</span></label>
                                    {nameError && <div className="invalid-tooltip">{nameError}</div> }
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-floating mb-4">
                                    <input {...occupation}
                                        type="text" 
                                        className={`form-control outline-none ${occupationError ? 'is-invalid':''}`}
                                        placeholder="Occupation"/>
                                    <label htmlFor="occupation">Occupation</label>
                                    {occupationError && <div className="invalid-tooltip">{occupationError}</div> }
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div className="form-floating mb-4">
                                    <input {...dob}
                                        type="date" 
                                        className={`form-control outline-none ${dobError ? 'is-invalid':''}`}
                                        placeholder="Your Date of Birth"/>
                                    <label htmlFor="dob">Your Date of Birth</label>
                                    {dobError && <div className="invalid-tooltip">{dobError}</div> }
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-floating mb-4">
                                    <select {...sex} className={`form-select outline-none ${sexError ? 'is-invalid':''}`}>
                                        <option className="bg-light text-info fw-bold" value="">Select your sex</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <label htmlFor="sex">Sex</label>
                                    {sexError && <div className="invalid-tooltip">{sexError}</div>}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-floating mb-4">
                                    <select {...country} className={`form-select outline-none ${countryError ? 'is-invalid':''}`}>
                                        <option className="bg-light text-info fw-bold" value="">Select your Country</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                    </select>
                                    <label htmlFor="country">Country</label>
                                    {countryError && <div className="invalid-tooltip">{countryError}</div>}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-floating mb-4">
                                    <input {...city}
                                        type="text" 
                                        className={`form-control outline-none ${cityError ? 'is-invalid':''}`}
                                        placeholder="Your Date of Birth"/>
                                    <label htmlFor="city">City</label>
                                    {cityError && <div className="invalid-tooltip">{cityError}</div> }
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="form-floating mb-4">
                                <textarea {...location}
                                    className={`form-control outline-none textarea-h-10 ${locationError ? 'is-invalid':''}`}
                                    placeholder="Your Location"
                                    cols="30" rows="10"
                                ></textarea>
                                <label htmlFor="location">Your Location</label>
                                {locationError && <div className="invalid-tooltip">{locationError}</div> }
                                {/* <input {...location}
                                    type="text" 
                                    className={`form-control outline-none ${dobError ? 'is-invalid':''}`}
                                    placeholder="Your Location"/>
                                <label htmlFor="location">Your Location</label>
                                {locationError && <div className="invalid-tooltip">{locationError}</div> } */}
                            </div>
                        </div>
                    
                        <div className="d-flex justify-content-between">
                            <button className={`btn btn-success outline-none ${isLoading ? 'disabled' : ''}`} type="submit">
                                <div className="d-flex justify-content-center">
                                    <div>Save </div> 
                                    {isLoading && <BtnLoader/>}
                                </div>
                            </button>
                            
                            <button  onClick={() => setIsEdit(false)} type="reset" className={`btn btn-outline-dark outline-none ${isLoading ? 'disabled' : ''}`}>Cancle</button>
                        </div>
                    </form>
                    :
                    <div>
                        <dl className="row justify-content-start text-muted mb-0 pb-0 overflow-auto">
                            <dt className="col-5 col-md-4 col-xl-3">Name</dt>
                            <dd className="col-7 col-md-8 col-xl-9">{user.name}</dd>

                            {user.email && <>
                                <dt className="col-5 col-md-4 col-xl-3">Email</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.email ?? ''}</dd>
                            </>}

                            {user.phone && <>
                                <dt className="col-5 col-md-4 col-xl-3">Phone</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.phone ?? ''}</dd>
                            </>}

                            {user.occupation && <>
                                <dt className="col-5 col-md-4 col-xl-3">Occupation</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.occupation ?? ''}</dd>
                            </>}

                            {user.dob && <>
                                <dt className="col-5 col-md-4 col-xl-3">Date of Birth</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.dob ?? ''}</dd>
                            </>}
                            
                            {user.sex && <>
                                <dt className="col-5 col-md-4 col-xl-3">Sex</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.sex ?? ''}</dd>                     
                            </>}

                            {user.country && <>
                                <dt className="col-5 col-md-4 col-xl-3">Country</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.country ?? ''}</dd>                     
                            </>}

                            {user.city && <>
                                <dt className="col-5 col-md-4 col-xl-3">City</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.city ?? ''}</dd>                     
                            </>}

                            {user.location && <>
                                <dt className="col-5 col-md-4 col-xl-3">Location</dt>
                                <dd className="col-7 col-md-8 col-xl-9">{user.location ?? ''}</dd>                     
                            </>}
                        </dl>
                    </div>
                }
            </div>
        </>
    )
}
