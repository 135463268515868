import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import AuthRoute from "../middleware/AuthRoute";
import GuestRoute from "../middleware/GuestRoute";
import RedirectRoute from "../middleware/RedirectRoute";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Home from "../pages/Home";
import NotFound404 from "../pages/NotFound404";
import Shop from "../pages/Shop";

import Contact from "../pages/static/Contact";
import Faq from "../pages/static/Faq";
import TermsAndConditions from "../pages/static/TermsAndConditions";

import TermsOfUse from "../pages/static/TermsOfUse";
import PrivacyPolicy from "../pages/static/PrivacyPolicy";
import ReturnPolicy from "../pages/static/ReturnPolicy";
import RefundPolicy from "../pages/static/RefundPolicy";

import About from "../pages/static/About";
import SiteMap from "../pages/static/SiteMap";
import EmailVerify from "../pages/auth/redirects/EmailVerify";
import EmailVerifySuccess from "../pages/auth/redirects/EmailVerifySuccess";
import EmailAlreadyVerifyed from "../pages/auth/redirects/EmailAlreadyVerified";
import EmailVerifyFail from "../pages/auth/redirects/EmailVerifyFail";
import Product from "../pages/Product";
import Cart from "../pages/Cart";
import ProfileRoute from "./ProfileRoute";
import PasswordReset from "../pages/auth/PasswordReset";
import DiagnosticService from "../pages/DiagnosticService"
import NurseAppointment from "../pages/nurse_appointment/NurseAppointment";
import AmbulanceBooking from "../pages/ambulance_booking/AmbulanceBooking"
import OperationSurgery from "../pages/OperationSurgery"
import Booking from "../pages/Services";
import InDoc from "../pages/doctor_appointment/InDoc";
import BdDoc from "../pages/doctor_appointment/BdDoc";
import BdDocAppointment from "../pages/doctor_appointment/BdDocAppointment";
import InDocAppointment from "../pages/doctor_appointment/InDocAppointment";
import JoinAsNurseStep1 from "../pages/nurse_join/JoinAsNurseStep1";
import JoinAsDoctorStep1 from "../pages/doctor_join/JoinAsDoctorStep1";
import JoinAsDoctorStep2 from "../pages/doctor_join/JoinAsDoctorStep2";
import JoinAsDoctorStep3 from "../pages/doctor_join/JoinAsDoctorStep3";
import JoinAsDoctorStep4 from "../pages/doctor_join/JoinAsDoctorStep4";
import BdSpecialist from "../pages/doctor_appointment/BdSpecialist";
import BdDocAppointmentNow from "../pages/doctor_appointment/BdDocAppointmentNow";
import InSpecialist from "../pages/doctor_appointment/InSpecialist";
import InDocAppointmentNow from "../pages/doctor_appointment/InDocAppointmentNow";
// import AudioConsultancy from "../pages/audio_consultancy/AudioConsultancy";
// import AudioSpecialist from "../pages/audio_consultancy/AudioSpecialist";
// import VideoSpecialist from "../pages/video_consultancy/VideoSpecialist";
// import VideoConsultancy from "../pages/video_consultancy/VideoConsultancy";
// import AudioDoc from "../pages/audio_consultancy/AudioDoc";
import VideoDoc from "../pages/video_consultancy/VideoDoc";
// import AudioConsultancyNow from "../pages/audio_consultancy/AudioConsultancyNow";
import VideoConsultancyNow from "../pages/video_consultancy/VideoConsultancyNow";
import DoctorView from "../pages/DoctorView";
import DoctorReview from "../pages/DoctorReview";
import JoinAsNurseStep2 from "../pages/nurse_join/JoinAsNurseStep2";
import JoinAsNurseStep3 from "../pages/nurse_join/JoinAsNurseStep3";
import JoinAsAmbulanceStep1 from "../pages/ambulance_join/JoinAsAmbulanceStep1";
import JoinAsAmbulanceStep2 from "../pages/ambulance_join/JoinAsAmbulanceStep2";
import Nurse from "../pages/nurse_appointment/Nurse";
import NurseAppointmentNow from "../pages/nurse_appointment/NurseAppointmentNow";
import Ambulance from "../pages/ambulance_booking/Ambulance";
import AmbulanceBookNow from "../pages/ambulance_booking/AmbulanceBookNow";
import DoctorRoute from "./DoctorRoute";
import NurseRoute from "./NurseRoute";
import AmbulanceRoute from "./AmbulanceRoute";
import AmbulanceView from "../pages/views/AmbulanceView";
import CartCheckout from "../pages/CartCheckout";
import AddReview from "../pages/reviews/AddReview";
import Referral from "../pages/Referral";
import InVideoSpecialist from "../pages/video_consultancy/InVideoSpecialist";
import BdVideoSpecialist from "../pages/video_consultancy/BdVideoSpecialist";
import BdVideoConsultancy from "../pages/video_consultancy/BdVideoConsultancy";
import InVideoConsultancy from "../pages/video_consultancy/InVideoConsultancy";


export default function MainRoute() {
    return (
        <>
            <Router>

                <Nav/>

                <Switch>

                    <Route exact path="/"><Home/></Route>
                    <Route exact path="/shop"><Shop/></Route>
                    <Route exact path="/product/:slug"><Product/></Route>
                    <Route exact path="/cart/show"><Cart/></Route>

                    <Route exact path="/services"><Booking/></Route>

                    {/* Auth Routes */}
                    <AuthRoute exact path="/referral"><Referral/></AuthRoute>
                    <AuthRoute path="/profile"><ProfileRoute/></AuthRoute>
                    <AuthRoute path="/sp/doctor"><DoctorRoute/></AuthRoute>
                    <AuthRoute path="/sp/nurse"><NurseRoute/></AuthRoute>
                    <AuthRoute path="/sp/ambulance"><AmbulanceRoute/></AuthRoute>

                    <AuthRoute path="/cart/checkout"><CartCheckout/></AuthRoute>

                    <Route exact path="/bd-doctor-appointment/specialists"><BdSpecialist/></Route>
                    <Route exact path="/in-doctor-appointment/specialists"><InSpecialist/></Route>
                    {/* <Route exact path="/audio-consultancy/specialists"><AudioSpecialist/></Route> */}
                    {/* <Route exact path="/video-consultancy/specialists"><VideoSpecialist/></Route> */}
                    {/* ------- */}
                    <Route exact path="/in-video-consultancy/specialists"><InVideoSpecialist/></Route>
                    <Route exact path="/bd-video-consultancy/specialists"><BdVideoSpecialist/></Route>
                    {/* -------- */}

                    <Route exact path="/nurse-appointment"><NurseAppointment/></Route>
                    <Route exact path="/ambulance-booking"><AmbulanceBooking/></Route>
                    <Route exact path="/diagnostic-service"><DiagnosticService/></Route>
                    <Route exact path="/operation-surgery"><OperationSurgery/></Route>

                    <Route exact path="/bd-doctor-appointment"><BdDocAppointment/></Route>
                    <Route exact path="/in-doctor-appointment"><InDocAppointment/></Route>
                    {/* <Route exact path="/audio-consultancy"><AudioConsultancy/></Route> */}
                    {/* <Route exact path="/video-consultancy"><VideoConsultancy/></Route> */}
                    {/* ----- */}
                    <Route exact path="/bd-video-consultancy"><BdVideoConsultancy/></Route>
                    <Route exact path="/in-video-consultancy"><InVideoConsultancy/></Route>
                    {/* ----- */}


                    <Route exact path="/bd-doctor-appointment/doctor/:id"><BdDoc/></Route>
                    <Route exact path="/in-doctor-appointment/doctor/:id"><InDoc/></Route>
                    {/* <Route exact path="/audio-consultancy/doctor/:id"><AudioDoc/></Route> */}
                    <Route exact path="/video-consultancy/doctor/:id"><VideoDoc/></Route>
                    <Route exact path="/nurse-appointment/nurse/:id"><Nurse/></Route>
                    <Route exact path="/ambulance-booking/ambulance/:id"><Ambulance/></Route>

                    <AuthRoute exact path="/bd-doctor-appointment/doctor/:id/now"><BdDocAppointmentNow/></AuthRoute>
                    <AuthRoute exact path="/in-doctor-appointment/doctor/:id/now"><InDocAppointmentNow/></AuthRoute>
                    {/* <AuthRoute exact path="/audio-consultancy/doctor/:id/now"><AudioConsultancyNow/></AuthRoute> */}
                    <AuthRoute exact path="/video-consultancy/doctor/:id/now"><VideoConsultancyNow/></AuthRoute>
                    <AuthRoute exact path="/nurse-appointment/nurse/:id/now"><NurseAppointmentNow/></AuthRoute>
                    <AuthRoute exact path="/ambulance-booking/ambulance/:id/now"><AmbulanceBookNow/></AuthRoute>

                    <Route exact path="/doctor/view/:id"><DoctorView/></Route>
                    <Route exact path="/ambulance/view/:id"><AmbulanceView/></Route>

                    <AuthRoute exact path="/doctor/review/:id"><DoctorReview/></AuthRoute>
                    {/* <AuthRoute exact path="/ambulance/review/:id/booking/:booking"><AmbulanceReview/></AuthRoute> */}
                    <AuthRoute exact path="/add-review"><AddReview/></AuthRoute>
                    
                    <AuthRoute exact path="/join/doctor/step-1"><JoinAsDoctorStep1/></AuthRoute>
                    <AuthRoute exact path="/join/doctor/step-2"><JoinAsDoctorStep2/></AuthRoute>
                    <AuthRoute exact path="/join/doctor/step-3"><JoinAsDoctorStep3/></AuthRoute>
                    <AuthRoute exact path="/join/doctor/step-4"><JoinAsDoctorStep4/></AuthRoute>

                    <AuthRoute exact path="/join/nurse/step-1"><JoinAsNurseStep1/></AuthRoute>
                    <AuthRoute exact path="/join/nurse/step-2"><JoinAsNurseStep2/></AuthRoute>
                    <AuthRoute exact path="/join/nurse/step-3"><JoinAsNurseStep3/></AuthRoute>
                    
                    <AuthRoute exact path="/join/ambulance/step-1"><JoinAsAmbulanceStep1/></AuthRoute>
                    <AuthRoute exact path="/join/ambulance/step-2"><JoinAsAmbulanceStep2/></AuthRoute>

                    {/* Guest Routes */}
                    <GuestRoute exact path="/login"><Login/></GuestRoute>
                    <GuestRoute exact path="/register"><Register/></GuestRoute>
                    <GuestRoute exact path="/forgot-password"><ForgotPassword/></GuestRoute>
                    <GuestRoute exact path="/password/reset"><PasswordReset/></GuestRoute>

                    {/* Redirect Routes */}
                    {/* <RedirectRoute exact path="/social/success"><Social/></RedirectRoute> */}
                    <RedirectRoute exact path="/email/verify"><EmailVerify/></RedirectRoute>
                    <RedirectRoute exact path="/email-verify-success"><EmailVerifySuccess/></RedirectRoute>
                    <RedirectRoute exact path="/email-verify-already-success"><EmailAlreadyVerifyed/></RedirectRoute>
                    <RedirectRoute exact path="/email-verify-fail"><EmailVerifyFail/></RedirectRoute>

                    {/* Static Routes */}
                    <Route exact path="/contact"><Contact/></Route>
                    <Route exact path="/faq"><Faq/></Route>
                    <Route exact path="/terms-and-condition"><TermsAndConditions/></Route>

                    <Route exact path="/terms-of-use"><TermsOfUse/></Route>
                    <Route exact path="/privacy-policy"><PrivacyPolicy/></Route>
                    <Route exact path="/return-policy"><ReturnPolicy/></Route>
                    <Route exact path="/refund-policy"><RefundPolicy/></Route>

                    
                    <Route exact path="/about"><About/></Route>
                    <Route exact path="/site-map"><SiteMap/></Route>

                    
                    {/* Not found 404 route */}
                    <Route path="*">
                        <NotFound404 />
                    </Route>
                </Switch>


                <Footer/>
            </Router>
        </>
    )
}
