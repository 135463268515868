import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useState } from "react";
import api from "../../services/api";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import BoxEmpty from "../../components/BoxEmpty";
import AmbulanceList from "../../components/AmbulanceList";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { selectToken } from "../../slice/userSlice";
import toast from "../../utlities/toast";
import useFormInput from "../../hooks/useFormInput";
import AmbulanceListDetails from "../../components/AmbulanceListDetails";
import Input from "../../components/form/Input";
import SubmitBtn from "../../components/form/SubmitBtn";

export default function AmbulanceBookNow() {
    const { id } = useParams();
    useEffect(() => {window.scrollTo(0, 0); return;}, [id]);

    const history = useHistory();
    const token = useSelector(selectToken);

    const [isLoading, setIsLoading] = useState(true);
    const [ambulance, setAmbulance]         = useState([]);
    const [error, setError]         = useState(null);

    const [bookingIsLoading, setBookingIsLoading] = useState(false);
    const start_location = useFormInput('');
    const end_location   = useFormInput('');
    const [allError, setAllError] = useState([]);


    useEffect(() => {
        api().get(`/api/ambulance-booking/${id}`)
        .then(response => {
            if(response.data === "") setError('This ambulance is unavailabel');
            else setAmbulance(response.data);
            setIsLoading(false);
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setIsLoading(false);
        });
        return () => {setAmbulance([]); setError(null)};
    }, [id]);


    function handleSubmitBooking(e){
        e.preventDefault();
        setBookingIsLoading(true);

        const body = {
            start_location: start_location.value,
            end_location  : end_location.value,
        };
        
        api(token).post(`api/ambulance/user/booking-now/${id}`, body)
        .then(response => {
            if(response.data.status === 'success'){
                Swal.fire(response.data.message,'We sand a notification to ambulance service provider, he/she call you soon, if you want you call him/her.',response.data.icon);
                setBookingIsLoading(false);
                history.replace('/profile/my-ambulance-booking');
            }
            else {
                Swal.fire('Something worng!', `${response.data.message}`,'warning');
            }
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            toast(error.message, 'error')
            setBookingIsLoading(false);

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }


    return (
        <>
            <section className="bg-light py-4 px-1">
                <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Ambulance service profider profile</div>
                <div className="container">
                    <div className="bg-white">
                        {isLoading && 
                            <div className="row">
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-5" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-3" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-4" />
                                </div>
                            </div>
                        }
                        {!isLoading && !error && ambulance.user?.name && 
                        <div className="row justify-content-center shadow-sm bg-white">
                            <AmbulanceList 
                                ambulance={ambulance}
                                next_arrow={false}
                                locationCharLimit={1000}
                            />

                            <AmbulanceListDetails ambulance={ambulance}/>
                            
                            <div className="col-12 bg-white border-top p-0">
                                <div className="p-2">
                                    <form onSubmit={handleSubmitBooking}>
                                        <h6 className="p-2 bg-light m-0 rounded">Journy details</h6>
                                            <div className="p-1 p-md-2">
                                                <Input 
                                                    data={start_location}
                                                    error={allError['start_location']}
                                                    label="Journy start location"
                                                    htmlFor="start_location"
                                                    groupClass="form-floating mb-4 mb-lg-5"
                                                    required={true}
                                                />

                                                <Input 
                                                    data={end_location}
                                                    error={allError['end_location']}
                                                    label="Journy end location"
                                                    htmlFor="end_location"
                                                    groupClass="form-floating mb-4 mb-lg-5"
                                                    required={true}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <SubmitBtn isLoading={bookingIsLoading} className="btn-success" title="Submit booking"/>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        }
                        {!isLoading && error && <div className="row"><div className="p-2 bg-white text-center"><BoxEmpty/>{error}</div></div>}
                    </div>
                </div>
            </section>
        </>
    )
}
