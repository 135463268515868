import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "../../slice/userSlice";
import Swal from "sweetalert2";
import api from "../../services/api";
import DoctorLoader from "../../components/loader/DoctorLoader";
import BoxEmpty from "../../components/BoxEmpty";
import useFormInput from "../../hooks/useFormInput";
import NurseList from "../../components/NurseList";
import DropZone from "../../components/DropZone";
import toast from "../../utlities/toast";
import SubmitBtn from "../../components/form/SubmitBtn";
import Input from "../../components/form/Input";
import InputSelect from "../../components/form/InputSelect";

export default function NurseAppointmentNow() {
    const { id } = useParams();
    useEffect(() => {window.scrollTo(0, 0); return;}, [id]);

    const user  = useSelector(selectUser);
    const token = useSelector(selectToken);
    const history = useHistory();
    let to_day = new Date();

    const [isLoading, setIsLoading] = useState(true);
    const [nurse, setNurse]         = useState([]);
    const [error, setError]         = useState(null);

    const [districtLists, setDistrictLists]   = useState([]);

    const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);

    const country_id      = useFormInput(1);
    const district_id     = useFormInput('');
    const sub_district_id = useFormInput('');
    const location        = useFormInput('');
    const phone_no        = useFormInput(user?.phone ?? '');
    const purpose         = useFormInput('');
    const [nid_passport_bs, setNid_passport_bs] = useState(null);
    const [nid_passport_bs2, setNid_passport_bs2] = useState(null);

    const [app_date, setApp_date] = useState('');
    const [nurse_service, setNurse_service] = useState('');
    const more_day = useFormInput('');

    const [allError, setAllError] = useState([]);

    function handleAppointmentSubmit(e){
        e.preventDefault();

        if(app_date === ''){
            Swal.fire('Invalid appointment date','Please select a date','error');
            return;
        }
        setIsAppointmentLoading(true);
        
        const body = new FormData();
        body.append('country_id',      country_id.value);
        body.append('district_id',     district_id.value);
        body.append('sub_district_id', sub_district_id.value);
        body.append('location',        location.value);
        body.append('phone_no',        phone_no.value);
        body.append('purpose',         purpose.value);
        body.append('nurse_service',   nurse_service);
        body.append('more_day',        more_day.value);
        body.append('app_date', app_date.toDateString());
        if(nid_passport_bs) body.append('nid_passport_bs', nid_passport_bs);
        if(nid_passport_bs2) body.append('nid_passport_bs2', nid_passport_bs2);

        console.log(body);
        
        api(token).post(`/api/auth/nurse-appointment/${id}/appointment`, body)
        .then(response => {
            setIsAppointmentLoading(false);
            setAllError([]);
            if(response.data.status !== 'success'){
                Swal.fire('Something worng!', response.data.message ?? '','warning');
                setIsLoading(false);
                return;
            }
            if(response.data.need_payment){
                Swal.fire({
                    title: 'Please wait...',
                    text: "Don't refresh or close the page.",
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
                Swal.showLoading();
                window.location.replace(process.env.REACT_APP_API_URL + '/payment/payment-submit/' + response.data.need_payment_id);
                return;
            }
            else{
                Swal.fire('Appointment sussessfully submited','','success');
                history.push('/profile/my-nurse-appointment');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsAppointmentLoading(false);
            toast(error.message, 'error')

            if(error.errors) {
                setAllError(error.errors); console.log(error.errors);
            }
            else setAllError([]);

            
        })
    }


    const dateFilter = (date, wdays) => {
        return wdays.find(wday => date === parseInt(wday.code));
    }


    useEffect(() => {
        api().get(`/api/nurse-appointment/${id}`)
        .then(response => {
            if(response.data === "") setError('This nurse is unavailable');
            else setNurse(response.data);
            setIsLoading(false);
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setIsLoading(false);
        });
        return () => {setNurse([]); setError(null)};
    }, [id]);

    useEffect(() => {
        api().get('/api/load-district-with-sub-district')
        .then(response => {
            setDistrictLists(response.data.district_lists);
        })
        .catch(err => { });
        return () => {setDistrictLists([]);};
    }, []);


    if(isLoading){
        return (
            <DoctorLoader/>
        )
    }

    else if(error){
        return (
            <section className="bg-light py-4 px-1">
                <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Nurse Profile</div>
                <div className="container">
                    <div className="bg-white">
                        <div className="row">
                            <div className="p-2 bg-white">
                                <div className="text-center"><BoxEmpty/>{error}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    else if(nurse.user){
        return (
            <>
                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Nurse Profile</div>
                    <div className="container">
                        <div className="bg-white">
                            <div className="row justify-content-center shadow-sm bg-white">
                                <NurseList 
                                    nurse={nurse}
                                    next_arrow={false}
                                    locationCharLimit={1000}
                                />

                                <div className="col-12 bg-white border-top">
                                    <div className="row text-secondary">
                                        <div className="p-2 col-6 col-sm-4 col-lg-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Nurse Code</p>
                                                <h6 className="text-dark fw-bold">{nurse.nurse_code}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2 col-6 col-sm-4 col-lg-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">District</p>
                                                <h6 className="text-dark fw-bold">{nurse.district?.name}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2 col-12 col-sm-4 col-lg-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Sub district</p>
                                                <h6 className="text-dark fw-bold">{nurse.sub_district?.name}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2 col-12 col-lg-6">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Location</p>
                                                <h6 className="text-dark fw-bold">{nurse.location}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 bg-white border-top">
                                    <div className="bg-light rounded mt-1">
                                        <p className="m-0 px-2 py-1">Availability and fees</p>
                                    </div>
                                    <div className="row text-secondary">
                                        {nurse.wdays?.map(wday => (
                                            <div key={wday.id} className="p-2 col-md-6 col-lg-4">
                                                <div className="bg-light rounded-3 p-2">
                                                    <p className="mb-1 text-dark fw-bold">{wday.title}</p>
                                                    {parseInt(wday.pivot.one_call) === 1 && <h6 className="text-dark small">One call, Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.one_call_fee}</span></h6>}
                                                    {parseInt(wday.pivot.hour_6_morning) === 1 && <h6 className="text-dark small">6 hours morning [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_6_morning_fee}</span></h6>}
                                                    {parseInt(wday.pivot.hour_6_evening) === 1 && <h6 className="text-dark small">6 hours evening [Start 12:00 PM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_6_evening_fee}</span></h6>}
                                                    {parseInt(wday.pivot.hour_12_day) === 1 && <h6 className="text-dark small">12 hours day [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_12_day_fee}</span></h6>}
                                                    {parseInt(wday.pivot.hour_12_night) === 1 && <h6 className="text-dark small">12 hours night [Start 06:00 PM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_12_night_fee}</span></h6>}
                                                    {parseInt(wday.pivot.hour_24) === 1 && <h6 className="text-dark small">24 hours [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_24_fee}</span></h6>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                         </div>
                    </div>
                </section>

                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Your Information</div>
                    <div className="container bg-white shadow-sm">
                        <div className="p-2">
                            <div className="row">
                                <div className="col-lg-6">
                                    <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                        elements={<>
                                            <option value={1}>Bangladesh</option>
                                        </>}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <InputSelect data={district_id} error={allError['district_id']} label="District" htmlFor="district_id" required={true}
                                        elements={<>
                                            <option disabled className="bg-light text-info fw-bold" value="">Select District</option>
                                            {districtLists?.map(districtList => (
                                                <option key={districtList.id} disabled={districtList.id !== nurse.district?.id} value={districtList.id}>{districtList.name}</option>
                                            ))}
                                        </>}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <InputSelect data={sub_district_id} error={allError['sub_district_id']} label="Sub District" htmlFor="sub_district_id" required={true}
                                        elements={<>
                                            <option disabled className="bg-light text-info fw-bold" value="">Select Sub district</option>
                                            {districtLists?.find(district => district.id === parseInt(district_id.value))?.sub_districts?.map(sub_districtList => (
                                                <option key={sub_districtList.id} value={sub_districtList.id}>{sub_districtList.name}</option>
                                            ))}
                                        </>}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Input data={phone_no} error={allError['phone_no']} label="Contact phone number" htmlFor="phone_no" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={location} error={allError['location']} label="Your full location" htmlFor="location" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={purpose} error={allError['purpose']} label="Purpose for nurse appointment" htmlFor="purpose" required={true}/>
                                </div>
                                
                                <div className="col-12">
                                    <h6 className="bg-light border rounded p-2">Upload NID or Barth Certificate or Passport (png, jpg, jpge)</h6>
                                    <div className="row row-cols-1 row-cols-md-2 justify-content-center">
                                        <div className="p-2 p-md-4">
                                            <DropZone callbackSetFiles={callbackFiles => {setNid_passport_bs(callbackFiles[0])}}
                                                classList="bg-light p-3 p-md-4 text-center border-dashed rounded" 
                                                text="Drag and drop NID/Barth Certificate/Passport here, or click to select*"
                                                fileClass="flex row-cols-2 row-cols-md-6"
                                                isPreview={true}
                                                isInvalid={allError['nid_passport_bs'] ? true : false}
                                                errorText={allError['nid_passport_bs']}
                                            />
                                        </div>
                                        <div className="p-2 p-md-4">
                                            <DropZone callbackSetFiles={callbackFiles => {setNid_passport_bs2(callbackFiles[0])}}
                                                classList="bg-light p-3 p-md-4 text-center border-dashed rounded" 
                                                text="Drag and drop NID/Barth Certificate/Passport here, or click to select"
                                                fileClass="flex row-cols-2 row-cols-md-6"
                                                isPreview={true}
                                                isInvalid={allError['nid_passport_bs2'] ? true : false}
                                                errorText={allError['nid_passport_bs2']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Appointment Schudule</div>
                    <div className="container bg-white shadow-sm">
                        <div className="py-2 py-md-4">
                            <div className="row justify-content-between">
                                <div className="col-lg-12">
                                    <div className="form-group mb-4">
                                        <label htmlFor="app_date">Choose appointment date<span className="text-danger">*</span></label>
                                        <DatePicker 
                                            onChange={date => setApp_date(date)}
                                            selected={app_date}
                                            minDate={to_day.setDate(to_day.getDate())}
                                            maxDate={to_day.setDate(to_day.getDate() + 60)}
                                            filterDate={date => dateFilter(date.getDay(), nurse.wdays)}
                                            className={`form-control outline-none ${allError['app_date'] ? 'is-invalid':''}`}
                                            placeholderText="Choose appointment date" required/>
                                        {allError['app_date'] && <div className="alert alert-danger py-1">{allError['app_date']}</div>}
                                    </div>
                                </div>
                                {app_date && <div className="col-lg-12">
                                    {nurse.wdays?.filter(filterItem => parseInt(filterItem.code) === new Date(app_date).getDay()).map(wday => (
                                        <div key={wday.id} className="border rounded p-2">
                                            <p className="text-dark fw-bold bg-light rounded p-2 mb-0">Select one on this date</p>
                                            {parseInt(wday.pivot.one_call) === 1 && 
                                                <div className="form-check pt-2">
                                                    <input onChange={e => setNurse_service('one_call')} checked={nurse_service === 'one_call'} className="form-check-input outline-none" type="radio" name="appointment_item" id="one_call"/>
                                                    <label className="form-check-label" htmlFor="one_call"><h6 className="text-dark small">One call, Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.one_call_fee}</span></h6></label>
                                                </div>
                                            }
                                            {parseInt(wday.pivot.hour_6_morning) === 1 && 
                                                <div className="form-check pt-2">
                                                    <input onChange={e => setNurse_service('hour_6_morning')} checked={nurse_service === 'hour_6_morning'} className="form-check-input outline-none" type="radio" name="appointment_item" id="hour_6_morning"/>
                                                    <label className="form-check-label" htmlFor="hour_6_morning"><h6 className="text-dark small">6 hours morning [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_6_morning_fee}</span></h6></label>
                                                </div>
                                            }
                                            {parseInt(wday.pivot.hour_6_evening) === 1 && 
                                                <div className="form-check pt-2">
                                                    <input onChange={e => setNurse_service('hour_6_evening')} checked={nurse_service === 'hour_6_evening'} className="form-check-input outline-none" type="radio" name="appointment_item" id="hour_6_evening"/>
                                                    <label className="form-check-label" htmlFor="hour_6_evening"><h6 className="text-dark small">6 hours evening [Start 12:00 PM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_6_evening_fee}</span></h6></label>
                                                </div>
                                            }
                                            {parseInt(wday.pivot.hour_12_day) === 1 && 
                                                <div className="form-check pt-2">
                                                    <input onChange={e => setNurse_service('hour_12_day')} checked={nurse_service === 'hour_12_day'} className="form-check-input outline-none" type="radio" name="appointment_item" id="hour_12_day"/>
                                                    <label className="form-check-label" htmlFor="hour_12_day"><h6 className="text-dark small">12 hours day [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_12_day_fee}</span></h6></label>
                                                </div>
                                            }
                                            {parseInt(wday.pivot.hour_12_night) === 1 && 
                                                <div className="form-check pt-2">
                                                    <input onChange={e => setNurse_service('hour_12_night')} checked={nurse_service === 'hour_12_night'} className="form-check-input outline-none" type="radio" name="appointment_item" id="hour_12_night"/>
                                                    <label className="form-check-label" htmlFor="hour_12_night"><h6 className="text-dark small">12 hours night [Start 06:00 PM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_12_night_fee}</span></h6></label>
                                                </div>
                                            }
                                            {parseInt(wday.pivot.hour_24) === 1 && 
                                                <div className="form-check pt-2">
                                                    <input onChange={e => setNurse_service('hour_24')} checked={nurse_service === 'hour_24'} className="form-check-input outline-none" type="radio" name="appointment_item" id="hour_24"/>
                                                    <label className="form-check-label" htmlFor="hour_24"><h6 className="text-dark small">24 hours [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_24_fee}</span></h6></label>
                                                </div>
                                            }
                                            <div className="form-check pt-2">
                                                <input onChange={e => setNurse_service('more_then_1_day')} checked={nurse_service === 'more_then_1_day'} className="form-check-input outline-none" type="radio" name="appointment_item" id="more_then_1_day"/>
                                                <label className="form-check-label" htmlFor="more_then_1_day"><h6 className="text-dark small">More then 1 day, Fee: <span className="text-success">Negotiable</span> </h6></label>
                                            </div>
                                            {nurse_service === 'more_then_1_day' && 
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-4">
                                                        <input {...more_day}
                                                            type="number" 
                                                            className={`form-control outline-none ${allError['more_day'] ? 'is-invalid':''}`}
                                                            placeholder="For how many days"/>
                                                        <label htmlFor="more_day">For how many days</label>
                                                        {allError['more_day'] && <div className="invalid-tooltip">{allError['more_day']}</div> }
                                                    </div>
                                                </div>
                                            }
                                            
                                            {allError['nurse_service'] && <div className="alert alert-danger py-1">{allError['nurse_service']}</div>}
                                            
                                        </div>
                                    ))}
                                </div>}
                            </div>

                            <form onSubmit={handleAppointmentSubmit}>
                                <div className="d-flex justify-content-end">
                                    <SubmitBtn isLoading={isAppointmentLoading} title="Submit Appointment"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    else return (<DoctorLoader/>)
}
