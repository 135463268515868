import { useState } from "react";
import FeesEdit from "./FeesEdit";

export default function Fees({doctor, updateDoctor}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="py-2">
            <div className="d-flex justify-content-between bg-info bg-opacity-10 shadow-sm rounded p-1 my-2">
                <h6 className="align-self-center m-0">Fees</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div>
                {parseInt(doctor.appointment_service) === 1 && 
                    <fieldset className="border rounded p-2">
                        <legend className="float-none w-auto small my-0">Appointment Service </legend>
                        <div className="row justify-content-start px-2">
                            <dt className="col-5 col-lg-4">Chamber Name </dt>
                            <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.chamber_name}</dd>
                            <dt className="col-5 col-lg-4">Chamber Location </dt>
                            <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.chamber_location}</dd>
                            
                            <dt className="col-8 col-lg-4">Appointment Fee </dt>
                            <dd className="col-4 col-lg-2 bg-light rounded shadow-sm py-1">{doctor.appointment_fee} Tk</dd>
                            <dt className="col-8 col-lg-4">Appointment Discount </dt>
                            <dd className="col-4 col-lg-2 bg-light rounded shadow-sm py-1">{doctor.appointment_discount} %</dd>
                        </div>
                    </fieldset>
                }
                {parseInt(doctor.audio_consultancy) === 1 && 
                    <fieldset className="border rounded p-2">
                        <legend className="float-none w-auto small my-0">Audio Service </legend>
                        <div className="row justify-content-start px-2">
                            <dt className="col-8 col-lg-4">Audio consultancy fee </dt>
                            <dd className="col-4 col-lg-2 bg-light rounded shadow-sm py-1">{doctor.audio_fee} Tk</dd>
                            <dt className="col-8 col-lg-4">Audio consultancy discount </dt>
                            <dd className="col-4 col-lg-2 bg-light rounded shadow-sm py-1">{doctor.audio_discount} %</dd>
                    
                        </div>
                    </fieldset>
                }
                {parseInt(doctor.video_consultancy) === 1 && 
                    <fieldset className="border rounded p-2">
                        <legend className="float-none w-auto small my-0">Video Service </legend>
                        <div className="row justify-content-start px-2">
                            <dt className="col-8 col-lg-4">Video consultancy fee </dt>
                            <dd className="col-4 col-lg-2 bg-light rounded shadow-sm py-1">{doctor.video_fee} Tk</dd>
                            <dt className="col-8 col-lg-4">Video consultancy Discount </dt>
                            <dd className="col-4 col-lg-2 bg-light rounded shadow-sm py-1">{doctor.video_discount} %</dd>
                    
                        </div>
                    </fieldset>
                }    
            </div>}

            {isEdit && <FeesEdit doctor={doctor} updateDoctor={updateDoctor} cancelEdit={(value) => setIsEdit(false)}/>}

        </div>
    )
}
