import Swal from "sweetalert2";

export default class MediaHandler {
    getPermissions(video = true, audio = true) {
        return new Promise((res, rej) => {
            navigator.mediaDevices.getUserMedia({video: video, audio: audio})
                .then((currentStream) => {
                    res(currentStream);
                })
                .catch(err => {
                    rej(err);
                    Swal.fire({
                        title: 'Please alow your camera and microphone',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then(() => {
                        window.location.reload();
                    })
                })
        });
    }
}