import { Link } from "react-router-dom";
import MyCF from "./MyCF";

export default function OrderItem({item}) {


    return (
        <div className="my-1 bg-light bg-opacity-50 item-title text-easy">
            <div className="row m-0 py-1">
                <div className="col-2 col-lg-1">
                    <div className="">
                        <img src={process.env.REACT_APP_STORAGE + item.product_image} className="img-fluid m-1" alt="product"/>
                    </div>
                </div>
                <div className="col-10 col-lg-6 align-self-center">
                    <Link to ={`/product/${item.product_slug}`} className="item-title">
                        <p className="text-truncate text-black small m-0">{item.product_title}</p>
                    </Link>
                </div>
                <div className="col-6 col-lg-3 align-self-center">
                    <div className="small"><span className="fw-light">Qty: </span>{item.qty} <span className="fw-light"> x </span> <MyCF value={item.product_price}/></div>
                </div>
                <div className="col-6 col-lg-2 align-self-center">
                    <div className="small">
                        <MyCF value={item.product_price * item.qty}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
