import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SubmitBtn from "../../../components/form/SubmitBtn";
import ItemLoader from "../../../components/loader/item_loder/ItemLoader";
import UserList from "../../../components/UserList";
import useScrollPosition from "../../../hooks/useScrollPosition";
import api from "../../../services/api";
import { selectToken } from "../../../slice/userSlice";
import toast from "../../../utlities/toast";
import { isEmpty } from "lodash";
import BoxEmpty from "../../../components/BoxEmpty";

export default function AppointmentView(){
    useScrollPosition('my-position', 150);

    const { appointment_id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [appointment, setAppointment] = useState({});

    const token = useSelector(selectToken);

    const [isEditSerialNo, setIsEditSerialNo] = useState(false);
    const [isEditSchedule, setIsEditSchedule] = useState(false);

    const [serial_no, setSerial_no] = useState('');
    const [app_date, setApp_date] = useState('');
    const [app_time, setApp_time] = useState('');

    const [allError, setAllError] = useState([]);

    const [isLoadingCompleted, setIsLoadingCompleted] = useState(false);
    const [isLoadingSerialNo, setIsLoadingSerialNo] = useState(false);
    const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);

    useEffect(() => {
        setSerial_no(appointment.serial_no ?? '');
        setApp_date(appointment.app_date ? moment(appointment.app_date, 'MMMM DD, YYYY').format('YYYY-MM-DD') : '');
        setApp_time(appointment.app_time ? moment(appointment.app_time, 'hh:mm:ss').format('hh:mm') : '');
    }, [appointment]);

    useEffect(() => {
        api(token).get(`/api/doctor/appointment/${appointment_id}/show`)
        .then(response => {
            setIsLoading(false);
            if(response.data.status === 'success'){
                setAppointment(response.data.appointment);
            }
            else {
                toast('Something worng!', 'error');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast(error.message, 'error')
        });
      return () => {setAppointment({})};
    }, [appointment_id, token]);
    

    function handleUpdateSerialNo(e){
        e.preventDefault();
        setIsLoadingSerialNo(true);

        const body = {
            serial_no: serial_no,
        }

        api(token).put(`/api/doctor/appointment/appointment/update/${appointment_id}/serial-no`, body)
        .then(response => {
            setIsLoadingSerialNo(false);
            if(response.data.status === 'success'){
                setIsEditSerialNo(false);
                setAppointment(response.data.appointment);
                toast('Serial no successfully updated.', 'success');
            }
            else{
                toast('Something wrong!','error');
            }
            setAllError([]);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoadingSerialNo(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    function handleUpdateSchedule(e){
        e.preventDefault();
        setIsLoadingSchedule(true);

        const body = {
            app_date: app_date,
            app_time: app_time,
        }

        api(token).put(`/api/doctor/appointment/appointment/update/${appointment_id}/schedule`, body)
        .then(response => {
            setIsLoadingSchedule(false);
            if(response.data.status === 'success'){
                setIsEditSchedule(false);
                setAppointment(response.data.appointment);
                toast('Schedule successfully updated.', 'success');
            }
            else{
                toast('Something wrong!','error');
            }
            setAllError([]);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoadingSchedule(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    function handleMakeCompleted(e){
        e.preventDefault();
        setIsLoadingCompleted(true);

        api(token).put(`/api/doctor/appointment/appointment/${appointment_id}/make-completed`)
        .then(response => {
            setIsLoadingCompleted(false);
            if(response.data.status === 'success'){
                setAppointment(response.data.appointment);
                toast('Status successfully updated.', 'success');
            }
            else toast('Something worng!','error');
        })
        .catch(err => {
            setIsLoadingCompleted(false);
            toast('Something worng!','error');
        });
        
    }
    
    return (
        <div id="my-position">
            {isLoading && <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                <div className="shadow">
                    <ItemLoader classList="p-4 my-2" />
                    <ItemLoader classList="p-5 my-2" />
                    <ItemLoader classList="p-4 my-2" />
                </div>
            </div>}

            {!isEmpty(appointment) && <><div className="p-2 bg-white shadow-sm border-top rounded-top border-3 border-master">
                <div className="d-flex justify-content-between">
                    <div>
                        <h6 className="m-0">Appointment Id: {appointment.id}</h6>
                        <p className="m-0 small">Status: <span className={appointment.appointment_status_style}>{appointment.status}</span></p>
                    </div>
                    {appointment.status === 'Ready' && <form onSubmit={handleMakeCompleted}>
                        <SubmitBtn title="Completed" isLoading={isLoadingCompleted} className="btn-master px-3 py-1"/>
                    </form>}
                </div>
            </div>
            <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">

                <div className='row row-cols-lg-2 px-2 overflow-auto'>
                    <UserList user={appointment.user}/>

                    <div className="py-2">
                        <h6 className="m-0 p-1 small bg-light">Patient info</h6>
                        <p className="text-dark small mb-0">Name: {appointment.name}</p>
                        <p className="text-dark small mb-0">Age: {appointment.patient_age}</p>
                        <p className="text-dark small mb-0">Gender: {appointment.gender}</p>
                        <p className="text-dark small mb-0">Country: {appointment.country?.name}</p>
                        <p className="text-dark small mb-0">City: {appointment.city}</p>
                        <p className="text-dark small mb-0">Occupation: {appointment.occupation}</p>
                        <p className="text-dark small mb-0">Patient type: {appointment.patient_type}</p>
                    </div>
                </div>
                <div className="border rounded p-2 mb-1">
                    <h6 className="">Disease about</h6>
                    <p className="m-0">{appointment.disease_about}</p>
                </div>

                <div className="pt-1 border-top">
                    {!isEditSerialNo ? <div className="d-flex">
                        <h6 className="text-muted m-0 py-2">Serial no: {appointment.serial_no}</h6> 
                        <div className="px-2">
                            <button onClick={() => setIsEditSerialNo(!isEditSerialNo)} type="button" className="btn btn-sm btn-success rounded-circle"><i className="bi bi-pencil-square"></i></button>
                        </div>
                    </div>
                    :<form onSubmit={handleUpdateSerialNo} className="mb-3 d-flex deb_zoom_in">
                        <div className="input-group w-100">
                            <input onChange={e => setSerial_no(e.target.value)} value={serial_no}
                                type="text" className={`form-control outline-none ${allError['serial_no'] ? ' is-invalid':''}`} id="serial_no" placeholder="Serial no"/>
                            {allError['serial_no'] && <div className="invalid-feedback">{allError['serial_no']}</div>}
                        </div>
                        <div className="p-1">
                            <SubmitBtn title="Save" isLoading={isLoadingSerialNo} className="btn-master px-3 py-1"/>
                        </div>
                    </form>}
                    {!isEditSchedule ? <div className="d-flex">
                        <h6 className="text-muted m-0 py-2">Schedule: {appointment.app_date} {appointment.app_time}</h6>
                        <div className="px-2">
                            <button onClick={() => setIsEditSchedule(!isEditSchedule)} type="button" className="btn btn-sm btn-success rounded-circle"><i className="bi bi-pencil-square"></i></button>
                        </div>
                    </div>
                    :<form onSubmit={handleUpdateSchedule} className="mb-3 d-flex deb_zoom_in">
                        <div className="input-group w-50">
                            <input onChange={e => setApp_date(e.target.value)} value={app_date}
                                type="date" className={`form-control outline-none ${allError['app_date'] ? ' is-invalid':''}`} id="app_date" placeholder="Appointment date"/>
                            {allError['app_date'] && <div className="invalid-feedback">{allError['app_date']}</div>}
                        </div>
                        <div className="input-group w-50 px-1">
                            <input onChange={e => setApp_time(e.target.value)} value={app_time}
                                type="time" className={`form-control outline-none ${allError['app_time'] ? ' is-invalid':''}`} id="app_time" placeholder="Appointment time"/>
                            {allError['app_time'] && <div className="invalid-feedback">{allError['app_time']}</div>}
                        </div>
                        <div className="px-1 align-self-center">
                            <SubmitBtn title="Save" isLoading={isLoadingSchedule} className="btn-master px-3 py-1"/>
                        </div>
                    </form>}
                </div>
                <div className="border-top pt-1 text-end">
                    <Link to="/sp/doctor/appointment" className="btn btn-sm btn-secondary">Back</Link>
                </div>
            </div></>}

            {!isLoading && isEmpty(appointment) && 
                <div className="p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
            }
        </div>
    )
}