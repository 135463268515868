import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { EchoContextProvider } from './context/EchoContext';
import { SocketContextProvider } from './context/SocketContext';
import './assets/lang/i18n';

// import Echo from 'laravel-echo';
// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.REACT_APP_PUSHER_KEY,
//     cluster: process.env.REACT_APP_PUSHER_CLUSTER,
//     forceTLS: true,
//     disableState: true,
//     // authEndpoint: process.env.REACT_APP_API_URL + '/broadcasting/auth',
// });

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <EchoContextProvider>
                <SocketContextProvider>
                    <App />
                </SocketContextProvider>
            </EchoContextProvider>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
