export default function InputClassic({
    data,
    setOnChange,
    type='text',
    label='', 
    htmlFor='', 
    labelIsTop=false,
    autoFocus=false, 
    required=false,
    error,
    groupClass='form-floating mb-4',
    controlClass='form-control outline-none',
    labelClass='',
    errorClass='invalid-tooltip',

}) {

    return (
        <div className={groupClass}>
            {labelIsTop === true && <label className={labelClass} htmlFor={htmlFor}>{label}{required === true && <span className="text-danger">*</span>}</label>}
            <input value={data}
                onChange={e => setOnChange(e.target.value)}
                type={type}
                className={`${controlClass} ${error ? 'is-invalid':''}`}
                placeholder={label}
                required={required}
                autoFocus={autoFocus}
            />
                
            {labelIsTop === false && <label className={labelClass} htmlFor={htmlFor}>{label}{required === true && <span className="text-danger">*</span>}</label>}
            {error && <div className={errorClass}>{error}</div> }
        </div>
    )
}
