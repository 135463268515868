import { Link } from "react-router-dom";
import AddToCartBtn from "./AddToCartBtn";

export default function ProductItem({item}) {
    return (
        <div className="px-only-sm-1">
            <div className="card border-0 shadow-sm item-card">
                <div className="card-header bg-white border-0 text-center p-0 overflow-hidden">
                    {item.discount > 0 ? <div className="p-1 bg-light rounded position-absolute text-danger small fw-bold" id="discount-container">{item.discount}% OFF</div> : <></>}
                    <Link to={`/product/${item.slug}`}>
                        <img className="img-fluid img-hover-scale w-100" src={process.env.REACT_APP_STORAGE + item.image.image} alt="" />
                    </Link>
                </div>
                <div className="card-body border-0 p-1 p-md-2">
                    <div className="d-flex justify-content-between">
                        <h6 className="text-success fw-bold"><span className="fw-bolder">৳ </span>{item.final_price}</h6>
                        {item.discount > 0 && <h6 className="text-warning fw-bold"><del><span className="fw-bolder">৳ </span>{parseFloat(item.price)}</del></h6>}
                    </div>
                    <Link to={`/product/${item.slug}`} className="item-title">
                        <p className="text-truncate text-black">{item.title}</p>
                        {/* <p className="overflow-hidden" style={{height: "50px"}}>{item.title}</p> */}
                    </Link>
                    <div className="">
                        <AddToCartBtn product={item} classList="btn-master w-100"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
