import { Link } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";

export default function RefundPolicy() {
    useScrollTop();

    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center fw-bold">Refund Policy</div>
            </div>

            <div className="bg-white rounded shadow-sm p-2 p-md-4">
                <div className="text-secondary">
                    <p>We Thank You For Choosing Duare Doctor.</p>
                    <p>If you are not completely satisfied with your purchase and delivery, we are here to help.</p>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Return</p>
                    <div className="text-muted">
                        <p>To be eligible for a return, you have to prove that wrong medicine, wrong product or any item different
                            than what you originally ordered was delivered to you. Your item must be in the same condition that
                            you received it. Your item must be in original medicine. You must put a “Return Request” in the
                            complaint box on the dashboard within 1 hour of receiving the item you want to return.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Refund</p>
                    <div className="text-muted">
                        <p>Refund requests will be processed under mentioned situation:</p>

                        <ul className=" text-justify font-weight-light">
                            <li>Customer must provide the prescriptions which proves that his medicines have been changed by the doctor.</li>
                            <li>Customer must has to provide the invoice.</li>
                            <li>Customer must purchase our medicine from duaredoctor.com</li>
                            <li>Customer must purchase our medicine from duaredoctor.com</li>
                            <li>The returned medicine price would be added in customer osudpotro wallet.</li>
                        </ul>

                        <p>For all the above scenarios, the refund amount will be sent to osudpotro wallet of the customer. And
                            the balance can only be used to purchase at duaredoctor.com. Upon customer&#39;s request, osudpotro will
                            transfer the refund amount to the user&#39;s original payment source within 7 days. Refund is only allowed
                            for customers who have paid via bKash or card or other electronic method.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Exchange Process</p>
                    <div className="text-muted">
                        <p>Customer will place a exchange request with below information</p>

                        <ul className=" text-justify font-weight-light">
                            <li>Return medicine name and unit.</li>
                            <li>Required medicine name and unit.</li>
                            <li>Return medicine purchasing invoice.</li>
                            <li>Doctor’s previous prescription and the updated prescription.</li>
                        </ul>

                        <p>Call center agent will keep the request. Once it get approved the call center team will update customer.</p>
                    </div>
                </div>
                
                <div className="pt-3">
                    <p className="fw-bold text-secondary">Supply Policy</p>
                    <div className="text-muted">
                        <p>duaredoctor, itself does not produce or manufacture any product, therefore osudpotro tries to control
                            the quality, but do not give quality assurance for any. We believe the manufacturer/vendor/supplier are
                            fully capable of assuring the quality of every product.</p>

                        <p>If any user, at the time of receiving the order delivery, finds any product injurious to health/life/security,
                            we will take full liability and will accept return of that product. If the user faces any threat after 12 hours
                            of the provided delivery, we will consider it as a lacking within the quality assurance from the manufacturers or suppliers.</p>

                        <p>All products displayed on the website are subject to change and are subject to availability.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Delivery</p>
                    <div className="text-muted">
                        <p>Delivery costs are non-refundable. If you receive a replacement for your returned item, the cost of
                            return delivery will be deducted from your refund.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Contact Us</p>
                    <div className="text-muted">
                    <p className="text-info"></p>
                        <p>If you have any questions on how to return your medicine to us, <Link to="/contact" className="text-decoration-none">Contact us</Link></p>

                        <p><b>Note:</b> 15% service charge would be deducted for every transaction or order refund.</p>

                    </div>
                </div>
                
            </div>
        </div>
    )
}
