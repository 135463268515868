import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/form/Input";
import InputSelect from "../../../../components/form/InputSelect";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function PersonalInformationEdit({ambulance, updateAmbulance ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const driver_name     = useFormInput(ambulance?.driver_name ?? '');
    const owner_name      = useFormInput(ambulance?.owner_name ?? '');
    const country_id      = useFormInput(ambulance?.country_id ?? '');
    const district_id     = useFormInput(ambulance?.district_id ?? '');
    const sub_district_id = useFormInput(ambulance?.sub_district_id ?? '');
    const location        = useFormInput(ambulance?.location ?? '');
    const contact_phone   = useFormInput(ambulance?.contact_phone ?? '');

    const [districtLists, setDistrictLists]   = useState([]);

    useEffect(() => {
        api().get('/api/load-district-with-sub-district')
        .then(response => {
            setDistrictLists(response.data.district_lists);
        })
        .catch(err => { });
        return () => {setDistrictLists([]);};
    }, []);

    function handlePersonalInformationSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            driver_name:     driver_name.value,
            owner_name:      owner_name.value,
            country_id:      country_id.value,
            district_id:     district_id.value,
            sub_district_id: sub_district_id.value,
            location:        location.value,
            contact_phone:   contact_phone.value,
        };

        api(token).post('/api/ambulance/update/personal-information', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.ambulance){
                updateAmbulance(response.data.ambulance);
            }
            toast('Personal information successfully updated', 'success');
            setAllError([]);
            cancelEdit(false);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handlePersonalInformationSubmit}>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Input data={driver_name} error={allError['driver_name']} label="Driver Name" htmlFor="driver_name" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={owner_name} error={allError['owner_name']} label="Owner Name" htmlFor="owner_name" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                elements={<>
                                    <option disabled className="bg-light text-info fw-bold" value="">Select country</option>
                                    <option value={1}>Bangladesh</option>
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={district_id} error={allError['district_id']} label="District" htmlFor="district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select District</option>
                                    {districtLists?.map(districtList => (
                                        <option key={districtList.id} value={districtList.id}>{districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={sub_district_id} error={allError['sub_district_id']} label="Sub District" htmlFor="sub_district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select Sub district</option>
                                    {districtLists?.find(district => district.id === parseInt(district_id.value))?.sub_districts?.map(sub_districtList => (
                                        <option key={sub_districtList.id} value={sub_districtList.id}>{sub_districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <Input data={location} error={allError['location']} label="Location" htmlFor="location" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={contact_phone} error={allError['contact_phone']} label="Contact phone number" htmlFor="contact_phone" required={true}/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}