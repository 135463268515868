import Bookings from "../components/home/Bookings";
import useScrollTop from "../hooks/useScrollTop";

export default function Services() {
    useScrollTop();
    return (
        <section className="bg-light">
            <div className="container py-4">
                <Bookings/>
            </div>
        </section>
    )
}
