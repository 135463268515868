import axios from 'axios';
import { useEffect, useState } from 'react';
import api from "../services/api";

let cancelToken;

export default function useProduct(api_path, search, order_by, sideQuery, nextPage) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState([]);
    const [items, setItems] = useState([]);
    const [paginatorIsLoading, setPaginatorIsLoading] = useState(false);

    useEffect(() => {
        return () => {setProducts([]);}
    },[]);
    
    useEffect(() => {
        setProducts([]);
    }, [search, order_by, sideQuery]);


    useEffect(() => {
        if(nextPage === 1) setIsLoading(true);
        setPaginatorIsLoading(true)
        setError(false);

        let query_string = '?page=' + nextPage;
        if(sideQuery)      query_string += '&category=' + sideQuery;
        if(order_by)        query_string += '&order_by=' + order_by;
        if(search)         query_string += '&keyword=' + search;

        if(cancelToken) cancelToken.cancel();
        cancelToken = axios.CancelToken.source();

        api().get(api_path + query_string, {cancelToken: cancelToken.token})
        .then(response => {
            // console.log(response.data);
            setProducts(prevProducts => {
                return [...new Set([...prevProducts, ...response.data.data])]
            });
            setItems(response.data);
            window.history.replaceState(null, "", query_string);
            setIsLoading(false);
            setPaginatorIsLoading(false);

        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setPaginatorIsLoading(false);
        });
        return () => {}

    }, [api_path, search, order_by, sideQuery, nextPage]);

    return { products, items, isLoading, paginatorIsLoading, error}
}