import { useState } from "react"
import { useSelector } from "react-redux";
import { selectUser } from "../../slice/userSlice";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import Map from "../../components/Map";
import useScrollTop from "../../hooks/useScrollTop";
import api from "../../services/api";
import toast from "../../utlities/toast";

export default function Contact() {
    useScrollTop();

    const user = useSelector(selectUser);
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName]       = useState(user ? user.name : '');
    const [email, setEmail]     = useState(user && user.email ? user.email : '');
    const [phone, setPhone]     = useState(user && user.phone ? user.phone : '');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [nameError, setNameError]       = useState(null);
    const [emailError, setEmailError]     = useState(null);
    const [phoneError, setPhoneError]     = useState(null);
    const [subjectError, setSubjectError] = useState(null);
    const [messageError, setMessageError] = useState(null);

    function handleNameChange(e){ setName(e.target.value); }
    function handleEmailChange(e){ setEmail(e.target.value); } 
    function handlePhoneChange(e){ setPhone(e.target.value); }
    function handleSubjectChange(e){ setSubject(e.target.value); }
    function handleMessageChange(e){ setMessage(e.target.value); }

    function setAllErrorNull(){
        setNameError(null);
        setEmailError(null);
        setPhoneError(null);
        setSubjectError(null);
        setMessageError(null);
    }

    function setAllInputNull(){
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
    }

    function handleSubmitContact(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            name: name,
            email: email,
            phone: phone,
            subject: subject,
            message: message,
        };

        api().post('/api/contact', body)
        .then(response => {
            setIsLoading(false);

            setAllErrorNull();
            setAllInputNull();

            toast(response.data.message, response.data.icon);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);

            toast(error.message, 'error')
            if(error.errors){
                error.errors.name    ? setNameError(error.errors.name[0])       : setNameError(null);
                error.errors.email   ? setEmailError(error.errors.email[0])     : setEmailError(null);
                error.errors.phone   ? setPhoneError(error.errors.phone[0])     : setPhoneError(null);
                error.errors.subject ? setSubjectError(error.errors.subject[0]) : setSubjectError(null);
                error.errors.message ? setMessageError(error.errors.message[0]) : setMessageError(null);
            }
            else {
                setAllErrorNull();
            }
        });
    }


    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center">Contact Us</div>
            </div>

            <div className="row row-cols-lg-11 justify-content-between mx-0 g-3 bg-white shadow-sm rounded my-3">
                <div className="col-lg-6 p-3">
                    <h5 className="text-muted border-bottom border-2 border-main w-25">Contact Info</h5>
  
                    <dl className="row justify-content-start text-muted rounded mx-2 py-3 bg-light shadow-sm">
                        <dd className="col-4 col-md-3"><i className="bi bi-geo-alt-fill text-main small"></i> Location</dd>
                        <dd className="col-8 col-md-9 small">: House: 248, Azampur Super Market, Sha Kobir Mazar Road, Dakhin khan, Uttara, Dhaka-1230</dd>
                        
                        <dd className="col-4 col-md-3"><i className="bi bi-envelope-fill text-main small"></i> Email</dd>
                        <dd className="col-8 col-md-9">: contct@duaredoctor.com</dd>
                        
                        <dd className="col-4 col-md-3"><i className="bi bi-telephone-fill text-main small"></i> Phone</dd>
                        <dd className="col-8 col-md-9">: +8801945-010304</dd>
                        
                        <dd className="col-4 col-md-3"><i className="bi bi-whatsapp text-main small"></i> Whatsapp</dd>
                        <dd className="col-8 col-md-9">: +8801945-010304</dd>

                    </dl>


                    <div className="pt-1">
                        <Map height={250}/>
                    </div>
                </div>

                <div className="col-lg-5 p-3">
                    <h5 className="text-muted pb-3">Send Message</h5>
                    
                    <form onSubmit={handleSubmitContact} className="needs-validation" noValidate>
                        <div className="form-floating mb-4">
                            <input value={name} onChange={handleNameChange}
                                type="text" 
                                className={`form-control ${nameError ? 'is-invalid':''}`}
                                placeholder="Name"/>
                            <label htmlFor="name">Name<span className="text-danger">*</span></label>
                            {nameError && <div className="invalid-tooltip">{nameError}</div> }
                        </div>
          
                        <div className="form-floating mb-4">
                            <input value={email} onChange={handleEmailChange}
                                type="email" 
                                className={`form-control ${emailError ? 'is-invalid':''}`}
                                placeholder="Email"/>
                            <label htmlFor="email">Email<span className="text-danger">*</span></label>
                            {emailError && <div className="invalid-tooltip">{emailError}</div> }
                        </div>

                        <div className="form-floating mb-4">
                            <input value={phone} onChange={handlePhoneChange}
                                type="tel" 
                                className={`form-control ${phoneError ? 'is-invalid':''}`}
                                placeholder="Phone"/>
                            <label htmlFor="phone">Phone<span className="text-danger">*</span></label>
                            {phoneError && <div className="invalid-tooltip">{phoneError}</div> }
                        </div>

                        <div className="form-floating mb-4">
                            <input value={subject} onChange={handleSubjectChange}
                                type="text" 
                                className={`form-control ${subjectError ? 'is-invalid':''}`}
                                placeholder="Subject"/>
                            <label htmlFor="subject">Subject<span className="text-danger">*</span></label>
                            {subjectError && <div className="invalid-tooltip">{subjectError}</div> }
                        </div>

                        <div className="form-floating mb-4">
                            <textarea value={message} onChange={handleMessageChange}
                                type="text" 
                                className={`form-control ${messageError ? 'is-invalid':''}`}
                                rows={5}
                                style={{height: '125px'}}
                                placeholder="Message"/>
                            <label htmlFor="message">Message<span className="text-danger">*</span></label>
                            {messageError && <div className="invalid-tooltip">{messageError}</div> }
                        </div>

                        <button className={`btn-master px-4 py-2 rounded ${isLoading ? 'disabled' : ''}`} type="submit">
                            <div className="d-flex justify-content-center">
                                <div>Send </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}