import moment from "moment";
import { useState } from "react"
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import BtnLoader from "../../../components/loader/btn_loader/BtnLoader";
import AppointmentTracker from "../../../components/Tracker/AppointmentTracker";
import UserList from "../../../components/UserList";
import api from "../../../services/api";
import { selectToken } from "../../../slice/userSlice";

export default function ItemList({booking}) {
    const [status, setStatus] = useState(booking.status);

    const token = useSelector(selectToken);
    const [ishandelarLoading, setIsHandelarLoading] = useState(false);

    function handleCompleted(e, id){
        e.preventDefault();
        setIsHandelarLoading(true);

        api(token).post(`/api/ambulance/provider/update-completed/${id}`)
        .then(response => {
            if(response.data.icon === 'success'){
                setStatus('Completed')
            }
            else{
                Swal.fire('Please try again','','warning');
            }
            setIsHandelarLoading(false);
        })
        .catch(err => {
            Swal.fire('Please try again','','question');
            setIsHandelarLoading(false);
        });
    }


    function handlePayServiceCharge(e, payment_id){
        e.preventDefault();
        Swal.fire({
            title: 'Please wait...',
            text: "Don't refresh or close the page.",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        Swal.showLoading();
        window.location.replace(process.env.REACT_APP_API_URL + '/payment/payment-submit/' + payment_id);
    }
    
    return (
        <div key={booking.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
            <div className="d-flex justify-content-between border-bottom pb-2">
                <div className="">
                    <h6 className="m-0">Booking Id: {booking.id}</h6>
                </div>
                <div className="">
                    <h6 className="m-0 text-end">{moment(booking.created_at).format('Do MMMM, YYYY hh:mm A')}</h6>
                </div>
            </div>

            <AppointmentTracker status={status}/>

            <div className='row row-cols-lg-2 px-2 overflow-auto'>
                <UserList user={booking.user}/>

                <div className="py-2">
                    <h6 className="m-0 p-1 small bg-light">Journy details</h6>
                    <p className="text-dark small mb-0"><strong className="text-muted">Start:</strong> {booking.start_location}</p>
                    <p className="text-dark small mb-0"><strong className="text-muted">End:</strong> {booking.end_location}</p>
                </div>
            </div>
            
            <div className="mt-2 d-flex justify-content-end">
                {status === 'Ready' && (
                    <button onClick={e => handleCompleted(e, booking.id)} className={`btn btn-success outline-none ${ishandelarLoading ? 'disabled' : ''}`} type="submit">
                        <div className="d-flex justify-content-center"><div>Completed </div> {ishandelarLoading && <BtnLoader/>}</div>
                    </button>
                )}
                {booking.payment_status === 'Pending' && status === 'Completed' && (
                    <button onClick={e => handlePayServiceCharge(e, booking.need_payment_crypt_id)} className={`btn btn-primary outline-none ${ishandelarLoading ? 'disabled' : ''}`} type="submit">
                        <div className="d-flex justify-content-center"><div>Pay service charge {parseInt(booking.total_amount)} Tk now </div> {ishandelarLoading && <BtnLoader/>}</div>
                    </button>
                )}
            </div>
        </div>
    )
}
