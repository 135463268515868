import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { selectToken } from "../../../slice/userSlice"

export default function EmailVerify() {

    const token = useSelector(selectToken);
    const history = useHistory();

    useEffect(() => {
        if(token) history.push('/');
    }, [token, history])

    return (
        <div className="py-5">
            <div className="d-flex justify-content-center">
                <div className="col-11 col-md-8 col-lg-6">
                    <div className="bg-white p-2 p-md-3 shadow rounded">

                        <h6 className="text-center text-secondary border-3 border-bottom border-master p-2">Verify Your Email Address</h6>

                        <p className="alert alert-warning text-center">
                            We sent a verification email to your email address. Please check your email.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
