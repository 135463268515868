import axios from 'axios';
import { useEffect, useState } from 'react';
import api from "../services/api";

let cancelToken;

export default function useNurses(api_path, search_val, district_and_subDistrict, order_by, nextPage) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nurses, setNurses] = useState([]);
    const [items, setItems] = useState([]);
    const [paginatorIsLoading, setPaginatorIsLoading] = useState(false);

    useEffect(() => {
        return () => {setNurses([]);}
    },[]);
    
    useEffect(() => {
        setNurses([]);
    }, [search_val, district_and_subDistrict, order_by]);


    useEffect(() => {
        if(nextPage === 1) setIsLoading(true);
        setPaginatorIsLoading(true)
        setError(false);

        let query_string = '?page=' + nextPage;
        if(search_val)                      query_string += '&keyword=' + search_val;
        if(district_and_subDistrict?.value) query_string += '&district_and_subDistrict=' + district_and_subDistrict.value + '&district=' + district_and_subDistrict.district;
        if(order_by)                        query_string += '&order_by=' + order_by;

        if(cancelToken) cancelToken.cancel();
        cancelToken = axios.CancelToken.source();

        api().get(api_path + query_string, {cancelToken: cancelToken.token})
        .then(response => {
            setNurses(prevItems => {
                return [...new Set([...prevItems, ...response.data.data])]
            });
            setItems(response.data);
            window.history.replaceState(null, "", query_string);
            setIsLoading(false);
            setPaginatorIsLoading(false);

        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setPaginatorIsLoading(false);
        });
        return () => {}

    }, [api_path, search_val, district_and_subDistrict, order_by, nextPage]);

    return { nurses, items, isLoading, paginatorIsLoading, error}
}