import { useState } from "react";
import CurrentWorkingPlaceEdit from "./CurrentWorkingPlaceEdit";

export default function CurrentWorkingPlace({doctor, updateDoctor}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="py-2">
            <div className="d-flex justify-content-between bg-info bg-opacity-10 shadow-sm rounded p-1 my-2">
                <h6 className="align-self-center m-0">Current Working Place</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div className="row justify-content-start px-2">
                <dt className="col-5 col-lg-4">Institution/Hospital </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.hospital_name }</dd>

                <dt className="col-5 col-lg-4">Department </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.department}</dd>
            
                <dt className="col-5 col-lg-4">Designation </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.designation}</dd>
            

                {doctor.memorable_instrutation_name && <>
                    <dt className="col-5 col-lg-4">Memorable Instrutation </dt>
                    <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.memorable_instrutation_name}</dd>
                </>}
            </div>}

            {isEdit && <CurrentWorkingPlaceEdit doctor={doctor} updateDoctor={updateDoctor} cancelEdit={(value) => setIsEdit(false)}/>}
        </div>
    )
}

