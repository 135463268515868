import { useState } from "react";
import ExperienceEdit from "./ExperienceEdit";

export default function Experience({doctor, updateDoctor}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="py-2">
            <div className="d-flex justify-content-between bg-info bg-opacity-10 shadow-sm rounded p-1 my-2">
                <h6 className="align-self-center m-0">Experience</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>
            {!isEdit && <div className="row justify-content-start px-2">
                <dt className="col-5 col-lg-4">Experience </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{doctor.final_experience}</dd>
            </div>}

            {isEdit && <ExperienceEdit doctor={doctor} updateDoctor={updateDoctor} cancelEdit={(value) => setIsEdit(false)}/>}
        </div>
    )
}
