import useScrollTop from "../../hooks/useScrollTop";
import { useState } from "react";
import DropZone from "../../components/DropZone";
import toast from "../../utlities/toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectToken, setUser } from "../../slice/userSlice";
import api from "../../services/api";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import Swal from "sweetalert2";

export default function JoinAsNurseStep3() {
    useScrollTop();
    const history = useHistory();
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [nid_bs, setNid_bs] = useState(null);
    const [nid_bs2, setNid_bs2] = useState(null);
    const [docs, setDocs] = useState({
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
    });
    const [allError, setAllError] = useState([]);

    function handleStep3Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = new FormData();
        if(nid_bs) body.append('nid_bs', nid_bs);
        if(nid_bs2) body.append('nid_bs2', nid_bs2);
        
        for(const index in docs){
            if(docs[index]) body.append('docs[]', docs[index]);
        }

        api(token).post('/api/nurse/join/step-3', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);

            if(response.data.icon === 'success'){
                dispatch(setUser(response.data.user));
                history.replace('/sp/nurse');
                Swal.fire('Successfully join as a Nurse','We will review your profile within 24 hours','success');
            }
            else if(response.data.icon === 'warning'){
                history.replace('/join/nurse/step-1');
                Swal.fire('Please flow step by step','','warning');
            }
            else if(response.data.icon === 'error'){
                Swal.fire('Something wrong','Please try again','error');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Nurse',
                    text: 'If you want to update your Nurse profile, Then click Edit Nurse Profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Nurse Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/nurse');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);

            console.log(error);
            toast(error.message, 'error')
            if(error.errors){
                setAllError(error.errors);
            }
            else {
                setAllError([]);
            }
        });
    }


    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Nurse [Step 3 of 3]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Upload NID or Barth Certificate (png, jpg, jpge)</h6>
                    <div className="row row-cols-1 row-cols-md-2 justify-content-center">
                        <div className="p-2 p-md-4">
                            <DropZone callbackSetFiles={callbackFiles => {setNid_bs(callbackFiles[0])}}
                                text="Drag and drop NID/Barth Certificate here, or click to select*"
                                fileClass="row row-cols-2"
                                isPreview={true}
                                isInvalid={allError['nid_bs'] ? true : false}
                                errorText={allError['nid_bs']}
                            />
                        </div>

                        <div className="p-2 p-md-4">
                            <DropZone callbackSetFiles={callbackFiles => {setNid_bs2(callbackFiles[0])}}
                                text="Drag and drop NID/Barth Certificate here, or click to select"
                                fileClass="row row-cols-2"
                                isPreview={true}
                                isInvalid={allError['nid_bs2'] ? true : false}
                                errorText={allError['nid_bs2']}
                            />
                        </div>
                    </div>
                </div>

                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Upload others document (png, jpg, jpge)</h6>
                    <div className="alert alert-info py-1">Upload at least one authentic document</div>
                    <div className="p-2 p-md-4">
                        {allError['docs'] && <div className="alert alert-danger py-1" role="alert">{allError['docs']}</div>}
                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 gy-3">
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 0: callbackFiles[0] })}
                                text="Document 1*"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.0'] ? true : false}
                                errorText={allError['docs.0']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 1: callbackFiles[0] })}
                                text="Document 2"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.1'] ? true : false}
                                errorText={allError['docs.1']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 2: callbackFiles[0] })}
                                text="Document 3"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.2'] ? true : false}
                                errorText={allError['docs.2']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 3: callbackFiles[0] })}
                                text="Document 4"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.3'] ? true : false}
                                errorText={allError['docs.3']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 4: callbackFiles[0] })}
                                text="Document 5"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.4'] ? true : false}
                                errorText={allError['docs.4']}
                            />
                        </div>
                    </div>
                </div>

                
                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep3Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            
            </div>
        </section>
    )
}
