import { createSlice } from '@reduxjs/toolkit';
import { getStorage, setStorage } from '../app/storage';

export const langSlice = createSlice({
    name: "lang",
    initialState : {
        lang: getStorage('lang') ?? 'en',
    },

    reducers: {
        changeLang: (state, action) => {
            setStorage('lang', action.payload);
            state.lang = action.payload;
        },
    },

});

export const { changeLang } = langSlice.actions;

export const selectLang = (state) =>  state.lang.lang;

export default langSlice.reducer;
