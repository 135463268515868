import { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useState } from "react";
import api from "../../services/api";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import BoxEmpty from "../../components/BoxEmpty";
import NurseList from "../../components/NurseList";
import ShowReviews from "../reviews/ShowReviews";

export default function Nurse() {
    const { id } = useParams();
    useEffect(() => {window.scrollTo(0, 0); return;}, [id]);

    const [isLoading, setIsLoading] = useState(true);
    const [nurse, setNurse]         = useState([]);
    const [error, setError]         = useState(null);


    useEffect(() => {
        api().get(`/api/nurse-appointment/${id}`)
        .then(response => {
            if(response.data === "") setError('This nurse is unavailable');
            else setNurse(response.data);
            setIsLoading(false);
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setIsLoading(false);
        });
        return () => {setNurse([]); setError(null)};
    }, [id]);


    return (
        <>
            <section className="bg-light py-4 px-1">
                <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Nurse Profile</div>
                <div className="container">
                    <div className="bg-white">
                        {isLoading && 
                            <div className="row">
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-5" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-3" />
                                </div>
                                <div className="p-2 bg-white">
                                    <ItemLoader classList="p-4" />
                                </div>
                            </div>
                        }
                        {!isLoading && !error && nurse.user && 
                        <div className="row justify-content-center shadow-sm bg-white">
                            <NurseList 
                                nurse={nurse}
                                next_arrow={false}
                                locationCharLimit={1000}
                            />

                            <div className="col-12 bg-white border-top">
                                <div className="row text-secondary">
                                    <div className="p-2 col-6 col-sm-4 col-lg-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Nurse Code</p>
                                            <h6 className="text-dark fw-bold">{nurse.nurse_code}</h6>
                                        </div>
                                    </div>
                                    <div className="p-2 col-6 col-sm-4 col-lg-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">District</p>
                                            <h6 className="text-dark fw-bold">{nurse.district?.name}</h6>
                                        </div>
                                    </div>
                                    <div className="p-2 col-12 col-sm-4 col-lg-2">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Sub district</p>
                                            <h6 className="text-dark fw-bold">{nurse.sub_district?.name}</h6>
                                        </div>
                                    </div>
                                    <div className="p-2 col-12 col-lg-6">
                                        <div className="bg-light rounded-3 p-2">
                                            <p className="mb-1">Location</p>
                                            <h6 className="text-dark fw-bold">{nurse.location}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 bg-white border-top">
                                <div className="bg-light rounded mt-1">
                                    <p className="m-0 px-2 py-1">Availability and fees</p>
                                </div>
                                <div className="row text-secondary">
                                    {nurse.wdays?.map(wday => (
                                        <div key={wday.id} className="p-2 col-md-6 col-lg-4">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1 text-dark fw-bold">{wday.title}</p>
                                                {parseInt(wday.pivot.one_call) === 1 && <h6 className="text-dark small">One call, Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.one_call_fee}</span></h6>}
                                                {parseInt(wday.pivot.hour_6_morning) === 1 && <h6 className="text-dark small">6 hours morning [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_6_morning_fee}</span></h6>}
                                                {parseInt(wday.pivot.hour_6_evening) === 1 && <h6 className="text-dark small">6 hours evening [Start 12:00 PM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_6_evening_fee}</span></h6>}
                                                {parseInt(wday.pivot.hour_12_day) === 1 && <h6 className="text-dark small">12 hours day [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_12_day_fee}</span></h6>}
                                                {parseInt(wday.pivot.hour_12_night) === 1 && <h6 className="text-dark small">12 hours night [Start 06:00 PM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_12_night_fee}</span></h6>}
                                                {parseInt(wday.pivot.hour_24) === 1 && <h6 className="text-dark small">24 hours [Start 06:00 AM], Fee: <span className="text-success"><span className="fw-bolder">৳ </span>{wday.pivot.hour_24_fee}</span></h6>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="col-12 bg-white border-top p-0">
                                <div className="p-2">
                                    <div className="bg-light p-2 text-center">
                                        <Link to={`/nurse-appointment/nurse/${nurse.id}/now`} className="btn btn-info text-white">Appointment Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {!isLoading && error && <div className="row"><div className="p-2 bg-white text-center"><BoxEmpty/>{error}</div></div>}
                    </div>
                </div>
            </section>

            <ShowReviews api_url={`/api/review/show/nurse-review/${nurse.id}`}/>
        </>
    )
}
