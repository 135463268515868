import { useSelector } from "react-redux";
import { NavLink, Switch } from "react-router-dom";
import { selectUser } from "../slice/userSlice";
import AuthRoute from "../middleware/AuthRoute";
import NotFound404 from "../pages/NotFound404";
import NurseProfile from "../pages/profile/nurse/NurseProfile";
import AppointmentList from "../pages/profile/nurse/AppointmentList";


export default function NurseRoute() {

    const user = useSelector(selectUser);

    return (
        <div className="bg-light">
            <div className="container-fluid py-2">
                <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-3 py-3" id="sticky-sidebar">
                        <div className="sticky-top top-except-nav">
                            <div className="bg-white shadow-sm mb-3 border-start border-4 border-master rounded">
                                <div className="list-group list-group-flush">
                                    <div className="list-group-item list-group-item-light p-3">
                                        {user.avatar ? <img className="rounded-circle border border-2" src={user.avatar.substring(0, 4).toLowerCase() === 'http' ? user.avatar : process.env.REACT_APP_STORAGE + user.avatar} alt="Name" width="40px" height="40px"/>
                                            : <span className="rounded-circle bg-success text-light fw-bolder p-2 m-1 font-xl">{user.name[0]}</span> 
                                        } <span className="fw-bold">{user.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="border-end bg-white shadow-sm">
                                <div className="list-group list-group-flush">
                                    <NavLink activeClassName="active" exact to="/sp/nurse" className="list-group-item list-group-item-action list-group-item-warning p-3 fw-bold">Nurse Profile</NavLink>
                                    <NavLink activeClassName="active" exact to="/sp/nurse/appointments" className="list-group-item list-group-item-action list-group-item-warning p-3 fw-bold">Appointment List</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9 py-3">

                        <Switch>
                            <AuthRoute exact path="/sp/nurse/"><NurseProfile/></AuthRoute>

                            <AuthRoute exact path="/sp/nurse/appointments"><AppointmentList/></AuthRoute>

                            <AuthRoute path="*"><NotFound404 /></AuthRoute>
                        </Switch>

                    </div>
                </div>
            </div>
        </div>
    )
}
