import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from '../services/api';
import { selectToken } from '../slice/userSlice';
import toast from '../utlities/toast';
import InputSelectClassic from './form/InputSelectClassic';
import BtnLoader from './loader/btn_loader/BtnLoader';

const Prescription = ({appointment}) => {
    const token = useSelector(selectToken);

    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);
    const [items, setItems] = useState([
        {medicine_name: "", dosage_instructions: "", duration: ""},
    ]);

    const [follow_up, setFollow_up] = useState('');
    const [complains, setComplains] = useState([]);
    const [diagnoses, setDiagnoses] = useState([]);
    const [advices, setAdvices] = useState([]);

    useEffect(() => {
        var medicineItem = [];
        appointment.prescription?.prescription_items?.forEach((item) => {
            medicineItem.push({medicine_name: item.medicine_name, dosage_instructions: item.dosage_instructions, duration: item.duration});
        });
        if(medicineItem.length > 0) setItems(medicineItem);

        setFollow_up(appointment.prescription?.follow_up ?? '');
 
        var complainsItem = [];
        appointment.prescription?.prescription_complains?.forEach((item) => {
            complainsItem.push({title: item.title});
        });
        setComplains(complainsItem);

        var diagnosesItem = [];
        appointment.prescription?.prescription_diagnosis?.forEach((item) => {
            diagnosesItem.push({title: item.title});
        });
        setDiagnoses(diagnosesItem);

        var advicesItem = [];
        appointment.prescription?.prescription_advices?.forEach((item) => {
            advicesItem.push({title: item.title});
        });
        setAdvices(advicesItem);

        return () => {
            setComplains([]);
            setDiagnoses([]);
            setAdvices([]);
        }
    }, [appointment])
    

    const handleItemChange = (e, index ,type) => {
        const { name, value } = e.target;
        if(type ==='items'){
            const list = [...items];
            list[index][name] = value;
            setItems(list);
        }
        else if(type === 'complains'){
            const list = [...complains];
            list[index][name] = value;
            setComplains(list);
        }
        else if(type === 'diagnoses'){
            const list = [...diagnoses];
            list[index][name] = value;
            setDiagnoses(list);
        }
        else if(type === 'advices'){
            const list = [...advices];
            list[index][name] = value;
            setAdvices(list);
        }
    }

    const handleRemoveItem = (e, index, type) => {
        e.preventDefault();
        if(type ==='items'){
            if(items.length !== 1){
                const list = [...items];
                list.splice(index, 1);
                setItems(list);
            }
        }
        else if(type === 'complains'){
            const list = [...complains];
            list.splice(index, 1);
            setComplains(list);
        }
        else if(type === 'diagnoses'){
            const list = [...diagnoses];
            list.splice(index, 1);
            setDiagnoses(list);
        }
        else if(type === 'advices'){
            const list = [...advices];
            list.splice(index, 1);
            setAdvices(list);
        }
    }

    const handleAddItem = (e, type) => {
        e.preventDefault();
        if(type ==='items'){
            setItems([...items, {medicine_name: "", dosage_instructions: "", duration: ""},]);
        }
        else if(type === 'complains'){
            setComplains([...complains, {title: ""},]);
        }
        else if(type === 'diagnoses'){
            setDiagnoses([...diagnoses, {title: ""},]);
        }
        else if(type === 'advices'){
            setAdvices([...advices, {title: ""},]);
        }
    }

    const handleSavePrescription = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const body = {
            items     :items,
            follow_up :follow_up,
            complains :complains,
            diagnoses :diagnoses,
            advices   :advices,
        }

        api(token).post(`/api/doctor/prescription/store/${appointment.id}`, body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            if(response.data.status === 'success'){
                toast('Prescription successfully saved.', 'success');
            }
            else toast('Something worng!','error');
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <section className='mt-3 p-2 p-md-3 bg-white shadow-sm'>
            <div className="border-bottom">
                <h5 className="text-center">Prescription</h5>
            </div>
            <div className="my-2">
                <h6 className=" bg-success bg-opacity-10 p-2 rounded-1 border">Medicines</h6>
                {items.map((item, index) => (
                    <div key={index} className="row">
                        <div className="col-lg-4">
                            <div className="form-floating mb-5">
                                <input onChange={e => handleItemChange(e, index, 'items')}
                                    value={item.medicine_name}
                                    name="medicine_name"
                                    type="text" 
                                    className={`form-control outline-none ${allError['items.' + index + '.medicine_name'] ? 'is-invalid':''}`}
                                    placeholder="Medicine name"/>
                                <label htmlFor="medicine_name">Medicine name<span className="text-danger">*</span></label>
                                {allError['items.' + index + '.medicine_name'] && <div className="invalid-tooltip">{allError['items.' + index + '.medicine_name']}</div> }
                            </div>
                        </div>

                        <div className="col-lg-5">
                            <div className="form-floating mb-5">
                                <input onChange={e => handleItemChange(e, index, 'items')}
                                    value={item.dosage_instructions}
                                    name="dosage_instructions"
                                    type="text" 
                                    className={`form-control outline-none ${allError['items.' + index + '.dosage_instructions'] ? 'is-invalid':''}`}
                                    placeholder="Dosage instructions"/>
                                <label htmlFor="dosage_instructions">Dosage instructions<span className="text-danger">*</span></label>
                                {allError['items.' + index + '.dosage_instructions'] && <div className="invalid-tooltip">{allError['items.' + index + '.dosage_instructions']}</div> }
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="form-floating mb-5">
                                <input onChange={e => handleItemChange(e, index, 'items')}
                                    value={item.duration}
                                    name="duration"
                                    type="text" 
                                    className={`form-control outline-none ${allError['items.' + index + '.duration'] ? 'is-invalid':''}`}
                                    placeholder="Duration"/>
                                <label htmlFor="duration">Duration<span className="text-danger">*</span></label>
                                {allError['items.' + index + '.duration'] && <div className="invalid-tooltip">{allError['items.' + index + '.duration']}</div> }
                            </div>
                        </div>

                        
                        <div className="col-lg-1">
                            <div className=" my-2 d-flex justify-content-around">
                                {items.length !== 1 && 
                                    <button onClick={e => handleRemoveItem(e, index, 'items')} className="btn btn-sm btn-danger btn-outline-none mx-1"><i className="bi bi-trash"></i></button>
                                }
                                {items.length === index+1 && 
                                    <button onClick={(e) => handleAddItem(e, 'items')} className="btn btn-sm btn-secondary btn-outline-none"><i className="bi bi-plus-circle"></i></button>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-5">
                <h6 className=" bg-success bg-opacity-10 p-2 rounded-1 border">Follow-up-within</h6>

                <InputSelectClassic data={follow_up} setOnChange={setFollow_up} error={allError['follow_up']} label="Follow-up-within" htmlFor="follow_up" required={false}
                    elements={<>
                        <option className="bg-light text-info fw-bold" value="">Select</option>
                        {Array.from(Array(30)).map((item, index) => (
                            <option key={index} value={`${index+1} ${index > 0  ? 'days':'day'}`}>{`${index+1} ${index > 0 ? 'days':'day'}`}</option>
                        ))}
                        {Array.from(Array(12)).map((item, index) => (
                            <option key={index} value={`${index+1} ${index > 0  ? 'months':'month'}`}>{`${index+1} ${index > 0 ? 'months':'month'}`}</option>
                        ))}
                    </>}
                />
            </div>

            <div className="mt-5">
                <h6 className=" bg-success bg-opacity-10 p-2 rounded-1 border">Chief Complaints</h6>
                {complains.map((complain, index) => (
                    <div key={index} className="row">
                        <div className="col-10 col-lg-11">
                            <div className="form-floating mb-5">
                                <input onChange={e => handleItemChange(e, index, 'complains')}
                                    value={complain.title}
                                    name="title"
                                    type="text" 
                                    className={`form-control outline-none ${allError['complains.' + index + '.title'] ? 'is-invalid':''}`}
                                    placeholder={`Complain line ${index+1}`}/>
                                <label htmlFor="title">Complain line {index+1}</label>
                                {allError['complains.' + index + '.title'] && <div className="invalid-tooltip">{allError['complains.' + index + '.title']}</div> }
                            </div>
                        </div>
                        
                        <div className="col-2 col-lg-1">
                            <div className=" my-2 d-flex justify-content-around">
                                <button onClick={e => handleRemoveItem(e, index, 'complains')} className="btn btn-sm btn-danger btn-outline-none mx-1"><i className="bi bi-trash"></i></button>

                                {complains.length === index+1 && 
                                    <button onClick={(e) => handleAddItem(e, 'complains')} className="btn btn-sm btn-secondary btn-outline-none"><i className="bi bi-plus-circle"></i></button>
                                }
                            </div>
                        </div>
                    </div>
                ))}
                {complains.length < 1 && (
                    <div className="text-center border rounded bg-light p-3">
                        <button onClick={() => setComplains([{title: ""}])} className="btn btn-sm btn-info">Add Complaints</button>
                    </div>
                )}
            </div>

            <div className="mt-5">
                <h6 className=" bg-success bg-opacity-10 p-2 rounded-1 border">Diagnoses</h6>
                {diagnoses.map((diagnosis, index) => (
                    <div key={index} className="row">
                        <div className="col-10 col-lg-11">
                            <div className="form-floating mb-5">
                                <input onChange={e => handleItemChange(e, index, 'diagnoses')}
                                    value={diagnosis.title}
                                    name="title"
                                    type="text" 
                                    className={`form-control outline-none ${allError['diagnoses.' + index + '.title'] ? 'is-invalid':''}`}
                                    placeholder={`Diagnosis ${index+1}`}/>
                                <label htmlFor="title">Diagnosis {index+1}</label>
                                {allError['diagnoses.' + index + '.title'] && <div className="invalid-tooltip">{allError['diagnoses.' + index + '.title']}</div> }
                            </div>
                        </div>
                        
                        <div className="col-2 col-lg-1">
                            <div className=" my-2 d-flex justify-content-around">
                                <button onClick={e => handleRemoveItem(e, index, 'diagnoses')} className="btn btn-sm btn-danger btn-outline-none mx-1"><i className="bi bi-trash"></i></button>
                                {diagnoses.length === index+1 && 
                                    <button onClick={(e) => handleAddItem(e, 'diagnoses')} className="btn btn-sm btn-secondary btn-outline-none"><i className="bi bi-plus-circle"></i></button>
                                }
                            </div>
                        </div>
                    </div>
                ))}
                {diagnoses.length < 1 && (
                    <div className="text-center border rounded bg-light p-3">
                        <button onClick={() => setDiagnoses([{title: ""}])} className="btn btn-sm btn-info">Add Diagnoses</button>
                    </div>
                )}
            </div>

            <div className="mt-5">
                <h6 className=" bg-success bg-opacity-10 p-2 rounded-1 border">Advices</h6>
                {advices.map((advice, index) => (
                    <div key={index} className="row">
                        <div className="col-10 col-lg-11">
                            <div className="form-floating mb-5">
                                <input onChange={e => handleItemChange(e, index, 'advices')}
                                    value={advice.title}
                                    name="title"
                                    type="text" 
                                    className={`form-control outline-none ${allError['advices.' + index + '.title'] ? 'is-invalid':''}`}
                                    placeholder={`Advice line ${index+1}`}/>
                                <label htmlFor="title">Advice line {index+1}</label>
                                {allError['advices.' + index + '.title'] && <div className="invalid-tooltip">{allError['advices.' + index + '.title']}</div> }
                            </div>
                        </div>
                        
                        <div className="col-2 col-lg-1">
                            <div className=" my-2 d-flex justify-content-around">
                                <button onClick={e => handleRemoveItem(e, index, 'advices')} className="btn btn-sm btn-danger btn-outline-none mx-1"><i className="bi bi-trash"></i></button>
                                {advices.length === index+1 && 
                                    <button onClick={(e) => handleAddItem(e, 'advices')} className="btn btn-sm btn-secondary btn-outline-none"><i className="bi bi-plus-circle"></i></button>
                                }
                            </div>
                        </div>
                    </div>
                ))}
                {advices.length < 1 && (
                    <div className="text-center border rounded bg-light p-3">
                        <button onClick={() => setAdvices([{title: ""}])} className="btn btn-sm btn-info">Add Advices</button>
                    </div>
                )}
            </div>

            <div className="d-flex justify-content-center pt-4">
                <button onClick={handleSavePrescription} className={`btn outline-none btn-master  ${isLoading ? 'disabled' : ''}`} type="submit">
                    <div className="d-flex justify-content-center"><div>Save </div> {isLoading && <BtnLoader/>}</div>
                </button>
            </div>
        </section>
    )
}

export default Prescription