import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SubmitBtn from "../../../components/form/SubmitBtn";
import ItemLoader from "../../../components/loader/item_loder/ItemLoader";
import UserList from "../../../components/UserList";
import useScrollPosition from "../../../hooks/useScrollPosition";
import api from "../../../services/api";
import { selectToken } from "../../../slice/userSlice";
import toast from "../../../utlities/toast";
import { isEmpty } from "lodash";
import BoxEmpty from "../../../components/BoxEmpty";
import CallToUser from "../../../components/LiveChat/CallToUser";
import Prescription from "../../../components/Prescription";

export default function VideoConsultancyView(){
    useScrollPosition('my-position', 150);

    const { appointment_id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [appointment, setAppointment] = useState({});

    const token = useSelector(selectToken);

    const [isLoadingCompleted, setIsLoadingCompleted] = useState(false);

    useEffect(() => {
        api(token).get(`/api/doctor/appointment/video-consultancy/${appointment_id}/show`)
        .then(response => {
            setIsLoading(false);
            if(response.data.status === 'success'){
                setAppointment(response.data.appointment);
            }
            else {
                toast('Something worng!', 'error');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast(error.message, 'error')
        });
      return () => {setAppointment({})};
    }, [appointment_id, token]);

    function handleMakeCompleted(e){
        e.preventDefault();
        setIsLoadingCompleted(true);

        api(token).put(`/api/doctor/appointment/video-consultancy/${appointment_id}/make-completed`)
        .then(response => {
            setIsLoadingCompleted(false);
            if(response.data.status === 'success'){
                setAppointment(response.data.appointment);
                toast('Status successfully updated.', 'success');
            }
            else toast('Something worng!','error');
        })
        .catch(err => {
            setIsLoadingCompleted(false);
            toast('Something worng!','error');
        });
        
    }
    
    return (
        <div id="my-position">
            {isLoading && <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                <div className="shadow">
                    <ItemLoader classList="p-4 my-2" />
                    <ItemLoader classList="p-5 my-2" />
                    <ItemLoader classList="p-4 my-2" />
                </div>
            </div>}

            {!isEmpty(appointment) && <><div className="p-2 bg-white shadow-sm border-top rounded-top border-3 border-master">
                <div className="d-flex justify-content-between">
                    <div>
                        <h6 className="m-0">Consultancy Id: {appointment.id}</h6>
                        <p className="m-0 small">Status: <span className={appointment.appointment_status_style}>{appointment.status}</span></p>
                    </div>
                    {appointment.status === 'Ready' && <form onSubmit={handleMakeCompleted}>
                        <SubmitBtn title="Completed" isLoading={isLoadingCompleted} className="btn-master px-3 py-1"/>
                    </form>}
                </div>
            </div>
            <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">

                <div className='row row-cols-lg-2 px-2 overflow-auto'>
                    <UserList user={appointment.user}/>

                    <div className="py-2">
                        <h6 className="m-0 p-1 small bg-light">Patient info</h6>
                        <p className="text-dark small mb-0">Name: {appointment.name}</p>
                        <p className="text-dark small mb-0">Age: {appointment.patient_age}</p>
                        <p className="text-dark small mb-0">Gender: {appointment.gender}</p>
                        <p className="text-dark small mb-0">Country: {appointment.country?.name}</p>
                        <p className="text-dark small mb-0">City: {appointment.city}</p>
                        <p className="text-dark small mb-0">Occupation: {appointment.occupation}</p>
                        <p className="text-dark small mb-0">Patient type: {appointment.patient_type}</p>
                    </div>
                </div>
                <div className="border rounded p-2 mb-1">
                    <h6 className="">Disease about</h6>
                    <p className="m-0">{appointment.disease_about}</p>
                </div>

                <div className="py-3 border-top">
                    <h6 className="text-muted m-0">{moment(appointment.created_at).format('Do MMMM, YYYY hh:mm A')}</h6>
                </div>

                <CallToUser patient={appointment.user} isVideo={true}/>

                <div className="border-top pt-1 text-end">
                    <Link to="/sp/doctor/video-consultancy" className="btn btn-sm btn-secondary">Back</Link>
                </div>
            </div></>}

            {!isLoading && isEmpty(appointment) && 
                <div className="p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
            }
            
            {!isEmpty(appointment) && (
                <Prescription appointment={appointment}/>
            )}
        </div>
    )
}