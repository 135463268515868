import useScrollTop from "../hooks/useScrollTop";

export default function OperationSurgery() {
    useScrollTop();

    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    
                    <h3 className="p-5 bg-light text-center">We working on it</h3>

                </div>
            </div>
        </section>
    )
}