import { useSelector } from "react-redux";
import { NavLink, Switch } from "react-router-dom";
import { selectUser } from "../slice/userSlice";
import ChangePassword from "../pages/profile/ChangePassword";
import Wishlist from "../pages/profile/Wishlist";
import MyOrder from "../pages/profile/MyOrder";
import ShippingAddress from "../pages/profile/ShippingAddress";
import Profile from "../pages/profile/Profile";
import AuthRoute from "../middleware/AuthRoute";
import NotFound404 from "../pages/NotFound404";
import UploadPrescription from "../pages/profile/UploadPrescription";
import MyDoctorAppointment from "../pages/profile/MyDoctorAppointment";
import MyAudioConsultancy from "../pages/profile/MyAudioConsultancy";
import MyVideoConsultancy from "../pages/profile/MyVideoConsultancy";
import MyNurseAppointment from "../pages/profile/MyNurseAppointment";
import MyAmbulanceBooking from "../pages/profile/MyAmbulanceBooking";
import MyPrescription from "../pages/profile/MyPrescription";
import { useTranslation } from 'react-i18next';
import MyBrokerEarnings from "../pages/profile/MyBrokerEarnings";
import LogoutBtn from "../components/LogoutBtn";
import MedicineRequest from "../pages/profile/MedicineRequest";
import MyMedicineRequest from "../pages/profile/MyMedicineRequest";

export default function ProfileRoute() {

    const user = useSelector(selectUser);
    const {t} = useTranslation();

    return (
        <div className="bg-light">
            <div className="container py-2">
                <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-3 py-3" id="sticky-sidebar">
                        <div className="sticky-top top-except-nav">
                            <div className="bg-white shadow-sm mb-3 border-start border-4 border-master rounded">
                                <div className="list-group list-group-flush">
                                    <div className="list-group-item list-group-item-light p-3">
                                        {user.avatar ? <img className="rounded-circle border border-2" src={user.avatar.substring(0, 4).toLowerCase() === 'http' ? user.avatar : process.env.REACT_APP_STORAGE + user.avatar} alt="Name" width="40px" height="40px"/>
                                            : <span className="rounded-circle bg-success text-light fw-bolder p-2 m-1 font-xl">{user.name[0]}</span> 
                                        } <span className="fw-bold">{user.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="border-end bg-white shadow-sm">
                                <div className="list-group list-group-flush small">
                                    <NavLink activeClassName="active" exact to="/profile" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("Profile")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-doctor-appointment" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Doctor Appointments")}</NavLink>
                                    {/* <NavLink activeClassName="active" exact to="/profile/my-audio-consultancy" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Audio Consultancy")}</NavLink> */}
                                    <NavLink activeClassName="active" exact to="/profile/my-video-consultancy" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Video Consultancy")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-nurse-appointment" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Nurse Appointments")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-ambulance-booking" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Ambulance Bookings")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-order" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Order")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-wishlist" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Wishlist")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/upload-prescription" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("Upload Prescription")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-prescription" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Uploaded Prescription")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-medicine-requests" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Medicine Requests")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-shipping-address" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("Shipping Address")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/my-broker-earnings" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("My Earnings")}</NavLink>
                                    <NavLink activeClassName="active" exact to="/profile/change-password" className="list-group-item list-group-item-action list-group-item-success py-2 px-3">{t("Change Password")}</NavLink>
                                    <li class="list-group-item p-0 bg-success bg-opacity-25"><LogoutBtn classList="btn btn-dark w-100"/></li>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9 py-3">

                        <Switch>
                            <AuthRoute exact path="/profile/"><Profile/></AuthRoute>

                            <AuthRoute exact path="/profile/my-doctor-appointment"><MyDoctorAppointment/></AuthRoute>

                            <AuthRoute exact path="/profile/my-audio-consultancy"><MyAudioConsultancy/></AuthRoute>
                            
                            <AuthRoute exact path="/profile/my-video-consultancy"><MyVideoConsultancy/></AuthRoute>
                            
                            <AuthRoute exact path="/profile/my-nurse-appointment"><MyNurseAppointment/></AuthRoute>
                            
                            <AuthRoute exact path="/profile/my-ambulance-booking"><MyAmbulanceBooking/></AuthRoute>

                            <AuthRoute exact path="/profile/my-order"><MyOrder/></AuthRoute>

                            <AuthRoute exact path="/profile/upload-prescription"><UploadPrescription/></AuthRoute>
                            
                            <AuthRoute exact path="/profile/my-prescription"><MyPrescription/></AuthRoute>

                            <AuthRoute exact path="/profile/my-wishlist"><Wishlist/></AuthRoute>

                            <AuthRoute exact path="/profile/medicine-request"><MedicineRequest/></AuthRoute>

                            <AuthRoute exact path="/profile/my-medicine-requests"><MyMedicineRequest/></AuthRoute>

                            <AuthRoute exact path="/profile/my-shipping-address"><ShippingAddress/></AuthRoute>
                           
                            <AuthRoute exact path="/profile/my-broker-earnings"><MyBrokerEarnings/></AuthRoute>

                            <AuthRoute exact path="/profile/change-password"><ChangePassword/></AuthRoute>

                            <AuthRoute path="*"><NotFound404 /></AuthRoute>
                        </Switch>

                    </div>
                </div>
            </div>
        </div>
    )
}
