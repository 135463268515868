import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AmbulanceList from "../../components/AmbulanceList";
import BoxEmpty from "../../components/BoxEmpty";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import AppointmentTracker from "../../components/Tracker/AppointmentTracker";
import useItems from "../../hooks/useItems";
import useScrollPosition from "../../hooks/useScrollPosition";
import { selectToken } from "../../slice/userSlice";

export default function MyAmbulanceBooking() {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/ambulance/user/my-ambulance-bookings', token, nextPage);

    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">My ambulance bookings</h6>
            </div>

            <div>
                
                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-5" />
                        </div>
                    </div>
                ))}

                {item_data.length>0 && item_data.map(booking => (
                    <div key={booking.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="d-flex justify-content-between border-bottom pb-2">
                            <div className="">
                                <h6 className="m-0">Booking Id: {booking.id}</h6>
                            </div>
                            <div className="">
                                <h6 className="m-0 text-end">{moment(booking.created_at).format('Do MMMM, YYYY hh:mm A')}</h6>
                            </div>
                        </div>

                        <AppointmentTracker status={booking.status}/>

                        <div className="border-top border-bottom mt-3">
                            <Link to={`/ambulance-booking/ambulance/${booking.ambulance.id}`} className="text-decoration-none">
                                <div className="row justify-content-center m-0">
                                    <AmbulanceList
                                        ambulance={booking.ambulance}
                                        next_arrow={true}
                                        locationCharLimit={50}
                                        hideRating={true}
                                    />
                                </div>
                            </Link>
                        </div>

                        <div className="bg-white">
                            <div className="px-2 py-1">
                                <div className="row row-cols-lg-2">
                                    <div className="border-end overflow-auto">
                                        <h6 className="m-0 p-1 small bg-light">Journy details</h6>
                                        <p className="text-dark small fw-light mb-0">Start: {booking.start_location}</p>
                                        <p className="text-dark small fw-light mb-0">End: {booking.end_location}</p>
                                    </div>
                                    <div className="overflow-auto">
                                        <h6 className="m-0 p-1 small bg-light">Contact details</h6>
                                        <p className="text-dark small fw-light mb-0">Phone: {booking.ambulance.contact_phone}</p>
                                        <p className="text-dark small fw-light mb-0">Email: {booking.ambulance.user.email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-end pt-2">
                            {booking.status === 'Completed' && !booking.reviewed && <Link to={{ 
                                pathname: '/add-review',
                                state: {
                                    api_url: `/api/review/store/ambulance-review/${booking.ambulance_id}/booking/${booking.id}`, 
                                    redirect_url: '/profile/my-ambulance-booking',
                                } 
                            }} className="btn btn-sm btn-outline-primary btn-outline-none">Write a review</Link>}
                        </div>

                    </div>
                ))}

                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-4">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>
        </div>
    )
}