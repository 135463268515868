export default function InputSwitch({
    data, 
    onClick,
    label='', 
    htmlFor='', 
    required=false,
    error,
    groupClass='form-check form-switch',
    controlClass='form-check-input outline-none',
    labelClass='form-check-label',

}) {
    
    return (
        <div className={groupClass}>
            <input onClick={e => onClick(!data)}
                defaultChecked={data}
                type="checkbox" 
                className={`${controlClass} ${error ? 'is-invalid':''}`}
                role="switch" 
                id={htmlFor}/>
            <label className={labelClass} htmlFor={htmlFor}>{label} {required === true && <span className="text-danger">*</span>}</label>
        </div>
    )
}
