import { createSlice } from '@reduxjs/toolkit';
import { setStorage } from '../app/storage';
import toast from '../utlities/toast';

function getStorage(key, need_parse = false){
    if(typeof(Storage) !== "undefined") {
        if (localStorage.getItem(key) !== null) {
            if(need_parse) {
                return JSON.parse(localStorage.getItem(key));
            } 
            else {
                return localStorage.getItem(key);
            }
        } 
        else {
            return [];
        }
    } 
    else {
        return [];
    }
}

export const cartSlice = createSlice({
    name: "cart",
    initialState : {
        cart: getStorage('cart', true)
    },

    reducers: {
        add_to_cart: (state, action) => {
            const product = action.payload;
            const new_item = {...action.payload, c_qty:1};
            let items = [];
            let flag = true;
            if(getStorage('cart')){
                items = getStorage('cart', true);
                items.forEach(item => {
                    if(item.id === product.id){
                        item.c_qty += 1;
                        flag = false;
                    }
                });
            }
            if(flag){
                items.push(new_item);
            }
            setStorage('cart', items, true);
            state.cart = getStorage('cart', true);

            toast('Successfully added to cart', 'success');
        },

        set_cart: (state, action) => {
            setStorage('cart', action.payload, true);
            state.cart = getStorage('cart', true);
        },

        delete_cart: (state, action) => {
            let storage_items = getStorage('cart', true);
            let items = storage_items.filter(item => item.id !== action.payload );
            setStorage('cart', items, true);
            state.cart = getStorage('cart', true);
            toast('Item successfully deleted', 'success');
        },

        increment_cart: (state, action) => {
            let items = [];
            if(getStorage('cart')){
                items = getStorage('cart', true);
                items.forEach(item => {
                    if(item.id === action.payload) item.c_qty += 1;
                });
                setStorage('cart', items, true);
                state.cart = getStorage('cart', true);
                toast('Quantity successfully incresed', 'success');
            }
            else{
                toast('Something worng', 'error');
            }
        },

        decrement_cart: (state, action) => {
            let items = [];
            if(getStorage('cart')){
                items = getStorage('cart', true);
                var flag = true;
                items.forEach(item => {
                    if(item.id === action.payload){
                        if(item.c_qty > 1) item.c_qty -= 1;
                        else flag = false;
                    }
                });
                if(flag){
                    setStorage('cart', items, true);
                    state.cart = getStorage('cart', true);
                    toast('Quantity successfully decreased', 'success');
                }
                else {
                    toast('Available quantity (1). Do not decrease this', 'warning')
                }
            }
            else{
                toast('Something worng', 'error');
            }
        }
    },

});

export const { add_to_cart, increment_cart, decrement_cart, delete_cart, set_cart } = cartSlice.actions;

export const selectCart = (state) => state.cart.cart;

export default cartSlice.reducer;
