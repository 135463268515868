import { Redirect, Route } from "react-router-dom";

export default function RedirectRoute({ children, ...rest }) {

    const isRedirected = true;

    return (
        <Route {...rest}
            render={({ location }) => isRedirected ? 
                (children) : 
                (<Redirect
                    to={{
                        pathname: "/",
                        state: { from: location }
                    }}
                />)
            }
        />
    );
}
