import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import api from "../../services/api";
import { login, setUser } from "../../slice/userSlice";
import useFormInput from "../../hooks/useFormInput";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import toast from "../../utlities/toast";
import useScrollTop from "../../hooks/useScrollTop";
import SocialLogin from "../../components/SocialLogin";


export default function Login() {
    useScrollTop();

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    let { from } = location.state || { from: { pathname: "/" } };

    const [showForm, setShowForm] = useState(true);

    const email = useFormInput('');
    const password = useFormInput('');

    // const [errorMessage, setErrorMessage] = useState(null);
    // const [successMessage, setSussceeMessage] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    async function handleLogin(e){
        setIsLoading(true);
        e.preventDefault();

        const body = { 
            email:    email.value,
            password: password.value,
        };

        await api().get('/sanctum/csrf-cookie').then(response => {
            api().post('/api/auth/login', body)
            .then(response => {
                setIsLoading(false);
                setEmailError(null);
                setPasswordError(null);

                if(response.data.verified === false){
                    toast(response.data.message, 'warning');
                    history.push('/email/verify');
                }
                else if(response.data.blocked === true){
                    toast(response.data.message, 'warning');
                }
                else{
                    dispatch(login(response.data.token));
                    dispatch(setUser(response.data.user));
                    toast(response.data.message, 'success');
                    history.replace(from);
                }
            })
            .catch((err) => {
                const error = err.response ? err.response.data : err;
                setIsLoading(false);
                toast(error.message, 'error')
                if(error.errors){
                    error.errors.email ? setEmailError(error.errors.email[0]) : setEmailError(null);
                    error.errors.password ? setPasswordError(error.errors.password[0]) : setPasswordError(null);
                }
                else {
                    setEmailError(null);
                    setPasswordError(null);
                }
            });
        });
    }

    return (
        <div className="py-5">
            <div className="d-flex justify-content-center">
                <div className="col-11 col-md-8 col-lg-6">
                    <div className="bg-white p-2 p-md-3 shadow rounded">

                        <h5 className="text-center text-secondary border-3 border-bottom border-master p-2">LOGIN</h5>

                        <SocialLogin from={from} showForm={showForm} setShowForm={setShowForm}/>

                        {/* {successMessage && <div className="alert alert-success text-center py-1">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger text-center py-1">{errorMessage}</div>} */}

                        {showForm && <>
                            <div className="pt-3"></div>
                            <form  className="py-2" onSubmit={handleLogin}>
                                <div className="input-group has-validation mb-4">
                                    <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white"><i className="bi bi-envelope bi-m text-secondary"></i></span>
                                    <input {...email}
                                        type="email" 
                                        className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${emailError ? 'is-invalid border-danger' : ''}`}
                                        id="email" 
                                        name="email"  
                                        placeholder="your.email@gmail.com" 
                                        autoComplete="off"/>
                                    {emailError && <div className="invalid-tooltip">{emailError}</div> }
                                </div>

                                <div className="input-group has-validation mb-4">
                                    <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white"><i className="bi bi-file-lock bi-m text-secondary"></i></span>
                                    <input {...password}
                                        type="password" 
                                        className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${passwordError ? 'is-invalid border-danger' : ''}`}
                                        id="password" 
                                        name="password"  
                                        placeholder="Password" 
                                        autoComplete="off"/>
                                    {passwordError && <div className="invalid-tooltip">{passwordError}</div> }
                                </div>

                                <div className="d-grid">
                                    <button className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`} type="submit">
                                        <div className="d-flex justify-content-center">
                                            <div>LOGIN </div> 
                                            {isLoading && <BtnLoader/>}
                                        </div>
                                        {/* { isLoading && <span  className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} */}
                                    </button>
                                </div>
                            </form>

                            <div className="pt-1">
                                <p className="text-secondary text-center">Don’t have an account? <Link to="/register" className="text-decoration-none text-primary fw-bolder">Sign Up Now!</Link></p>
                            </div>

                            <div>
                                <p className="text-secondary text-center my-0"><Link to="/forgot-password" className="text-decoration-none text-primary fw-bold">Forgot Password?</Link></p>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    );
}
