import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import useScrollPosition from "../../../hooks/useScrollPosition";
import api from "../../../services/api";
import { selectToken } from "../../../slice/userSlice";
import Availability from "./Profile/Availability";
import CurrentWorkingPlace from "./Profile/CurrentWorkingPlace";
import Experience from "./Profile/Experience";
import Fees from "./Profile/Fees";
import MyHospital from "./Profile/MyHospital";
import PersonalInformation from "./Profile/PersonalInformation";
import Qualification from "./Profile/Qualification";
import Signature from "./Profile/Signature";
import SpecialistIn from "./Profile/SpecialistIn";


export default function DoctorProfile() {
    useScrollPosition('my-position', 150);
    const token = useSelector(selectToken);
    const history = useHistory();

    const [doctor, setDoctor] = useState([]);

    useEffect(() => {
        api(token).get('/api/doctor/profile')
        .then(response => {
            setDoctor(response.data);
        })
        .catch(err => {
            if(err.response.status === 403){
                Swal.fire('Unauthorized','','warning');
                history.replace('/');
            }
            else {
                Swal.fire('Something wrong!','','error');
            }
        });
        return () => setDoctor([]);
    }, [token, history])


    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm border-top rounded-top border-3 border-master">
                <h6 className="m-0">Doctor Profile</h6>
            </div>

            <section>
                <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                    <div>
                        <PersonalInformation doctor={doctor} updateDoctor={setDoctor}/>
                        
                        {parseInt(doctor.country_id) === 2 && (
                            <MyHospital doctor={doctor} updateDoctor={setDoctor}/>
                        )}

                        <CurrentWorkingPlace doctor={doctor} updateDoctor={setDoctor}/>

                        <SpecialistIn doctor={doctor} updateDoctor={setDoctor}/>

                        <Experience doctor={doctor} updateDoctor={setDoctor}/>

                        <Qualification doctor={doctor} updateDoctor={setDoctor}/>

                        <Availability doctor={doctor} updateDoctor={setDoctor}/>

                        <Fees doctor={doctor} updateDoctor={setDoctor}/>

                        <Signature doctor={doctor}/>
                    </div>
                </div>
            </section>
        </div>
    )
}
