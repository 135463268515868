import { useState } from "react";
import { useSelector } from "react-redux";
import BoxEmpty from "../../components/BoxEmpty";
import ImageModal from "../../components/ImageModal";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import OrderTracker from "../../components/Tracker/OrderTracker";
import useItems from "../../hooks/useItems";
import useScrollPosition from "../../hooks/useScrollPosition";
import { selectToken } from "../../slice/userSlice";

export default function MyPrescription() {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);
    const [images, setImages] = useState([]);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    function handleShowImage(e, images){
        e.preventDefault();
        setImages(images);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/auth/get/my-prescription', token, nextPage);

    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">My prescription</h6>
            </div>

            <div>

                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-5" />
                        </div>
                    </div>
                ))}

                {item_data.length>0 && item_data.map(prescription => (
                    <div  key={prescription.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="d-flex justify-content-between border-bottom">
                            <div className="">
                                <h6>Prescription Id: {prescription.id}</h6>
                            </div>
                        </div>

                        <div className="">
                            <OrderTracker status={prescription.status}/>
                        </div>
                        <div className="p-2">
                            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-5 justify-content-start">
                                {prescription.beimages.map(image => (
                                    <div key={image.id} className="p-1">
                                        <img onClick={e => handleShowImage(e, prescription.beimages)} 
                                            className="img-fluid img-thumbnail w-100" 
                                            src={process.env.REACT_APP_STORAGE + image.image} 
                                            alt="prescription" 
                                            // role="button" data-bs-toggle="modal" data-bs-target="#imageModal"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
                
                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-2">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>
            <ImageModal title="Prescription images" images={images}/>
        </div>
    )
}

