import useScrollTop from "../../hooks/useScrollTop";
import useFormInput from "../../hooks/useFormInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../../slice/userSlice";
import api from "../../services/api";
import toast from "../../utlities/toast";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Input from "../../components/form/Input";
import InputSelect from "../../components/form/InputSelect";
import InputSwitch from "../../components/form/InputSwitch";

export default function JoinAsDoctorStep1() {
    useScrollTop();
    const token   = useSelector(selectToken);
    const history = useHistory();

    const [countryLists, setCountryLists]   = useState([]);
    const [specialistOptions, setSpecialistOptions] = useState([]);
    const [hospitalOptions, setHospitalOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);
    
    const title                  = useFormInput('');
    const gender                 = useFormInput('');
    const country_id             = useFormInput('');
    const district_id            = useFormInput('');
    const sub_district_id        = useFormInput('');
    const state_id               = useFormInput('');
    const location               = useFormInput('');
    const nid_passport_no        = useFormInput('');
    const registration_no        = useFormInput('');
    const registered_institution = useFormInput('');
    const language               = useFormInput('');

    const hospital_id            = useFormInput('');
    
    const hospital_name     = useFormInput('');
    const designation       = useFormInput('');
    const department        = useFormInput('');
    const [memorable_instrutation, setMemorable_instrutation] = useState(false);
    const memorable_instrutation_name = useFormInput('')

    const experience        = useFormInput('');
    
    const [appointment_service, setAppointment_service] = useState(false);
    const chamber_name         = useFormInput('');
    const chamber_location     = useFormInput('');
    const appointment_fee      = useFormInput('');
    const appointment_discount = useFormInput('');
    const [audio_consultancy, setAudio_consultancy] = useState(false);
    const audio_fee            = useFormInput('');
    const audio_discount       = useFormInput('');
    const [video_consultancy, setVideo_consultancy] = useState(false);
    const video_fee            = useFormInput('');
    const video_discount       = useFormInput('');

    const [specialists, setSpecialists] = useState([]);

    useEffect(() => {
        api().get('/api/load-country-with-other-child-specialist-list')
        .then(response => {
            setCountryLists(response.data.country_lists);
            // response.data.specialist_lists.forEach(item => {
            //     setSpecialistOptions(specialistOptions => [...specialistOptions, {valu: item.id, labe: `${item.title}`}]);
            // });
            setSpecialistOptions(response.data.specialist_lists)
            setHospitalOptions(response.data.hospital_lists)
        })
        .catch(err => {

        });
        return () => {
            setCountryLists([]);
            setSpecialistOptions([]);
        };
    }, []);

    const [withState, setWithState] = useState(false);
    useEffect(() => {
        if(country_id.value && countryLists.find(country => country.id === parseInt(country_id.value)) && parseInt(countryLists.find(country => country.id === parseInt(country_id.value)).is_state) === 1){
            setWithState(true);
        }
        else setWithState(false);
    }, [country_id, countryLists]);


    function handleStep1Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            title                  : title.value,
            gender                 : gender.value,
            country_id             : country_id.value,
            district_id            : district_id.value,
            sub_district_id        : sub_district_id.value,
            state_id               : state_id.value,
            location               : location.value,
            nid_passport_no        : nid_passport_no.value,
            registration_no        : registration_no.value,
            registered_institution : registered_institution.value,
            language               : language.value,

            hospital_id            : hospital_id.value,

            hospital_name              : hospital_name.value,
            department                 : department.value,
            designation                : designation.value,
            memorable_instrutation     : memorable_instrutation,
            memorable_instrutation_name: memorable_instrutation_name.value,

            experience        : experience.value,

            appointment_service  : appointment_service,
            chamber_name         : chamber_name.value,
            chamber_location     : chamber_location.value,
            appointment_fee      : appointment_fee.value,
            appointment_discount : appointment_discount.value,
            audio_consultancy    : audio_consultancy,
            audio_fee            : audio_fee.value,
            audio_discount       : audio_discount.value,
            video_consultancy    : video_consultancy,
            video_fee            : video_fee.value,
            video_discount       : video_discount.value,

            specialists : specialists.map(specialist => specialist.id),
        };

        api(token).post('/api/doctor/join/step-1', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            
            if(response.data.icon === 'success'){
                history.replace('/join/doctor/step-2');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Doctor',
                    text: 'If you want to update your Doctor profile, Then click Edit Doctor Profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Doctor Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/doctor');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Doctor [Step 1 of 4]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Personal Information</h6>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <InputSelect data={title} error={allError['title']} label="Title" htmlFor="title" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Title</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="Prof. Dr.">Prof. Dr.</option>
                                    <option value="Assoc. Prof. Dr.">Assoc. Prof. Dr.</option>
                                    <option value="Asst. Prof. Dr.">Asst. Prof. Dr.</option>
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={gender} error={allError['gender']} label="Gender" htmlFor="gender" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                elements={<>
                                    <option disabled className="bg-light text-info fw-bold" value="">Select country</option>
                                    {countryLists.map(countryList => (
                                        <option key={countryList.id} value={countryList.id}>{countryList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        {!withState && <div className="col-lg-6">
                            <InputSelect data={district_id} error={allError['district_id']} label="District" htmlFor="district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select District</option>
                                    {countryLists.find(country => country.id === parseInt(country_id.value))?.districts?.map(districtList => (
                                        <option key={districtList.id} value={districtList.id}>{districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>}

                        {!withState && <div className="col-lg-6">
                            <InputSelect data={sub_district_id} error={allError['sub_district_id']} label="Sub District" htmlFor="sub_district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select Sub district</option>
                                    {countryLists.find(country => country.id === parseInt(country_id.value))?.districts?.find(district => district.id === parseInt(district_id.value))?.sub_districts?.map(sub_districtList => (
                                        <option key={sub_districtList.id} value={sub_districtList.id}>{sub_districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>}

                        {withState && <div className="col-lg-6">
                            <InputSelect data={state_id} error={allError['state_id']} label="State" htmlFor="state_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select State</option>
                                    {countryLists.find(country => country.id === parseInt(country_id.value))?.states?.map(stateList => (
                                        <option key={stateList.id} value={stateList.id}>{stateList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>}

                        <div className="col-lg-6">
                            <Input data={location} error={allError['location']} label="Location" htmlFor="location" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={nid_passport_no} error={allError['nid_passport_no']} label="National ID/Passport Number" htmlFor="nid_passport_no" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={registration_no} error={allError['registration_no']} label="Registration Number" htmlFor="registration_no" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={registered_institution} error={allError['registered_institution']} label="Registered Institution Name" htmlFor="registered_institution" required={true}/>
                        </div>
                    </div>
                </div>

                {withState && <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Language <span className="small text-primary fw-light">(Example: Bangla, English, Hindi)</span></h6>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <Input data={language} error={allError['language']} label="Language" htmlFor="language" required={true}/>
                        </div>
                    </div>
                </div>}

                {withState && <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Hospital</h6>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <InputSelect data={hospital_id} error={allError['hospital_id']} label="Hospital" htmlFor="hospital_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Hospital</option>
                                    {hospitalOptions?.map((hospital) => (
                                        <option key={hospital.id} value={hospital.id}>{hospital.name}</option>
                                    ))}
                                </>}
                            />
                        </div>
                    </div>
                </div>}

                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Current Working Place</h6>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Input data={hospital_name} error={allError['hospital_name']} label="Institution/Hospital Name" htmlFor="hospital_name" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={department} error={allError['department']} label="Department" htmlFor="department" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={designation} error={allError['designation']} label="Designation" htmlFor="designation" required={true}/>
                        </div>

                        <div className="col-lg-12">
                            <InputSwitch
                                data={memorable_instrutation} 
                                onClick={value =>setMemorable_instrutation(value)} 
                                htmlFor="memorable_instrutation"
                                label="If any most memorable experience instrutation"
                                groupClass="form-check form-switch mb-2"
                            />
                        </div>
                        
                        {memorable_instrutation && <div className="col-lg-12">
                            <div className="col-lg-12">
                                <Input data={memorable_instrutation_name} error={allError['memorable_instrutation_name']} label="Memorable Instrutation Name" htmlFor="memorable_instrutation_name" required={true}/>
                            </div>
                        </div>}
                    </div>
                </div>
            
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Specialist In</h6>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <div className="form-group mb-4">
                                <Select 
                                    onChange={setSpecialists}
                                    isMulti
                                    options={specialistOptions}
                                    placeholder="Select*"
                                    components={makeAnimated()}
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                />
                                {allError["specialists"] && <div className="alart alert-danger p-1 rounded h-auto shadow-sm">{allError["specialists"]}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Experience</h6>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <InputSelect data={experience} error={allError['experience']} label="Experience" htmlFor="experience" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Experience</option>
                                    {Array.from(Array(31)).map((year, index) => (
                                        <option key={index} value={index}>{index < 1 ? 'Less than 1': index} year{index > 1 ? 's':''}</option>
                                    ))}
                                </>}
                            />
                        </div>
                    </div>
                </div>
            
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Select What services do you want to provide <span className="small text-muted">(select at least one or more)</span></h6>
                    <fieldset className={`${appointment_service ? 'border border-primary': ''} rounded p-2 my-3`}>
                        <legend className="float-none w-auto my-0">
                            <InputSwitch
                                data={appointment_service} 
                                onClick={value =>setAppointment_service(value)} 
                                htmlFor="appointment_service"
                                label="Appointment Service"
                                labelClass="form-check-label small"
                            />
                        </legend>

                        {appointment_service && 
                            <div className="row justify-content-between">
                                <div className="col-lg-6">
                                    <Input data={chamber_name} error={allError['chamber_name']} label="Chamber/Clinic/Hospital Name" htmlFor="chamber_name" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={chamber_location} error={allError['chamber_location']} label="Chamber/Clinic/Hospital Location" htmlFor="chamber_location" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={appointment_fee} error={allError['appointment_fee']} label="Appointment Fee" htmlFor="appointment_fee" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={appointment_discount} error={allError['appointment_discount']} label="Appointment Discount (0-99)%" htmlFor="appointment_discount"/>
                                </div>
                            </div>
                        }
                    </fieldset>

                    <fieldset className={`${audio_consultancy ? 'border border-primary': ''} rounded p-2 my-3`}>
                        <legend className="float-none w-auto my-0">
                            <InputSwitch
                                data={audio_consultancy} 
                                onClick={value =>setAudio_consultancy(value)} 
                                htmlFor="audio_consultancy"
                                label="Audio Consultancy Service"
                                labelClass="form-check-label small"
                            />
                        </legend>

                        {audio_consultancy && 
                            <div className="row justify-content-between">
                                <div className="col-lg-6">
                                    <Input data={audio_fee} error={allError['audio_fee']} label="Audio consultancy Fee" htmlFor="audio_fee" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={audio_discount} error={allError['audio_discount']} label="Audio consultancy Discount (0-99)%" htmlFor="audio_discount"/>
                                </div>
                            </div>
                        }
                    </fieldset>

                    <fieldset className={`${video_consultancy ? 'border border-primary': ''} rounded p-2 my-3`}>
                        <legend className="float-none w-auto my-0">
                            <InputSwitch
                                data={video_consultancy} 
                                onClick={value =>setVideo_consultancy(value)} 
                                htmlFor="video_consultancy"
                                label="Video Consultancy Service"
                                labelClass="form-check-label small"
                            />
                        </legend>

                        {video_consultancy && 
                            <div className="row justify-content-between">
                                <div className="col-lg-6">
                                    <Input data={video_fee} error={allError['video_fee']} label="Video consultancy Fee" htmlFor="video_fee" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={video_discount} error={allError['video_discount']} label="Video consultancy Discount (0-99)%" htmlFor="video_discount"/>
                                </div>
                            </div>
                        }
                    </fieldset>
                    {allError['appointment_service'] && <div className="small alaert alert-danger p-1 rounded shadow-sm">{allError['appointment_service']}</div>}
                </div>

                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep1Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}