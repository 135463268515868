import Avatar from "./Avatar";

export default function NurseShortList({nurse, locationCharLimit = 256}) {
    return (
        <>
            <div>
                <div className="d-flex justify-content-start">
                    <div className="py-2">
                        <Avatar avatarPath={process.env.REACT_APP_STORAGE + nurse.user.avatar} sizeClass='my-lg-avatar' rounded='rounded'/>
                        {/* <img className="img-fluid img-thumbnail p-1" width="100px" height="100px" src={process.env.REACT_APP_STORAGE + nurse.user.avatar} alt={nurse.user.name} /> */}
                    </div>
                    <div className="p-2 w-100">
                        <h6 className="fw-bold text-dark">{nurse.user.name}</h6>
                        <h6 className="small my-1 text-secondary">{nurse.district?.name},{nurse.sub_district?.name}</h6>
                        <h6 className="small my-0 text-secondary">Location: <span className="fw-light">{nurse.location.substring(0,locationCharLimit)}</span></h6>
                    </div>
                </div>
            </div>
        </>
    )
}
