import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactSlick from 'react-slick';
import Avatar from '../../Avatar';

class NurseSlick extends React.Component {

    constructor(props) {
        super(props)

        this.settings = {
            dots: false,
            infinite: this.props.items.length > 5 ? true : false,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: this.props.items.length > 2 ? true : false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: this.props.items.length > 1 ? true : false,
                        // initialSlide: 1
                    }
                },
              ]
        };
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {

        return (
            <ReactSlick {...this.settings}>
                {this.props.items.map(item => (
                    <div key={item.id} className="p-sm-2 mb-3">
                        <div className="px-only-sm-1">
                            <div className="card border-0 shadow-sm item-card p-2">
                                <div onClick={() => this.props.history.push(`/nurse-appointment/nurse/${item.id}`)} className="d-flex g-0" role='button'>
                                    <div className="">
                                        <Avatar avatarPath={process.env.REACT_APP_STORAGE + '../images/defaults/nurse_avatar.png'} sizeClass='my-lg-avatar' rounded='rounded'/>
                                    </div>
                                    <div className="">
                                        <div className="card-body py-0">
                                            <h6 className="fw-bold text-dark">{item.user.name}</h6>
                                            <p className="small m-0">Experience: <span className='fw-500 small'>{item.final_experience}</span></p>
                                            <div className="d-flex position-absolute bottom-5">
                                                {Array.from(Array(5)).map((i, index) => (
                                                    <i key={index} className={`bi bi-star${parseInt(item.reviews_avg_rating ?? 0) > index ? '-fill':''} text-warning`}></i>
                                                ))}
                                                <p className="my-0 px-2 text-dark fw-bold">{parseFloat(item.reviews_avg_rating ?? 0).toFixed(1)}</p>
                                                <p className="cart-text m-0 px-1">({item.reviews_count})</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </ReactSlick>
        )
    }


}

export default withRouter(NurseSlick)