import useScrollTop from "../../hooks/useScrollTop";
import useFormInput from "../../hooks/useFormInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../../slice/userSlice";
import api from "../../services/api";
import toast from "../../utlities/toast";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Input from "../../components/form/Input";
import RadioClassic from "../../components/form/RadioClassic";
import InputSelect from "../../components/form/InputSelect";

export default function JoinAsAmbulanceStep1() {
    useScrollTop();
    const token   = useSelector(selectToken);
    const history = useHistory();

    const [districtLists, setDistrictLists]   = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);
    
    const driver_name     = useFormInput('');
    const owner_name      = useFormInput('');
    const country_id      = useFormInput('');
    const district_id     = useFormInput('');
    const sub_district_id = useFormInput('');
    const location        = useFormInput('');
    const contact_phone   = useFormInput('');
    const vehicle_no      = useFormInput('');
    const [condition, setCondition] = useState('Non AC');
    const starting_area   = useFormInput('');



    useEffect(() => {
        api().get('/api/load-district-with-sub-district')
        .then(response => {
            setDistrictLists(response.data.district_lists);
        })
        .catch(err => { });
        return () => {setDistrictLists([]);};
    }, []);


    function handleStep1Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            driver_name:     driver_name.value,
            owner_name:      owner_name.value,
            country_id:      country_id.value,
            district_id:     district_id.value,
            sub_district_id: sub_district_id.value,
            location:        location.value,
            contact_phone:   contact_phone.value,
            vehicle_no:      vehicle_no.value,
            condition:        condition,
            starting_area:   starting_area.value,
        };

        api(token).post('/api/ambulance/join/step-1', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            
            if(response.data.icon === 'success'){
                history.replace('/join/ambulance/step-2');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Ambulance service',
                    text: 'If you want to update your Ambulance service profile, Then click Edit Ambulance service profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Ambulance Service Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/ambulance');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Ambulance service provider [Step 1 of 2]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Personal Information</h6>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Input data={driver_name} error={allError['driver_name']} label="Driver Name" htmlFor="driver_name" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={owner_name} error={allError['owner_name']} label="Owner Name" htmlFor="owner_name" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                elements={<>
                                    <option disabled className="bg-light text-info fw-bold" value="">Select country</option>
                                    <option value={1}>Bangladesh</option>
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={district_id} error={allError['district_id']} label="District" htmlFor="district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select District</option>
                                    {districtLists?.map(districtList => (
                                        <option key={districtList.id} value={districtList.id}>{districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={sub_district_id} error={allError['sub_district_id']} label="Sub District" htmlFor="sub_district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select Sub district</option>
                                    {districtLists?.find(district => district.id === parseInt(district_id.value))?.sub_districts?.map(sub_districtList => (
                                        <option key={sub_districtList.id} value={sub_districtList.id}>{sub_districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <Input data={location} error={allError['location']} label="Location" htmlFor="location" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={contact_phone} error={allError['contact_phone']} label="Contact phone number" htmlFor="contact_phone" required={true}/>
                        </div>
                    </div>
                </div>

                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Ambulance Information</h6>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Input data={vehicle_no} error={allError['vehicle_no']} label="Vehicle Number" htmlFor="vehicle_no" required={true}/>
                        </div>
                        
                        <div className="col-lg-6">
                            <Input data={starting_area} error={allError['starting_area']} label="Ambulance area/Starting area" htmlFor="starting_area" required={true}/>
                        </div>

                        <div className="col-12">
                            <p className="m-0 p-1 bg-light">Ambulance condition</p>
                            <RadioClassic data={condition} setOnChange={setCondition} htmlFor="ac" label="AC" value="AC" name="conditionRadios"/>
                            <RadioClassic data={condition} setOnChange={setCondition} htmlFor="non_ac" label="Non AC" value="Non AC" name="conditionRadios"/>
                        </div>
                    </div>
                </div>
            
                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep1Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
