import PopularDoctor from "../../components/PopularDoctor";
import Specialist from "../../components/Specialist";

export default function BdVideoSpecialist() {
    return (
        <>
            <PopularDoctor api_url="/api/popular-bd-video-doctor" next_link="/bd-video-consultancy" next_route="/video-consultancy/doctor"/>
            <Specialist next_link="/bd-video-consultancy"/>
        </>
    )
}
