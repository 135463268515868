export default function CheckboxSwitch({
    data,
    setOnChange,
    htmlFor='',
    label='',
    name='',
    groupClass='form-check form-switch mb-3',
    disabled=false,
}) {
    return (
        <div className={groupClass}>
            <input onChange={() => setOnChange(!data)} checked={data} className="form-check-input outline-none" type="checkbox" role="switch" name={name} id={htmlFor} disabled={disabled}/>
            <label className="form-check-label" htmlFor={htmlFor}>{label}</label>
        </div>
    )
}

