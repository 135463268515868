import useScrollTop from "../../hooks/useScrollTop";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../../slice/userSlice";
import api from "../../services/api";
import toast from "../../utlities/toast";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

export default function JoinAsDoctorStep2() {
    useScrollTop();
    const token    = useSelector(selectToken);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const [qualifications, setQualifications] = useState([
        {degree: "", institution_name: "", institution_location: ""},
        {degree: "", institution_name: "", institution_location: ""},
    ]);

    function handleRemoveQualification(e, index){
        e.preventDefault();
        if(qualifications.length !== 1){
            const list = [...qualifications];
            list.splice(index, 1);
            setQualifications(list);
        }
    }

    function handleAddQualification(e){
        e.preventDefault();
        if(qualifications.length < 5){
            setQualifications([...qualifications, {degree: "", institution_name: "", institution_location: ""},]);
        }
        else Swal.fire('Do not add more then 5 qualifications','','warning')
    }

    function handleQualificationChange(e, index){
        const { name, value } = e.target;
        const list = [...qualifications];
        list[index][name] = value;
        setQualifications(list);
    }


    function handleStep2Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            qualifications : qualifications,
        };

        api(token).post('/api/doctor/join/step-2', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            
            if(response.data.icon === 'success'){
                history.replace('/join/doctor/step-3');
            }
            else if(response.data.icon === 'warning'){
                history.replace('/join/doctor/step-1');
                Swal.fire('Please flow step by step','','warning');
            }
            else if(response.data.icon === 'error'){
                Swal.fire('Something wrong','Please try again','error');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Doctor',
                    text: 'If you want to update your Doctor profile, Then click Edit Doctor Profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Doctor Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/doctor');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;

            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    

    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Doctor [Step 2 of 4]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Personal Qualification</h6>
                    {/* <pre className="">{JSON.stringify(qualifications, null, 2)}</pre> */}
                    {qualifications.map((qualification, index) => (
                        <div key={index} className="row">
                            <div className="col-lg-3">
                                <div className="form-floating my-4">
                                    <input onChange={e => handleQualificationChange(e, index)}
                                        value={qualification.degree}
                                        name="degree"
                                        type="text" 
                                        className={`form-control outline-none ${allError['qualifications.' + index + '.degree'] ? 'is-invalid':''}`}
                                        placeholder="Institution Location"/>
                                    <label htmlFor="degree">Degree<span className="text-danger">*</span></label>
                                    {allError['qualifications.' + index + '.degree'] && <div className="invalid-tooltip">{allError['qualifications.' + index + '.degree']}</div> }
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-floating my-4">
                                    <input onChange={e => handleQualificationChange(e, index)}
                                        value={qualification.institution_name}
                                        name="institution_name"
                                        type="text" 
                                        className={`form-control outline-none ${allError['qualifications.' + index + '.institution_name'] ? 'is-invalid':''}`}
                                        placeholder="Institution Name"/>
                                    <label htmlFor="institution_name">Institution Name<span className="text-danger">*</span></label>
                                    {allError['qualifications.' + index + '.institution_name'] && <div className="invalid-tooltip">{allError['qualifications.' + index + '.institution_name']}</div> }
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-floating my-4">
                                    <input onChange={e => handleQualificationChange(e, index)}
                                        value={qualification.institution_location}
                                        name="institution_location"
                                        type="text" 
                                        className={`form-control outline-none ${allError['qualifications.' + index + '.institution_location'] ? 'is-invalid':''}`}
                                        placeholder="Degree"/>
                                    <label htmlFor="institution_location">Institution Location<span className="text-danger">*</span></label>
                                    {allError['qualifications.' + index + '.institution_location'] && <div className="invalid-tooltip">{allError['qualifications.' + index + '.institution_location']}</div> }
                                </div>
                            </div>
                            <div className="col-lg-1 align-self-center">
                                <div className="my-2 d-flex justify-content-around">
                                    {qualifications.length !== 1 && 
                                        <button onClick={e => handleRemoveQualification(e, index)} className="btn btn-sm btn-danger btn-outline-none"><i className="bi bi-trash"></i></button>
                                    }
                                    {qualifications.length === index+1 && 
                                        <button onClick={handleAddQualification} className="btn btn-sm btn-secondary btn-outline-none"><i className="bi bi-plus-circle"></i></button>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep2Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            
            </div>
        </section>
    )
}
