import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import api from "../services/api";
import BoxEmpty from "./BoxEmpty";
import ItemLoader from "./loader/item_loder/ItemLoader";

const PopularHospital = ({next_link}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [hospitals, setHospitals] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api().get('/api/load-hospital-all')
        .then(response => {
            setHospitals(response.data);
            setIsLoading(false);
        })
        .catch(err => {});
        return () => setHospitals([]);
    }, []);

    return (
        <section className="bg-light">
            <div className="container py-4">
                <div className="p-2 p-md-3 shadow-sm rounded-sm bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="m-0">Hospitals</h5>
                        <Link to={next_link} className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View All Doctor</Link>
                    </div>
                </div>
                <div className="row row-cols-sm-2 row-cols-lg-3 p-1">
                    {isLoading && Array.from(Array(6)).map((item, index) => (
                        <div key={index} className="p-1 p-md-2">
                            <div className="shadow-sm rounded-sm bg-white ">
                                <ItemLoader classList="p-5"/>
                            </div>
                        </div>
                    ))}

                    {hospitals?.map((hospital) => (
                        <div key={hospital.id} className="p-1 p-md-2">
                            <div className="bg-white shadow-sm rounded-start">
                                <Link to={`${next_link}?hospital=${hospital.id}`} className="text-decoration-none">
                                    <div className="row m-0 item-card">
                                        <div className="col-3 col-sm-4 p-0">
                                            <img className="img-fluid bg-light rounded-start"  src={process.env.REACT_APP_STORAGE + hospital.logo} alt={hospital.name}/>
                                        </div>
                                        <div className="col-9 col-sm-8 align-self-center p-1">
                                            <h6 className='small fw-500 text-center m-0 text-dark'>{hospital.name}</h6>
                                        </div>
                                    </div>
                                </Link>

                            </div>
                        </div>
                    ))}
                    {!isLoading && hospitals.length < 1 && (
                        <div className="m-auto">
                            <BoxEmpty/>
                        </div>
                    )}
                </div>

            </div>
        </section>
    )
}

export default PopularHospital