import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useFormInput from "../hooks/useFormInput";
import api from "../services/api";
import { selectToken, selectUser } from "../slice/userSlice";
import toast from "../utlities/toast";
import DropZone from "./DropZone";
import Input from "./form/Input";
import SubmitBtn from "./form/SubmitBtn";
import TextArea from "./form/TextArea";
import ItemLoader from "./loader/item_loder/ItemLoader";
import { useTranslation } from 'react-i18next';

export default function PrescriptionUpload() {

    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const history = useHistory();
    const { t } = useTranslation();

    const [images, setImages] = useState([]);
    const day_for = useFormInput('');
    const unnecessary_medicine = useFormInput('');
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const [shipping_address, setShipping_address] = useState({});
    const [isLoadingShippingAddress, setIsLoadingShippingAddress] = useState(true);
    // const [shippingFee, setShippingFee] = useState(30);


    useEffect(() => {
        api(token).get('/api/auth/get-shipping-address')
        .then(response => {
            setShipping_address(response.data.shipping_address);
            // setShippingFee(response.data.shipping_fee);
            setIsLoadingShippingAddress(false);
        })
        .catch(err => {
            setIsLoadingShippingAddress(false);
        })
        return () => {setShipping_address([])}
    }, [token]);

    function handlePrescriptionUpload(e){
        e.preventDefault();
        if(isEmpty(shipping_address)){
            Swal.fire('Shiping address not found','Please first add your shipping address','warning');
            return;
        }

        if(images.length < 1){
            Swal.fire('No image','Please first add prescription images','warning');
            return;
        }
        
        setIsLoading(true);

        const data = new FormData();
        images.forEach(image => {
            data.append('images[]', image);
        });
        data.append('day_for', day_for.value);
        data.append('unnecessary_medicine', unnecessary_medicine.value);

        api(token).post('/api/auth/store/upload-prescription', data)
        .then(response => {
            setAllError([]);
            setIsLoading(false);

            if(response.data.status === 'success'){
                toast('Prescription successfully uploaded.','success');
                setImages([]);
                history.push('/profile/my-prescription');
            }
            else{
                toast('Something wrong, Try again.','error');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            console.log(error);
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }


    return (
        <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
            <div className="row row-cols-1 row-cols-lg-2">
                <div className="">

                    <div className="border p-1 p-md-2 rounded mb-5">
                        <div className="d-flex justify-content-between">
                            <h6 className="m-0">Shipping informatin</h6>
                            {!isEmpty(shipping_address) && <Link to="/profile/my-shipping-address?_reb=upload_prescription" className="btn btn-sm btn-master px-3 btn-outline-none">Edit</Link>}
                        </div>
                        {isLoadingShippingAddress ? <div>
                            <ItemLoader classList="py-5 my-2 shadow-sm"/>
                        </div>
                        :
                        <div>
                            {!isEmpty(shipping_address) ? <div className="bg-light rounded p-2 mt-1">
                                <h6 className="small"> <span className="text-master"><i className="bi bi-geo-alt-fill"></i></span> {shipping_address.name || user.name}</h6>
                                <div className="d-flex">
                                    <h6 className="bg-info bg-opacity-25 rounded p-1 m-0 mx-2 h-100">{shipping_address.effective_delivery}</h6>
                                    <p className="m-0 align-self-center"> {shipping_address?.country?.name}, {shipping_address?.district?.name}, {shipping_address?.sub_district?.name}</p>
                                </div>
                            </div>
                            :
                            <div className="bg-light rounded p-2 mt-1 text-center">
                                <h6 className="small text-info">We didn't get your shipping address. Please first add your shipping address.</h6>
                                <Link to="/profile/my-shipping-address?_reb=upload_prescription" className="btn btn-sm btn-master px-3 btn-outline-none">Add shipping address</Link>
                            </div>}
                        </div>}
                    </div>


                    <div className="mb-2">
                        <h6 className="m-0">Have any prescription? Upload here</h6>
                        <small className="m-0 font-xm">Select Maximum 5 image</small>
                    </div>

                    <form onSubmit={handlePrescriptionUpload}>
                        <DropZone callbackSetFiles={callbackFiles => {setImages(callbackFiles)}}
                            text="Drag and drop image here, or click to select*"
                            fileClass="row row-cols-5 m-0"
                            isPreview={true}
                            isMulti={true}
                            maxFiles={5}
                            isInvalid={allError['images'] ? true : false}
                            errorText={allError['images']}
                        />
                        <div className="p-1">
                            {Array.from(Array(5)).map((item,index) => (
                                allError[`images.${index}`] && <div key={index} className="alert alert-danger font-xm py-0 px-1 my-1">{allError[`images.${index}`]}</div>
                            ))}
                        </div>
                        <div>
                            <Input type="number" data={day_for} error={allError['day_for']} label={t("medicine_order_title1")} htmlFor="day_for"/>
                        </div>
                        <div>
                            <TextArea
                                data={unnecessary_medicine} 
                                label={t("medicine_order_title2")}
                                htmlFor='unnecessary_medicine'
                                autoFocus={false}
                                error={allError['unnecessary_medicine']}
                            />
                        </div>

                        <div className="py-2">
                            <SubmitBtn isLoading={isLoading} className="w-100 btn-master" title="Upload Prescription"/>
                        </div>
                    </form>
                </div>

                <div className="border-start">
                    <div className="mb-2">
                        <h6 className="m-0">Valid Prescription Guide</h6>
                        <small className="m-0 font-xm">Upload valid prescription for better service</small>
                    </div>
                    <div className=" text-muted small">
                        <ul>
                            <li>Include details of doctor and patient+clinic visit details.</li>
                            <li>Medicines will be dispensed as per prescription.</li>
                            <li>Select as many image as you want to upload together</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
