import useScrollTop from "../hooks/useScrollTop";
import Bookings from "../components/home/Bookings";
// import PopularShopItem from "../components/PopularShopItem";
import HomeSlider from "../components/sliders/HomeSlider";
import PopularHomeItems from "../components/home/PopularHomeItems";
// import ProductShortList from "../components/home/ProductShortList";

export default function Home() {
    useScrollTop();

    return (
        <>
            <section className="bg-light">
                <div className="p-2">
                    <HomeSlider/>
                </div>
            </section>

            <section className="bg-light">
                <div className="container py-4">
                    <Bookings/>
                </div>
            </section>

            {/* <PopularShopItem/> */}

            {/* <ProductShortList/> */}

            <PopularHomeItems/>

            <section className="bg-light">
                <div className="py-4"></div>
            </section>
            

            {/* <PopularCategory categories={categories}/> */}

            {/* <TopSellsItem/> */}

            {/* <DiscountItem/> */}
            
        </>
    )
}
