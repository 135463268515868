import useScrollTop from "../../hooks/useScrollTop";

export default function SiteMap() {
    useScrollTop();

    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center fw-bold">Site Mape</div>
            </div>

            <div className="bg-white rounded shadow-sm p-2 p-md-4">
                
                
            </div>
        </div>
    )
}
