import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SubmitBtn from "../../../../components/form/SubmitBtn";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function SpecialistInEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const [specialistOptions, setSpecialistOptions] = useState([]);
    const [specialists, setSpecialists] = useState([]);

    useEffect(() => {
        api().get('/api/load-specialists-all')
        .then(response => {
            response.data.forEach(item => {
                // setSpecialistOptions(specialistOptions => [...specialistOptions, {value: item.id, label: `${item.title}`}]);
                if(doctor?.specialists?.find(sp => sp.id === item.id)){
                    setSpecialists(prevSpecialists => [...prevSpecialists, {id: item.id, title: `${item.title}`}]);
                }
            });
            setSpecialistOptions(response.data)
        })
        .catch(err => {});
        return () => {setSpecialistOptions([]);};
    }, [doctor]);

    function handleSpecialistSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            specialists : specialists.map(specialist => specialist.id),
        };

        api(token).post('/api/doctor/update/specialist', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor){
                updateDoctor(response.data.doctor);
            }
            toast('Specialist successfully updated', 'success');
            setAllError([]);
            cancelEdit(false);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleSpecialistSubmit}>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <div className="form-group mb-4">
                                <Select
                                    onChange={setSpecialists}
                                    isMulti
                                    options={specialistOptions}
                                    value={specialists}
                                    placeholder="Select*"
                                    components={makeAnimated()}
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                />
                                {allError["specialists"] && <div className="alart alert-danger p-1 rounded h-auto shadow-sm">{allError["specialists"]}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}