import { createSlice } from '@reduxjs/toolkit';
import { getStorage, removeStorage, setStorage } from '../app/storage';


export const fromLocationSlice = createSlice({
    name: "fromLocation",
    initialState : {
        fromLocation: getStorage('fromLocation', true)
    },

    reducers: {
        setFromLocation: (state, action) => {
            setStorage('fromLocation', action.payload, true);
            state.fromLocation = getStorage('fromLocation', true);
        },

        removeFromLocation: (state) => {
            removeStorage('fromLocation');
            state.fromLocation = getStorage('fromLocation', true);
        }
    },

});

export const { setFromLocation, removeFromLocation } = fromLocationSlice.actions;

export const selectFromLocation = (state) => state.fromLocation.fromLocation;

export default fromLocationSlice.reducer;
