import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BoxEmpty from "../../../components/BoxEmpty";
import ItemLoader from "../../../components/loader/item_loder/ItemLoader";
import MostSimplePaginator from "../../../components/pagination/MostSimplePaginator";
import UserList from "../../../components/UserList";
import useItems from "../../../hooks/useItems";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { selectToken } from "../../../slice/userSlice";

export default function AppointmentList() {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/doctor/appointment/appointments', token, nextPage);

    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm border-top rounded-top border-3 border-master">
                <h6 className="m-0">Appointment list <span className="small fw-normal text-info">(All times are in Bangladesh Standard Time)</span></h6>
            </div>

            <div>
                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-5" />
                        </div>
                    </div>
                ))}

                {item_data.length>0 && item_data.map(appointment => (
                    <div  key={appointment.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="d-flex justify-content-between border-bottom">
                            <div className="">
                                <h6 className="m-0 text-end">Appointment Id: {appointment.id}</h6>
                            </div>
                            <div className="">
                                <p className="m-0">Status: <span className={appointment.appointment_status_style}>{appointment.status}</span></p>
                            </div>
                        </div>

                        <div className='row row-cols-lg-2 px-2 overflow-auto'>
                            <UserList user={appointment.user}/>

                            <div className="py-2">
                                <h6 className="m-0 p-1 small bg-light">Patient info</h6>
                                <p className="text-dark small mb-0">Name: {appointment.name}</p>
                                <p className="text-dark small mb-0">Age: {appointment.patient_age}</p>
                                <p className="text-dark small mb-0">Gender: {appointment.gender}</p>
                                <p className="text-dark small mb-0">Country: {appointment.country?.name}</p>
                                <p className="text-dark small mb-0">City: {appointment.city}</p>
                                <p className="text-dark small mb-0">Occupation: {appointment.occupation}</p>
                                <p className="text-dark small mb-0">Patient type: {appointment.patient_type}</p>
                            </div>
                        </div>

                        <div className="pt-1 border-top d-flex justify-content-between">
                            <div>
                                <h6 className="text-muted m-0">Serial no: {appointment.serial_no}</h6>
                                <h6 className="text-muted m-0">Schedule: {appointment.app_date} {appointment.app_time}</h6>
                            </div>
                            <div>
                                <Link 
                                    to={{
                                        pathname: `/sp/doctor/appointment/${appointment.id}/view`,
                                        state: {appointment: appointment}
                                    }}
                                    className="btn btn-sm btn-master px-3 btn-outline-none">View</Link>
                            </div>
                        </div>

                    </div>
                ))}

                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-2">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>
        </div>
    )
}
