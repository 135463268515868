import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { selectToken } from "../../slice/userSlice";
import Swal from "sweetalert2";
import api from "../../services/api";
import DoctorLoader from "../../components/loader/DoctorLoader";
import moment from "moment";
import BoxEmpty from "../../components/BoxEmpty";
import useFormInput from "../../hooks/useFormInput";
import DoctorList from "../../components/DoctorList";
import Input from "../../components/form/Input";
import InputSelect from "../../components/form/InputSelect";
import TextArea from "../../components/form/TextArea";
import toast from "../../utlities/toast";
import SubmitBtn from "../../components/form/SubmitBtn";
import CheckboxSwitch from "../../components/form/CheckboxSwitch";

export default function InDocAppointmentNow() {
    const { id } = useParams();
    useEffect(() => {window.scrollTo(0, 0); return;}, [id]);

    const token = useSelector(selectToken);

    let to_day = new Date();

    const [isLoading, setIsLoading] = useState(true);
    const [doctor, setDoctor]       = useState([]);
    const [error, setError]         = useState(null);
    const [isReferralLoading, setIsReferralLoading] = useState(false);

    const [referral_code, setReferral_code] = useState('');
    const [final_referral_code, setFinal_referral_code] = useState(null);
    const name          = useFormInput('');
    const dob           = useFormInput('');
    const gender        = useFormInput('');
    const occupation    = useFormInput('');
    const country_id    = useFormInput('');
    const city          = useFormInput('');
    const disease_about = useFormInput('');
    const [patient_type, setPatient_type] = useState('New')

    const [app_date, setApp_date] = useState('');
    const [app_time, setApp_time] = useState('');

    const [medical_visa, setMedical_visa] = useState(false);
    const [guide, setGuide]               = useState(false);
    const [transport, setTransport]       = useState(false);
    const [hotel, setHotel]               = useState(false);
    const [diagnostic, setDiagnostic]     = useState(false);
    const [sergery, setSergery]           = useState(false);

    const [allError, setAllError] = useState([]);
    const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);

    function handleApplyReferralCode(e){
        e.preventDefault();
        setIsReferralLoading(true);

        const body = {
            referral_code: referral_code,
        }

        api(token).post(`/api/auth/doctor-appointment/apply-referral-code`, body)
        .then(response => {
            setIsReferralLoading(false);
            setAllError([]);
            if(response.data.status === 'success'){
                setFinal_referral_code(response.data.referral_code);
                Swal.fire('Congratulations', response.data.message, 'success');
            }
            else{
                setFinal_referral_code(null);
                Swal.fire('Invalid referral code', '', 'warning');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsReferralLoading(false);
            setFinal_referral_code(null);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        })
    }

    function handleAppointmentSubmit(e){
        e.preventDefault();
        // if(true){
        //     Swal.fire('We working on it','','question');
        // }
        
        if(app_date === ''){
            Swal.fire('Invalid appointment date','Please select a date','error');
            return;
        }

        setIsAppointmentLoading(true);

        const body = {
            referral_code: final_referral_code,
            name       : name.value,
            dob        : dob.value,
            gender     : gender.value,
            occupation : occupation.value,
            country_id : country_id.value,
            city       : city.value,
            disease_about: disease_about.value,
            patient_type : patient_type,
            app_date: app_date.toDateString(),
            app_time: app_time,

            medical_visa : medical_visa,
            guide        : guide,
            transport    : transport,
            hotel        : hotel,
            diagnostic   : diagnostic,
            sergery      : sergery,
        };

        api(token).post(`/api/auth/doctor-appointment/in-doctor/${id}/appointment`, body)
        .then(response => {
            setIsAppointmentLoading(false);
            setAllError([]);
            if(response.data.status !== 'success'){
                Swal.fire('Something worng!', `${response.data.message}`,'warning');
                setIsLoading(false);
                return;
            }
            if(response.data.need_payment){
                Swal.fire({
                    title: 'Please wait...',
                    text: "Don't refresh or close the page.",
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
                Swal.showLoading();
                window.location.replace(process.env.REACT_APP_API_URL + '/payment/payment-submit/' + response.data.need_payment_id);
                return;
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsAppointmentLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        })
    }


    const dateFilter = (date, wdays) => {
        return wdays.find(wday => date === parseInt(wday.code));
    }


    useEffect(() => {
        api().get(`/api/in-doctor-appointment/${id}`)
        .then(response => {
            if(response.data === "") setError('Doctor not found');
            else setDoctor(response.data);
            setIsLoading(false);
        }).catch(err => {
            const error = err.response ? err.response.data : err;
            setError(error.message);
            setIsLoading(false);
        });
        return () => {setDoctor([]); setError(null)};
    }, [id]);


    if(isLoading){
        return (
            <DoctorLoader/>
        )
    }

    else if(error){
        return (
            <section className="bg-light py-4 px-1">
                <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Doctor Profile</div>
                <div className="container">
                    <div className="bg-white">
                        <div className="row">
                            <div className="p-2 bg-white">
                                <div className="text-center"><BoxEmpty/>{error}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    else if(doctor.user){
        return (
            <>
                <section className="bg-light p-4 py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Doctor Profile</div>
                    <div className="container">
                        <div className="bg-white">
                            <div className="row justify-content-center shadow-sm bg-white">
                                <DoctorList
                                    doctor={doctor}
                                    fee={doctor.appointment_fee}
                                    final_fee={doctor.final_appointment_fee}
                                    discount={doctor.appointment_discount}
                                    fee_title="Per appointment"
                                    next_arrow={false}
                                />
                                    
                                <div className="col-12 bg-white border-top">
                                    <div className="row  row-cols-2 row-cols-lg-4 text-secondary">
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Available days</p>
                                                <h6 className="text-dark fw-bold">
                                                    {doctor.wdays?.map((wday,index) => (
                                                        <span key={wday.id}>{index !== 0 && ', '}{wday.short_title}</span>
                                                    ))}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Available time</p>
                                                <h6 className="text-dark fw-bold">{moment(doctor.time_from, 'hh:mm:ss').format('h:m A')} - {moment(doctor.time_to, 'hh:mm:ss').format('h:m A')}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Doctor Code</p>
                                                <h6 className="text-dark fw-bold">{doctor.doc_id}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Patient attended</p>
                                                <h6 className="text-dark fw-bold">{doctor.doctor_appointments_count}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 bg-white border-top">
                                    <div className="row text-secondary">
                                        <div className="p-2 col-6 col-lg-3">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">State</p>
                                                <h6 className="text-dark fw-bold">{doctor.state?.name}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2 col-6 col-lg-3">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Chamber name</p>
                                                <h6 className="text-dark fw-bold">{doctor.chamber_name}</h6>
                                            </div>
                                        </div>
                                        <div className="p-2 col-12 col-lg-6">
                                            <div className="bg-light rounded-3 p-2">
                                                <p className="mb-1">Chamber location</p>
                                                <h6 className="text-dark fw-bold">{doctor.chamber_location}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        
                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Indian additional services (Choose if you need)</div>
                    <div className="container bg-white shadow-sm">
                        <div className="p-2 p-md-4">
                            <div className="form-check form-switch mb-3">
                                <input onChange={e => setSergery(true)} className="form-check-input" type="checkbox" role="switch" id="doctor_appointment" checked disabled/>
                                <label className="form-check-label text-success fw-bold" htmlFor="doctor_appointment">Doctor Appointment</label>
                            </div>
                            <CheckboxSwitch data={medical_visa} setOnChange={setMedical_visa} htmlFor="medical_visa" name="medical_visa" label="Medical Visa Service"/>
                            
                            <CheckboxSwitch data={guide} setOnChange={setGuide} htmlFor="guide" name="guide" label="Guide Service"/>
                            
                            <CheckboxSwitch data={transport} setOnChange={setTransport} htmlFor="transport" name="transport" label="Transport Service"/>

                            <CheckboxSwitch data={hotel} setOnChange={setHotel} htmlFor="hotel" name="hotel" label="Hotel Booking" disabled={true}/>

                            <CheckboxSwitch data={diagnostic} setOnChange={setDiagnostic} htmlFor="diagnostic" name="diagnostic" label="Diagnostic Service" disabled={true}/>

                            <CheckboxSwitch data={sergery} setOnChange={setSergery} htmlFor="sergery" name="sergery" label="Requirment Sergery" disabled={true}/>
                        </div>
                    </div>
                </section>

                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Referral Code <span className="m-0 text-primary small fw-normal">(If you have any referral code please apply and get discount)</span></div>
                    <div className="container bg-white shadow-sm">
                        <form className="p-2" onSubmit={handleApplyReferralCode} >
                            <div className="input-group my-3">
                                <input value={referral_code} onChange={e => setReferral_code(e.target.value)} type="text" className="form-control outline-none" placeholder="Referral code" aria-describedby="referral-code-btn"/>
                                <SubmitBtn isLoading={isReferralLoading} className="btn btn-secondary btn-outline-none" title="Apply"/>
                            </div>
                            {allError['referral_code'] && <div className="alert alert-danger py-1">{allError['referral_code']}</div>}
                        </form>
                    </div>
                </section>

                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Patient Information</div>
                    <div className="container bg-white shadow-sm">
                        <div className="p-2">
                            <div className="row">
                                <div className="col-lg-6">
                                    <Input data={name} error={allError['name']} label="Name" htmlFor="name" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={dob} type="date" error={allError['dob']} label="Your Date of Birth" htmlFor="dob" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <InputSelect data={gender} error={allError['gender']} label="Gender" htmlFor="gender" required={true}
                                        elements={<>
                                            <option className="bg-light text-info fw-bold" disabled value="">Select your gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </>}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Input data={occupation} error={allError['occupation']} label="Occupation" htmlFor="occupation" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                        elements={<>
                                            <option disabled className="bg-light text-info fw-bold" value="">Select your country</option>
                                            <option value="1">Bangladesh</option>
                                        </>}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <Input data={city} error={allError['city']} label="City" htmlFor="city" required={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Disease Information</div>
                    <div className="container bg-white shadow-sm">
                        <div className="py-2 py-md-4">
                            <div className="px-md-3">

                                <TextArea
                                    data={disease_about} 
                                    label='Short about diseas'
                                    htmlFor='disease_about'
                                    autoFocus={false}
                                    error={allError['disease_about']}
                                    required={true}
                                />

                                <div className="mb-4 border rounded p-2">
                                    <h6 className="d-flex ">Select patient type <span className="text-danger">*</span> </h6>
                                    <div className="d-flex justify-content-evenly">
                                        <div onClick={e => setPatient_type('New')}
                                            role="button"
                                            className={`border p-3 rounded ${patient_type === "New" ? 'border-info text-info shadow-sm':''}`}>

                                            <div className="px-2">New Patient</div>
                                        </div>

                                        <div onClick={e => setPatient_type('Old')}
                                            role="button"
                                            className={`border p-3 rounded ${patient_type === "Old" ? 'border-primary text-primary shadow-sm':''}`}>
                                            <div className="px-2">Old Patient</div>
                                        </div>
                                    </div>
                                    {allError['patient_type'] && <div className="alert alert-danger py-1 my-2">{allError['patient_type']}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-light py-4 px-1">
                    <div className="container bg-white shadow-sm p-2 mb-1 fw-bold">Appointment Schudule <span className="small fw-normal text-info">(All times are in Bangladesh Standard Time)</span></div>
                    <div className="container bg-white shadow-sm">
                        <div className="py-2 py-md-4">
                            <form onSubmit={handleAppointmentSubmit} className="needs-validation px-md-3" noValidate>
                                <div className="row justify-content-between">
                                    <div className="col-lg">
                                        <div className="form-group mb-4">
                                            <label htmlFor="appdate">Choose appointment date<span className="text-danger">*</span></label>
                                            <DatePicker 
                                                onChange={date => setApp_date(date)}
                                                selected={app_date}
                                                minDate={to_day.setDate(to_day.getDate() + 7)}
                                                maxDate={to_day.setDate(to_day.getDate() + 60)}
                                                filterDate={date => dateFilter(date.getDay(), doctor.wdays)}
                                                className={`form-control outline-none ${0 ? 'is-invalid':''}`}
                                                placeholderText="Choose appointment date" required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <label htmlFor="apptime">Choose appointment time<span className="text-danger">*</span></label>
                                            <input onChange={e => setApp_time(e.target.value)}
                                                value={app_time}
                                                type="time" 
                                                min="10:00"
                                                max="17:00"
                                                className={`form-control outline-none ${0 ? 'is-invalid':''}`}
                                                placeholder="Choose appintment time" required/>
                                            {false && <div className="invalid-tooltip">{0}</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <SubmitBtn isLoading={isAppointmentLoading} title="Submit Appointment"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
        
            </>
        )
    }

    else return (<DoctorLoader/>)
}
