import { useState } from "react";
import FeesEdit from "./FeesEdit";

export default function Fees({nurse, updateNurse}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="py-2">
            <div className="d-flex justify-content-between bg-warning bg-opacity-10 shadow-sm rounded p-1 my-2">
                <h6 className="align-self-center m-0">Availability and fees</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div>
                {nurse.wdays?.map(wday => (
                    <fieldset key={wday.id} className="border rounded p-2">
                        <legend className="float-none w-auto small my-0 fw-bold">{wday.title} </legend>
                        <div className="row justify-content-start px-2">
                            {parseInt(wday.pivot?.one_call) === 1 && <>
                                <dt className="col-9 col-lg-4 small">One call fee</dt>
                                <dd className="col-3 col-lg-2 bg-light rounded shadow-sm py-1">{wday?.pivot?.one_call_fee} Tk</dd>
                            </>}
                            {parseInt(wday.pivot?.hour_6_morning) === 1 && <>
                                <dt className="col-9 col-lg-4 small">6 hours morning [start 06:00 AM]</dt>
                                <dd className="col-3 col-lg-2 bg-light rounded shadow-sm py-1">{wday?.pivot?.hour_6_morning_fee} Tk</dd>
                            </>}
                            {parseInt(wday.pivot?.hour_6_evening) === 1 && <>
                                <dt className="col-9 col-lg-4 small">6 hours evening [start 12:00 PM]</dt>
                                <dd className="col-3 col-lg-2 bg-light rounded shadow-sm py-1">{wday?.pivot?.hour_6_evening_fee} Tk</dd>
                            </>}
                            {parseInt(wday.pivot?.hour_12_day) === 1 && <>
                                <dt className="col-9 col-lg-4 small">12 hours day [start 06:00 AM]</dt>
                                <dd className="col-3 col-lg-2 bg-light rounded shadow-sm py-1">{wday?.pivot?.hour_12_day_fee} Tk</dd>
                            </>}
                            {parseInt(wday.pivot?.hour_12_night) === 1 && <>
                                <dt className="col-9 col-lg-4 small">12 hours night [start 06:00 PM]</dt>
                                <dd className="col-3 col-lg-2 bg-light rounded shadow-sm py-1">{wday?.pivot?.hour_12_night_fee} Tk</dd>
                            </>}
                            {parseInt(wday.pivot?.hour_24) === 1 && <>
                                <dt className="col-9 col-lg-4 small">24 hours [start 06:00 AM]</dt>
                                <dd className="col-3 col-lg-2 bg-light rounded shadow-sm py-1">{wday?.pivot?.hour_24_fee} Tk</dd>
                            </>}

                        </div>
                    </fieldset>
                ))}
            </div>}

            {isEdit && <FeesEdit nurse={nurse} updateNurse={updateNurse} cancelEdit={(value) => setIsEdit(false)}/>}

        </div>
    )
}
