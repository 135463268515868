import React from 'react';
import { Link } from 'react-router-dom';
import ItemsRequest from '../../request/ItemsRequest';
import ItemSlickSlider from '../ItemSlickSlider';
import AmbulanceSlick from './slick/AmbulanceSlick';
import DoctorSlick from './slick/DoctorSlick';
import NurseSlick from './slick/NurseSlick';

const PopularHomeItems = () => {

    const items = ItemsRequest('/api/home/popular-home-items');

    return (
        <section>
            {items?.data?.products?.length > 0 && (
                <div className="bg-light">
                    <div className="container py-3">
                        <div className="d-flex justify-content-between p-1 bg-white shadow-sm rounded">
                            <h6 className="px-2 align-self-center m-0">Popular Item</h6>
                            <Link to="/shop" className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View More</Link>
                        </div>
                        <div className="px-3 py-2">
                            <ItemSlickSlider items={items.data.products}/>
                        </div>
                    </div>
                </div>
            )}

            {items?.data?.bdDoctors?.length > 2 && (
                <div className="bg-light">
                    <div className="container py-3">
                        <div className="d-flex justify-content-between p-1 bg-white shadow-sm rounded">
                            <h6 className="px-2 align-self-center m-0">Popular bangladeshi doctor</h6>
                            <Link to="/bd-doctor-appointment" className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View More</Link>
                        </div>
                        <div className="px-3 py-2">
                            <DoctorSlick items={items.data.bdDoctors}/>
                        </div>
                    </div>
                </div>
            )}

            {items?.data?.inDoctors?.length > 2 && (
                <div className="bg-light">
                    <div className="container py-3">
                        <div className="d-flex justify-content-between p-1 bg-white shadow-sm rounded">
                            <h6 className="px-2 align-self-center m-0">Popular indian doctor</h6>
                            <Link to="/in-doctor-appointment" className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View More</Link>
                        </div>
                        <div className="px-3 py-2">
                            <DoctorSlick items={items.data.inDoctors}/>
                        </div>
                    </div>
                </div>
            )}
            
            {items?.data?.nurses?.length > 2 && (
                <div className="bg-light">
                    <div className="container py-3">
                        <div className="d-flex justify-content-between p-1 bg-white shadow-sm rounded">
                            <h6 className="px-2 align-self-center m-0">Popular nurse</h6>
                            <Link to="/nurse-appointment" className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View More</Link>
                        </div>
                        <div className="px-3 py-2">
                            <NurseSlick items={items.data.nurses}/>
                        </div>
                    </div>
                </div>
            )}
            
            {items?.data?.ambulances?.length > 2 && (
                <div className="bg-light">
                    <div className="container py-3">
                        <div className="d-flex justify-content-between p-1 bg-white shadow-sm rounded">
                            <h6 className="px-2 align-self-center m-0">Popular ambulance</h6>
                            <Link to="/ambulance-booking" className="btn btn-sm btn-master px-2 py-1 rounded outline-none">View More</Link>
                        </div>
                        <div className="px-3 py-2">
                            <AmbulanceSlick items={items.data.ambulances}/>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default PopularHomeItems