import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import useScrollPosition from "../../../hooks/useScrollPosition";
import api from "../../../services/api";
import { selectToken } from "../../../slice/userSlice";
import Fees from "./profile/Fees";
import PersonalInformation from "./profile/PersonalInformation";

export default function NurseProfile() {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const history = useHistory();

    const [nurse, setNurse] = useState([]);

    useEffect(() => {
        api(token).get('/api/nurse/profile')
        .then(response => {
            setNurse(response.data);
        })
        .catch(err => {
            if(err.response.status === 403){
                Swal.fire('Unauthorized','','warning');
                history.replace('/');
            }
            else {
                Swal.fire('Something wrong!','','error');
            }
        });
        return () => setNurse([]);
    }, [token, history])

    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-master">
                <h6 className="m-0">Nurse Profile</h6>
            </div>

            <section>
                <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                    <div>
                        <PersonalInformation nurse={nurse} updateNurse={setNurse}/>

                        <Fees nurse={nurse} updateNurse={setNurse}/>
                    </div>
                </div>
            </section>
        </div>
    )
}
