import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser, setUser } from "../../slice/userSlice";
import BtnLoader from "../loader/btn_loader/BtnLoader";
import api from "../../services/api";
import toast from "../../utlities/toast";
import DropZone from "../DropZone";
import Avatar from "../Avatar";

export default function ProfilePhoto() {
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [avatar, setAvatar] = useState('');
    const [avatarError, setaAatarError] = useState(null); 
    const [avatarIsLoaded, setAvatarIsLoaded] = useState(false);

    const [imagePath, setImagePath] = useState(null);

    // function onFileChange(e) {
    //     setAvatar(e.target.files[0]);
    //     setAvatarIsLoaded(true);
    // }
    function onFileChange(files) {
        setAvatar(files[0]);
        setAvatarIsLoaded(true);
    }


    function handleSubmitAvatar(e){
        e.preventDefault();
        setIsLoading(true);

        const data = new FormData();
        data.append('avatar', avatar);

        api(token).post('/api/auth/update-avatar', data)
        .then(response => {

            setIsLoading(false);
            setAvatarIsLoaded(false);
            setaAatarError(null);
            setAvatar('');

            toast(response.data.message, 'success');
            dispatch(setUser(response.data.user));
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors){
                error.errors.avatar ? setaAatarError(error.errors.avatar[0]) : setaAatarError(null);
            }
        });
    }

    useEffect(() => {
        if(avatarIsLoaded && avatar) setImagePath(URL.createObjectURL(avatar));
        else if(user.avatar) setImagePath(user.avatar.substring(0, 4).toLowerCase() === 'http' ? user.avatar : process.env.REACT_APP_STORAGE + user.avatar);
    }, [avatarIsLoaded, avatar, user.avatar]);


    return (
        <div className="py-3">
            <div className="p-2 border-bottom">
                <h5 className="text-muted ">Profile Photo</h5>
            </div>

            <div className="p-1 pmd-2 border-bottom">  
                <div className="row justify-content-center g-3">

                    <div className="col-11 col-md-5 text-center">
                        <Avatar avatarPath={imagePath} sizeClass='my-lg-avatar' rounded='rounded m-3 border'/>
                        {/* <img className="img-thumbnail" src={imagePath} alt="" width="150px" /> */}
    
                    </div>
                    <div className="col-11 col-md-7">
                        <form onSubmit={handleSubmitAvatar} className="needs-validation" noValidate encType="multipart/form-data">
  
                            <div className="mb-4">
                                <label className="pb-2" htmlFor="avatar">Change profile photo <span className="small text-info">(PNG/JPG/JPEG)</span></label>
                                <DropZone callbackSetFiles={onFileChange}
                                    text="Drag and drop Photo here, or click to select"
                                    fileClass=""
                                    isPreview={false}
                                    isInvalid={avatarError}
                                    errorText={avatarError}
                                />
                            </div>

                            {avatarIsLoaded && <button className={`btn btn-success outline-none ${isLoading ? 'disabled' : ''}`} type="submit">
                                <div className="d-flex justify-content-center">
                                    <div>Update</div> 
                                    {isLoading && <BtnLoader/>}
                                </div>
                            </button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
