import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import BoxEmpty from "../../components/BoxEmpty";
import SubmitBtn from "../../components/form/SubmitBtn";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import OrderItem from "../../components/OrderItem";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import useItems from "../../hooks/useItems";
import useScrollPosition from "../../hooks/useScrollPosition";
import { selectToken } from "../../slice/userSlice";
import MyCF from "../../components/MyCF";
import OrderTracker from "../../components/Tracker/OrderTracker";
import useQuery from "../../hooks/useQuery";

export default function MyOrder() {
    useScrollPosition('my-position', 150);
    let query = useQuery();

    useEffect(() => {
        if(query.get('trn_status') && query.get('trn_message')){
            setTimeout(() => {
                Swal.fire(query.get('trn_message'), '', query.get('trn_status'));
            }, 2000);
        }
    }, [query]);

    const token = useSelector(selectToken);
    const [nextPage, setNextPage] = useState(1);
    const [isLoadingPayNow, setIsLoadingPayNow] = useState(false);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    const {
        item_data, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useItems('/api/auth/order/my-orders', token, nextPage);

    function handlePayNow(e, id){
        e.preventDefault();

        setIsLoadingPayNow(true);

        Swal.fire({
            title: 'Please wait...',
            text: "Don't refresh or close the page.",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        Swal.showLoading();
        window.location.replace(process.env.REACT_APP_API_URL + '/payment/payment-submit/' + id);
    }


    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">My order</h6>
            </div>

            <div>

                {isLoading && Array.from(Array(5)).map((item,index) => (
                    <div key={index} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="shadow">
                            <ItemLoader classList="p-5" />
                        </div>
                    </div>
                ))}

                {item_data.length>0 && item_data.map(order => (
                    <div  key={order.id} className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                        <div className="d-flex justify-content-between border-bottom">
                            <div className="">
                                <h6 className="m-0">Total: <MyCF value={order.total_amount}/></h6>
                                <p className="m-0 text-muted font-xm">Method: <span className={order.payment_method_style}>{parseInt(order.payment_method) === 1 ? 'Online Payment' : 'Cash on delivery'}</span></p>
                            </div>
                            <div className="">
                                <h6 className="m-0 text-end">Order Id: {order.id}</h6>
                                <p className="m-0 text-muted font-xm">Payment status: <span className={order.payment_status_style}>{order.payment_status}</span></p>

                            </div>
                        </div>

                        {parseInt(order.payment_method) === 1 && order.payment_status === 'Pending' && 
                            <div className="text-end py-2">
                                <form onSubmit={e => handlePayNow(e, order.need_payment_crypt_id)}>
                                    <SubmitBtn isLoading={isLoadingPayNow} className="btn-master px-3 py-1" title="Pay now"/>
                                </form>
                            </div>
                        }

                        <OrderTracker status={order.order_status}/>

                        {order?.order_items?.map(item => (
                            <OrderItem key={item.id} item={item}/>
                        ))} 
                    </div>
                ))}
                
                {!isLoading && item_data.length<1 && 
                    <div className="mt-3 p-2 p-md-3 bg-white shadow-sm"><BoxEmpty/></div>
                }

                <div className="py-2">
                    <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                </div>
            </div>
        </div>
    )
}
