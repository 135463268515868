export default function Map({height}) {
    return (
        <div className="bg-white p-2 rounded shadow-sm">
            <iframe title="This is the title" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3648.603344654667!2d90.40886661498358!3d23.86821498453144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c528cbff808f%3A0xd2455a553c5f48e9!2sWOW%20COFFEE!5e0!3m2!1sen!2sbd!4v1646819137700!5m2!1sen!2sbd" width="100%" height={height} allowFullScreen="" loading="lazy"></iframe>
        </div>
    )
}

Map.defaultProps ={
    height: 450,
}