import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import SubmitBtn from '../../components/form/SubmitBtn';
import TextArea from '../../components/form/TextArea';
import Rating from '../../components/Rating';
import useFormInput from '../../hooks/useFormInput';
import useScrollPosition from '../../hooks/useScrollPosition';
import api from '../../services/api';
import { selectToken } from '../../slice/userSlice';
import toast from '../../utlities/toast';

const AddReview = () => {

    useScrollPosition('my-position', 150);
    const {state} = useLocation();

    const history = useHistory();
    const token   = useSelector(selectToken);

    const [isLoading, setIsLoading]   = useState(false);
    const [rating, setRating]         = useState(5);
    const detail                      = useFormInput('');
    const [allError, setAllError]     = useState([]);

    const handleReviewSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const body = {
            rating    : rating,
            detail    : detail.value,
            service   : 'AmbulanceBooking',
        }
        api(token).post(state.api_url, body)
        .then(response => {
            setIsLoading(false);
            if(response.data.status === 'success'){
                Swal.fire(response.data.message,'','success');
                history.push(state.redirect_url ?? '/');
            }
            else Swal.fire(response.data.message,'',response.data.status);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <section className="bg-light py-4 px-1" id="my-position">
            <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Write a review</div>
            <div className="container bg-white shadow-sm">
                <form onSubmit={handleReviewSubmit} className="p-2 p-md-3">
                    <div className="row row-cols-lg-2">
                        <div className="mb-4">
                            <Rating
                                initialRating={rating} 
                                finalRating={(ret_num) => setRating(ret_num)}
                            />
                        </div>
                        <div>
                            <h6 className="bg-light small p-2 m-0 rounded border">Review detail</h6>
                            <TextArea
                                data={detail} 
                                label='Review detail'
                                htmlFor='detail'
                                autoFocus={false}
                                error={allError['detail']}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <SubmitBtn isLoading={isLoading} className="btn-success" title="Submit"/>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default AddReview