import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/form/Input";
import InputSelect from "../../../../components/form/InputSelect";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function PersonalInformationEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const title                  = useFormInput(doctor.title);
    const gender                 = useFormInput(doctor.gender ?? '');
    const country_id             = useFormInput(doctor.country_id ?? '');
    const district_id            = useFormInput(doctor.district_id ?? '');
    const sub_district_id        = useFormInput(doctor.sub_district_id ?? '');
    const state_id               = useFormInput(doctor.state_id ?? '');
    const location               = useFormInput(doctor.location ?? '');
    const registration_no        = useFormInput(doctor.registration_no ?? '');
    const registered_institution = useFormInput(doctor.registered_institution ?? '');
    const language               = useFormInput(doctor.language ?? '');

    const [countryLists, setCountryLists]   = useState([]);

    useEffect(() => {
        api().get('/api/load-country-with-other-child-list')
        .then(response => {
            setCountryLists(response.data.country_lists);
        })
        .catch(err => { });
        return () => {setCountryLists([]);};
    }, []);

    const [withState, setWithState] = useState(false);
    
    useEffect(() => {
        if(country_id.value && countryLists.find(country => country.id === parseInt(country_id.value)) && parseInt(countryLists.find(country => country.id === parseInt(country_id.value)).is_state) === 1){
            setWithState(true);
        }
        else setWithState(false);
    }, [country_id, countryLists]);


    function handlePersonalInformationSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            title                  : title.value,
            gender                 : gender.value,
            country_id             : country_id.value,
            district_id            : district_id.value,
            sub_district_id        : sub_district_id.value,
            state_id               : state_id.value,
            location               : location.value,
            registration_no        : registration_no.value,
            registered_institution : registered_institution.value,
            language               : language.value,
        };

        api(token).post('/api/doctor/update/personal-information', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor){
                updateDoctor(response.data.doctor);
            }
            toast('Personal information successfully updated', 'success');
            setAllError([]);
            cancelEdit(false);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handlePersonalInformationSubmit}>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <InputSelect data={title} error={allError['title']} label="Title" htmlFor="title" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Title</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="Prof. Dr.">Prof. Dr.</option>
                                    <option value="Assoc. Prof. Dr.">Assoc. Prof. Dr.</option>
                                    <option value="Asst. Prof. Dr.">Asst. Prof. Dr.</option>
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={gender} error={allError['gender']} label="Gender" htmlFor="gender" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" disabled value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </>}
                            />
                        </div>

                        <div className="col-lg-6">
                            <InputSelect data={country_id} error={allError['country_id']} label="Country" htmlFor="country_id" required={true}
                                elements={<>
                                    <option disabled className="bg-light text-info fw-bold" value="">Select country</option>
                                    {countryLists.map(countryList => (
                                        <option key={countryList.id} value={countryList.id}>{countryList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>

                        {!withState && <div className="col-lg-6">
                            <InputSelect data={district_id} error={allError['district_id']} label="District" htmlFor="district_id" required={true}
                                elements={<>
                                    <option disabled className="bg-light text-info fw-bold" value="">Select District</option>
                                    {countryLists.find(country => country.id === parseInt(country_id.value))?.districts?.map(districtList => (
                                        <option key={districtList.id} value={districtList.id}>{districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>}

                        {!withState && <div className="col-lg-6">
                            <InputSelect data={sub_district_id} error={allError['sub_district_id']} label="Sub District" htmlFor="sub_district_id" required={true}
                                elements={<>
                                    <option className="bg-light text-info fw-bold" value="">Select Sub district</option>
                                    {countryLists.find(country => country.id === parseInt(country_id.value))?.districts?.find(district => district.id === parseInt(district_id.value))?.sub_districts?.map(sub_districtList => (
                                        <option key={sub_districtList.id} value={sub_districtList.id}>{sub_districtList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>}

                        {withState && <div className="col-lg-6">
                            <InputSelect data={state_id} error={allError['state_id']} label="State" htmlFor="state_id" required={true}
                                elements={<>
                                    <option disabled className="bg-light text-info fw-bold" value="">Select State</option>
                                    {countryLists.find(country => country.id === parseInt(country_id.value))?.states?.map(stateList => (
                                        <option key={stateList.id} value={stateList.id}>{stateList.name}</option>
                                    ))}
                                </>}
                            />
                        </div>}

                        <div className="col-lg-6">
                            <Input data={location} error={allError['location']} label="Location" htmlFor="location" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={registration_no} error={allError['registration_no']} label="Registration Number" htmlFor="registration_no" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={registered_institution} error={allError['registered_institution']} label="Registered Institution Name" htmlFor="registered_institution" required={true}/>
                        </div>

                        {withState && (
                            <div className="col-lg-6">
                                <Input data={language} error={allError['language']} label="Language" htmlFor="language" required={true}/>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}