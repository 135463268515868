import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Input from "../../components/form/Input";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import useFormInput from "../../hooks/useFormInput";
import useScrollTop from "../../hooks/useScrollTop";
import api from "../../services/api";
import { selectToken } from "../../slice/userSlice";
import toast from "../../utlities/toast";

export default function JoinAsDoctorStep3() {
    useScrollTop();
    const token   = useSelector(selectToken);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const wdays =[
        {id: 1, short_title: 'Sun'},
        {id: 2, short_title: 'Mon'},
        {id: 3, short_title: 'Tue'},
        {id: 4, short_title: 'Wed'},
        {id: 5, short_title: 'Thu'},
        {id: 6, short_title: 'Fri'},
        {id: 7, short_title: 'Sat'},
    ];

    const [availabe_days, setAvailabe_days] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
    });
    const time_from = useFormInput('');
    const time_to   = useFormInput('');
    const [allError, setAllError] = useState([]);
    
    function handleStep3Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            availabe_days : availabe_days,
            time_from     : time_from.value,
            time_to       : time_to.value,
        };

        api(token).post('/api/doctor/join/step-3', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            
            if(response.data.icon === 'success'){
                history.replace('/join/doctor/step-4');
            }
            else if(response.data.icon === 'warning'){
                history.replace('/join/doctor/step-1');
                Swal.fire('Please flow step by step','','warning');
            }
            else if(response.data.icon === 'error'){
                Swal.fire('Something wrong','Please try again','error');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Doctor',
                    text: 'If you want to update your Doctor profile, Then click Edit Doctor Profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Doctor Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/doctor');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;

            setIsLoading(false);
            toast(error.message, 'error')
            
            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }


    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Doctor  [Step 3 of 4]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Availabe Days</h6>
                    <div className="row justify-content-center g-2 g-md-3">
                        {wdays.map((wday, index) => (
                            <div key={wday.id} className="col-3 col-md-2 col-lg-1">
                                <div className="checkbox-input-group">
                                    <input
                                        checked={availabe_days[index+1] === true}
                                        onChange={() => setAvailabe_days({ ...availabe_days, [index+1]: !availabe_days[index+1] })}
                                        value={availabe_days[index+1] === true}
                                        type="checkbox"
                                        id={wday.short_title}
                                    />
                                    <div className="bg-light shadow-sm rounded text-center fw-bold  p-2">
                                        <span>{wday.short_title}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {allError['availabe_days'] && <div className="alert alert-danger my-2 py-1 text-center">{allError['availabe_days']}</div>}
                </div>

                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Available Time <span className="small fw-normal text-info">(All times are in Bangladesh Standard Time)</span></h6>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Input data={time_from} error={allError['time_from']} label="From" htmlFor="time_from" type="time" required={true}/>
                        </div>
                        
                        <div className="col-lg-6">
                            <Input data={time_to} error={allError['time_to']} label="To" htmlFor="time_to" type="time" required={true}/>
                        </div>
                    </div>
                </div>
                
                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep3Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            
            </div>
        </section>
    )
}
