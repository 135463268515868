export default function TextArea({
    data, 
    label='', 
    htmlFor='', 
    labelIsTop=false, 
    cols='30', 
    rows='10', 
    autoFocus=false, 
    required=false,
    error,
    groupClass='form-floating mb-4 mt-1',
    controlClass='form-control outline-none textarea-h-10',
    labelClass='',
    errorClass='invalid-tooltip',
}) {

    return (
        <div className={groupClass}>
            {labelIsTop === true && <label className={labelClass} htmlFor={htmlFor}>{label} {required === true && <span className="text-danger">*</span>}</label>}
            <textarea {...data}
                className={`${controlClass} ${error ? 'is-invalid':''}`}
                placeholder={label}
                cols={cols} 
                rows={rows}
                autoFocus={autoFocus}
                required={required}
            ></textarea>
            {labelIsTop === false && <label className={labelClass} htmlFor={htmlFor}>{label} {required === true && <span className="text-danger">*</span>}</label>}
            {error && <div className={errorClass}>{error}</div> }
        </div>
    )
}
