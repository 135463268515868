import PrescriptionUpload from "../../components/PrescriptionUpload";
import useScrollPosition from "../../hooks/useScrollPosition";

export default function UploadPrescription() {
    useScrollPosition('my-position', 150);

    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">Upload prescription</h6>
            </div>
            
            <PrescriptionUpload/>
            
        </div>
    )
}
