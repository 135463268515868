import { useState } from "react";

export default function Rating({initialRating, finalRating}) {
    const [rating, setRating]         = useState(initialRating);
    const [ratingText, setRatingText] = useState('Delightful');

    function handleHoverRating(e, ret_num, ret_text){
        e.preventDefault();
        setRating(ret_num);
        setRatingText(ret_text);
        finalRating(ret_num);
    }


    return (
        <>
            <h6 className="bg-light small p-2 m-0 rounded border">Rating</h6>
            <div className="mt-2">
                <div className="d-flex justify-content-center gap-2">
                    <span role="button" onClick={e => handleHoverRating(e, 1, 'Very Poor')} className={`${rating >=1 ? 'text-warning':'text-secondary'}`}><i className="bi bi-star-fill icon-huge"></i></span>
                    <span role="button" onClick={e => handleHoverRating(e, 2, 'Poor')} className={`${rating >=2 ? 'text-warning':'text-secondary'}`}><i className="bi bi-star-fill icon-huge"></i></span>
                    <span role="button" onClick={e => handleHoverRating(e, 3, 'Neutral')} className={`${rating >=3 ? 'text-warning':'text-secondary'}`}><i className="bi bi-star-fill icon-huge"></i></span>
                    <span role="button" onClick={e => handleHoverRating(e, 4, 'Satisfactory')} className={`${rating >=4 ? 'text-warning':'text-secondary'}`}><i className="bi bi-star-fill icon-huge"></i></span>
                    <span role="button" onClick={e => handleHoverRating(e, 5, 'Delightful')} className={`${rating >=5 ? 'text-warning':'text-secondary'}`}><i className="bi bi-star-fill icon-huge"></i></span>
                </div>
                <p className="m-0 fw-light text-center">{ratingText}</p>
            </div>
        </>
    )
}
