import Avatar from "./Avatar";

export default function UserList({user}) {
    return (
        <>
            <div className="d-flex justify-content-start border-end">
                <div className="py-2">
                    <Avatar avatarPath={process.env.REACT_APP_STORAGE + user.avatar} sizeClass='my-lg-avatar' rounded='rounded'/>
                </div>
                <div className="p-2 w-100">
                    <h6 className="fw-bold text-dark">{user.name}</h6>
                    <div className="small">
                        <p className="text-dark fw-light mb-0">Phone: {user.phone}</p>
                        <p className="text-dark fw-light mb-0">Email: {user.email}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
