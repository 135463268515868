import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectToken, selectUser, setUser } from "../../slice/userSlice";
import api from "../../services/api";
import useFormInput from "../../hooks/useFormInput";
import toast from "../../utlities/toast";
import BtnLoader from "../loader/btn_loader/BtnLoader";

export default function Mobile() {
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const phone       = useFormInput(user.phone ?? '');
    const [phoneError, setPhoneError] = useState(null);
    const [isEdit, setIsEdit] = useState(false);


    function handleSubmitMobile(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            phone: phone.value,
        };

        api(token).post('/api/auth/set-phone', body)
        .then(response => {
            setIsLoading(false);
            setIsEdit(false);
            setPhoneError(null);
            toast(response.data.message, response.data.icon);

            if(response.data.icon === 'success'){

                dispatch(setUser(response.data.user));
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);

            toast(error.message, 'error')
            if(error.errors){
                error.errors.phone ? setPhoneError(error.errors.phone[0]) : setPhoneError(null);
            }
        });
        
    }
    return (
        <div className="pt-4">
            <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                <h5 className="text-muted ">Mobile Number</h5>
                <Link to="#" onClick={() => setIsEdit(true)} className="btn btn-sm btn-outline-success outline-none">Set Mobile Number</Link>
            </div>

            <div className="p-4 border-bottom">  
                {isEdit && 
                    <form onSubmit={handleSubmitMobile} className="needs-validation" noValidate>
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <div className="form-floating mb-4">
                                    <input {...phone}
                                        type="tel"
                                        className={`form-control ${phoneError ? 'is-invalid':''}`}
                                        placeholder="mobile no"/>
                                    <label htmlFor="email">Mobile<span className="text-danger">*</span></label>
                                    {phoneError && <div className="invalid-tooltip">{phoneError}</div> }
                                </div>
                            </div>
                        </div>
                    
                        <div className="d-flex justify-content-between">
                            <button className={`btn btn-success outline-none ${isLoading ? 'disabled' : ''}`} type="submit">
                                <div className="d-flex justify-content-center">
                                    <div>Save </div> 
                                    {isLoading && <BtnLoader/>}
                                </div>
                            </button>
                            <button  onClick={() => setIsEdit(false)} type="reset" className={`btn btn-outline-dark outline-none ${isLoading ? 'disabled' : ''}`}>Cancle</button>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}
