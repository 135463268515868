import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from "../assets/img/logo.png";
import { selectCart } from '../slice/cartSlice';
import { changeLang, selectLang } from '../slice/langSlice';
import { selectToken, selectUser } from '../slice/userSlice';
import LogoutBtn from './LogoutBtn';

export default function Nav() {
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const cart_content = useSelector(selectCart);

    const [expend, setExpend] = useState(false);

    const handleChangeLang = (e, language) => {
        e.preventDefault();
        dispatch(changeLang(language));
    }


    return (
        <>
            <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-white shadow-sm p-0">
                <div className="container-fluid px-lg-3">
                    <Link className="navbar-brand d-lg-none" to="/">
                        <img src={Logo} alt="" width="95px"/>
                    </Link>
                    <button onClick={() => setExpend(!expend)} className="navbar-toggler p-0 border-0 outline-none" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`navbar-collapse offcanvas-collapse justify-content-between ${expend ? 'open': ''}`} id="navbarsExampleDefault">
                        <Link className="navbar-brand d-none d-lg-block" to="/">
                            <img src={Logo} alt="" width="125px"/>
                        </Link>
                        

                        <ul className="navbar-nav mb-2 mb-lg-0 nav-active">
                            <li className="nav-item">
                                <NavLink onClick={() => setExpend(false)} className="nav-link" exact to="/">{t("Home")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={() => setExpend(false)} className="nav-link" exact to="/shop">{t("Medicine Order")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={() => setExpend(false)} className="nav-link" exact to="/services">{t("Services")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={() => setExpend(false)} className="nav-link" exact to="/about">{t("About")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={() => setExpend(false)} className="nav-link" exact to="/contact">{t("Contact")}</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="joinDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{t("Join")}</a>
                                <ul className="dropdown-menu dropdown-menu-end p-2 mt-2 border-0 border-top border-master border-2 shadow rounded-0" aria-labelledby="joinDropdown">
                                    <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/join/doctor/step-1"><i className="bi bi-bag-plus"></i> {t("Join as a Doctor")}</Link></li>
                                    <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/join/nurse/step-1"><i className="bi bi-bookmark-heart"></i> {t("Join as a Nurse")}</Link></li>
                                    <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/join/ambulance/step-1"><i className="bi bi-truck"></i> {t("Join as a Ambulance")}</Link></li>
                                </ul>
                            </li>
                            {user?.roles?.map(role => (
                                <li key={role.id} className="nav-item">
                                    <NavLink onClick={() => setExpend(false)} className="nav-link" to={`/sp/${role.name.toLowerCase()}`}>{t(role.name)} <span className="d-inline-block d-lg-none d-xl-inline-block">{t("Profile")}</span></NavLink>
                                </li>
                            ))}

                            {token && (
                                <li className="nav-item">
                                    <NavLink onClick={() => setExpend(false)} className="nav-link" exact to="/referral">{t("Referral")}</NavLink>
                                </li>
                            )}
                        </ul>
                        
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <div className="btn-group nav-link px-0" role="group">
                                    <button onClick={(e) =>handleChangeLang(e, 'en')} type="button" className={`btn btn-sm btn-${lang === 'en' ? 'success' : 'outline-success'} outline-none`}>En</button>
                                    <button onClick={(e) =>handleChangeLang(e, 'bn')} type="button" className={`btn btn-sm btn-${lang === 'bn' ? 'success' : 'outline-success'} outline-none`}>বাং</button>
                                </div>
                            </li>
                            <li className="nav-item px-2 d-none d-lg-block">
                                <Link to="/cart/show" onClick={() => setExpend(false)} className="nav-link position-relative">
                                    <i className="bi bi-cart4 bi-m icon-xlg"></i>
                                    <span className="position-absolute top-25 translate-middle badge rounded-pill bg-success">{cart_content.length}</span>
                                </Link>
                            </li>
                            {user && token ? 
                                <li className="nav-item dropdown">
                                    <button className="nav-link rounded bg-master text-white dropdown-toggle border px-4" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        {user.avatar ? <img src={user.avatar.substring(0, 4).toLowerCase() === 'http' ? user.avatar : process.env.REACT_APP_STORAGE + user.avatar} alt="" className="rounded-circle img-profile" /> : <i className="bi bi-person-circle mx-2"></i>}
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end p-2 mt-2 border-0 border-top border-master border-2 shadow rounded-0 mb-5" aria-labelledby="navbarDropdown">
                                        <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/profile"><i className="bi bi-emoji-smile"></i> {t("Profile")}</Link></li>
                                        <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/profile/my-order"><i className="bi bi-box-seam"></i> {t("My Order")}</Link></li>
                                        <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/profile/my-wishlist"><i className="bi bi-heart"></i> {t("My Wishlist")}</Link></li>
                                        <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/profile/upload-prescription"><i className="bi bi-cloud-plus"></i> {t("Upload Prescription")}</Link></li>
                                        <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/profile/my-shipping-address"><i className="bi bi-pin-map"></i> {t("Shipping Address")}</Link></li>
                                        <li><Link  onClick={() => setExpend(false)} className="dropdown-item" to="/profile/change-password"><i className="bi bi-key"></i> {t("Change Password")}</Link></li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li><LogoutBtn classList="dropdown-item"/></li>
                                    </ul>
                                </li>
                            :
                                <li className="nav-item align-self-center">
                                    <Link onClick={() => setExpend(false)} className="nav-link rounded btn-master px-3" to="/login"><i className="bi bi-person-circle mx-2"></i> Login</Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            
            <nav className="fixed-bottom d-lg-none">
                <div className="d-flex justify-content-around bg-white shadow p-2">
                    <div>
                        <button
                            onClick={() => setExpend(!expend)}
                            className="navbar-toggler outline-none" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                            <i className="bi bi-list text-secondary icon-xlg"></i>
                        </button>
                    </div>
                    <div className="mobile-nav">
                        <NavLink onClick={() => setExpend(false)} exact to="/" className="text-secondary"><i className="bi bi-house-door icon-xlg"></i></NavLink>
                    </div>
                    <div className="mobile-nav">
                        <NavLink onClick={() => setExpend(false)} exact to="/shop" className="text-secondary"><i className="bi bi-shop icon-xlg"></i> </NavLink>
                    </div>
                    <div className="mobile-nav">
                        <NavLink onClick={() => setExpend(false)} exact to="/cart/show" className="text-secondary"><i className="bi bi-cart4 icon-xlg"></i><span className="badge rounded-pill bg-master">{cart_content.length}</span></NavLink>
                    </div>
                    <div className="mobile-nav">
                        <NavLink onClick={() => setExpend(false)} exact to={user && token ? '/profile' : '/login'} className="text-secondary"><i className="bi bi-person-circle icon-xlg"></i></NavLink>
                    </div>
                </div>
            </nav>
        </>
    )
}
