export default function AmbulanceListDetails({ambulance}) {
    return (
        <div className="col-12 bg-white border-top">
            <div className="row text-secondary">
                <div className="p-2 col-6 col-sm-4 col-lg-2">
                    <div className="bg-light rounded-3 p-2">
                        <p className="mb-1">Ambulance Code</p>
                        <h6 className="text-dark fw-bold">{ambulance.ambulance_code}</h6>
                    </div>
                </div>
                <div className="p-2 col-6 col-sm-4 col-lg-2">
                    <div className="bg-light rounded-3 p-2">
                        <p className="mb-1">District</p>
                        <h6 className="text-dark fw-bold">{ambulance.district?.name}</h6>
                    </div>
                </div>
                <div className="p-2 col-12 col-sm-4 col-lg-2">
                    <div className="bg-light rounded-3 p-2">
                        <p className="mb-1">Sub district</p>
                        <h6 className="text-dark fw-bold">{ambulance.sub_district?.name}</h6>
                    </div>
                </div>
                <div className="p-2 col-12 col-lg-6">
                    <div className="bg-light rounded-3 p-2">
                        <p className="mb-1">Location</p>
                        <h6 className="text-dark fw-bold">{ambulance.location}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}
