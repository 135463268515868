import ItemLoader from "./item_loder/ItemLoader";

export default function DoctorLoader() {
    return (
        <section className="bg-light py-4 px-1">
            <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Doctor Profile</div>
            <div className="container">
                <div className="bg-white">
                    <div className="row">
                        <div className="p-2 bg-white">
                            <ItemLoader classList="p-5" />
                        </div>
                        <div className="p-2 bg-white">
                            <ItemLoader classList="p-3" />
                        </div>
                        
                        <div className="p-2 bg-white">
                            <ItemLoader classList="p-4" />
                        </div>
                        <div className="py-4 bg-light"></div>
                        <div className="p-2 bg-white">
                            <ItemLoader classList="p-5" />
                        </div>
                        <div className="p-2 bg-white">
                            <ItemLoader classList="p-5" />
                        </div>
                        <div className="p-2 bg-white">
                            <ItemLoader classList="p-5" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
