import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login, selectToken } from "../../slice/userSlice";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import useScrollPosition from "../../hooks/useScrollPosition";
import api from "../../services/api";
import useFormInput from "../../hooks/useFormInput";
import toast from "../../utlities/toast";

export default function ChangePassword() {
    useScrollPosition('my-position', 150);

    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const oldPassword           = useFormInput('');
    const password              = useFormInput('');
    const password_confirmation = useFormInput('');

    const [oldpasswordError, setOldPasswordError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    function handleSubmitChangePassword(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            old_password          : oldPassword.value,
            password             : password.value,
            password_confirmation: password_confirmation.value,
        }

        api(token).put('/api/auth/change-password', body)
        .then(response => {
            setIsLoading(false);
            setOldPasswordError(null);
            setPasswordError(null);

            toast(response.data.message, 'success');
            dispatch(login(response.data.token));
            history.push('/profile');
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);

            toast(error.message, 'error')
            if(error.errors){
                error.errors.password     ? setPasswordError(error.errors.password[0])        : setPasswordError(null);
                error.errors.old_password ? setOldPasswordError(error.errors.old_password[0]) : setOldPasswordError(null);
            }
            else {
                setPasswordError(null);
                setOldPasswordError(null);
            }
        });
        
    }


    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-success">
                <h6 className="m-0">Change password</h6>
            </div>

            <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                <div className="p-4 border-bottom">  
                    <form onSubmit={handleSubmitChangePassword} className="needs-validation" noValidate>
   
                        <div className="form-floating mb-4">
                            <input {...oldPassword}
                                type="password" 
                                className={`form-control ${oldpasswordError ? 'is-invalid':''}`}
                                placeholder="Old Password"/>
                            <label htmlFor="oldPassword">Old password<span className="text-danger">*</span></label>
                            {oldpasswordError && <div className="invalid-tooltip">{oldpasswordError}</div> }
                        </div>

                        <div className="form-floating mb-4">
                            <input {...password}
                                type="password" 
                                className={`form-control ${passwordError ? 'is-invalid':''}`}
                                placeholder="New password"/>
                            <label htmlFor="password">New password<span className="text-danger">*</span></label>
                            {passwordError && <div className="invalid-tooltip">{passwordError}</div> }
                        </div>

                        <div className="form-floating mb-4">
                            <input {...password_confirmation}
                                type="password" 
                                className={`form-control ${passwordError ? 'is-invalid':''}`}
                                placeholder="Confirm Password"/>
                            <label htmlFor="password_confirmation">Confirm new password<span className="text-danger">*</span></label>
                        </div>

                        <button className={`btn btn-success btn-outline-0 ${isLoading ? 'disabled' : ''}`} type="submit">
                            <div className="d-flex justify-content-center">
                                <div>Save </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
