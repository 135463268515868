import moment from 'moment';
import { useState } from 'react';
import AvailabilityEdit from './AvailabilityEdit';

export default function Availability({doctor, updateDoctor}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="py-2">
            <div className="d-flex justify-content-between bg-info bg-opacity-10 shadow-sm rounded p-1 my-2">
                <h6 className="align-self-center m-0">Availability</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div className="row justify-content-start px-2">
                <dt className="col-5 col-lg-4">Days </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">
                    {doctor.wdays && doctor.wdays.map(wday => (
                        <span key={wday.id} className="bg-white m-1 px-1 rounded-1">{wday.short_title}</span>
                    ))}
                </dd>
                
                <dt className="col-5 col-lg-4">Time </dt>
                <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">
                    <span className="bg-light p-2">From {moment(doctor.time_from, 'hh:mm:ss').format('hh: mm A')}</span>
                    <span className="bg-light p-2">To {moment(doctor.time_to, 'hh:mm:ss').format('hh: mm A')}</span>
                </dd>
            </div>}

            {isEdit && <AvailabilityEdit doctor={doctor} updateDoctor={updateDoctor} cancelEdit={(value) => setIsEdit(false)}/>}
        </div>
    )
}
