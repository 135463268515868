import { Link } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop"
import { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import api from "../../services/api";
import MostSimplePaginator from "../../components/pagination/MostSimplePaginator";
import ItemLoader from "../../components/loader/item_loder/ItemLoader";
import BoxEmpty from "../../components/BoxEmpty";
import changeScrollPosition from "../../services/changeScrollPosition";
import AmbulanceList from "../../components/AmbulanceList";
import useAmbulances from "../../hooks/useAmbulances";
import RadioClassic from "../../components/form/RadioClassic";
import { debounce } from "lodash";
import CommonSlider from "../../components/sliders/CommonSlider";

export default function AmbulanceBooking() {
    useScrollTop();

    const searchRef = useRef();
    const [nextPage, setNextPage] = useState(1);

    const [expendFilter, setExpendFilter] = useState(true);

    const [districtWithSubDistrictOptions, setDistrictWithSubDistrictOptions] = useState([]);

    const [districtOrSubDistrict, setDistrictOrSubDistrict] = useState([]);
    const [search, setSearch]                               = useState('');
    const [order_by, setOrder_by]                           = useState('');
    const [condition, setCondition]                         = useState('');

    const handleSetSearch = debounce((search_text) => {
        setSearch(search_text);
    }, 700);

    function handleSetNextPage(page) { 
        setNextPage(page);
    }

    useEffect(() => {
        changeScrollPosition('my-position', 150);
        return () => {};
    }, [districtOrSubDistrict, condition, order_by]);

    useEffect(() => {
        setNextPage(1);
        return () => {setNextPage(1)};
    }, [search, districtOrSubDistrict, condition, order_by]);

    function handleResetFilter(e){
        e.preventDefault();
        setDistrictOrSubDistrict([]);
        setSearch('');
        searchRef.current.value = '';
        setCondition('');
        setOrder_by('');
        setNextPage(1);
    }

    useEffect(() => {
        api().get('/api/load-district-with-sub-district')
        .then(response => {
            response.data.district_lists.forEach(item => {
                setDistrictWithSubDistrictOptions(districtWithSubDistrictOptions => [...districtWithSubDistrictOptions, {value: item.id, district: 'district', label: `${item.name} District`}]);
                item.sub_districts.forEach(sub_item => {
                    setDistrictWithSubDistrictOptions(districtWithSubDistrictOptions => [...districtWithSubDistrictOptions, {value: sub_item.id, district: 'sub', label: `${sub_item.name}`}]);
                });
            });
        })
        .catch(err => {});
        return () => {
            setDistrictWithSubDistrictOptions([]);
        }
    }, []);


    const {
        ambulances, 
        items, 
        isLoading, 
        paginatorIsLoading,
        // error,
    } = useAmbulances('/api/ambulance-booking', search, districtOrSubDistrict, condition, order_by, nextPage);


    return (
        <section className="bg-light">
            <div className="container-fluid py-4">
                <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-3 mb-3" id="sticky-sidebar">
                        <div className="bg-white shadow-sm sticky-top">
                            <div className="category-hero">
                                <button onClick={() => setExpendFilter(!expendFilter)} className="btn-master px-3 py-2 w-100 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                                    <div className="d-flex justify-content-between">
                                        <span><i className="bi bi-list"></i></span>
                                        <span>Filters</span>
                                        {expendFilter ? <span><i className="bi bi-chevron-down"></i></span>:<span><i className="bi bi-chevron-up"></i></span>}
                                    </div>
                                </button>
                                <div className="collapse show" id="collapseExample">
                                    <div className="card card-body border-0 shadow-sm rounded-0 small py-1">

                                        <div className="form-group bg-light px-1 py-1">
                                            <label className="w-100 px-1 fw-bold" htmlFor=""><i className="bi bi-pin-map"></i> District or sub district</label>
                                            <Select
                                                onChange={setDistrictOrSubDistrict}
                                                value={districtOrSubDistrict}
                                                options={districtWithSubDistrictOptions}
                                                placeholder="Search..."
                                            />
                                        </div>

                                        <div className="bg-light px-1 py-1 mt-2">
                                            <div className="w-100 px-1 fw-bold"><i className="bi bi-truck"></i> Condition</div>
                                            <div className="border-top p-1">
                                                <RadioClassic data={condition} setOnChange={setCondition} htmlFor="ac" label="AC" value="AC" name="conditionRadios" />
                                                <RadioClassic data={condition} setOnChange={setCondition} htmlFor="non_ac" label="Non AC" value="Non AC" name="conditionRadios" />
                                            </div>
                                        </div>

                                        <div className="bg-light px-1 py-1 mt-2">
                                            <div className="w-100 px-1 fw-bold"><i className="bi bi-sort-down"></i> Sort By</div>
                                            <div className="border-top p-1">
                                                <RadioClassic data={order_by} setOnChange={setOrder_by} htmlFor="rating" label="Rating" value="rating" name="orderRadios" />
                                            </div>
                                        </div>

                                        <div className="d-grid bg-light px-1 py-1 mt-2">
                                            <button onClick={handleResetFilter} type="button"  className="btn btn-sm btn-warning text-white fw-bold btn-outline-none">Reset Filter</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9" id="my-position">
                        <div className="bg-white shadow-sm">
                            <div className="form-group d-flex p-2 p-md-3 bg-light">
                                <div className="input-group">
                                    <input onChange={e => handleSetSearch(e.target.value)} ref={searchRef} className="form-control form-control-lg outline-none" type="search"  placeholder="Search..."/>
                                    <button className="btn btn-success btn-outline-none"><i className="bi bi-search"></i></button>
                                </div>
                            </div>

                            <CommonSlider url={'/api/sliders/ambulance-slider'}/>
                            <div className="p-2 bg-light"></div>

                            <div className="flex gx-2">
                                {isLoading && Array.from(Array(5)).map((item,index) => (
                                    <div key={index} className="p-3">
                                        <div className="shadow">
                                            <ItemLoader classList="p-5" />
                                        </div>
                                    </div>
                                ))}
                                {ambulances.length>0 && ambulances.map(ambulance => (
                                    <div key={ambulance.id} className="p-3">
                                        <Link to={`/ambulance-booking/ambulance/${ambulance.id}`} className="text-decoration-none">
                                            <div className="row justify-content-center shadow">
                                                <AmbulanceList
                                                    ambulance={ambulance}
                                                    next_arrow={true}
                                                    locationCharLimit={100}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                                {!isLoading && ambulances.length<1 && <BoxEmpty/>}
                            </div>

                            <div className="py-4">
                                <MostSimplePaginator data={items} load_paginator={paginatorIsLoading} parentCallback={handleSetNextPage}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    )
}
