export default function RadioClassic({
    data,
    setOnChange,
    htmlFor='',
    label='',
    value='',
    name='',
    
}) {
    return (
        <div className="form-check">
            <input onChange={e => setOnChange(e.target.value)} checked={data === value} className="form-check-input outline-none" type="radio" name={name} id={htmlFor} value={value}/>
            <label className="form-check-label" htmlFor={htmlFor}>{label}</label>
        </div>
    )
}
