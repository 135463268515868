

export default function TopFilter({items, handleParentOrderByFilter}) {

    function handleOrderBy(e, sort_by){
        e.preventDefault();
        handleParentOrderByFilter(sort_by)
    }

    return (
        <div className="py-2">
            <div className="p-2 bg-white shadow-sm border-3 border-top border-master">
                <div className="d-flex justify-content-between">
                    <div className="align-self-center">
                        {/* <h6>Total Result {items.total} Showing 0 to {items.to ?? '0'}</h6> */}
                        <h6>Showing 0 to {items.to ?? '0'}</h6>
                    </div>
                    <div className="align-self-center">
                        <div className="dropdown">
                            <button className="btn btn-white fw-bold outline-none border dropdown-toggle px-2 px-md-5" type="button" id="top-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                SORT BY
                            </button>
                            <ul className="dropdown-menu border-0 rounded-0 p-2 shadow" aria-labelledby="top-filter-dropdown">
                                <li><button onClick={(e) => handleOrderBy(e, "title_asc")} className="dropdown-item small">Title: A to Z</button></li>
                                <li><button onClick={(e) => handleOrderBy(e, "title_desc")} className="dropdown-item small">Title: Z to A</button></li>
                                <li><button onClick={(e) => handleOrderBy(e, "price_asc")} className="dropdown-item small">Price: low to high</button></li>
                                <li><button onClick={(e) => handleOrderBy(e, "price_desc")} className="dropdown-item small">Price: high to low</button></li>
                                {/* <li><button onClick={(e) => handleOrderBy(e, "")} className="dropdown-item small fw-bold text-dark bg-main">Reset Filter</button></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
