import { useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/form/Input";
import InputSwitch from "../../../../components/form/InputSwitch";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function CurrentWorkingPlaceEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const hospital_name     = useFormInput(doctor.hospital_name ?? '');
    const designation       = useFormInput(doctor.designation ?? '');
    const department        = useFormInput(doctor.department ?? '');
    const [memorable_instrutation, setMemorable_instrutation] = useState(doctor.memorable_instrutation_name ? true : false);
    const memorable_instrutation_name = useFormInput(doctor.memorable_instrutation_name ?? '')

    function handleCurrentWorkingPlaceSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            hospital_name              : hospital_name.value,
            department                 : department.value,
            designation                : designation.value,
            memorable_instrutation     : memorable_instrutation,
            memorable_instrutation_name: memorable_instrutation_name.value,
        };

        api(token).post('/api/doctor/update/current-working-place', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor){
                updateDoctor(response.data.doctor);
            }
            toast('Current working place successfully updated', 'success');
            setAllError([]);
            cancelEdit(false);
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleCurrentWorkingPlaceSubmit}>
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <Input data={hospital_name} error={allError['hospital_name']} label="Institution/Hospital Name" htmlFor="hospital_name" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={department} error={allError['department']} label="Department" htmlFor="department" required={true}/>
                        </div>

                        <div className="col-lg-6">
                            <Input data={designation} error={allError['designation']} label="Designation" htmlFor="designation" required={true}/>
                        </div>

                        <div className="col-lg-12">
                            <InputSwitch
                                data={memorable_instrutation} 
                                onClick={value =>setMemorable_instrutation(value)} 
                                htmlFor="memorable_instrutation"
                                label="If any most memorable experience instrutation"
                                groupClass="form-check form-switch mb-2"
                            />
                        </div>
                        
                        {memorable_instrutation && <div className="col-lg-12">
                            <div className="col-lg-12">
                                <Input data={memorable_instrutation_name} error={allError['memorable_instrutation_name']} label="Memorable Instrutation Name" htmlFor="memorable_instrutation_name" required={true}/>
                            </div>
                        </div>}
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}