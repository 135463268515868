import { useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/form/Input";
import InputSwitch from "../../../../components/form/InputSwitch";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function FeesEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const [appointment_service, setAppointment_service] = useState(doctor && parseInt(doctor.appointment_service) === 1 ? true : false);
    const chamber_name         = useFormInput(doctor?.chamber_name ?? '');
    const chamber_location     = useFormInput(doctor?.chamber_location ?? '');
    const appointment_fee      = useFormInput(doctor?.appointment_fee ?? '');
    const appointment_discount = useFormInput(doctor?.appointment_discount ?? '');
    const [audio_consultancy, setAudio_consultancy] = useState(doctor && parseInt(doctor.audio_consultancy) === 1 ? true : false);
    const audio_fee            = useFormInput(doctor?.audio_fee ?? '');
    const audio_discount       = useFormInput(doctor?.audio_discount ?? '');
    const [video_consultancy, setVideo_consultancy] = useState(doctor && parseInt(doctor.video_consultancy) === 1 ? true : false);
    const video_fee            = useFormInput(doctor?.video_fee ?? '');
    const video_discount       = useFormInput(doctor?.video_discount ?? '');

    function handleFeesSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            appointment_service  : appointment_service,
            chamber_name         : chamber_name.value,
            chamber_location     : chamber_location.value,
            appointment_fee      : appointment_fee.value,
            appointment_discount : appointment_discount.value,
            audio_consultancy    : audio_consultancy,
            audio_fee            : audio_fee.value,
            audio_discount       : audio_discount.value,
            video_consultancy    : video_consultancy,
            video_fee            : video_fee.value,
            video_discount       : video_discount.value,
        };

        api(token).post('/api/doctor/update/fees', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor && response.data.status === 'success'){
                updateDoctor(response.data.doctor);
                toast('Fees successfully updated', 'success');
                setAllError([]);
                cancelEdit(false);
            }
            else{
                toast('Something wrong! Please try again.', 'error');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleFeesSubmit}>
                    <fieldset className={`${appointment_service ? 'border border-primary': ''} rounded p-2 my-3`}>
                        <legend className="float-none w-auto my-0">
                            <InputSwitch
                                data={appointment_service} 
                                onClick={value =>setAppointment_service(value)} 
                                htmlFor="appointment_service"
                                label="Appointment Service"
                                labelClass="form-check-label small"
                            />
                        </legend>

                        {appointment_service && 
                            <div className="row justify-content-between">
                                <div className="col-lg-6">
                                    <Input data={chamber_name} error={allError['chamber_name']} label="Chamber/Clinic/Hospital Name" htmlFor="chamber_name" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={chamber_location} error={allError['chamber_location']} label="Chamber/Clinic/Hospital Location" htmlFor="chamber_location" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={appointment_fee} error={allError['appointment_fee']} label="Appointment Fee" htmlFor="appointment_fee" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={appointment_discount} error={allError['appointment_discount']} label="Appointment Discount (0-99)%" htmlFor="appointment_discount"/>
                                </div>
                            </div>
                        }
                    </fieldset>

                    <fieldset className={`${audio_consultancy ? 'border border-primary': ''} rounded p-2 my-3`}>
                        <legend className="float-none w-auto my-0">
                            <InputSwitch
                                data={audio_consultancy} 
                                onClick={value =>setAudio_consultancy(value)} 
                                htmlFor="audio_consultancy"
                                label="Audio Consultancy Service"
                                labelClass="form-check-label small"
                            />
                        </legend>

                        {audio_consultancy && 
                            <div className="row justify-content-between">
                                <div className="col-lg-6">
                                    <Input data={audio_fee} error={allError['audio_fee']} label="Audio consultancy Fee" htmlFor="audio_fee" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={audio_discount} error={allError['audio_discount']} label="Audio consultancy Discount (0-99)%" htmlFor="audio_discount"/>
                                </div>
                            </div>
                        }
                    </fieldset>

                    <fieldset className={`${video_consultancy ? 'border border-primary': ''} rounded p-2 my-3`}>
                        <legend className="float-none w-auto my-0">
                            <InputSwitch
                                data={video_consultancy} 
                                onClick={value =>setVideo_consultancy(value)} 
                                htmlFor="video_consultancy"
                                label="Video Consultancy Service"
                                labelClass="form-check-label small"
                            />
                        </legend>

                        {video_consultancy && 
                            <div className="row justify-content-between">
                                <div className="col-lg-6">
                                    <Input data={video_fee} error={allError['video_fee']} label="Video consultancy Fee" htmlFor="video_fee" required={true}/>
                                </div>

                                <div className="col-lg-6">
                                    <Input data={video_discount} error={allError['video_discount']} label="Video consultancy Discount (0-99)%" htmlFor="video_discount"/>
                                </div>
                            </div>
                        }
                    </fieldset>
                    {allError['appointment_service'] && <div className="alaert alert-danger my-2 p-1 text-center rounded shadow-sm">{allError['appointment_service']}</div>}
                
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}