import { isEmpty } from "lodash";
import { util } from "peerjs";
import { useCallback } from "react";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SocketContext } from "../../context/SocketContext";
import CallReceiver from "./CallReceiver";

export default function ChatNotification() {

    const { callStack, setCallStack, callStatus, setCallStatus, rejectCall, acceptCall } = useContext(SocketContext);
    const [showModal, setShowModal] = useState(false);
    const [callerName, setCallerName] = useState('caller');

    const checkBrowserSuported = useCallback(() => {
        if(util.supports.data) {
            return true;
        }
        else {
            Swal.fire({
                title: 'This Browser not support Audio and Video call',
                text: 'Use Chrome or Mozilla or Internet explorer or any other browser that support audio and video call.',
                showConfirmButton: true,
                confirmButtonText: 'Close',
                confirmButtonColor: 'red',
            });
            return false;
        }
    },[])

    useEffect(() => {
        if(callStack.name) setCallerName(callStack.name)
        if(isEmpty(callStack)) setShowModal(false)
    },[callStack])

    useEffect(() => {
        if(!isEmpty(callStack) && callStatus === 'ringing'){
            Swal.close()
            Swal.fire({
                title: `${callStack.name || 'Caller'} calling you...`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: 'green',
                cancelButtonColor: 'red',
                confirmButtonText: 'Accept Call',
                cancelButtonText: 'Reject Call',
                allowOutsideClick: false,
                allowEscapeKey: false,
    
            }).then((result) => {
                if (result.value) {
                    if(checkBrowserSuported()){
                        setShowModal(true);
                        acceptCall(callStack.from, callStack.peerId, callStack.isVideo);
                        Swal.close();
                    }
                    else {
                        rejectCall();
                    }
                }
                else if(result.dismiss === "cancel") {
                    rejectCall();
                }
            })
        }
        
        if(!isEmpty(callStack) && callStatus === 'received') Swal.close();
    }, [callStack, rejectCall, callStatus, acceptCall, checkBrowserSuported]);

    useEffect(() => {
        if(callStatus === 'missed-call'){
            Swal.close()
            Swal.fire({
                title: 'Missed Call',
                text: `You missed a call from ${callerName}`,
                showConfirmButton: true,
                confirmButtonText: 'Close',
                confirmButtonColor: 'red',
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                if(result.value){
                    setCallStack({})
                    setCallStatus(null)
                }
            })
        }
        if(callStatus === 'end-call') {
            Swal.close()
            Swal.fire({
                title: 'Call Ended',
                showConfirmButton: true,
                confirmButtonColor: 'red',
                confirmButtonText: 'Close',
            }).then((result) => {
                if(result.value) setCallStatus(null)
            })
            // window.location.reload(); // need atention to remove this 
        }
    },[callerName, callStack, callStatus, setCallStack, setCallStatus])

    return (
        <>
            <CallReceiver showModal={showModal} setShowModal={setShowModal}/>
        </>
    );
};
