import React, { createContext, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import Peer from 'peerjs';
import { useSelector } from 'react-redux';
import { selectToken, selectUser } from '../slice/userSlice';
import api from '../services/api';
import MediaHandler from "../handler/MediaHandler";
import Swal from 'sweetalert2';

const SocketContext = createContext();

const SocketContextProvider = ({ children }) => {

    const [localPeerId, setLocalPeerId] = useState('');
    const [remoteStream, setRemoteStream] = useState(null);
    const [localStream, setLocalStream] = useState(null);
    const [callEvent, setCallEvent] = useState(undefined);
    const [callStack, setCallStack] = useState({});

    const [mediaPermission, setMediaPermission] = useState(false);
    const [callStatus, setCallStatus] = useState(null); // null, ringing, received, missed-call, end-call

    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const [me, setMe] = useState(0);

    const [peer, setPeer] = useState({})
    const myVideo = useRef(null);
    const userVideo = useRef(null)
    const mediaHandler = useMemo(() => new MediaHandler(),[]);

    const [ringtone] = useState(new Audio(process.env.REACT_APP_STORAGE + 'ringtone/caller_tune.mp3'));
    const [subscriber, setSubscriber] = useState(0);

    const clearSubscription = useCallback(() => {
        try{
            window.Echo.leave(`deb-call-channel.calling.${subscriber}`)
        } catch{ }
    }, [subscriber])

    useEffect(() => {
        if(callStatus === 'ringing'){
            ringtone.play();
            ringtone.loop = true;
        }
        else {
            ringtone.pause();
        }
    }, [callStatus, ringtone]);

    useEffect(() => {
        setMe(user?.id ?? 0);
    }, [user])

    useEffect(() => {
        if(callStatus === 'end-call'){
            setCallStack({})
            localStream?.getTracks().forEach((track) => track.stop());
        }
    },[callStatus, localStream])
    
    useEffect(() => {
        if(parseInt(me) > 0  && token) {
            window.Echo.private(`deb-call-channel.calling.${me}`)
                .listen('CallJoinEvent', ({from, callerName, peerId, isVideo, avatar}) => {
                    setCallStack({ isReceivingCall: true, from, name: callerName, peerId, isVideo, avatar});
                    setCallStatus('ringing')
            })

            window.Echo.private(`deb-call-channel.accepted-by-other-decices.${me}`)
                .listen('CallAcceptedEven', () => {
                    setCallStack({})
                    setCallStatus(null)
                    Swal.close()
            })

            window.Echo.private(`deb-call-channel.missed-call.${me}`)
                .listen('LeaveCallEvent', () => {
                    setCallStatus(prev => prev === 'ringing' ? 'missed-call' : 'end-call')
            })
            setSubscriber(me);
        }

        else {
            clearSubscription();
        }
    }, [me, token, clearSubscription]);

    const allowMedia = useCallback((video = true, audio = true) => {
        return new Promise((resolve, reject) => {
            mediaHandler.getPermissions(video, audio).then((currentStream) => {
                setMediaPermission(true);
                setLocalStream(currentStream);
                resolve(currentStream);
            }).catch(e => {
                reject(false);
            });
        })
    },[mediaHandler])

    
    const rejectCall = useCallback(() => {
        setCallStack({})
        setCallStatus(null)
    }, [])

    const leaveCall = (userId) => {
        if(callEvent){
            callEvent.close();
            setCallEvent(undefined)
        }
        localStream?.getTracks().forEach((track) => track.stop());
        setCallStack({})
        setCallStatus(null)
        setLocalStream(null)
        setRemoteStream(null)

        api(token).post(`/api/api-chat/leave-call/${userId}`);
    }

    const sendCallRequest = (userId, isVideo) => {
        allowMedia(isVideo).then((mediatStream) => {
            api(token).post(`/api/api-chat/call-user/${userId}/${me}`, { peerId: localPeerId, isVideo: isVideo });
            setCallStatus('ringing')
            peer.on('call', (call) => {
                if(mediatStream.active){
                    answerCall(call, mediatStream)
                }
            })
        }).catch((e) => {
            console.log( 'fail to answer ', e);
        })
    }

    const answerCall = (call, mediatStream) => {
        call.answer(mediatStream);
        call.on('stream', (remoteStream) => {
            setRemoteStream(remoteStream);
        });
        call.on('close', function (){
            setCallStatus('end-call')
        })
        setCallEvent(call)
        setCallStatus('received')
    }

    const acceptCall = useCallback((userId, peerId, isVideo = true) => {
        if(callStatus === 'received') return;

        allowMedia(isVideo).then((mediatStream) => {
            var call = peer.call(peerId, mediatStream);
            call.on('stream', (remoteStream) => {
                setRemoteStream(remoteStream);
            });
            call.on('close', function (){
                setCallStatus('end-call')
            })

            api(token).post(`/api/api-chat/answer-call/${userId}`, {}, {
                headers: {
                    "X-Socket-Id": window.Echo?.socketId(),
                }
            });

            setLocalStream(mediatStream);
            setCallEvent(call);
            setCallStatus('received')
        }).catch((e) => {
            console.log( 'fail to accept call ', e);
        })
    },[allowMedia, peer, callStatus, token])

    useEffect(() => {
        const myPeer = new Peer(undefined, {
            iceServers: [
                {urls: "stun:stun.l.google.com:19302"}, 
                {credential: "peerjsp", urls: "turn:0.peerjs.com:3478", username: "peerjs"}
            ], 
            sdpSemantics: "unified-plan"
        })
        setPeer(myPeer);

        myPeer.on('open', (id) => {
            setLocalPeerId(id);
        })

        myPeer.on('error', (error) => {
            console.log('error', error);
        })

        return () => {
            setCallEvent(undefined);
            setCallStack({})
            setCallStatus(null)
            myPeer?.destroy();
        }
    }, [])

    useEffect(() => {
        if(localStream){
            try {
                myVideo.current.srcObject = localStream;
            } catch (e) {
                myVideo.current.src = window.URL.createObjectURL(localStream);
            }
        }

        if(remoteStream){
            try {
                userVideo.current.srcObject = remoteStream;
            } catch (e) {
                userVideo.current.src = window.URL.createObjectURL(remoteStream);
            }
        }
    }, [localStream, remoteStream])
 

    return (
        <SocketContext.Provider value={{
            callStack,
            setCallStack,
            callStatus,
            setCallStatus,
            rejectCall,
            myVideo,
            userVideo,
            localPeerId,
            localStream,
            remoteStream,
            sendCallRequest,
            leaveCall,
            acceptCall,
            mediaPermission,
            allowMedia,
        }}>{children}</SocketContext.Provider>
    );
};

export { SocketContextProvider, SocketContext };