import { useState } from "react";
import QualificationEdit from "./QualificationEdit";

export default function Qualification({doctor, updateDoctor}) {

    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="py-2">
            <div className="d-flex justify-content-between bg-info bg-opacity-10 shadow-sm rounded p-1 my-2">
                <h6 className="align-self-center m-0">Personal Qualification</h6>
                <button onClick={() => setIsEdit(!isEdit)} type="button" className="btn btn-sm btn-master px-3 btn-outline-none">{isEdit ? 'Cancle' : 'Edit'}</button>
            </div>

            {!isEdit && <div>
                {doctor.qualifications && doctor.qualifications.map((qualification, index)=> (
                    <fieldset key={qualification.id} className="border rounded p-2">
                        <legend className="float-none w-auto small my-0">Qualification {index+1}</legend>
                        <div className="row justify-content-start px-2">
                            <dt className="col-5 col-lg-4">Degree </dt>
                            <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{qualification.degree}</dd>
                            
                            <dt className="col-5 col-lg-4">Institution Name </dt>
                            <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{qualification.institution_name}</dd>
                            
                            <dt className="col-5 col-lg-4">Institution Location </dt>
                            <dd className="col-7 col-lg-8 bg-light rounded shadow-sm py-1">{qualification.institution_location}</dd>
                        </div>
                    </fieldset>
                ))}
            </div>}

            {isEdit && <QualificationEdit doctor={doctor} updateDoctor={updateDoctor} cancelEdit={(value) => setIsEdit(false)}/>}
        </div>
    )
}
