import useScrollTop from "../../hooks/useScrollTop";
import { useState } from "react";
import DropZone from "../../components/DropZone";
import toast from "../../utlities/toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectToken, setUser } from "../../slice/userSlice";
import api from "../../services/api";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import Swal from "sweetalert2";

export default function JoinAsAmbulanceStep2() {
    useScrollTop();
    const history = useHistory();
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [photo, setPhoto]               = useState(null);
    const [nid_passport, setNid_passport] = useState(null);
    const [nid_passport2, setNid_passport2] = useState(null);
    const [docs, setDocs] = useState({
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
    });
    const [allError, setAllError] = useState([]);

    function handleStep2Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = new FormData();
        if(photo) body.append('photo', photo);
        if(nid_passport) body.append('nid_passport', nid_passport);
        if(nid_passport2) body.append('nid_passport2', nid_passport2);

        for(const index in docs){
            if(docs[index]) body.append('docs[]', docs[index]);
        }

        api(token).post('/api/ambulance/join/step-2', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);

            if(response.data.icon === 'success'){
                dispatch(setUser(response.data.user));
                history.replace('/sp/ambulance');
                Swal.fire('Successfully join as a Ambulance service provider','We will review your profile within 24 hours','success');
            }
            else if(response.data.icon === 'warning'){
                history.replace('/join/ambulance/step-1');
                Swal.fire('Please flow step by step','','warning');
            }
            else if(response.data.icon === 'error'){
                Swal.fire('Something wrong','Please try again','error');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Ambulance service provider',
                    text: 'If you want to update your Ambulance service profile, Then click Edit Ambulance service Profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Ambulance service Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/ambulance');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);

            console.log(error);
            toast(error.message, 'error')
            if(error.errors){
                setAllError(error.errors);
            }
            else {
                setAllError([]);
            }
        });
    }


    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Ambulance service provider [Step 2 of 2]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <div className="row">
                        <div className="col-lg-4">
                            <h6 className="bg-light border rounded p-2">Upload ambulance photo (png, jpg, jpge)</h6>
                            <div className="p-2 p-md-4">
                                <DropZone callbackSetFiles={callbackFiles => {setPhoto(callbackFiles[0])}}
                                    text="Drag and drop ambulance photo here, or click to select*"
                                    fileClass="row row-cols-2 row-cols-md-4"
                                    isPreview={true}
                                    isInvalid={allError['photo'] ? true : false}
                                    errorText={allError['photo']}
                                />
                            </div>
                        </div>
                        <div className="col-lg-8 border-start border-">
                            <h6 className="bg-light border rounded p-2">Upload NID or Passport (png, jpg, jpge)</h6>
                            <div className="row row-cols-1 row-cols-md-2 justify-content-center">
                                <div className="p-2 p-md-4">
                                    <DropZone callbackSetFiles={callbackFiles => {setNid_passport(callbackFiles[0])}}
                                        text="Drag and drop NID/Passport here, or click to select*"
                                        fileClass="row row-cols-2 row-cols-md-4"
                                        isPreview={true}
                                        isInvalid={allError['nid_passport'] ? true : false}
                                        errorText={allError['nid_passport']}
                                    />
                                </div>

                                <div className="p-2 p-md-4">
                                    <DropZone callbackSetFiles={callbackFiles => {setNid_passport2(callbackFiles[0])}}
                                        text="Drag and drop NID/Passport here, or click to select*"
                                        fileClass="row row-cols-2 row-cols-md-4"
                                        isPreview={true}
                                        isInvalid={allError['nid_passport2'] ? true : false}
                                        errorText={allError['nid_passport2']}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Upload ambulance documents (png, jpg, jpge)</h6>
                    <div className="alert alert-info py-1">Upload at least one authentic document</div>
                    <div className="p-2 p-md-4">
                        {allError['docs'] && <div className="alert alert-danger py-1" role="alert">{allError['docs']}</div>}
                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 gy-3">
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 0: callbackFiles[0] })}
                                text="Document 1*"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.0'] ? true : false}
                                errorText={allError['docs.0']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 1: callbackFiles[0] })}
                                text="Document 2"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.1'] ? true : false}
                                errorText={allError['docs.1']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 2: callbackFiles[0] })}
                                text="Document 3"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.2'] ? true : false}
                                errorText={allError['docs.2']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 3: callbackFiles[0] })}
                                text="Document 4"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.3'] ? true : false}
                                errorText={allError['docs.3']}
                            />
                            <DropZone callbackSetFiles={callbackFiles => setDocs({ ...docs, 4: callbackFiles[0] })}
                                text="Document 5"
                                fileClass=""
                                isPreview={true}
                                isInvalid={allError['docs.4'] ? true : false}
                                errorText={allError['docs.4']}
                            />
                        </div>
                    </div>
                </div>

                
                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep2Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            
            </div>
        </section>
    )
}
