import ReactSlick from 'react-slick';
import ProductItem from "./ProductItem";

export default function ItemSlickSlider({items}) {

    const settings = {
        dots: false,
        infinite: items.length > 5 ? true : false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: items.length > 4 ? true : false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: items.length > 3 ? true : false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: items.length > 2 ? true : false,
                    // initialSlide: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: items.length > 1 ? true : false,
                }
            }
          ]
    };

    return (
        <ReactSlick {...settings}>
            {items.map(item => (
                <div key={item.id} className="p-sm-2">
                    <ProductItem item={item}/>
                </div>
            ))}
        </ReactSlick>
    )
}
