import { createSlice } from '@reduxjs/toolkit';
import { getStorage, removeStorage, setStorage } from '../app/storage';

// const initialState = {       
//     token: null,
//     user: null,
// };


export const userSlice = createSlice({
    name: "user",
    initialState : {
        user: getStorage('user', true),
        token: getStorage('token'),
    },

    reducers: {
        login: (state, action) => {
            setStorage('token', action.payload);
            state.token = getStorage('token');
        },

        logout: (state) => {
            removeStorage('user');
            removeStorage('token');
            state.user  = getStorage('user', true);
            state.token = getStorage('token');
            // state.user  = null;
            // state.token = null;
        },

        setUser: (state, action) => {
            setStorage('user', action.payload, true);
            state.user  = getStorage('user', true);
        },
    },

});

export const { login, logout, setUser } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectToken = (state) => state.user.token;

export default userSlice.reducer;
