import React, { useEffect, useState } from 'react';
import ItemsRequest from '../../request/ItemsRequest';
import ItemLoader from '../loader/item_loder/ItemLoader';

export default function ShopSlider() {
    const sliders = ItemsRequest('/api/sliders/shop-slider');
    const [isSliderLoading, setIsSliderIsLoading] = useState(true);

    useEffect(() => {
        if(sliders.length > 0){
            setIsSliderIsLoading(false);
        }
    },[sliders]);


    if(isSliderLoading){
        return (
            <section>
                {Array.from(Array(1)).map((item,index) => (
                    <ItemLoader key={index} classList="h-slider shadow-sm w-100"/>
                ))}
            </section>
        )
    }
    else{
        return (
            <div className="slider-hero shadow-sm">
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                    <div className="carousel-indicators">
                        {sliders.map((slider, i) => (
                            <button key={slider.id} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i} className={i === 0 ? 'active': ''} aria-current={i === 0 ? 'true': ''} aria-label={`Slide ${i+1}`}></button>
                        ))}
                    </div>
                    <div className="carousel-inner">
                        {sliders.map((slider, i) => (
                            <div key={slider.id} className={`carousel-item ${i===0 ? ' active': ''}`}>
                                <img src={process.env.REACT_APP_STORAGE + slider.image} className="d-block w-100" alt=""/>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        )
    }
}
