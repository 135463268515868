import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { set_cart, decrement_cart, delete_cart, increment_cart, selectCart } from "../slice/cartSlice"
import BoxEmpty from "../components/BoxEmpty";
import useScrollTop from "../hooks/useScrollTop";
import api from "../services/api";
import MyCF from "../components/MyCF";

export default function Cart() {
    useScrollTop();

    const dispatch = useDispatch();

    const cart_content = useSelector(selectCart);
    const [subTotal, setSubTotal] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [shippingFeeID, setShippingID] = useState(0);
    const [shippingFeeOD, setShippingOD] = useState(0);

    useEffect(() => {
        var total = 0;
        cart_content.forEach(item => {
            total += item.final_price * item.c_qty;
        });
        setSubTotal(total);
    }, [cart_content]);

    useEffect(() => {
        let items_id = [];
        cart_content.forEach(item => {
            items_id.push(item.id);
        });

        if(items_id.length > 0 && !isLoaded){
            api().post(`/api/cart/index/${JSON.stringify( items_id )}`)
            .then(response => {
                let items = [];
                response.data.cart_items.forEach(item => {
                    items.push({...item, c_qty: cart_content.find(cItem => cItem.id === item.id).c_qty ?? 1});
                });

                setShippingID(response.data.shipping_fee_id);
                setShippingOD(response.data.shipping_fee_od);

                dispatch(set_cart(items));
            })
            .catch(err => {
                console.log(err);
            });
            setIsLoaded(true);
        }
        return () => {}
    }, [cart_content, isLoaded, dispatch]);



    function handleDecrement(e, id){
        e.preventDefault();
        dispatch(decrement_cart(id));
    }

    function handleincrement(e, id){
        e.preventDefault();
        dispatch(increment_cart(id));
    }

    function handleDelete(e, id){
        e.preventDefault();
        dispatch(delete_cart(id));
    }


    return (
        <section className="bg-light">
            <div className="container py-4">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-8">
                        <div className="p-2 bg-white shadow-sm rounded-top border-top border-3 border-master">
                            <div className="d-flex justify-content-between">
                                <h6 className="m-0">Cart ({cart_content.length} {cart_content.length > 1 ? 'items' : 'item'})</h6>
                                <h6 className="m-0">Total: <MyCF value={subTotal}/></h6>
                            </div>
                        </div>
                        <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                            {cart_content.length ? cart_content.map(item =>(
                                <div key={item.id} className="border-bottom py-2">
                                    <div className="row justify-content-bet">
                                        <div className="col-4 col-md-3 col-xl-2">
                                            <img  className=" img-fluid" src={item.image ? process.env.REACT_APP_STORAGE + item.image?.image : process.env.REACT_APP_STORAGE + item.images[0]?.image} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 col-xl-10">
                                            <div className="text-secondary ">
                                                <Link className=" link-info text-decoration-none" to={{pathname: '/product/' + item.slug}}>{item.title}</Link>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <div className="text-dark"><MyCF value={item.final_price * item.c_qty}/></div>
                                                    {item.discount > 0 && <div className="text-decoration-line-through text-warning"><MyCF value={item.price * item.c_qty}/></div>}
                                                </div>
                                                <div className="">
                                                    <div className="d-flex">
                                                        <button onClick={(e) => handleDecrement(e, item.id)} className="btn btn-sm btn-light outline-none">-</button>
                                                        <h5 className="px-1 px-md-3">{item.c_qty}</h5>
                                                        <button onClick={(e) => handleincrement(e, item.id)} className="btn btn-sm btn-light outline-none">+</button>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <button onClick={(e) => handleDelete(e, item.id)} className="btn btn-sm btn-danger outline-none"><i className="bi bi-trash"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))
                                : 
                                <BoxEmpty/>   
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-4 pt-3 pt-md-0">
                        <div className="sticky-top top-except-nav">
                            <div className="p-2 bg-white shadow-sm rounded-top shadow-sm border-top border-3 border-master">
                                <h6 className="m-0">Price Summary</h6>
                            </div>
                            {cart_content.length > 0 && <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                                <h6 className="small">Shipping fee</h6>
                                <div className="bg-light rounded p-2 fw-light">
                                    <p className="small m-0">Inside dhaka ৳ {shippingFeeID}</p>
                                    <p className="small m-0">Outside dhaka ৳ {shippingFeeOD}</p>
                                </div>
                                <div className="d-flex justify-content-between border-bottom pt-3">
                                    <p className="m-0 py-2">Sub Total</p>
                                    <p className="m-0 py-2"><MyCF value={subTotal}/></p>
                                </div>  
                                <div className="d-flex justify-content-between border-bottom fw-bold">
                                    <p className="m-0 py-2">Total</p>
                                    <p className="m-0 py-2"><MyCF value={subTotal}/> + Shipping fee</p>
                                </div>   
                                <div className="d-grid pt-3">
                                    <Link to="/cart/checkout" className="btn btn-sm btn-master outline-none">PROCEED TO CHECKOUT </Link>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
