import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import bn from "./bn/translation.json";
import en from "./en/translation.json";


const resources = {
    en: { translation: en },
    bn: { translation: bn },
};

  
i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3', 
    lng: 'en',
    fallbackLng: 'en',
    resources,
    supportedLngs: ['en', 'bn'],
    interpolation: {
        escapeValue: false 
    },
    react: {
        useSuspense:false,
    }
  });

export default i18n;