import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api";
import { logout, selectToken, setUser } from "../slice/userSlice";

export default function useValidAuth() {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    useEffect(() => {
        function checkIsValidAuth(token){
            api(token).post('/api/auth/check-is-valid-auth')
            .then(response => {
                if(response.data.status === 'valid-auth'){
                    dispatch(setUser(response.data.user));
                }
                else {
                    dispatch(logout());
                }
            })
            .catch(err => {
                if(err?.response?.status === 401 || err?.response?.status === 403){
                    dispatch(logout());
                }
            });
        }

        if(token && token.length > 5){
            checkIsValidAuth(token);
        }
        return () => {
            
        }
    }, [token, dispatch]);
}
