function setStorage(key, value, need_stringify = false){
    if(need_stringify){
        localStorage.setItem(key, JSON.stringify(value));
    }
    else{
        localStorage.setItem(key, value);
    }
}

function removeStorage(key){
    localStorage.removeItem(key);
}

function getStorage(key, need_parse = false){
    if(typeof(Storage) !== "undefined") {
        if (localStorage.getItem(key) !== null) {
            if(need_parse) {
                return JSON.parse(localStorage.getItem(key));
            } 
            else {
                return localStorage.getItem(key);
            }
        } 
        else {
            return null;
        }
    } 
    else {
        return null;
    }
}

export {setStorage, removeStorage, getStorage};