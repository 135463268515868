import { useEffect, useState } from "react";
import api from "../services/api";

export default function ItemsRequest(requrst_url) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        api().get(requrst_url)
        .then(response => {
            setItems(response.data);
        }).catch(err => {
            console.log(err);
        });
        return () => {
            setItems([]);
        }
    }, [requrst_url]);

    return items;
}
