import { Link } from 'react-router-dom'
import logo from "../assets/img/footer_logo.png";
import useWindowScroll from '../hooks/useWindowScroll';

export default function Footer() {
    const scroll_top = useWindowScroll();
    function handleScrollTop(e){
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    return (

        <footer className="text-white pb-5 pb-lg-0 footer-content">

            <div className="container py-5">

                {/* <section className="mb-4">
                    <a className="btn btn-outline-light btn-floating m-1" href="facebook.com" role="button">
                        <i className="bi bi-facebook"></i>
                    </a>

                    <a className="btn btn-outline-light btn-floating m-1" href="twitter" role="button"
                        ><i className="bi bi-twitter"></i>
                    </a>

                    <a className="btn btn-outline-light btn-floating m-1" href="google.com" role="button"
                        ><i className="bi bi-google"></i>
                    </a>

                    <a className="btn btn-outline-light btn-floating m-1" href="linkedin.com" role="button"
                        ><i className="bi bi-linkedin"></i>
                    </a>
                </section> */}

                {/* <section className="mb-4">
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt distinctio earum
                    repellat quaerat voluptatibus placeat nam, commodi optio pariatur est quia magnam
                    eum harum corrupti dicta, aliquam sequi voluptate quas.
                </p>
                </section> */}

                <section className="">

                <div className="row">

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <img className="rounded img-thumbnail w-100" src={logo} alt=""/>
                        <p className="py-2 text-white-50 ">
                            Duare Doctor is an online medical service targeting emerging markets that are rapidly digitizing.    
                        </p>
                        <section className="mb-4">
                            <a className="btn btn-outline-light btn-floating m-1" rel="noreferrer" href="https://facebook.com/duaredoctor" target="_blank" role="button">
                                <i className="bi bi-facebook"></i>
                            </a>

                            <a className="btn btn-outline-light btn-floating m-1" rel="noreferrer" href="https://twitter.com/duaredoctor" target="_blank" role="button"
                                ><i className="bi bi-twitter"></i>
                            </a>

                            <a className="btn btn-outline-light btn-floating m-1" rel="noreferrer" href="https://google.com/duaredoctor" target="_blank" role="button"
                                ><i className="bi bi-google"></i>
                            </a>

                            <a className="btn btn-outline-light btn-floating m-1" rel="noreferrer" href="https://www.linkedin.com/in/duaredoctor" target="_blank" role="button"
                                ><i className="bi bi-linkedin"></i>
                            </a>
                        </section>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h6 className="text-uppercase border-bottom border-2 pb-2 w-50">Support</h6>
                        <ul className="list-unstyled mb-0">
                            <li className="py-1"><Link to="/contact" className="text-white-50 text-decoration-none">Contact Us</Link></li>
                            <li className="py-1"><Link to="/faq" className="text-white-50 text-decoration-none">FAQ</Link> </li>
                            <li className="py-1"><Link to="/terms-and-condition" className="text-white-50 text-decoration-none">Terms and Contidions</Link></li>
                            {/* <li className="py-1"><Link to="/blog" className="text-white-50 text-decoration-none">Blog</Link></li> */}
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h6 className="text-uppercase border-bottom border-2 pb-2 w-50">Policies</h6>
                        <ul className="list-unstyled mb-0">
                            {/* <li className="py-1"><Link to="/terms-of-use" className="text-white-50 text-decoration-none">Terms of Use</Link></li> */}
                            <li className="py-1"><Link to="/privacy-policy" className="text-white-50 text-decoration-none">Privacy Policy</Link> </li>
                            {/* <li className="py-1"><Link to="/return-policy" className="text-white-50 text-decoration-none">Return Policy</Link></li> */}
                            <li className="py-1"><Link to="/refund-policy" className="text-white-50 text-decoration-none">Refund Policy</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h6 className="text-uppercase border-bottom border-2 pb-2 w-50">Get to know</h6>
                        <ul className="list-unstyled mb-0">
                            <li className="py-1"><Link to="/about" className="text-white-50 text-decoration-none">About Us</Link></li>
                            {/* <li className="py-1"><Link to="/site-map" className="text-white-50 text-decoration-none">Site Map</Link> </li> */}
                        </ul>
                    </div>

                </div>

                </section>
            </div>

            <div className="footer-bottom">
                <div className="container  d-flex justify-content-between py-3">
                    <div className="">
                        © All rights reserved  <a target="_blank" rel="noreferrer" className="text-white" href="/">duaredoctor.com</a>
                    </div>
                    <div>
                        Developed by <a className="text-white" target="_blank" rel="noreferrer" href="https://nakuldeb.github.io">Nakul Deb Nath</a>
                    </div>
                </div>
            </div>
            {scroll_top > 250 && <button onClick={handleScrollTop} className="border rounded d-inline" id="scroll-top-btn"><i className="bi bi-arrow-up-circle"></i></button>}
            
        </footer>

    )
}
