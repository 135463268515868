import { useDispatch } from "react-redux";
import { add_wishlist } from "../slice/wishlistSlice";
import toast from "../utlities/toast";

export default function WishlistBtn({product}) {
    const dispatch = useDispatch();

    function handleAddWishlist(e){
        e.preventDefault();

        const new_item = {...product};
        let items = [];
        let flag = true;
        if(localStorage.getItem('wishlist')){
            items = JSON.parse(localStorage.getItem('wishlist'));
            for(let item of items) {
                if(item.id === product.id){
                    flag = false;
                    break;
                }
            };
        }
        if(flag){
            items.push(new_item);
        }
        toast('Successfully added to wishlist', 'success');
        dispatch(add_wishlist(items));
    }


    return (
        <div className="w-100"> 
            <button onClick={handleAddWishlist} className="btn btn-outline-danger outline-none w-100"><i className="bi bi-heart"></i></button>
        </div>
    )
}
