import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import ChatNotification from './components/LiveChat/ChatNotification';
import useUpdateUserStatus from './hooks/useUpdateUserStatus';
import useValidAuth from './hooks/useValidAuth';
import MainRoute from './routers/MainRoute';
import { selectLang } from './slice/langSlice';
import { useTranslation } from "react-i18next";

function App() {
    useUpdateUserStatus();
    useValidAuth();

    const {i18n} = useTranslation();
    const lang = useSelector(selectLang);
    
    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n])
    

    return (
        <HelmetProvider>
            <MainRoute/>
            <ChatNotification/>
        </HelmetProvider>
    );
}

export default App;
