import Map from "../../components/Map";
import useScrollTop from "../../hooks/useScrollTop";

export default function About() {
    useScrollTop();

    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center">About Us</div>
            </div>

            <div className="bg-white shadow-sm rounded p-2 p-md-3">
                <p className="text-muted">Duare Doctor is an online medical service targeting emerging markets that are rapidly digitizing.</p>
            
                <p className="text-muted">Duare Doctor systematically focuses on expanding access to affordable healthcare services to all. We
                    promise to improve our solutions continuously through our scalable technology and data analytics
                    capabilities. Our passionate team is committed to excellence and work extremely hard to ensure we
                    make a positive contribution to the healthcare of the future.</p>
            
                <p className="text-muted">Our mission is brings to you a digital platform for all your healthcare needs from genuine medicines to
                    vitamins, doctor consultations, and even lab testing with sample collection conveniently from your
                    home.</p>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">E-Pharmacy</p>

                    <ul className=" text-justify font-weight-light text-muted">
                        <li>Order medicines and healthcare products online and get it delivered to your door.</li>
                        <li>Easy prescription upload checked by a qualified pharmacist.</li>
                        <li>Large central warehousing hub for fulfillment of orders.</li>
                        <li>Genuine medication direct from manufacturers.</li>
                        <li>Same day and express delivery options.</li>
                        <li>Repeat prescription services.</li>
                        <li>Discounted medicine and customer loyalty cashback.</li>
                        <li>Customer services help desk for any queries.</li>
                    </ul>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Doctor Consultation</p>
                    <div className="text-muted">
                        <p>On-demand doctor appointments and video solution capabilities.</p>
                        <p>Talk to a doctor within minutes. Our qualified network of doctors is available 24/7. You can get a
                            consultation and prescription whenever you need. Patients and doctors can keep their previous
                            consultation history and view online prescriptions.</p>
                        <p>Safety and quality is at the heart of everything we do. Each doctor is certified, registered, and operates
                            to the highest clinical standards. DocTime uses 256 bit encryption to secure the video consultation.</p>
                    </div>
                </div>
                
                <div className="pt-3">
                    <p className="fw-bold text-secondary">Lab Testing</p>
                    <ul className=" text-justify font-weight-light text-muted">
                        <li>Book lab tests online for home sample collection and get reports online.</li>
                        <li>Simply order your tests at duare doctor.</li>
                        <li>Also you can get a medical test from your nearest Diagnostic/Medical/Test Service Center.</li>
                        <li>Select a local Diagnostic/Medical/Test Service Center near you to give your sample for testing And confidentially receive your results in 1-3 days in your secure duaredoctor.com account</li>
                    </ul>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Ambulance Booking</p>
                    <div className="text-muted">
                        <p>Be able to book an ambulance, which will help the patient to reach the destination at the right time.</p>
                        <p>Simply book an amulance with your location. An ambulance driver near you will call you very quickly.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">Nurse Appointment</p>
                    <div className="text-muted">
                        <p>You dont have to go the hospital to get nursing services. Once you make an appointment, the nurse will arrive at you door at the right time.</p>
                    </div>
                </div>
            </div>
            

            <div className="map pt-3">
                <Map height={450}/>
            </div>
        </div>
    )
}
