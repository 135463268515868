import CurrencyFormat from "react-currency-format";

export default function MyCF({value}) {
    return (
        <CurrencyFormat 
            value={value} displayType={'text'} 
            thousandSeparator={true} 
            decimalScale={2} 
            prefix={' ৳ '} 
            fixedDecimalScale={true}
        />
    )
}
