import BtnLoader from "../loader/btn_loader/BtnLoader";

export default function SubmitBtn({
    isLoading,
    className='btn-success',
    title,

}) {
    return (
        <button className={`btn outline-none ${className} ${isLoading ? 'disabled' : ''}`} type="submit">
            <div className="d-flex justify-content-center"><div>{title} </div> {isLoading && <BtnLoader/>}</div>
        </button>
    )
}
