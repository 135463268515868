import useScrollTop from "../hooks/useScrollTop";
import boxEmptyImg from "../assets/img/box_empty.png";
export default function BoxEmpty({scroll = true}) {
    useScrollTop(scroll);

    return (
        <div>
            <div id="result-empty" style={{backgroundImage: `url(${boxEmptyImg})`}}></div>
            <h5 className="text-center text-secondary bg-light py-3">Empty</h5>
        </div>
    )
}
