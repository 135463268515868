import { useEffect, useState } from "react"

export default function ImageModal({title, images}) {

    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(images[0]?.image);
        return () => {}
    }, [images]);


    return (
        <div className="modal fade" id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content rounded-0">
                    <div className="modal-header border-0 rounded-0 p-2 bg-dark text-white">
                        <h6 className="modal-title" id="imageModalLabel">{title}</h6>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-secondary py-0">
                        <div className="h-100">
                            <div className="h-75 bg-dark bg-opacity-10"
                                style={{ 
                                    backgroundImage: `url(${process.env.REACT_APP_STORAGE + image})` ,
                                    backgroundSize: 'auto',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    
                                    }}>
                                </div>
                            <div className="h-25 row justify-content-center align-items-center">
                                {images.map(image => (
                                    <div key={image.id} className="col-2 col-lg-1">
                                        <img onClick={() => setImage(image.image)} role="button" className="img-fluid img-thumbnail w-100" src={process.env.REACT_APP_STORAGE + image.image}  alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer  border-0 py-0 px-3 bg-dark">
                        <button type="button" className="btn btn-sm btn-warning" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
