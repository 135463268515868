import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slice/userSlice';
import cartReducer from '../slice/cartSlice';
import wishlistReducer from '../slice/wishlistSlice';
import fromLocationReducer from '../slice/fromLocationSlice';
import langReducer from '../slice/langSlice';


export const store = configureStore({
    reducer: {
        user: userReducer,
        cart: cartReducer,
        wishlist: wishlistReducer,
        fromLocation: fromLocationReducer,
        lang: langReducer,
    },
});
