import axios from "axios";

export default function api(token = null) {
    

    const api = axios.create({
        baseURL:         process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers:         {'Authorization': `Bearer ${token}`}
    });

    // api.interceptors.response.use(response => response, error => {
    //     if (error.response.status === 401) {

    //         // useDispatch(logout());

    //         // return Promise.reject()
    //     }

    //     // return Promise.reject(error)
    // })
    
    return api;
}
