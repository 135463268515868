import useScrollTop from "../../hooks/useScrollTop";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../../slice/userSlice";
import api from "../../services/api";
import toast from "../../utlities/toast";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

export default function JoinAsNurseStep2() {
    useScrollTop();
    const token   = useSelector(selectToken);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);

    const [availabe_days, setAvailabe_days] = useState([
        {day:false, day_id:1, one_call:false, one_call_fee:'', hour_6_morning:false, hour_6_morning_fee:'', hour_6_evening:false, hour_6_evening_fee:'', hour_12_day:false, hour_12_day_fee:'', hour_12_night:false, hour_12_night_fee:'', hour_24:false, hour_24_fee:'',},
        {day:false, day_id:2, one_call:false, one_call_fee:'', hour_6_morning:false, hour_6_morning_fee:'', hour_6_evening:false, hour_6_evening_fee:'', hour_12_day:false, hour_12_day_fee:'', hour_12_night:false, hour_12_night_fee:'', hour_24:false, hour_24_fee:'',},
        {day:false, day_id:3, one_call:false, one_call_fee:'', hour_6_morning:false, hour_6_morning_fee:'', hour_6_evening:false, hour_6_evening_fee:'', hour_12_day:false, hour_12_day_fee:'', hour_12_night:false, hour_12_night_fee:'', hour_24:false, hour_24_fee:'',},
        {day:false, day_id:4, one_call:false, one_call_fee:'', hour_6_morning:false, hour_6_morning_fee:'', hour_6_evening:false, hour_6_evening_fee:'', hour_12_day:false, hour_12_day_fee:'', hour_12_night:false, hour_12_night_fee:'', hour_24:false, hour_24_fee:'',},
        {day:false, day_id:5, one_call:false, one_call_fee:'', hour_6_morning:false, hour_6_morning_fee:'', hour_6_evening:false, hour_6_evening_fee:'', hour_12_day:false, hour_12_day_fee:'', hour_12_night:false, hour_12_night_fee:'', hour_24:false, hour_24_fee:'',},
        {day:false, day_id:6, one_call:false, one_call_fee:'', hour_6_morning:false, hour_6_morning_fee:'', hour_6_evening:false, hour_6_evening_fee:'', hour_12_day:false, hour_12_day_fee:'', hour_12_night:false, hour_12_night_fee:'', hour_24:false, hour_24_fee:'',},
        {day:false, day_id:7, one_call:false, one_call_fee:'', hour_6_morning:false, hour_6_morning_fee:'', hour_6_evening:false, hour_6_evening_fee:'', hour_12_day:false, hour_12_day_fee:'', hour_12_night:false, hour_12_night_fee:'', hour_24:false, hour_24_fee:'',},
    ]);

    function handleDayChange(e, index, value){
        if(value) setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], day: value}});
        else setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], day: value, one_call: false, hour_6_morning: false,  hour_6_evening: false, hour_12_day: false, hour_12_night: false, hour_24: false,}});
    }
    
    function availabeDayTitle(index){
        switch (index) {
            case 0: return 'Sunday';
            case 1: return 'Monday';
            case 2: return 'Tuesday';
            case 3: return 'Wednesday';
            case 4: return 'Thursday';
            case 5: return 'Friday';
            case 6: return 'Saturday';
            default: return '';
        }
    }

    function handleStep2Submit(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            availabe_days: availabe_days
        };

        api(token).post('/api/nurse/join/step-2', body)
        .then(response => {
            setIsLoading(false);
            setAllError([]);
            
            if(response.data.icon === 'success'){
                history.replace('/join/nurse/step-3');
            }
            else if(response.data.icon === 'warning'){
                history.replace('/join/nurse/step-1');
                Swal.fire('Please flow step by step','','warning');
            }
            else if(response.data.icon === 'error'){
                Swal.fire('Something wrong','Please try again','error');
            }
            else{
                Swal.fire({
                    title: 'You already applied for Nurse',
                    text: 'If you want to update your Nurse profile, Then click Edit Nurse Profile',
                    showConfirmButton: true,
                    confirmButtonText: 'Edit Nurse Profile',
                    icon: response.data.icon,
        
                }).then((result) => {
                    if (result.value) {
                        history.replace('/sp/nurse');
                    }
                });
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;

            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <section className="bg-light py-4 font-roboto">
            <div className="container bg-white shadow-sm fw-bold p-2 mb-1">Join as a Nurse [Step 2 of 3]</div>
            <div className="container bg-white shadow-sm">
                <div className="p-2 p-md-3">
                    <h6 className="bg-light border rounded p-2">Select which day are you available <span className="small text-muted">(select at least one or more)</span></h6>
                    {allError[`availabe_days`] && <div className="p-2 alert alert-danger small">{allError[`availabe_days`]}</div> }
                    {Array.from(Array(7)).map((item,index) => (
                        <fieldset key={index} className={`${availabe_days[index]['day'] === true ? 'border border-primary': ''} rounded p-2 my-3`}>
                            <legend className="float-none w-auto my-0">
                                <div className="form-check form-switch">
                                    <input onChange={e => handleDayChange(e,index,!availabe_days[index]['day'])}
                                        checked={availabe_days[index]['day'] === true}
                                        value={availabe_days[index]['day'] === true}
                                        type="checkbox" 
                                        className={`form-check-input ${allError[`availabe_days.${index}`] ? 'is-invalid':''}`}
                                        role="switch" 
                                        id={`availabe_days.${index}`}/>
                                    <label className="form-check-label small" htmlFor={`availabe_days.${index}`}>{availabeDayTitle(index)}</label>
                                </div>
                            </legend>

                            {availabe_days[index]['day'] === true && 
                                <div className="row justify-content-between">
                                    <div className="col-12 col-md-7 col-lg-5">
                                        <div className="form-check form-switch mb-3">
                                            <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], one_call: !availabe_days[index]['one_call']} })}
                                                value={availabe_days[index]['one_call']}
                                                checked={availabe_days[index]['one_call'] === true}
                                                className={`form-check-input outline-none ${allError[`one_call_${index}`] ? 'is-invalid':''}`}
                                                type="checkbox" 
                                                role="switch" 
                                                id={`one_call_${index}`}/>
                                            <label className="form-check-label" htmlFor={`one_call_${index}`}>One Call</label>
                                            {allError[`one_call_${index}`] && <div className="invalid-tooltip">{allError[`one_call_${index}`]}</div> }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-7">
                                        {availabe_days[index]['one_call'] === true && <>
                                            <div className="form-floating mb-4">
                                                <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], one_call_fee: e.target.value} })}
                                                    value={availabe_days[index]['one_call_fee']}
                                                    type="number" 
                                                    className={`form-control outline-none ${allError[`availabe_days.${index}.one_call_fee`] ? 'is-invalid':''}`}
                                                    placeholder="One call fee"/>
                                                <label htmlFor={`availabe_days.${index}.one_call_fee`}>One call fee<span className="text-danger">*</span></label>
                                                {allError[`availabe_days.${index}.one_call_fee`] && <div className="invalid-tooltip">{allError[`availabe_days.${index}.one_call_fee`]}</div> }
                                            </div>
                                        </>}
                                    </div>

                                    <div className="col-12 col-md-7 col-lg-5">
                                        <div className="form-check form-switch mb-3">
                                            <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_6_morning: !availabe_days[index]['hour_6_morning']} })}
                                                value={availabe_days[index]['hour_6_morning']}
                                                checked={availabe_days[index]['hour_6_morning'] === true}
                                                className={`form-check-input outline-none ${allError[`hour_6_morning${index}`] ? 'is-invalid':''}`}
                                                type="checkbox" 
                                                role="switch" 
                                                id={`hour_6_morning${index}`}/>
                                            <label className="form-check-label" htmlFor={`hour_6_morning${index}`}>6 hours morning [start 06:00 AM]</label>
                                            {allError[`hour_6_morning${index}`] && <div className="invalid-tooltip">{allError[`hour_6_morning${index}`]}</div> }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-7">
                                        {availabe_days[index]['hour_6_morning'] === true && <>
                                            <div className="form-floating mb-4">
                                                <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_6_morning_fee: e.target.value} })}
                                                    value={availabe_days[index]['hour_6_morning_fee']}
                                                    type="number" 
                                                    className={`form-control outline-none ${allError[`availabe_days.${index}.hour_6_morning_fee`] ? 'is-invalid':''}`}
                                                    placeholder="6 hours morning fee"/>
                                                <label htmlFor={`availabe_days.${index}.hour_6_morning_fee`}>6 hours morning fee<span className="text-danger">*</span></label>
                                                {allError[`availabe_days.${index}.hour_6_morning_fee`] && <div className="invalid-tooltip">{allError[`availabe_days.${index}.hour_6_morning_fee`]}</div> }
                                            </div>
                                        </>}
                                    </div>

                                    <div className="col-12 col-md-7 col-lg-5">
                                        <div className="form-check form-switch mb-3">
                                            <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_6_evening: !availabe_days[index]['hour_6_evening']} })}
                                                value={availabe_days[index]['hour_6_evening']}
                                                checked={availabe_days[index]['hour_6_evening'] === true}
                                                className={`form-check-input outline-none ${allError[`hour_6_evening${index}`] ? 'is-invalid':''}`}
                                                type="checkbox" 
                                                role="switch" 
                                                id={`hour_6_evening${index}`}/>
                                            <label className="form-check-label" htmlFor={`hour_6_evening${index}`}>6 hours evening [start 12:00 PM]</label>
                                            {allError[`hour_6_evening${index}`] && <div className="invalid-tooltip">{allError[`hour_6_evening${index}`]}</div> }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-7">
                                        {availabe_days[index]['hour_6_evening'] === true && <>
                                            <div className="form-floating mb-4">
                                                <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_6_evening_fee: e.target.value} })}
                                                    value={availabe_days[index]['hour_6_evening_fee']}
                                                    type="number" 
                                                    className={`form-control outline-none ${allError[`availabe_days.${index}.hour_6_evening_fee`] ? 'is-invalid':''}`}
                                                    placeholder="6 hours evening fee"/>
                                                <label htmlFor={`availabe_days.${index}.hour_6_evening_fee`}>6 hours evening fee<span className="text-danger">*</span></label>
                                                {allError[`availabe_days.${index}.hour_6_evening_fee`] && <div className="invalid-tooltip">{allError[`availabe_days.${index}.hour_6_evening_fee`]}</div> }
                                            </div>
                                        </>}
                                    </div>

                                    <div className="col-12 col-md-7 col-lg-5">
                                        <div className="form-check form-switch mb-3">
                                            <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_12_day: !availabe_days[index]['hour_12_day']} })}
                                                value={availabe_days[index]['hour_12_day']}
                                                checked={availabe_days[index]['hour_12_day'] === true}
                                                className={`form-check-input outline-none ${allError[`hour_12_day${index}`] ? 'is-invalid':''}`}
                                                type="checkbox" 
                                                role="switch" 
                                                id={`hour_12_day${index}`}/>
                                            <label className="form-check-label" htmlFor={`hour_12_day${index}`}>12 hours day [start 06:00 AM]</label>
                                            {allError[`hour_12_day${index}`] && <div className="invalid-tooltip">{allError[`hour_12_day${index}`]}</div> }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-7">
                                        {availabe_days[index]['hour_12_day'] === true && <>
                                            <div className="form-floating mb-4">
                                                <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_12_day_fee: e.target.value} })}
                                                    value={availabe_days[index]['hour_12_day_fee']}
                                                    type="number" 
                                                    className={`form-control outline-none ${allError[`availabe_days.${index}.hour_12_day_fee`] ? 'is-invalid':''}`}
                                                    placeholder="12 hours day fee"/>
                                                <label htmlFor={`availabe_days.${index}.hour_12_day_fee`}>12 hours day fee<span className="text-danger">*</span></label>
                                                {allError[`availabe_days.${index}.hour_12_day_fee`] && <div className="invalid-tooltip">{allError[`availabe_days.${index}.hour_12_day_fee`]}</div> }
                                            </div>
                                        </>}
                                    </div>

                                    <div className="col-12 col-md-7 col-lg-5">
                                        <div className="form-check form-switch mb-3">
                                            <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_12_night: !availabe_days[index]['hour_12_night']} })}
                                                value={availabe_days[index]['hour_12_night']}
                                                checked={availabe_days[index]['hour_12_night'] === true}
                                                className={`form-check-input outline-none ${allError[`hour_12_night${index}`] ? 'is-invalid':''}`}
                                                type="checkbox" 
                                                role="switch" 
                                                id={`hour_12_night${index}`}/>
                                            <label className="form-check-label" htmlFor={`hour_12_night${index}`}>12 hours night [start 06:00 PM]</label>
                                            {allError[`hour_12_night${index}`] && <div className="invalid-tooltip">{allError[`hour_12_night${index}`]}</div> }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-7">
                                        {availabe_days[index]['hour_12_night'] === true && <>
                                            <div className="form-floating mb-4">
                                                <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_12_night_fee: e.target.value} })}
                                                    value={availabe_days[index]['hour_12_night_fee']}
                                                    type="number" 
                                                    className={`form-control outline-none ${allError[`availabe_days.${index}.hour_12_night_fee`] ? 'is-invalid':''}`}
                                                    placeholder="12 hours night fee"/>
                                                <label htmlFor={`availabe_days.${index}.hour_12_night_fee`}>12 hours night fee<span className="text-danger">*</span></label>
                                                {allError[`availabe_days.${index}.hour_12_night_fee`] && <div className="invalid-tooltip">{allError[`availabe_days.${index}.hour_12_night_fee`]}</div> }
                                            </div>
                                        </>}
                                    </div>

                                    <div className="col-12 col-md-7 col-lg-5">
                                        <div className="form-check form-switch mb-3">
                                            <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_24: !availabe_days[index]['hour_24']} })}
                                                value={availabe_days[index]['hour_24']}
                                                checked={availabe_days[index]['hour_24'] === true}
                                                className={`form-check-input outline-none ${allError[`hour_24${index}`] ? 'is-invalid':''}`}
                                                type="checkbox" 
                                                role="switch" 
                                                id={`hour_24${index}`}/>
                                            <label className="form-check-label" htmlFor={`hour_24${index}`}>24 hours [start 06:00 AM]</label>
                                            {allError[`hour_24${index}`] && <div className="invalid-tooltip">{allError[`hour_24${index}`]}</div> }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-7">
                                        {availabe_days[index]['hour_24'] === true && <>
                                            <div className="form-floating mb-4">
                                                <input onChange={e => setAvailabe_days({ ...availabe_days, [index]: {...availabe_days[index], hour_24_fee: e.target.value} })}
                                                    value={availabe_days[index]['hour_24_fee']}
                                                    type="number" 
                                                    className={`form-control outline-none ${allError[`availabe_days.${index}.hour_24_fee`] ? 'is-invalid':''}`}
                                                    placeholder="24 hours fee"/>
                                                <label htmlFor={`availabe_days.${index}.hour_24_fee`}>24 hours fee<span className="text-danger">*</span></label>
                                                {allError[`availabe_days.${index}.hour_24_fee`] && <div className="invalid-tooltip">{allError[`availabe_days.${index}.hour_24_fee`]}</div> }
                                            </div>
                                        </>}
                                    </div>
                                    {allError[`availabe_days.${index}`] && <div className="p-2 mx-3 w-75 alert alert-danger small">{allError[`availabe_days.${index}`]}</div> }
                                </div>
                            }
                        </fieldset>
                    ))}
                </div>

                <div className="p-2 p-md-3">
                    <div className="d-flex justify-content-end">
                        <button onClick={handleStep2Submit} type="submit" className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <div>Next <i className="bi bi-arrow-right"></i> </div> 
                                {isLoading && <BtnLoader/>}
                            </div>
                        </button>
                    </div>
                </div>
            
            </div>
        </section>
    )
}
