import useScrollTop from "../../hooks/useScrollTop";

export default function TermsAndConditions() {
    useScrollTop();

    return (
        <div className="container py-5">
            <div className="bg-white shadow-sm py-2 mb-3 border-start border-end border-4 border-main rounded">
                <div className="text-muted text-center fw-bold">Terms And Conditions</div>
            </div>

            <div className="bg-white rounded shadow-sm p-2 p-md-4">
                <div className="text-secondary">
                    <p>Duare Doctor as provided and operated by Duare Doctor Limited. aims to make healthcare more
                        accessible and affordable. Under our brand name we are currently providing our mobile and digital
                        health and wellness related products and services (“About Us). Your access or use of the Services and
                        related technology infrastructure or any part thereof for access to the Services are governed by these
                        Terms of Service (“Terms”). Please read these Terms carefully before registering with or using the
                        Services. If you do not agree to these Terms, you may not use the Services. These Terms, together with
                        the Privacy Policy of Duare doctor, constitute the entire agreement between you and GTT regarding our
                        Services.</p>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">GENERAL TERMS &amp; CONDITIONS</p>
                    <div className="text-muted">
                        <p>These Terms apply to all the Services that you may avail through our Duare doctor Website or Duare
                            doctor App or other means or platforms (e.g. mobile or digital platforms, websites etc.). When you use
                            the Duare doctor Website or Duare doctor App, you may access a range of Services we offer, and a
                            variety of services offered by third-party providers.</p>

                        <p>There are different variants of our Services with different features and levels of benefits. Specific terms
                            for specific features are mentioned below. The variant that you subscribe for may or may not have all
                            the features and levels of benefits. Accordingly, all specific terms for specific features may or may not
                            apply to you, depending on the variant that you have subscribed for. </p>

                        <p>By registering with or accessing or using our Services, you agree to be bound by these Terms and to use
                            our Services in accordance with the provisions herein stipulated.</p>

                        <p>If you are purchasing the Services and/or entering into these Terms on behalf of others (for example,
                            your customers, employees or other individuals), you represent that you have the authority to purchase
                            the Services on their behalf and to bind them to these Terms, as if the Terms were purchased and
                            accepted by them directly.</p>

                        <p>If your Services are purchased by or if you are registered as a user by your employer or any third party
                            (for example, bank, financial institution, payment service provider, supermarket etc.), then your
                            subsequent access to and/ or use of any feature of our Services shall be deemed as your full acceptance
                            of these Terms, as if the Services were purchased directly by you or as if you registered for the Services
                            on your own.</p>

                        <p>We reserve the right to make changes to these Terms from time to time. If we change these Terms we
                            will post the revised Terms on our website and update the “Last Revised” date on the website. We may
                            also (but shall not have the obligation to) notify you of revised Terms by SMS, email or by any other
                            means of written communication. You are responsible for being aware of any changes made to these
                            Terms. By continuing to access or use our Services after we post such changes on our website, you shall
                            be deemed to have agreed to the Terms, as modified. We also reserve the right to make changes to the
                            Services from time to time without any notification to you.</p>

                        <p>The agreement between you and Us in connection to the Services shall commence on the date you
                            accept these Terms (or when you are registered as a user) and shall continue in effect for as long as your
                            authorized use of the Services continues.</p>

                        <p>By subscribing for our Services, you acknowledge and accept that we may contact you from time to time
                            in relation to other services we may deliver (including without limitation any benefits, membership
                            status or relevant special offers).</p>

                        <p>Our use of any information you provide to Us hereunder is governed by the terms of our Privacy Policy,
                            which is incorporated by this reference into these Terms.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">THE SERVICES</p>

                    <div className="text-muted">
                        <h5 className="small text-info">Doctor Consultation</h5>

                        <p>The Doctor Consultation feature of our Services enables users to get connected with registered
                            medical practitioners (“Doctors”) on our specially designed mobile and digital platforms for medical
                            consultations via our audio, video and chat applications. Doctors are not employed by Us. Each Doctor is
                            an independent contractor and is either self-employed or employed by an independent third-party
                            company. We only provide the technology platform to connect you with Doctors, who shall be available
                            to provide you with certain non-emergency medical advice. A direct patient-physician relation will arise
                            between you and the Doctor. We are not liable or responsible to you or any third party in any way in
                            relation medical advice or service provided to you by any Doctor. Our role is simply to connect you to a
                            Doctor, without any liability or responsibility. Any advice or service that you take from a Doctor shall be
                            at your sole discretion and risk.</p>

                        <p>The medical advice you receive from your Doctors is not a substitute for other medical treatment you
                            may need. If you are experiencing a medical emergency, you must seek emergency medical help. For the
                            avoidance of doubt, our Services entail the provision of non-emergency medical advice only.</p>

                        <p>In some cases, our Services may not be the most appropriate for you. For example, certain health issues
                            may require an in-person procedure or highly specialized doctor which is not available through our
                            Services. You will be informed if our Services are unable to help with your particular issue.</p>
                    </div>

                    <div className="text-muted pt-3">
                        <h5 className="small text-info">Video Doctor Consultation</h5>

                        <p>Video Doctor Consultation is an extension of our Doctor Consultation feature. Through this feature,
                            users can get connected with and receive medical consultations from a General or Specialized Doctor
                            through video calls via our App and Web. Anyone who has access to our App or Web with internet will
                            be able to avail the service, upon making payment for it.</p>

                        <p>A high-speed internet connection is necessary for the Video Doctor Consultation feature. It shall be the
                            responsibility of the users to ensure, at their own costs, that they have internet access with sufficient
                            bandwidth for video calls for the Video Doctor Consultation sessions. If any user faces any connection-
                            related difficulty or misses any pre-scheduled Video Doctor Consultation session due to bad internet
                            speed or connection, We shall not be liable for the same.</p>

                        <p>Users will not be entitled to any refund for missing any pre-scheduled Live Doctor session.
                            Terms mentioned above for Doctor Consultation apply to Video Doctor Consultation to the extent
                            applicable. We are not liable or responsible to you or any third party in any way in relation to medical
                            advice or service provided to you via Video Doctor Consultation feature. Any advice or service that you
                            take under the Video Doctor Consultation feature shall be at your sole discretion and risk.</p>
                    </div>
                    
                    <div className="text-muted pt-3">
                        <h5 className="small text-info">Medicine Delivery</h5>

                        <p>Services under the Medicine Delivery feature are operated by third-party pharmacies (“Pharmacies”)
                            and not by Us. Such Pharmacies are responsible for receiving your order and payment and for delivering
                            the products to you. We are not liable or responsible to you or any third party in any way in relation to
                            the medicine delivery order or the pricing or the products or devices sold or supplied to you by them or
                            on their behalf (“Pharmacy Products”). Our role is simply to connect you to a Pharmacy, without any
                            liability or responsibility. We do not endorse or recommend the Pharmacies to you or Pharmacy
                            Products offered by them. Any order that you place with a Pharmacy shall be at your sole discretion and
                            risk without any representation, warranty or endorsement from Us. Any compliant or dispute that you
                            may have in relation to, including but not limited to, the medicine delivery order or Pharmacy Products,
                            etc. shall be raised directly to the Pharmacy.</p>

                        <p>We do not have control over and do not guarantee: the existence, availability, quality, quantity,
                            safety, legality or timely delivery of Pharmacy Products; the truth or accuracy of descriptions of
                            Pharmacy Products, the ability of Pharmacies to sell the Pharmacy Products; or that Pharmacies will</p>

                        <p>actually complete a transaction or provide a replacement or refund. To the fullest extent permitted by
                            applicable law, we disclaim all liabilities and responsibilities arising from or related to in any way to the
                            Pharmacy Products or Pharmacies, including (without limitation) regarding authenticity, reliability,
                            effectiveness, title, safety, legality or quality, quantity of Pharmacy Products or time taken by
                            Pharmacies or delays or failures by Pharmacies in delivering the Pharmacy Products.</p>

                        <p>Orders will be confirmed by and Pharmacy Products will be delivered directly by Pharmacies.</p>

                        <p>This Medicine Delivery feature is not intended for and must not be used or relied on in case of medical emergencies.</p>
                    </div>
                    
                    <div className="text-muted pt-3">
                        <h5 className="small text-info">Diagnostics &amp; Tests</h5>

                        <p>Eligible users may be able to avail services relating to diagnostics and tests from third-party
                            diagnostic centers, laboratories, clinics and hospitals that are enrolled with us (“Diagnostic Service
                            Providers”). Such Diagnostic Service Providers are responsible for collecting your samples and payments,
                            and for delivering the services and reports to you and for offering discounts (if any). We are not liable or
                            responsible to you or any third party in any way in relation to the services or discounts provided by
                            Diagnostic Service Providers (“Diagnostic Services”). Our role is simply to connect you to Diagnostic
                            Service Providers, without any liability or responsibility. Any Diagnostic Service that you take from a
                            Diagnostic Service Provider shall be at your sole discretion and risk. We do not endorse or recommend
                            Diagnostic Service Providers or any of their Diagnostic Services to you. Diagnostic Services that you may
                            take from them shall be based on your sole discretion and judgment, without any representation,
                            warranty or endorsement from Us. Any complaint or dispute that you may have in relation to Diagnostic
                            Services or discounts shall be raised directly to the Diagnostic Service Providers.</p>

                        <p>We do not control and do not guarantee: the availability, quality, quantity, safety, legality or timely
                            delivery of services by Diagnostic Service Providers; the truth or accuracy of descriptions of Diagnostic
                            Service, the ability of Diagnostic Service Providers to offer Diagnostic Services. To the fullest extent
                            permitted by applicable law, we disclaim all liabilities and responsibilities arising from or related to in
                            any way to the Diagnostic Services or Diagnostic Service Providers, including (without limitation)
                            regarding authenticity, reliability, effectiveness, safety or quality of Diagnostic Service or time taken by
                            Diagnostic Service Providers or delays or failures by them in delivering the Diagnostic Services.
                            If Doctors provide any medical advice to users based on reports from Diagnostic Service Providers and
                            the reports were inaccurate or incorrect or false negative or false positive, Doctors or Us, in no way</p>

                        <p>whatsoever, shall be liable or responsible for such medical advice or consequences of such medical advice.</p>
                        
                        <p>Further details regarding diagnostics and tests may be published by us on DH website.</p>
                    </div>
                    
                    <div className="text-muted pt-3">
                        <h5 className="small text-info">Discounts</h5>
                        
                        <p>Eligible users may be able to avail discounts from various hospitals, clinics, laboratories,
                            pharmacies and other businesses that have agreed to offer discounts to our users (“Discount Partners”).
                            Such Discount Partners are responsible for supplying their products or services to you. Any product or
                            service that you take from a Discount Partners shall be at your sole discretion and risk. We do not
                            endorse or recommend Discount Partners or any of their products or services to you. Products or
                            services that you may take from them shall be based on your sole discretion and judgment, without any
                            representation, warranty or endorsement from Us. Any complaint or dispute that you may have in
                            relation to their products, services or discounts shall be raised directly to the Discount Partners. Further
                            details regarding Discount Partners may be published by us on DH website.</p>
                    </div>
                    
                    <div className="text-muted pt-3">
                        <h5 className="small text-info">Appointment Booking</h5>

                        <p>Eligible users may be able to book appointments with medical practitioners who have agreed to be
                            enrolled with us (“Partner Doctors”). Such Partner Doctors are responsible for providing their services
                            to you. Any service or advice that you take from a Partner Doctor shall be at your sole discretion and
                            risk. We do not endorse or recommend Partner Doctors or any of their services to you. Services that you
                            may take from them shall be based on your sole discretion and judgment, without any representation,
                            warranty or endorsement from Us. Any complaint or dispute that you may have in relation to their
                            services shall be raised directly to the Partner Doctors. Further details regarding Partner Doctors may be
                            published by us on DH website.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">REGISTRATION</p>
                    <div className="text-muted">
                        <p>If you purchase Services directly from Us, your registration for subscription to our Services will be
                            subject to the following procedure, unless a different procedure is introduced by us. To use our Services,
                            you may be first required to register with Us and become a Member. You will be able to register for
                            Services via the web, through USSD, IVR or by calling Us. When you register, you may be required to
                            provide certain information. For example, when you set up an account with us, you will be required to 
                            enter your MSISDN (mobile number) and set a password. Other information may also be requested (e.g.
                            National ID number, email address, name, date of birth, contact information, credit card information
                            and medical history, as applicable) and upon completion of registration, such information will be used
                            by Us (and Our affiliates and service providers as the case may be) in accordance with our Privacy Policy.</p>
                            
                        <p>If your Services are purchased by, or if you are registered as a user by your employer or any third party
                            (for example, bank, financial institution, payment service provider, supermarket etc.), in that case, your
                            subscription to our Services will subject to the following procedure, unless a different procedure is
                            introduced by us. Upon receipt of your MSISDN as shared with Us by your employer or any third party
                            (for example, back, financial institution, payment service provider, supermarket etc.) with your prior
                            consent, we will register you with us and activate an account against your MSISDN. For completion of
                            the registration process, you may be required to provide certain information, for example, National ID
                            number, email address, name, date of birth, contact information, credit card information and medical
                            history, as applicable and upon completion of registration, such information will be used by Us (and Our
                            affiliates and service providers as the case may be) in accordance with our Privacy Policy.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">PRICE &amp; PAYMENT</p>
                    <div className="text-muted">
                        <p>If you purchase Services directly from Us, in that case, you will make payment for our Services as per below-</p>
                    
                        <ol className=" text-justify font-weight-light">
                            <li>You agree to pay fees for the right to use the Services at the current rates set forth on our website
                                from time to time or as otherwise provided to you by Us. Fees are subject to change, and any changes
                                will be provided on our website or as otherwise communicated to you by Us. It is your responsibility to
                                remain informed about the current payment rates from time to time in respect of the Services. Unless
                                otherwise agreed to by Us, all fees paid are non-refundable.</li>

                            <li>The fees (and any other charges) relating to the Services will be payable by you in Bangladeshi Taka
                                using mobile financial services, debits cards, credit cards or other available payment methods.</li>

                            <li>Your payment transactions will be executed by an appointed &quot;Payment Gateway&quot;. The Payment
                                Gateway is a separate entity that will collect your payment and forward it to the processing bank for
                                further execution. The Payment Gateway will be responsible for executing all transactions with your
                                bank or MFS provider. All information related to your MFS, Card/Bank Account will be exclusively dealt
                                with by the Payment Gateway and the Bank. We will not ask for your payment information on our site
                                nor do we retain your payment information. The use of your MFS, credit/debit card or account to
                                account transfer services is governed by the agreed terms and conditions between you and your bank.
                                For any unsuccessful payment or other payment related queries, you must contact your Card issuing
                                Bank or the Bank in which you maintain your account. Once you confirm your payment, you will not be
                                able to cancel your order. You agree that we are authorized to bill your account and charge your
                                designated method of payment when payments are due, including any sales or other applicable tax that
                                may be required.</li>

                            <li>There can be a situation where some technical problems may occur which are completely
                                unintentional and due to which your payment may be deducted but we may not receive confirmation
                                and consequently delivery or activation of your desired service may be delayed.</li>
                        </ol>

                        <p>If your Services are purchased by, or if you are registered as a user by, your employer or any third
                            party (for example, bank, financial institution, payment service provider, supermarket etc.), in that case
                            the following processes and terms will apply, unless a different process and/or term has been agreed
                            with the third party-</p>

                        <ol className=" text-justify font-weight-light">
                            <li>We shall issue invoices to your employer or the third party for our Services to you and
                                payments shall be payable by them to Us as per terms and conditions as agreed between Us
                                and them. We reserve the right to suspend and/or terminate our Services to you with
                                immediate effect in case your employer or the third party fails to make payments to us as
                                per our agreement with them or is in breach of any provision of the agreement or the
                                agreement gets expired or terminated for any reason.</li>
                        </ol>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">CANCELLATION &amp; TERMINATION</p>
                    <div className="text-muted">
                        <p>For subscription based Services, you may unsubscribe from our Services after a minimum subscription
                            period of one month, at any time for any reason by sending Us an email which indicates your intention
                            to unsubscribe from our Services or by calling Us or by visiting our USSD menu. You must subscribe to
                            the Services for a minimum period of one month, such period starts from the date that you first
                            subscribe to our Services. You understand that termination of your subscription to the Services may
                            involve erasure/deletion of your information from our databases. However, we may retain your
                            information in accordance with applicable laws and professional standards and as set out in the Privacy
                            Policy. You agree that we will not be liable to you or any third party for any termination of your access to
                            our Services or erasure, deletion or removal of your information from our databases following
                            termination of your subscription to the Services.</p>

                        <p>We reserve the right to modify or discontinue (temporarily or permanently) our Services, or any portion
                            thereof, with or without notice, whether due to regulatory reasons. We will not be liable to you or any
                            third party, in any manner whatsoever, should we exercise such right. In such a case, our aggregate and
                            maximum liability to you shall be to refund on a prorated basis the subscription fees that you have paid.
                            al shall be subject to our sole discretion and, to the extent permitted by law, we reserve the right to
                            postpone or reject any such auto-renewal for any reason and without any liability.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">DISCLAIMER &amp; LIMITATION OF LIABILITY</p>
                    <div className="text-muted">
                        <p>Our Services are provided on an “as is” basis. Your access to or use of our Services is voluntary and at
                            your sole discretion. To the fullest extent permissible by applicable law, we do not make any express or
                            implied warranties, representations and/or endorsements of any kind (including, without limitation,
                            warranties of title or non-infringement, or any warranties of merchantability or fitness for a particular
                            purpose) with regard to our Services, or with respect to any information, product, service, merchandise
                            or other material provided on or through our Services (including any consultations or other services you
                            may receive from Doctors and/ or our Third Party Partners). We do not warrant that the Services will
                            function without delays, disruptions, interferences, imperfections, or any adverse incident.</p>
                            
                        <p>Whilst we use reasonable endeavors to engage with reputable Doctors, Insurance Providers,
                            Pharmacies, Diagnostic Service Providers, Discount Partners, Partner Doctors and other third parties
                            (collectively “Third Parties”), we do not warrant or guarantee the accuracy, completeness, reliability,
                            timeliness, legality or usefulness of their products or services. We are not liable or responsible to you or
                            any third party in any way in relation to the products, services or discounts provided by Third Parties.
                            Our role is simply to connect you to Third Parties, without any liability or responsibility. We do not
                            endorse or recommend Third Parties or any of their products or services to you. Any product or service
                            that you take from Third Parties shall be at your sole discretion and risk, without any representation,
                            warranty or endorsement from Us. Any complaint or dispute that you may have in relation to their
                            products, services or discounts shall be raised directly to them by you.</p>

                        <p>In no event will We, our parents, subsidiaries, affiliates, licensors, and/or suppliers, and/or officers,
                            directors, employees, consultants, agents and/or any other mobile service providers, through whom We
                            provide the Services, be liable to you or any third party for any direct, indirect, punitive, exemplary,
                            incidental, special or consequential damages, whether in contract, tort (including negligence), or
                            otherwise, or the use of, or the inability to use, our Services, including, without limitation, any
                            information made available through our Services or any services performed by any Third Parties you
                            connect with via our Services (including claims of medical malpractice or negligence), even if we have
                            been advised of the possibility of such damages.</p>

                        <p>If you are dissatisfied with any of our materials, products, or services, or with any of the provisions of
                            these Terms, your sole and exclusive remedy is to discontinue using the Services.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">INDEMNIFICATION</p>
                    <div className="text-muted">
                        <p>You agree to indemnify and hold Us, our parents, subsidiaries, affiliates, licensors, suppliers, and/or
                            officers, directors, employees, consultants, agents, and/or any other mobile service providers, through
                            whom we provide the Services, harmless from and against any and all third-party claims, liabilities,
                            damages, losses, costs, expenses and fees (including reasonable attorneys’ fees and court costs) that We
                            or such parties may incur as a result of or arising from your use of our Services, your violation of these.</p>

                        <p>Terms, or your violation of any rights of any other person or entity. We reserve the right to control the
                            defense of any claim for which we are entitled to indemnification, and you agree to provide us with such
                            cooperation as is reasonably requested by us.</p>
                    </div>
                </div>

                <div className="pt-3">
                    <p className="fw-bold text-secondary">FORCE MAJEURE</p>
                    <div className="text-muted">
                        <p>We shall not be liable for any failure of or delay in the performance of the Services provided that such
                            failure or delay is due to causes beyond our reasonable control, including but not limited to lack or
                            shortage of power supply, failures of electronic communications, unavailability of mobile network
                            connection, unavailability or low speed of internet connection, acts of God, war, flood, political unrest,
                            riots, strikes or labor disputes, embargoes, government orders, regulatory restrictions or objections, or
                            any other event which is beyond our reasonable control.</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

