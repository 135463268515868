import { useHistory } from "react-router-dom";
import toast from "../../../utlities/toast";

export default function EmailAlreadyVerifyed() {

    const history = useHistory();
    toast('Email already verified!', 'warning');
    history.push('/');

    return (
        <></>
    )
}
