export default function InputSelect({
    data, 
    label='', 
    htmlFor='', 
    labelIsTop=false,
    autoFocus=false, 
    required=false,
    error,
    groupClass='form-floating mb-4',
    controlClass='form-select outline-none',
    labelClass='',
    errorClass='invalid-tooltip',
    elements,
}) {

    return (
        <div className={groupClass}>
            {labelIsTop === true && <label className={labelClass} htmlFor={htmlFor}>{label}{required === true && <span className="text-danger">*</span>}</label>}
            
            <select {...data} className={`${controlClass} ${error ? 'is-invalid':''}`} autoFocus={autoFocus}>
                {elements}
            </select>
            
            {labelIsTop === false && <label className={labelClass} htmlFor={htmlFor}>{label}{required === true && <span className="text-danger">*</span>}</label>}
            {error && <div className={errorClass}>{error}</div> }
        </div>
    )
}
