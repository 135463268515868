import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToCartBtn from "../../components/AddToCartBtn";
import BoxEmpty from "../../components/BoxEmpty";
import useScrollPosition from "../../hooks/useScrollPosition";
import toast from "../../utlities/toast";

export default function Wishlist() {
    useScrollPosition('my-position', 150);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if(localStorage.getItem('wishlist')){
            setItems(JSON.parse(localStorage.getItem('wishlist')));
        }
    }, []);

    function handleRemoveFromVavorite(e, id){
        e.preventDefault();
        if(localStorage.getItem('wishlist')){
            let storage_items = JSON.parse(localStorage.getItem('wishlist'));
            let items = storage_items.filter(item => item.id !== id );
            setItems(items);
            localStorage.setItem('wishlist', JSON.stringify(items));
            toast('Item successfully remove from your favorite list', 'success');
        }
        else{
            toast('Something Wrong', 'error');
        }
    }


    return (
        <div id="my-position">
            <div className="p-2 bg-white shadow-sm border-top rounded-top border-3 border-success">
                <h6 className="m-0">My wish list</h6>
            </div>
            <div className="mt-3 p-2 p-md-3 bg-white shadow-sm">
                {items.length ? items.map(item =>(
                    <div key={item.id} className="border-bottom py-2">
                        <div className="row justify-content-bet">
                            <div className="col-4 col-md-3 col-xl-2">
                                <img  className=" img-fluid" src={item.image ? process.env.REACT_APP_STORAGE + item.image?.image : process.env.REACT_APP_STORAGE + item.images[0]?.image} alt="" />
                            </div>
                            <div className="col-8 col-md-8 col-xl-10">
                                <div className="text-secondary ">
                                    <Link className=" link-info text-decoration-none" to={{pathname: '/product/' + item.slug}}>{item.title}</Link>
                                    {item.author && <div className="d-flex">By <p className="small text-success px-2">{item.author.name}</p></div> }
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <div className="text-dark"><span className="text-dark"><i className="bi bi-currency-dollar"></i></span>{item.final_price}</div>
                                        {item.discount !== 0 && <div className="text-decoration-line-through text-warning"><span className="text-dark"><i className="bi bi-currency-dollar"></i></span>{item.price}</div>}
                                    </div>
                                    <div className="">
                                        <button onClick={(e) => handleRemoveFromVavorite(e, item.id)} className="btn btn-sm btn-danger outline-0"><i className="bi bi-trash"></i></button>
                                    </div>
                                    <div className="">
                                        <AddToCartBtn classList={'btn btn-sm btn-success outline-0'} product={item}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                    : 
                    <BoxEmpty/>   
                }
            </div>

        </div>
    )
}
