import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BtnLoader from "../../components/loader/btn_loader/BtnLoader";
import api from "../../services/api";
import useFormInput from "../../hooks/useFormInput";
import toast from "../../utlities/toast";
import useScrollTop from "../../hooks/useScrollTop";

export default function PasswordReset() {
    useScrollTop();
    
    const history = useHistory();

    const password              = useFormInput('');
    const password_confirmation = useFormInput('');

    const [passwordError, setPasswordError] = useState(null);
    const [isLoading, setIsLoading]         = useState(false);

    let query = new URLSearchParams(useLocation().search);
    const queryToken = query.get("token");
    const queryEmail = query.get("email");

    
    useEffect(() => {
        if(!queryToken || !queryEmail){
            history.push('/');
        }
    }, [history,queryToken, queryEmail]);


    function handlePasswordReset(e){
        e.preventDefault();
        setIsLoading(true);

        const body = {
            token                 : queryToken,
            email                 : queryEmail,
            password              : password.value,
            password_confirmation : password_confirmation.value
        }

        api().post('/api/auth/reset-password', body)
        .then(response=> {
            setIsLoading(false);
            setPasswordError(null);

            if(response.data.status === false){
                toast(response.data.message, 'error');
            }
            else {
                toast(response.data.message, 'success');
                history.push('/login');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            setIsLoading(false);
            toast(error.message, 'error');

            if(error.errors){
                error.errors.password ? setPasswordError(error.errors.password[0]) : setPasswordError(null);
            }
            else {
                setPasswordError(null);
            }
        });
    }

    return (
        <div className="py-3">
            <div className="d-flex justify-content-center">
                <div className="col-11 col-md-8 col-lg-6">
                    <div className="bg-white p-2 p-md-3 shadow rounded">
                        <h5 className="text-center text-secondary border-3 border-bottom border-master p-2">Reset Password</h5>
                        
                        <form onSubmit={handlePasswordReset}>

                            <div className="input-group has-validation mb-4">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-file-lock bi-m text-secondary"></i>
                                </span>
                                <input {...password}
                                    type="password" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none ${passwordError ? 'is-invalid border-danger' : ''}`} 
                                    id="password" 
                                    name="password"  
                                    placeholder="Password" 
                                    autoComplete="off"/>
                                {passwordError && <div className="invalid-tooltip">{passwordError}</div>}
                            </div>

                            <div className="input-group has-validation mb-3">
                                <span className="input-group-text border-0 border border-2 border-bottom rounded-0 bg-white">
                                    <i className="bi bi-file-lock bi-m text-secondary"></i>
                                    </span>
                                <input {...password_confirmation}
                                    type="password" 
                                    className={`form-control border-0 border border-2 border-bottom rounded-0 outline-none`} 
                                    id="password_confirmation" 
                                    name="password_confirmation"  
                                    placeholder="Confirm Password" 
                                    autoComplete="off"/>
                            </div>

                            <div className="d-grid">
                                <button className={`btn btn-success btn-outline-none ${isLoading ? 'disabled' : ''}`} type="submit">
                                    <div className="d-flex justify-content-center">
                                        <div>Reset </div> 
                                        {isLoading && <BtnLoader/>}
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
