import { useDispatch } from "react-redux";
import { add_to_cart } from "../slice/cartSlice";

export default function AddToCartBtn({product, classList}) {

    const dispatch = useDispatch();

    function handleAddToCart(e){
        e.preventDefault();
        dispatch(add_to_cart(product));
    }

    return (
        <button onClick={handleAddToCart} className={classList}><span className=" d-none d-sm-inline-block small">Add to</span> <i className="bi bi-cart4"></i></button>
    )
}
