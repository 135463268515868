import useScrollTop from "../hooks/useScrollTop";
import ItemLoader from "./loader/item_loder/ItemLoader";

export default function DoctorReviews({doctor_id}) {
    useScrollTop();

    const isLoading = false;
    const reviews =[
        {id: 1, name: 'Mr. Jon Dow', avatar: '../images/defaults/avatar.png', date: '02-11-2020', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et! adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et!'},
        {id: 2, name: 'Mr. Jon Dow', avatar: '../images/defaults/avatar.png', date: '02-11-2020', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et! adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et!'},
        {id: 3, name: 'Mr. Jon Dow', avatar: '../images/defaults/avatar.png', date: '02-11-2020', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et! adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et!'},
        {id: 4, name: 'Mr. Jon Dow', avatar: '../images/defaults/avatar.png', date: '02-11-2020', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et! adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et!'},
        {id: 5, name: 'Mr. Jon Dow', avatar: '../images/defaults/avatar.png', date: '02-11-2020', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et! adipisicing elit. Cumque voluptatem praesentium repudiandae. Ex sequi reprehenderit inventore voluptates, eum error et!'},
    ];


    return (
        <section className="bg-light py-4 px-1">
            <div className="container bg-white shadow-sm p-2 fw-bold mb-1">Reviews</div>
            <div className="container p-0">
                <div className="bg-white shadow-sm">
                    {isLoading &&  isLoading && Array.from(Array(5)).map((item, index) => (
                        <div key={index} className="p-2 bg-white">
                            <ItemLoader classList="p-5" />
                        </div>
                    ))}
                    {reviews?.map(review => (
                        <div key={review.id} className="py-2">
                            <div className="flex d-md-flex justify-content-start shadow-sm p-2">
                                <div className="py-2">
                                    <img className="img-fluid img-thumbnail p-1" width="100px" height="100px" src={process.env.REACT_APP_STORAGE + review.avatar} alt={review.name} />
                                </div>
                                <div className="p-2 w-100">
                                    <h6 className="fw-bold text-dark my-0">{review.name}</h6>
                                    <p className="text-dark small fw-light mb-1">{review.date}</p>
                                    <p className="small text-seconday my-0">{review.body}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="py-3 text-center">
                        <div className="btn btn-danger">Load more</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
