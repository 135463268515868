import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

export default function DropZone({
    classList = 'bg-light p-2 text-center border-dashed rounded h-dropzone', 
    text, 
    isPreview, 
    fileClass, 
    isInvalid, 
    errorText, 
    callbackSetFiles,
    isMulti = false,
    maxFiles = 1,
}) {

    const [files, setFiles] = useState([]);
    const [hasRejection, setHasRejection] = useState(false);

    const {getRootProps, getInputProps, isDragReject} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles, fileRejections) => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            callbackSetFiles(acceptedFiles);
            
            if(fileRejections && fileRejections.length > 0){
                setHasRejection(true);
            }
            else {
                setHasRejection(false);
            }
        },
        maxFiles: maxFiles,
    });
    

    const thumbs = files.map(file => (
        <div key={file.name} >
            <img className="img-fluid img-thumbnail d-block" alt="drop_image"
                src={file.preview}
            />
        </div>
    ));
    

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    return (
        <div>
            <div {...getRootProps({className: isDragReject ? classList + ' border-danger' : classList})} type="button" >
                <input {...getInputProps({multiple: isMulti})} className={`${isInvalid ? 'is-invalid':''}`} />
                <div className="text-center text-secondary"><i className="bi bi-cloud-arrow-up-fill icon-huge"></i></div>
                <p className="text-muted mb-1 mb-lg-2 small">{text}</p>
                {isInvalid && <div className="invalid-feedback">{errorText}</div>}
                {(isDragReject || hasRejection) && <p className="m-0 small text-danger">Not acceptable, Select maximum {maxFiles} file{maxFiles > 1 ? 's':''}</p>}
            </div>
            {isPreview &&
                <aside className={`pt-2 ${fileClass}`}>
                    {thumbs}
                </aside>
            }
        </div>
    );
}
