import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function QualificationEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);
    
    const [qualifications, setQualifications] = useState([]);

    useEffect(() => {
        doctor?.qualifications?.forEach(qualification => {
            setQualifications(prevQualification => [...prevQualification, {degree: qualification.degree, institution_name: qualification.institution_name, institution_location: qualification.institution_location}]);
        });
        return () => {setQualifications([]);}
    }, [doctor]);

    function handleRemoveQualification(e, index){
        e.preventDefault();
        if(qualifications.length !== 1){
            const list = [...qualifications];
            list.splice(index, 1);
            setQualifications(list);
        }
    }

    function handleAddQualification(e){
        e.preventDefault();
        if(qualifications.length < 5){
            setQualifications(prevQualification => [...prevQualification, {degree: "", institution_name: "", institution_location: ""},]);
        }
        else Swal.fire('Do not add more then 5 qualifications','','warning')
    }

    function handleQualificationChange(e, index){
        const { name, value } = e.target;
        const list = [...qualifications];
        list[index][name] = value;
        setQualifications(list);
    }

    function handleQualificationSubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            qualifications : qualifications,
        };

        api(token).post('/api/doctor/update/qualification', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor && response.data.status === 'success'){
                updateDoctor(response.data.doctor);
                toast('Qualification successfully updated', 'success');
                setAllError([]);
                cancelEdit(false);
            }
            else{
                toast('Something wrong! Please try again.', 'error');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleQualificationSubmit}>
                    {qualifications.map((qualification, index) => (
                        <div key={index} className="row">
                            <div className="col-lg-3">
                                <div className="form-floating my-4">
                                    <input onChange={e => handleQualificationChange(e, index)}
                                        value={qualification.degree}
                                        name="degree"
                                        type="text" 
                                        className={`form-control outline-none ${allError['qualifications.' + index + '.degree'] ? 'is-invalid':''}`}
                                        placeholder="Institution Location"/>
                                    <label htmlFor="degree">Degree<span className="text-danger">*</span></label>
                                    {allError['qualifications.' + index + '.degree'] && <div className="invalid-tooltip">{allError['qualifications.' + index + '.degree']}</div> }
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-floating my-4">
                                    <input onChange={e => handleQualificationChange(e, index)}
                                        value={qualification.institution_name}
                                        name="institution_name"
                                        type="text" 
                                        className={`form-control outline-none ${allError['qualifications.' + index + '.institution_name'] ? 'is-invalid':''}`}
                                        placeholder="Institution Name"/>
                                    <label htmlFor="institution_name">Institution Name<span className="text-danger">*</span></label>
                                    {allError['qualifications.' + index + '.institution_name'] && <div className="invalid-tooltip">{allError['qualifications.' + index + '.institution_name']}</div> }
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-floating my-4">
                                    <input onChange={e => handleQualificationChange(e, index)}
                                        value={qualification.institution_location}
                                        name="institution_location"
                                        type="text" 
                                        className={`form-control outline-none ${allError['qualifications.' + index + '.institution_location'] ? 'is-invalid':''}`}
                                        placeholder="Degree"/>
                                    <label htmlFor="institution_location">Institution Location<span className="text-danger">*</span></label>
                                    {allError['qualifications.' + index + '.institution_location'] && <div className="invalid-tooltip">{allError['qualifications.' + index + '.institution_location']}</div> }
                                </div>
                            </div>
                            <div className="col-lg-1 align-self-center px-0">
                                <div className="my-2 d-flex justify-content-around">
                                    {qualifications.length !== 1 && 
                                        <button onClick={e => handleRemoveQualification(e, index)} className="btn btn-sm btn-danger btn-outline-none"><i className="bi bi-trash"></i></button>
                                    }
                                    {qualifications.length === index+1 && 
                                        <button onClick={handleAddQualification} className="btn btn-sm btn-secondary btn-outline-none"><i className="bi bi-plus-circle"></i></button>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}