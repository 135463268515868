import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/form/Input";
import SubmitBtn from "../../../../components/form/SubmitBtn";
import useFormInput from "../../../../hooks/useFormInput";
import api from "../../../../services/api";
import { selectToken } from "../../../../slice/userSlice";
import toast from "../../../../utlities/toast";

export default function AvailabilityEdit({doctor, updateDoctor ,cancelEdit}) {

    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [allError, setAllError] = useState([]);
    
    const time_from = useFormInput(doctor.time_from ? moment(doctor.time_from, 'hh:mm:ss').format('hh:mm') : '');
    const time_to   = useFormInput(doctor.time_to ? moment(doctor.time_to, 'hh:mm:ss').format('hh:mm') : '');

    const [availabe_days, setAvailabe_days] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
    });

    const wdays =[
        {id: 1, short_title: 'Sun'},
        {id: 2, short_title: 'Mon'},
        {id: 3, short_title: 'Tue'},
        {id: 4, short_title: 'Wed'},
        {id: 5, short_title: 'Thu'},
        {id: 6, short_title: 'Fri'},
        {id: 7, short_title: 'Sat'},
    ];

    useEffect(() => {
        setAvailabe_days({
            1: doctor?.wdays?.find(element => parseInt(element.id) === 1) ? true : false,
            2: doctor?.wdays?.find(element => parseInt(element.id) === 2) ? true : false,
            3: doctor?.wdays?.find(element => parseInt(element.id) === 3) ? true : false,
            4: doctor?.wdays?.find(element => parseInt(element.id) === 4) ? true : false,
            5: doctor?.wdays?.find(element => parseInt(element.id) === 5) ? true : false,
            6: doctor?.wdays?.find(element => parseInt(element.id) === 6) ? true : false,
            7: doctor?.wdays?.find(element => parseInt(element.id) === 7) ? true : false,
        });
        return () => { }
    }, [doctor]);

    function handleAvailabilitySubmit(e){
        e.preventDefault();
        setIsLoading(true);
        
        const body = {
            availabe_days: availabe_days,
            time_from    : time_from.value,
            time_to      : time_to.value,
        };

        api(token).post('/api/doctor/update/availability', body)
        .then(response =>{
            setIsLoading(false);
            if(response.data.doctor && response.data.status === 'success'){
                updateDoctor(response.data.doctor);
                toast('Availability successfully updated', 'success');
                setAllError([]);
                cancelEdit(false);
            }
            else{
                toast('Something wrong! Please try again.', 'error');
            }
        })
        .catch(err => {
            const error = err.response ? err.response.data : err;
            
            setIsLoading(false);
            toast(error.message, 'error')

            if(error.errors) setAllError(error.errors);
            else setAllError([]);
        });
    }

    return (
        <>
            <div className="py-2 deb_zoom_in">
                <form onSubmit={handleAvailabilitySubmit}>
                    <div className="p-2 p-md-3">
                        <div className="row justify-content-center g-2 g-md-3">
                            {wdays.map((wday, index) => (
                                <div key={wday.id} className="col-3 col-md-2 col-lg-1">
                                    <div className="checkbox-input-group">
                                        <input
                                            checked={availabe_days[index+1] === true}
                                            onChange={() => setAvailabe_days({ ...availabe_days, [index+1]: !availabe_days[index+1] })}
                                            value={availabe_days[index+1] === true}
                                            type="checkbox"
                                            id={wday.short_title}
                                        />
                                        <div className="bg-light shadow-sm rounded text-center fw-bold  p-2">
                                            <span>{wday.short_title}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {allError['availabe_days'] && <div className="alert alert-danger my-2 py-1 text-center">{allError['availabe_days']}</div>}
                    </div>

                    <div className="py-3">
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <Input data={time_from} error={allError['time_from']} label="From" htmlFor="time_from" type="time" required={true}/>
                            </div>
                            
                            <div className="col-lg-6">
                                <Input data={time_to} error={allError['time_to']} label="To" htmlFor="time_to" type="time" required={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="button" onClick={() => cancelEdit(false)} className="btn btn-sm btn-dark btn-outline-none">Cancel</button>
                        <SubmitBtn isLoading={isLoading} className="btn-master px-3 py-1" title="Save"/>
                    </div>
                </form>
            </div>
        </>
    )
}